import * as React from 'react';
import { useParams } from 'estafette-router';
import { Public } from 'features/public/organisms';
import { Restore, ConfirmRestore } from 'features/public/templates';
import { Content } from 'features/public/atoms';

export const RestorePage: React.FC = () => {
  const { token } = useParams();

  return (
    <Public>
      <Content className="container mini">{token ? <ConfirmRestore token={token} /> : <Restore />}</Content>
    </Public>
  );
};
