import * as React from 'react';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useHistory, Link } from 'estafette-router';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { NomenclaturesContext, UserContext } from 'contexts';
import { useModules, useStateHandlers } from 'hooks';
import { Save } from 'libs/http/api/index.types';
import { sessions, users } from 'libs/http/api';
import { parseQuery } from 'libs/object';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { dateFormat, format } from 'libs/date';
import { Input, Button, Checkbox, Alert, ModalFooterButton, Icon, Modal } from 'ui/atoms';
import { Expande, Expanded } from 'ui/atoms/Table/Expanded';
import { InputPhone } from 'ui/molecules';
import { Form, FormItem, InputPassword } from 'ui/organisms';

import './Login.scss';

interface UsersLogin {
  refresh: string;
  access: string;
  message?: string;
}

const phoneUtil = PhoneNumberUtil.getInstance();

export const Login: React.FC = () => {
  const { t } = useIntl();
  const { push, location } = useHistory();
  const { session } = parseQuery<{ session?: number }>(location.search);
  const { request, data, errors, loading } = useRequest<UsersLogin>({ data: {} });
  const { request: requestRegister, loading: loadingRegister } = useRequest<Save>();
  const { request: requestSession, data: dataSession, loading: loadingSession } = useRequest<Session>({ data: {} });
  const { indexModule } = useModules();
  const { onVerifyUser, userData, onChangeLang } = React.useContext(UserContext);
  const { countries, getCountries, geoip, getGeoip } = React.useContext(NomenclaturesContext);

  const [country, setCountry] = React.useState<number>();
  const [registerToSession, setRegisterToSession] = React.useState(false);

  const [use, setUse] = useStateHandlers({
    type: 'email',
    recommend: false,
  });

  const [state, setState] = useStateHandlers({
    email: '',
    password: '',
    remember: false,
  });

  const [isEmail, isPhone] = React.useMemo(
    () => [
      !!state.email.match(/@/),
      state.email.length && (state.email[0] === '+' || !isNaN(parseInt(state.email[0]))),
    ],
    [state.email],
  );

  const handleSubmit = (): Promise<UsersLogin> => {
    let username = state.email;

    if (!isEmail) {
      username = username.replace('+', '');

      try {
        const values = (phoneUtil.parseAndKeepRawInput(`+${username}`) as any).values_;

        username = `${values[1]}${values[2]}`;
      } catch {
        //
      }
    }

    return request(users.login.action({ username, password: state.password }));
  };

  const onUseClick = (): void => {
    setState({ email: '' });

    setUse((prevState) => ({ ...prevState, type: prevState.type === 'email' ? 'phone' : 'email', recommend: true }));
  };

  React.useEffect(() => {
    getGeoip();
    getCountries();
    return () => {
      sessions.load.cancel();
      sessions.register.cancel();
      users.login.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (geoip.country_code2) {
      setCountry(countries?.filter((item) => item.code === geoip.country_code2.toLowerCase())[0]?.value);
    }
  }, [geoip, countries]);

  React.useEffect(() => {
    if (isPhone && use.type !== 'phone' && !use.recommend) {
      setState({ email: '' });
      setUse({ type: 'phone' });
    }
  }, [isPhone]);

  React.useEffect(() => {
    if (data.access) {
      onVerifyUser({ token: data.access, refresh: data.refresh, remember: state.remember });
    }
  }, [data]);

  React.useEffect(() => {
    if (userData.role && indexModule) {
      if (userData.language) {
        onChangeLang(userData.language, false, false);
      }

      if (session) {
        setRegisterToSession(true);
        requestSession(sessions.load.action({ id: session }));
      } else {
        push(indexModule.route, indexModule.params);
      }
    }
  }, [userData.role]);

  const handleConfirmRegistration = (): void => {
    requestRegister(sessions.register.action({ id: session })).then(() => {
      if (userData.role && indexModule) {
        push(indexModule.route, indexModule.params);
      }
      return;
    });
  };

  const handleCancelRegister = (): void => {
    if (userData.role && indexModule) {
      push(indexModule.route, indexModule.params);
    }
  };

  const expanded = (): Expande => {
    const response = [];
    if (dataSession.id) {
      response.push(
        [
          {
            label: t('session'),
            value: dataSession.course.name,
          },
        ],
        [
          {
            label: t('location'),
            value: [
              dataSession.country && dataSession.country.title,
              dataSession.city && dataSession.city.title,
              dataSession.address,
            ]
              .filter((i) => i)
              .join(', '),
          },
        ],
        [
          {
            label: t('sessionDuration'),
            value: `${format(dataSession.start_date, dateFormat)} - ${format(dataSession.end_date, dateFormat)}`,
          },
        ],
      );
    }
    return response;
  };

  return (
    <>
      <Alert message={errors.message || data.message || errors.non_field_errors || errors.detail} form type="error" />

      {registerToSession && (
        <Modal
          mask={true}
          contentClass={`zh-confirmation-content`}
          onClose={handleCancelRegister}
          title={t('confirmAcceptance')}
          footer={
            <ModalFooterButton>
              <Button onClick={handleCancelRegister} disabled={loadingRegister}>
                {t('refuse')}
              </Button>

              <Button
                type="primary"
                prefix={<Icon type="check" />}
                loading={loadingRegister}
                onClick={handleConfirmRegistration}
              >
                {t('confirm')}
              </Button>
            </ModalFooterButton>
          }
        >
          <>
            <Icon type="alert" size="big" />
            <h2>{t('careful')}</h2>
            <p>{t('areYouSureComplete')}</p>
            {!loadingSession && <Expanded data={expanded()} />}
          </>
        </Modal>
      )}

      <Form onSubmit={handleSubmit} className="auth__block">
        <h3>{t('loginDetails')}</h3>

        <FormItem extra={errors.username}>
          {use.type === 'email' ? (
            <Input
              value={state.email}
              onChange={(email) => setState({ email })}
              placeholder={`${t('email')} ${t('or')} ${t('phone')}`}
            />
          ) : (
            <InputPhone
              country={countries?.filter((item) => item.value === country)[0]?.code}
              autoFocus={true}
              value={state.email}
              onChange={(email) => setState({ email })}
            />
          )}

          {use.type === 'phone' || use.recommend ? (
            <span className="useEmail" onClick={onUseClick}>
              {use.type === 'phone' ? t('loginByEmail') : t('loginByPhone')}
            </span>
          ) : null}
        </FormItem>

        <FormItem extra={errors.password}>
          <InputPassword
            value={state.password}
            onChange={(value: string): void => setState({ password: value })}
            type="password"
            placeholder={t('password')}
          />
        </FormItem>

        <div className="auth__block--options">
          <Checkbox
            checked={state.remember}
            onChange={() => setState((prevState) => ({ remember: !prevState.remember }))}
            label={t('keepLogged')}
          />
          <Link route="RestorePage">{t('forgotPassword')}</Link>
        </div>

        <Button type="primary" submit loading={loading}>
          {t('login')}
        </Button>
      </Form>
    </>
  );
};
