import * as React from 'react';
import OldTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

import './Tooltip.scss';

interface Props {
  onVisibleChange?: (visible: boolean) => void;
  visible?: boolean;
  getTooltipContainer?: ((node: HTMLElement) => HTMLElement) | undefined;
  overlayClassName?: string;
  className?: string;
  fixed?: boolean;
  content: (() => React.ReactNode) | React.ReactNode;
  trigger?: string[];
  placement?: string;
  loading?: boolean;
  children: any;
}

export const Tooltip: React.FC<Props> = ({
  onVisibleChange,
  visible,
  getTooltipContainer,
  overlayClassName = '',
  className = '',
  fixed,
  trigger = ['click'],
  placement,
  content,
  children,
}) => {
  const [isVisible, setVisible] = React.useState(visible);

  React.useEffect(() => setVisible(visible), [visible]);

  const handleVisibleChange = (v: boolean): void => {
    if (onVisibleChange) {
      onVisibleChange(v);
    }
    setVisible(v);
  };

  return (
    <OldTooltip
      visible={isVisible}
      onVisibleChange={handleVisibleChange}
      getTooltipContainer={getTooltipContainer}
      overlay={<div className={`zh-tooltip-content ${className}`}>{content}</div>}
      trigger={trigger}
      overlayClassName={`zh-tooltip zh-tooltip-${fixed ? 'fixed' : 'relative'} ${overlayClassName}`}
      placement={placement}
      destroyTooltipOnHide={true}
    >
      {children}
    </OldTooltip>
  );
};
