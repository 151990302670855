import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { EurasiaCountry, Country, Phone } from './countries.types';

export const countries = {
  get: {
    action: ({ lang = 'en', ...params }) =>
      axios
        .get<EurasiaCountry[]>(`countries`, {
          params,
          cancelToken: new cancelToken((c: Canceler) => (countries.get.cancel = c)),
        })
        .then((response) => ({
          ...response,
          data: response.data.map(
            (item): Country => ({
              value: item.id,
              title:
                item[`title_${lang}` as 'title_en' | 'title_ro' | 'title_ru'] || item.title || item.title_en || 'n/a',
              code: item.code,
              phone: item.phone_prefix,
            }),
          ),
        })),
    cancel: (() => null) as Canceler,
  },
  active: {
    action: ({ lang = 'en', ...params }) =>
      axios
        .get<EurasiaCountry[]>(`countries/active-list`, {
          params,
          cancelToken: new cancelToken((c: Canceler) => (countries.get.cancel = c)),
        })
        .then((response) => ({
          ...response,
          data: response.data.map(
            (item): Country => ({
              value: item.id,
              title:
                item[`title_${lang}` as 'title_en' | 'title_ro' | 'title_ru'] || item.title || item.title_en || 'n/a',
              code: item.code,
              phone: item.phone_prefix,
            }),
          ),
        })),
    cancel: (() => null) as Canceler,
  },
  phone: {
    action: ({ country = '', ...params }) =>
      country &&
      axios.get<Phone>(`countries/${country}/prefixes`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (countries.get.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  timezones: {
    action: ({ ...params }) =>
      axios.get<{ timezones: string[] }>(`countries/timezones`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (countries.timezones.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
