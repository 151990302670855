import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useHistory, useParams } from 'estafette-router';
import { institutions, users } from 'libs/http/api';
import { User } from 'libs/http/api/me/me.types';
import { Users } from 'libs/http/api/users/users.types';
import { renderPhone } from 'libs/mask';
import { perPage } from 'libs/storage';
import { useFilters } from 'hooks';
import { SortBy, SendMessage, Actions, InputSearch, Head } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { Card, CardHeader, CardFooter, Table, AvatarInline, Icon, Button, Info, Loader } from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';

import { InstituteLayout } from '../organisms';

export const StudentsInstitutePage: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { id } = useParams();
  const { request: requestStudents, data, loading } = useRequest<Users>({ data: { results: [] } });
  const { request: requestDelete, loading: loadingDelete } = useRequest();
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    ordering: '' as Sorts,
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    return () => {
      institutions.getStudents.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (id && !loadingDelete) {
      onFetchStudents();
    }
  }, [id, filters, loadingDelete]);

  const onFetchStudents = (): {} => requestStudents(institutions.getStudents.action({ id, ...filters }));

  const onDeleteStudent = React.useCallback((id) => requestDelete(users.delete.action({ id })), []);
  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });
  const onManagementStudent = (action = 'add'): void =>
    push('StudentManagementPage', { id, action, query: { page: filters.page } });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const columns: Column<User>[] = React.useMemo(
    () => [
      {
        title: t('nameAndSurname'),
        render: ({ first_name: firstName, last_name: lastName, profile_picture: profilePicture }): React.ReactNode => (
          <AvatarInline img={profilePicture} alt={[firstName, lastName].filter((i) => i).join(' ')} size="small" />
        ),
      },
      {
        title: 'E-mail',
        render: ({ email }): React.ReactNode => (
          <div className="nowrap">
            <Info icon={<Icon type="mail-filled" />} label={email || '---'} />
          </div>
        ),
      },
      {
        title: t('phone'),
        render: ({ phone_number: phoneNumber }): React.ReactNode => (
          <div className="nowrap">
            <Info icon={<Icon type="phone" />} label={phoneNumber ? renderPhone(phoneNumber) : '---'} />
          </div>
        ),
      },
      {
        title: t('place'),
        render: ({ country, city }) =>
          country && country.title ? (
            <Info
              icon={<Icon type="pin" />}
              label={[country && country.title, city && city.title].filter((i) => i).join(', ')}
            />
          ) : (
            '---'
          ),
      },
      {
        title: t('age'),
        render: ({ birth_date: birthDate }): React.ReactNode => {
          const age = birthDate
            ? Math.floor((new Date().getTime() - new Date(birthDate).getTime()) / (1000 * 60 * 60 * 24 * 365))
            : '---';

          return (age > 0 && age) || '---';
        },
      },
      {
        title: t('actions'),
        render: ({ id: userID }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <SendMessage users={userID ? [userID] : []} />

            <Actions onEdit={() => onManagementStudent(`${userID}`)} onDelete={() => onDeleteStudent(userID)} />
          </div>
        ),
      },
    ],
    [t],
  );

  return (
    <>
      <Head t="students" />

      <InstituteLayout>
        <Card>
          <CardHeader
            title={t('students')}
            count={data.count}
            leftSide={
              <InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />
            }
            rightSide={
              <>
                <SortBy
                  options={[
                    {
                      title: (
                        <>
                          <Icon type="asc" />
                          {t('firstName')}
                        </>
                      ),
                      value: 'first_name',
                    },
                    {
                      title: (
                        <>
                          <Icon type="desc" />
                          {t('firstName')}
                        </>
                      ),
                      value: '-first_name',
                    },
                    {
                      title: (
                        <>
                          <Icon type="asc" />
                          {t('lastName')}
                        </>
                      ),
                      value: 'last_name',
                    },
                    {
                      title: (
                        <>
                          <Icon type="desc" />
                          {t('lastName')}
                        </>
                      ),
                      value: '-last_name',
                    },
                    {
                      title: (
                        <>
                          <Icon type="asc" />
                          {t('age')}
                        </>
                      ),
                      value: 'birth_date',
                    },
                    {
                      title: (
                        <>
                          <Icon type="desc" />
                          {t('age')}
                        </>
                      ),
                      value: '-birth_date',
                    },
                  ]}
                  value={filters.ordering}
                  onChange={(newValue) => onChangeFilters('ordering', newValue)}
                />
              </>
            }
          />
          <Loader loading={loading}>
            <Table size="small" page={filters.page} data={data.results} columns={columns} />

            <CardFooter
              onRefresh={onRefetch}
              onRefreshDisabled={!filters.filtered}
              page={filters.page}
              pages={data.total_pages}
              perPage={filters.per_page}
              onChangePerPage={onChangePerPage}
              rightSide={
                <>
                  <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                    {t('previous')}
                  </Button>

                  <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                    {t('next')}
                  </Button>
                </>
              }
            />
          </Loader>
        </Card>
      </InstituteLayout>
    </>
  );
};
