import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useRequest } from 'estafette';
import { sessions } from 'libs/http/api';
import { Button, Icon, Input } from 'ui/atoms';
import { UserContext } from 'contexts';
import { User } from 'libs/http/api/me/me.types';
import { grade as gradeConvertor } from 'libs/mask';

interface EditGradeProps {
  id: number;
  student: number;
  field: string;
  grade?: number;
  onSuccess?: () => void;
}

export const EditGrade: React.FC<EditGradeProps> = ({ id, field, student, grade, onSuccess }) => {
  const { t } = useIntl();
  const { userData } = React.useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(grade?.toString());
  const { request: requestSave, loading: loadingSave } = useRequest<User>();

  const isAdmin = React.useMemo(() => userData.role === 'admin', [userData.role]);

  const onSaveGrade = async (): Promise<void> => {
    await requestSave(
      sessions.saveStudent.action({
        id,
        student: student,
        ...(value ? { [field]: parseInt(value) } : {}),
      }) as any,
    ).then(() => {
      setOpen(false);
      if (onSuccess) {
        onSuccess();
      }
    });
  };
  const handleKeyboardEvent = (e: React.KeyboardEvent<HTMLImageElement>): void => {
    if (e.key === 'Enter') {
      onSaveGrade();
    }
  };

  return (
    <div className="edit-grade">
      {open ? (
        <Input
          value={value}
          onKeyDown={handleKeyboardEvent}
          postfix={
            <Button size="small" type="primary" onClick={onSaveGrade} loading={loadingSave} disabled={!value}>
              <Icon type="check" className="check-icon-color" />
            </Button>
          }
          onChange={(v) => setValue(gradeConvertor(v).toString())}
          className="grade"
        />
      ) : (
        <div className="pointer" onClick={isAdmin ? () => setOpen((v) => !v) : undefined}>
          {value || <span className="grey">{t('arrear')}</span>}
        </div>
      )}
    </div>
  );
};
