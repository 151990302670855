import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { Link, useParams } from 'estafette-router';
import { institutions, sessions } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { Session, Student } from 'libs/http/api/sessions/sessions.types';
import { Grades } from 'libs/http/api/courses/courses.types';
import { User } from 'libs/http/api/me/me.types';
import { renderPhone, grade } from 'libs/mask';
import { copySessionPublicLink } from 'libs/copy';
import { perPage } from 'libs/storage';
import { UserContext } from 'contexts';
import { useFilters, useStateHandlers } from 'hooks';
import { SortBy, SendMessage, Actions, InputSearch, EditAccount, Head, InviteStudent } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { Card, CardHeader, CardFooter, Table, AvatarInline, Icon, Button, Info, Loader, Input } from 'ui/atoms';
import { ModalEditPracticeGrade } from 'ui/molecules';
import { Column } from 'ui/atoms/Table/Table';
import { SessionLayout } from '../organisms';
import { notify } from 'react-notify-toast';

const initialEditGrade = {
  row: null,
  type: null,
  value: null,
};

export const StudentsSessionPage: React.FC = () => {
  const { t } = useIntl();
  const { id } = useParams();
  const { userData } = React.useContext(UserContext);
  const { request: requestSession, data: sessionData } = useRequest<Session>({ data: {} });
  const { request: requestStudents, data, loading, setData } = useRequest<Results<Student>>({ data: { results: [] } });
  const { request: requestGradeFields, data: dataGradeFields, loading: loadingGradeFields } = useRequest<Grades[]>();
  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<User>();
  const { request: requestDelete, loading: loadingDelete } = useRequest();
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    ordering: '' as Sorts,
  });

  const [stateGrade, setEditGrade] = useStateHandlers<{ [key: string]: string | number | null }>(initialEditGrade);
  const [filters, setFilters] = useFilters({ ...initialFilters.current });
  const [userId, setUserId] = React.useState<string | number | null>(null);
  const [edit, setEdit] = React.useState(false);
  const [invite, setInvite] = React.useState(false);

  const [selectedPracticeGradeModal, setSelectedPracticeGradeModal] = React.useState<Student>();

  const canEdit = React.useMemo(() => ['admin', 'coordinator'].includes(userData.role), [userData.role]);
  const isProfessor = React.useMemo(() => userData.role === 'professor', [userData.role]);

  React.useEffect(() => {
    return () => {
      institutions.getStudents.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (id && !loadingDelete) {
      onFetchSession();
      onFetchStudents();
      onFetchGradeFields();
    }
  }, [id, filters, loadingDelete]);

  React.useEffect(() => {
    if (dataSave.id) {
      notify.show(t('successGrade'), 'success');
    }
  }, [dataSave]);

  React.useEffect(() => {
    if (errors.non_field_errors || errors.detail) {
      notify.show(errors.non_field_errors || errors.detail, 'error');
    }
  }, [errors]);

  const onFetchSession = (): {} => requestSession(sessions.load.action({ id }));
  const onFetchStudents = (): {} => requestStudents(sessions.getStudents.action({ id, ...filters }));
  const onFetchGradeFields = (): {} => requestGradeFields(sessions.getGradeFields.action({ id }));

  const onDeleteStudent = React.useCallback(
    (value) => requestDelete(sessions.removeStudent.action({ id, studentId: value })),
    [],
  );
  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onOpenEditAccount = React.useCallback((value): void => {
    setUserId(value);
    setEdit(true);
  }, []);

  const onCloseEditAccount = (): void => {
    setUserId(null);
    setEdit(false);
  };

  const onSuccessEdit = (): void => {
    onCloseEditAccount();
    onRefetch();
  };

  const onToggleInvite = (): void => setInvite((s) => !s);

  const onSaveGrade = async (): Promise<void> => {
    await requestSave(
      sessions.saveStudent.action({
        id,
        student: stateGrade.row,
        ...(stateGrade.value ? { [`${stateGrade.type}_grade`]: parseInt(stateGrade.value as string) } : {}),
      }) as any,
    ).then((response) =>
      setData((preData: Results<Student>) => ({
        ...preData,
        results: preData.results?.map((item) => {
          if (item.id !== stateGrade.row) return item;

          return {
            ...item,
            individual_work_grade: response.individual_work_grade,
            course_grade: response.course_grade,
            average_grade: response.average_grade,
            practise_grade: response.practise_grade,
          };
        }),
      })),
    );

    setEditGrade({ ...initialEditGrade });
  };

  const handleSetData = (
    rowId: number | null | undefined,
    practise_grades: { [key: string]: string | number | null | undefined },
  ): void => {
    setData((preData: Results<Student>) => ({
      ...preData,
      results: preData.results?.map((item) => {
        if (item.id !== rowId) return item;
        return {
          ...item,
          ...practise_grades,
        };
      }),
    }));
  };

  const onChangeGrade = (value: string): void => setEditGrade({ value: grade(value) });

  const onEditGrade = ({ row, type, value }: any): void => setEditGrade({ row, type, value });

  const handleKeyboardEvent = (e: React.KeyboardEvent<HTMLImageElement>): void => {
    if (e.key === 'Enter') {
      onSaveGrade();
    }
  };
  const columns: Column<Student>[] = React.useMemo(
    () => [
      {
        title: t('nameAndSurname'),
        render: ({ id, profile_picture, first_name, last_name }): React.ReactNode => (
          <AvatarInline
            img={profile_picture}
            shortAlt={[first_name, last_name].filter((i) => i).join(' ')}
            alt={
              <Link route="ProfilePage" params={{ action: id }}>
                {[first_name, last_name].filter((i) => i).join(' ')}
              </Link>
            }
            size="small"
          />
        ),
      },
      {
        title: t('email_phone'),
        render: ({ email, phone_number }): React.ReactNode => (
          <div className="nowrap">
            {email ? (
              <Info icon={<Icon type="mail-filled" />} label={email || '---'} />
            ) : (
              <Info icon={<Icon type="phone" />} label={phone_number ? renderPhone(phone_number) : '---'} />
            )}
          </div>
        ),
      },
      dataGradeFields.includes('course_grade')
        ? {
            title: t('course_grade'),
            render: (
              { id: studentId, course_grade: courseGrade }, // courseGrade || <span className="grey">{t('arrears')}</span>
            ) =>
              stateGrade.row === studentId && stateGrade.type && stateGrade.type === 'course' ? (
                <Input
                  value={stateGrade.value}
                  onKeyDown={handleKeyboardEvent}
                  postfix={
                    <Button
                      size="small"
                      type="primary"
                      onClick={onSaveGrade}
                      loading={loadingSave}
                      disabled={!stateGrade.value}
                    >
                      <Icon type="check" />
                    </Button>
                  }
                  onChange={onChangeGrade}
                  className="grade"
                />
              ) : (
                <div
                  className={canEdit || (isProfessor && !courseGrade) ? 'pointer' : undefined}
                  onClick={
                    canEdit || (isProfessor && !courseGrade)
                      ? () => onEditGrade({ row: studentId, type: 'course', value: courseGrade || '' })
                      : undefined
                  }
                >
                  {courseGrade || <span className="grey">{t('arrears')}</span>}
                </div>
              ),
          }
        : {},
      dataGradeFields.includes('individual_work_grade')
        ? {
            title: t('individual_work_grade'),
            render: ({ id: studentId, individual_work_grade: individualWorkGrade }) =>
              stateGrade.row === studentId && stateGrade.type && stateGrade.type === 'individual_work' ? (
                <Input
                  onKeyDown={handleKeyboardEvent}
                  value={stateGrade.value}
                  postfix={
                    <Button
                      size="small"
                      type="primary"
                      onClick={onSaveGrade}
                      loading={loadingSave}
                      disabled={!stateGrade.value}
                    >
                      <Icon type="check" />
                    </Button>
                  }
                  onChange={onChangeGrade}
                  className="grade"
                />
              ) : (
                <div
                  className={canEdit || (isProfessor && !individualWorkGrade) ? 'pointer' : undefined}
                  onClick={
                    canEdit || (isProfessor && !individualWorkGrade)
                      ? () => onEditGrade({ row: studentId, type: 'individual_work', value: individualWorkGrade || '' })
                      : undefined
                  }
                >
                  {individualWorkGrade || <span className="grey">{t('arrears')}</span>}
                </div>
              ),
          }
        : {},
      dataGradeFields.includes('practise_grade')
        ? {
            title: t('practise_grade'),
            render: (item) =>
              stateGrade.row === item.id && stateGrade.type && stateGrade.type === 'practise' ? (
                <div className="grade" onClick={() => setSelectedPracticeGradeModal(item)}>
                  {stateGrade.value}
                </div>
              ) : (
                <div
                  className={
                    canEdit ||
                    (isProfessor &&
                      (!item.practise_grade ||
                        !item.practise_sub_grade_1 ||
                        !item.practise_sub_grade_2 ||
                        !item.practise_sub_grade_3 ||
                        !item.practise_sub_grade_4 ||
                        !item.practise_sub_grade_5))
                      ? 'pointer'
                      : undefined
                  }
                  onClick={
                    canEdit ||
                    (isProfessor &&
                      (!item.practise_grade ||
                        !item.practise_sub_grade_1 ||
                        !item.practise_sub_grade_2 ||
                        !item.practise_sub_grade_3 ||
                        !item.practise_sub_grade_4 ||
                        !item.practise_sub_grade_5))
                      ? () => setSelectedPracticeGradeModal(item)
                      : undefined
                  }
                >
                  {(item.practise_grade && parseFloat(item.practise_grade).toFixed(2)) || (
                    <span className="grey">{t('arrears')}</span>
                  )}
                </div>
              ),
          }
        : {},
      dataGradeFields.includes('average_grade')
        ? {
            title: t('averageNote'),
            render: ({ average_grade: averageGrade }) =>
              averageGrade ? (averageGrade as number).toFixed(2) : <span className="grey">{t('arrears')}</span>,
          }
        : {},
      {
        title: t('actions'),
        render: ({ id: $userId }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <SendMessage users={$userId ? [$userId] : []} />

            <Actions
              onEdit={() => onOpenEditAccount($userId)}
              onDelete={() => onDeleteStudent($userId)}
              loadingDelete={loadingDelete}
            />
          </div>
        ),
      },
    ],
    [t, dataGradeFields, userData, canEdit, stateGrade, isProfessor],
  );

  const handleCopyLink = (): void => {
    copySessionPublicLink(sessionData);
    notify.show(`${t('successfullyCopied')}`, 'success');
  };

  const handleClose = (): void => {
    setSelectedPracticeGradeModal(undefined);
  };

  return (
    <>
      {edit && (
        <EditAccount
          provider="session"
          view="modal"
          onClose={onCloseEditAccount}
          onSuccess={onSuccessEdit}
          session={id}
          id={userId}
        />
      )}
      {invite && <InviteStudent id={id} onClose={onToggleInvite} onRefetch={onRefetch} />}

      <Head t="students" />

      <SessionLayout>
        <Card>
          <CardHeader
            title={t('students')}
            count={data.count}
            leftSide={
              <InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />
            }
            rightSide={
              <>
                <Button
                  prefix={<Icon type="plus" />}
                  type="invert"
                  disabled={!sessionData?.has_invite_students}
                  onClick={onToggleInvite}
                >
                  {t('inviteStudent')}
                </Button>

                <Button
                  disabled={!['professor_invitation_accepted', 'accepted'].includes(sessionData.status)}
                  onClick={handleCopyLink}
                >
                  {t('copyLink')}
                </Button>

                {/* todo temporal deactivated  */}
                {/* <Button prefix={<Icon type="plus" />} onClick={() => onOpenEditAccount('add')}>
                  {t('addStudent')}
                </Button> */}

                <SortBy
                  options={[
                    {
                      title: t('firstName'),
                      value: 'first_name',
                    },
                    {
                      title: t('lastName'),
                      value: 'last_name',
                    },
                  ]}
                  value={filters.ordering}
                  onChange={(newValue) => onChangeFilters('ordering', newValue)}
                />
              </>
            }
          />
          <Loader loading={loading || loadingGradeFields}>
            <Table size="small" page={filters.page} data={data.results} columns={columns} />

            <CardFooter
              onRefresh={onRefetch}
              onRefreshDisabled={!filters.filtered}
              page={filters.page}
              pages={data.total_pages}
              perPage={filters.per_page}
              onChangePerPage={onChangePerPage}
              rightSide={
                <>
                  <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                    {t('previous')}
                  </Button>

                  <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                    {t('next')}
                  </Button>
                </>
              }
            />
          </Loader>
        </Card>
      </SessionLayout>
      {selectedPracticeGradeModal && selectedPracticeGradeModal?.id && (
        <ModalEditPracticeGrade
          onClose={handleClose}
          studentId={selectedPracticeGradeModal?.id}
          data={selectedPracticeGradeModal}
          sessionId={id}
          setData={handleSetData}
        />
      )}
    </>
  );
};
