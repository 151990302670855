export type $Object = { [key: string]: any };

export function keys<O>(o: O): (keyof O)[] {
  return Object.keys(o) as (keyof O)[];
}

export function parseQuery<T extends object>(queryString: string): T {
  if (queryString !== '') {
    const query: any = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

    let i = 0;
    for (i; i < pairs.length; i += 1) {
      const pair = pairs[i].split('=');

      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    return query;
  }

  return {} as T;
}

export const validate = (errors: string[] | { [key: string]: string[] }): string[] => {
  if (errors) {
    if (Array.isArray(errors)) {
      return errors;
    }

    return keys(errors)
      .reduce((acc, current) => [...acc, ...errors[current]], [] as string[])
      .filter((v, i, a) => a.indexOf(v) === i);
  }

  return [];
};
