import * as React from 'react';
import { useHistory, Link } from 'estafette-router';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useModules, useStateHandlers } from 'hooks';
import { NomenclaturesContext, UserContext } from 'contexts';
import { today, toISO, dateTimeFormat, format, dateFormat } from 'libs/date';
import { parseQuery } from 'libs/object';
import { hasUpperCase } from 'libs/string';
import { users, faculties, institutions } from 'libs/http/api';
import { sessions } from 'libs/http/api/sessions/sessions';
import { List } from 'libs/http/api/index.types';
import { EurasiaRegister } from 'libs/http/api/users/users.types';
import { SessionListItem } from 'libs/http/api/sessions/sessions.types';
import { Male, Female } from 'icons';
import { Form, FormItem, FormItems, InputPassword } from 'ui/organisms';
import { Input, Button, Select, Checkbox, Alert, CityInput } from 'ui/atoms';
import { FacultiesList } from 'libs/http/api/faculties/faculties.types';
import { InputPhone } from 'ui/molecules';

import './Registration.scss';

export const Registration: React.FC = () => {
  const { t } = useIntl();
  const { push, location } = useHistory();
  const query = parseQuery<{ session?: number }>(location.search);
  const { indexModule } = useModules();
  const { onVerifyUser, userData } = React.useContext(UserContext);
  const { getCountries, countries, getCities, cities, loadingCities, geoip, getGeoip } = React.useContext(
    NomenclaturesContext,
  );

  const [state, setState] = useStateHandlers<{ [key: string]: any }>({
    first_name: '',
    last_name: '',
    sex: 0,
    country: null,
    city: null,
    phone_number: '',
    email: '',
    session: null,
    subscribe: false,
    agree: false,
    password: '',
    confirm_password: '',
    submit: false,
  });

  const [searchCountries, setSearchCountries] = React.useState('');
  const [searchCities, setSearchCities] = React.useState('');
  const [institution, setInstitution] = React.useState(null);
  const [faculty, setFaculty] = React.useState(null);
  const [checked, setChecked] = React.useState(false);

  const { request: requestInstitutions, data: dataInstitutions, loading: loadingInstitutions } = useRequest<List[]>();
  const { request: requestFaculties, data: dataFaculties, loading: loadingFaculties } = useRequest<FacultiesList[]>();
  const { request: sendRegister, data: dataRegister, errors } = useRequest<EurasiaRegister>({ data: {} });
  const { request: requestSessions, data: dataSessions, loading: loadingSessions } = useRequest<SessionListItem[]>();

  React.useEffect(() => {
    requestInstitutions(institutions.list.action({ is_active: true }));
    requestFaculties(faculties.list.action());

    return () => {
      institutions.list.cancel();
      faculties.list.cancel();
      sessions.list.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (query.session) {
      setChecked(true);
      requestSessions(
        sessions.list.action({
          end_date__gte: toISO(today.format(dateTimeFormat)),
        }),
      );
    } else {
      requestSessions(
        sessions.list.action({
          country: state.country,
          city: state.city,
          institution,
          faculty,
          end_date__gte: toISO(today.format(dateTimeFormat)),
        }),
      );

      getGeoip();
    }
  }, [institution, faculty, state.country, state.city, query.session]);

  React.useEffect(() => {
    getCountries(searchCountries);
  }, [searchCountries]);

  React.useEffect(() => {
    getCities(state.country, searchCities);
  }, [state.country, searchCities]);

  React.useEffect(() => {
    requestInstitutions(institutions.list.action({ is_active: true, country: state.country, city: state.city }));
  }, [state.country, state.city]);

  React.useEffect(() => {
    if (geoip.country_code2 && !state.country) {
      setState({
        country: countries?.filter((item) => item.code === geoip.country_code2.toLowerCase())[0]?.value,
      });
    }
  }, [geoip, countries, state]);

  React.useEffect(() => {
    if (dataRegister.access) {
      onVerifyUser({ token: dataRegister.access, refresh: dataRegister.refresh });
    } else if (dataRegister.email || dataRegister.phone_number) {
      push('SessionsPage', { query: { [`${!dataRegister.email ? 'sms' : ''}confirm`]: true } });
    }
  }, [dataRegister]);

  React.useEffect(() => {
    if (userData.role && indexModule) {
      push(indexModule.route, indexModule.params);
    }
  }, [userData]);

  const onSubmit = (): void => {
    const isNewCity = typeof state?.city === 'string';

    setState({ submit: true });

    const { city, ...restState } = state;

    sendRegister(
      users.register.action({
        ...restState,
        email: state.email ? state.email : null,
        gender: state.sex ? 'M' : 'F',
        session: query.session ? query.session : state.session,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...(!isNewCity && city && { city }),
        ...(isNewCity && { city_data: { title: state?.city, country: state?.country } }),
      }),
    );
  };

  const institutionsOptions = React.useMemo(
    () => dataInstitutions.map((institution) => ({ value: institution.key, title: institution.label })),
    [dataInstitutions],
  );

  const facultiesOptions = React.useMemo(
    () => dataFaculties.map((faculty) => ({ value: faculty.key, title: faculty.label })),
    [dataFaculties],
  );

  const sessionsOptions = React.useMemo(
    () =>
      dataSessions.map((session) => ({
        value: session.key,
        title: `${session.label || session.course?.name || '---'} | ${session.institution?.name || '---'} | ${format(
          session.start_date,
          dateFormat,
        )} - ${format(session.end_date, dateFormat)}`,
      })),
    [dataSessions],
  );

  const onChange = React.useCallback((target: string, value: any): void => {
    const isCountryTarget = target === 'country';

    setState({
      [target]: value,
      ...(isCountryTarget ? { city: null } : {}),
    });

    isCountryTarget && setSearchCities('');
  }, []);

  const handleCountriesSearch = (input: string): void => setSearchCountries(input);

  const handleCitiesSearch = (input: string): void => setSearchCities(input);

  return (
    <>
      <Alert message={dataRegister.message || errors.non_field_errors || errors.detail} form type="error" />

      <Form onSubmit={onSubmit} className="register__block">
        <FormItems className="mx-w-720">
          <FormItem required label={<h3>{t('personalData')}</h3>} extra={errors.last_name}>
            <Input
              value={state.last_name}
              onChange={(value: string): void => onChange('last_name', value)}
              placeholder={t('lastName')}
            />
          </FormItem>
          <FormItem extra={errors.first_name}>
            <Input
              value={state.first_name}
              onChange={(value: string): void => onChange('first_name', value)}
              placeholder={t('firstName')}
            />
          </FormItem>
        </FormItems>

        <FormItems className="mx-w-720">
          <FormItem label={<h3>{t('sex')}</h3>}>
            <Checkbox icon={Female} checked={state.sex === 0} onChange={() => onChange('sex', 0)} label={t('female')} />
          </FormItem>

          <FormItem>
            <Checkbox icon={Male} checked={state.sex === 1} onChange={() => onChange('sex', 1)} label={t('male')} />
          </FormItem>
        </FormItems>

        <FormItems className="mx-w-720">
          <FormItem required label={<h3>{t('contactData')}</h3>} extra={errors.country}>
            <Select
              dropdownClassName="hideCheck"
              placeholder={t('country')}
              onSearch={handleCountriesSearch}
              value={state.country}
              options={countries}
              onChange={(value: string): void => onChange('country', value)}
            />
          </FormItem>

          <FormItem extra={errors.city}>
            <CityInput
              placeholder={t('city')}
              loading={loadingCities}
              onSearch={handleCitiesSearch}
              value={state.city}
              options={state.country ? cities.filter((city) => city.countryID === state.country) : []}
              onChange={(value: string): void => onChange('city', value)}
              disabled={!state.country}
            />
          </FormItem>
        </FormItems>

        <FormItems className="mx-w-720">
          <FormItem label=" " extra={errors.phone_number}>
            <InputPhone
              country={countries?.filter((item) => item.value === state.country)[0]?.code}
              value={state.phone_number}
              onChange={(value: string): void => onChange('phone_number', value)}
            />
          </FormItem>

          <FormItem extra={errors.email}>
            <Input
              value={state.email}
              onChange={(value: string): void => onChange('email', value)}
              placeholder={t('email')}
            />
          </FormItem>
        </FormItems>

        <FormItems className="mx-w-720">
          <FormItem required extra={errors.password} label={<h3>{t('password')}</h3>}>
            <InputPassword
              value={state.password}
              onChange={(value: string): void => onChange('password', value)}
              placeholder={t('password')}
            />
          </FormItem>

          <FormItem
            extra={
              errors.confirm_password || (state.password && !hasUpperCase(state.password) && t('recommendsPassword'))
            }
          >
            <InputPassword
              value={state.confirm_password}
              onChange={(value: string): void => onChange('confirm_password', value)}
              placeholder={t('confirm_password')}
            />
          </FormItem>
        </FormItems>
        {checked ? (
          <>
            <FormItems className="mx-w-720">
              <FormItem label={<h3>{t('selectInstitute')}</h3>}>
                <Select
                  cancelValue={null}
                  placeholder={t('selectInstitute')}
                  value={institution ? institution : null}
                  options={institutionsOptions}
                  loading={loadingInstitutions}
                  onChange={(value) => setInstitution(value)}
                />
              </FormItem>
            </FormItems>

            <FormItems className="mx-w-720">
              <FormItem label={<h3>{t('selectFaculty')}</h3>}>
                <Select
                  cancelValue={null}
                  placeholder={t('selectFaculty')}
                  value={faculty ? faculty : null}
                  options={facultiesOptions}
                  loading={loadingFaculties}
                  onChange={(value) => setFaculty(value)}
                />
              </FormItem>
            </FormItems>

            <FormItems className="mx-w-720">
              <FormItem label={<h3>{t('sessionsList')}</h3>} className="groupFields" extra={errors.session}>
                <Select
                  placeholder={t('selectSession')}
                  options={sessionsOptions}
                  loading={loadingSessions}
                  value={query?.session ? parseInt(query.session.toString()) : state.session}
                  onChange={(value): void => onChange('session', value)}
                />
              </FormItem>
            </FormItems>
          </>
        ) : (
          <FormItem className="mx-w-720">
            <Checkbox checked={checked} onChange={() => setChecked(true)} label={t('wantRegisterLocalInstitute')} />
          </FormItem>
        )}

        <FormItem className="mx-w-720">
          <Checkbox
            checked={state.subscribe}
            onChange={(): void => onChange('subscribe', !state.subscribe)}
            label={t('subscribe')}
          />
        </FormItem>

        <FormItem extra={state.submit && !state.agree ? t('mustAccepted') : undefined} className="mx-w-720">
          <Checkbox
            checked={state.agree}
            onChange={(): void => onChange('agree', !state.agree)}
            label={
              <>
                {t('agree')}
                <Link route="PrivacyPolicyPage" className="document-link">
                  {t('privacy_policy_single')}
                </Link>
                {t('and')}
                <Link route="TermsOfUsePage" className="document-link">
                  {t('terms_of_use_single')}
                </Link>
              </>
            }
          />
        </FormItem>

        <FormItem className="actions border">
          <Button type="transparent" onClick={(): void => push('SessionsPage')}>
            {t('cancel')}
          </Button>

          <Button type="primary" disabled={!state.agree} submit>
            {t('continue')}
          </Button>
        </FormItem>
      </Form>
    </>
  );
};
