import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Head } from 'ui/organisms';
import { AnnouncementsLayout } from '../organisms';
import { $AnnouncementsTemplate as AnnouncementsTemplate } from '../templates/$AnnouncementsTemplate';

export const AnnouncementsPage: React.FC = () => {
  const { t } = useIntl();

  return (
    <>
      <Head t="announcements" />

      <AnnouncementsLayout title={t('announcements')}>
        <AnnouncementsTemplate />
      </AnnouncementsLayout>
    </>
  );
};
