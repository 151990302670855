import * as React from 'react';
import { useHistory } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { parseQuery } from 'libs/object';
import { Button, Icon, Modal, ModalFooterButton } from 'ui/atoms';
import { Public } from 'features/public/organisms';
import { Login } from 'features/public/templates';
import { Content } from 'features/public/atoms';

export const SignInPage: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { confirm } = parseQuery<{ confirm: string }>(window.location.search);

  return (
    <Public>
      <Content className="container mini">
        <Login />

        {confirm === 'true' && (
          <Modal
            footer={
              <ModalFooterButton align="center">
                <Button submit form="edit-account" type="primary" onClick={() => push('SignInPage')}>
                  {t('confirm')}
                </Button>
              </ModalFooterButton>
            }
            size="small"
          >
            <div className="confirmed-container">
              <Icon type="check" />
              {t('successfullyVerified')}
            </div>
          </Modal>
        )}
      </Content>
    </Public>
  );
};
