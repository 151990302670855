import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Tooltip, Icon, LoaderSpinner } from 'ui/atoms';
import { Confirmation } from 'ui/organisms';

import './Actions.scss';

interface Props {
  onEdit?: () => void;
  onEditLabel?: string;
  onDelete?: () => void;
  onDeleteLabel?: string;
  loadingDelete?: boolean;

  onAccept?: () => void;
  onAcceptLabel?: string;

  onConfirm?: () => void;
  onConfirmLabel?: string;

  onSendToConfirm?: () => void;
  onSendToConfirmLabel?: string;

  onCancel?: () => void;
  onCancelLabel?: string;
}

export const Actions: React.FC<Props> = ({
  onEdit,
  onEditLabel,

  onDelete,
  onDeleteLabel,

  onAccept,
  onAcceptLabel,

  onConfirm,
  onConfirmLabel,

  onSendToConfirm,
  onSendToConfirmLabel,

  onCancel,
  onCancelLabel,

  loadingDelete,
}) => {
  const { t } = useIntl();

  const [visible, setVisible] = React.useState(false);

  const onVisibleChange = (visible: boolean): void => setVisible(visible);

  const onDeleteClick = (): void => {
    onDelete && onDelete();

    setVisible(false);
  };

  return (
    <div className="zh-action-wrapper">
      <Tooltip
        placement="bottom"
        className="zh-action-tooltip"
        visible={visible}
        onVisibleChange={onVisibleChange}
        content={
          <>
            <div className="zh-action-tooltip-title">{t('actions')}</div>

            <div className="zh-action-tooltip-items">
              {onAccept !== undefined && (
                <div className="zh-action-tooltip-item" onClick={onAccept}>
                  {onAcceptLabel || t('accept')}
                </div>
              )}

              {onConfirm !== undefined && (
                <div className="zh-action-tooltip-item" onClick={onConfirm}>
                  {onConfirmLabel || t('finalConfirmation')}
                </div>
              )}

              {onSendToConfirm !== undefined && (
                <div className="zh-action-tooltip-item" onClick={onSendToConfirm}>
                  {onSendToConfirmLabel || t('sendForConfirmation')}
                </div>
              )}

              {onEdit !== undefined && (
                <div className="zh-action-tooltip-item" onClick={onEdit}>
                  {onEditLabel || t('edit')}
                </div>
              )}

              {onDelete !== undefined && (
                <Confirmation onConfirm={onDeleteClick}>
                  <div className="zh-action-tooltip-item">
                    {loadingDelete && <LoaderSpinner size="small" className="loader-display" />}
                    <span>{onDeleteLabel || t('delete')}</span>
                  </div>
                </Confirmation>
              )}

              {onCancel !== undefined && (
                <div className="zh-action-tooltip-item" onClick={onCancel}>
                  {onCancelLabel || t('reject')}
                </div>
              )}
            </div>
          </>
        }
      >
        <Icon type="dots" className="zh-action-tooltip-icon" />
      </Tooltip>
    </div>
  );
};
