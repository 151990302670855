import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { UserContext } from 'contexts';
import { Layout } from 'ui/organisms';
import { Button, Icon } from 'ui/atoms';
import { Breadcrumb } from 'ui/atoms/Breadcrumbs/Breadcrumbs';

interface Props {
  breadcrumbs?: Breadcrumb[];
  onOpenUpload: () => void;
}

export const ResourceLayout: React.FC<Props> = ({ children, onOpenUpload }) => {
  const { t } = useIntl();
  const { userData } = React.useContext(UserContext);

  return (
    <Layout
      title={t('resources')}
      rightSide={
        ['student', 'professor'].indexOf(userData.role) < 0 && (
          <Button type="invert" prefix={<Icon type="plus" />} className="nowrap" onClick={onOpenUpload}>
            {t('addFile')}
          </Button>
        )
      }
    >
      {children}
    </Layout>
  );
};
