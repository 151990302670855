interface Link {
  type?: string;
  link: string;
  _blank?: boolean;
}

const getLink = ({ type, link }: Link): string => {
  switch (type) {
    case 'telegram':
      return `https://telegram.me/${link}`;
    case 'skype':
      return `skype:${link}?chat`;
    case 'whatsapp':
      return `https://api.whatsapp.com/send?phone=${link}`;
    case 'viber':
      return `viber://chat?number=${link}`;
    default:
      return link;
  }
};

export function redirect({ type, link, _blank = true }: Link): void {
  window.open(getLink({ type, link }), _blank ? '_blank' : undefined);
}
