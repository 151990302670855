import * as React from 'react';
import { useRequest } from 'estafette';
import { useParams, useHistory, Link } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { useFilters } from 'hooks';
import { Roles } from 'hooks/useModules';
import { users } from 'libs/http/api';
import { User } from 'libs/http/api/me/me.types';
import { Results } from 'libs/http/api/index.types';
import { Indicator } from 'libs/http/api/users/users.types';
import { Student } from 'libs/http/api/sessions/sessions.types';
import { UserContext } from 'contexts';
import { parseQuery } from 'libs/object';
import { perPage } from 'libs/storage';
import { Loader, Table, Card, CardHeader, CardFooter, Button, Icon } from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';
import { Expande } from 'ui/atoms/Table/Expanded';
import { ModalEditPracticeGrade } from 'ui/molecules';
import { SortBy, ProfileCard, InputSearch, Head } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { MyStudentsLayout, EditGrade, AddSession } from '../organisms';
import { GraduatesTable } from 'features/graduations/organisms';

export const ProfilePage: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { action } = useParams();
  const { userData } = React.useContext(UserContext);
  const { request, data, loading } = useRequest<User>();
  const { request: requestDelete } = useRequest();

  const { request: requestIndicators, data: dataIndicators, loading: loadingIndicators, setData } = useRequest<
    Results<Indicator>
  >({ data: { results: [] } });

  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    refetch: 0,
    ordering: '' as Sorts,
  });
  const { role } = parseQuery<{ role: Roles }>(window.location.search);
  const isAdmin = React.useMemo(() => userData.role === 'admin', [userData.role]);
  const isProfessor = React.useMemo(() => userData.role === 'professor', [userData.role]);
  const [addSession, setAddSession] = React.useState(false);
  const [selectedPracticeGradeModal, setSelectedPracticeGradeModal] = React.useState<Student[]>() as any;

  const [filters, setFilters] = useFilters<{ [key: string]: any }>({ ...initialFilters.current });

  React.useEffect(() => {
    return () => {
      users.indicators.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (action && Number(action)) {
      request(users.load.action({ id: action }));
    }

    return () => {
      users.load.cancel();
    };
  }, [action]);

  React.useEffect(() => {
    if (action && Number(action)) {
      requestIndicators(users.indicators.action({ id: action, ...filters }));
    }
  }, [action, filters]);

  const onEdit = (): void => {
    switch (role) {
      case 'coordinator':
        push('CoordinatorEditPage', { action });
        break;
      case 'professor':
        push('TeacherEditPage', { action });
        break;
      default:
        push('MyStudentEditPage', { action });
    }
  };
  const onDelete = React.useCallback(async (id) => {
    await requestDelete(users.delete.action({ id }));
    push('MyStudentsUsersPage');
  }, []);

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const handleRefetch = (): void => {
    setFilters((prevState) => ({ refetch: prevState.refetch + 1 }));
  };

  const handleAddSession = (): void => {
    onRefetch();
  };

  const handleCloseAddSession = (): void => {
    setAddSession(false);
  };

  const handleClose = (): void => {
    setSelectedPracticeGradeModal(undefined);
  };

  const handleSetData = (
    rowId: number | null | undefined,
    practise_grades: { [key: string]: string | number | null | undefined },
  ): void => {
    setData((preData: Results<Indicator>) => ({
      ...preData,
      session: preData.results?.map((sessionData) => {
        sessionData?.sessions.map((item) => {
          if (item.id !== rowId) return item;
          return {
            ...item,
            ...practise_grades,
          };
        });
      }),
    }));
    onRefetch();
  };

  const columns = React.useMemo(
    (): Column<Indicator>[] => [
      {
        title: t('faculty'),
        render: ({ name }): React.ReactNode => name,
      },
      {
        title: t('graduated'),
        className: 'checked',
        render: ({ has_graduated }) => has_graduated && <Icon type="check" />,
      },
      {
        title: t('session2'),
        render: ({ sessions_count: count }): React.ReactNode =>
          `${count} ${count > 1 ? t('sessions').toLowerCase() : t('session2').toLowerCase()}`,
      },
      {
        title: t('generalNote'),
        width: 135,
        render: ({ sessions, sessions_count: count }): React.ReactNode => {
          const note = sessions.reduce((note, current) => note + current.final_grade, 0) / count;

          return `${note && !isNaN(note) ? Math.ceil(note) : 0}%`;
        },
      },
    ],
    [t],
  );

  const expandable = ({ sessions }: Indicator): Expande => [
    [
      [{ value: t('faculty') }],
      [{ value: t('course') }],
      [{ value: t('language') }],
      [{ value: t('individual_work_grade') }],
      [{ value: t('practise_grade') }],
      [{ value: t('course_grade') }],
      [{ value: t('note') }],
    ],

    ...sessions.map(
      ({
        id,
        faculty,
        language,
        course,
        final_grade: finalMark,
        grades_to_show,
        individual_work_grade,
        course_grade,
        practise_grade,
        practise_sub_grade_1,
        practise_sub_grade_2,
        practise_sub_grade_3,
        practise_sub_grade_4,
        practise_sub_grade_5,
      }): any => [
        [{ value: faculty }],
        [
          {
            value: course?.name ? (
              <Link className="text-center" route="GeneralSessionPage" params={{ id: id }}>
                {course.name}
              </Link>
            ) : (
              '---'
            ),
          },
        ],
        [{ value: language?.name || '---' }],
        [
          grades_to_show?.includes('individual_work_grade')
            ? {
                value: (
                  <EditGrade
                    id={id}
                    field="individual_work_grade"
                    student={action}
                    grade={individual_work_grade}
                    onSuccess={handleRefetch}
                  />
                ),
              }
            : { value: '---' },
        ],
        [
          grades_to_show?.includes('practise_grade')
            ? {
                value: (
                  <div
                    className="edit-grade pointer"
                    onClick={
                      isAdmin || isProfessor
                        ? () =>
                            setSelectedPracticeGradeModal({
                              id,
                              practise_grade,
                              practise_sub_grade_1,
                              practise_sub_grade_2,
                              practise_sub_grade_3,
                              practise_sub_grade_4,
                              practise_sub_grade_5,
                            })
                        : undefined
                    }
                  >
                    {practise_grade ? (
                      (practise_grade as number).toFixed(2)
                    ) : (
                      <span className="grey">{t('arrear')}</span>
                    )}
                  </div>
                ),
              }
            : { value: '---' },
        ],
        [
          grades_to_show?.includes('course_grade')
            ? {
                value: (
                  <EditGrade
                    id={id}
                    field="course_grade"
                    student={action}
                    grade={course_grade}
                    onSuccess={handleRefetch}
                  />
                ),
              }
            : { value: '---' },
        ],
        [{ value: `${finalMark && !isNaN(finalMark) ? finalMark : 0}%` }],
      ],
    ),
  ];

  const title = React.useMemo(
    () =>
      role === 'coordinator'
        ? t('coordinatorDetails')
        : role === 'professor'
        ? t('professorDetails')
        : t('studentDetails'),
    [role, t],
  );

  return (
    <>
      <Head title={!loading ? `${data.first_name} ${data.last_name}` : 'n/a'} />

      <MyStudentsLayout role={role}>
        <h1>{title}</h1>

        <Loader loading={loading}>
          <ProfileCard data={data} role={role || 'student'} onEdit={onEdit} onDelete={onDelete} />
        </Loader>

        <GraduatesTable studentId={action} />

        {!role && (
          <Card>
            <CardHeader
              title={`${t('indicators')}`}
              count={dataIndicators.count}
              leftSide={
                <InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />
              }
              rightSide={
                <>
                  {userData.role === 'admin' && data.allowed_roles?.includes('student') && (
                    <Button type="primary" onClick={() => setAddSession(true)} prefix={<Icon type="plus" />}>
                      {t('addSession')}
                    </Button>
                  )}
                  <SortBy
                    options={[
                      {
                        title: t('faculty'),
                        value: 'name',
                      },
                      {
                        title: t('session2'),
                        value: 'sessions_count',
                      },
                    ]}
                    value={filters.ordering}
                    onChange={(newValue) => onChangeFilters('ordering', newValue)}
                  />
                </>
              }
            />

            <Loader loading={loadingIndicators} height={200}>
              <Table
                size="small"
                className="student-table"
                rowClassName="rc-table-row-leftBorder"
                data={dataIndicators.results}
                columns={columns}
                expandable={expandable}
                expandColumn
              />

              <CardFooter
                onRefresh={onRefetch}
                onRefreshDisabled={filters.page === 1}
                page={filters.page}
                pages={dataIndicators.total_pages}
                perPage={filters.per_page}
                onChangePerPage={onChangePerPage}
                rightSide={
                  <>
                    <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                      {t('previous')}
                    </Button>

                    <Button onClick={onIncreasePage} disabled={filters.page >= dataIndicators.total_pages}>
                      {t('next')}
                    </Button>
                  </>
                }
              />
            </Loader>
          </Card>
        )}
        {addSession && <AddSession onSuccess={handleAddSession} onCancel={handleCloseAddSession} />}
      </MyStudentsLayout>
      {selectedPracticeGradeModal && (
        <ModalEditPracticeGrade
          onClose={handleClose}
          studentId={action}
          data={selectedPracticeGradeModal}
          sessionId={selectedPracticeGradeModal?.id}
          setData={handleSetData}
        />
      )}
    </>
  );
};
