import * as React from 'react';
import { useHistory, useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { UserContext } from 'contexts';
import { parseQuery } from 'libs/object';
import { EditAccount, Head } from 'ui/organisms';

import { SessionLayout } from '../organisms';

export const AddStudentSessionPage: React.FC = () => {
  const { t } = useIntl();
  const { action } = useParams();
  const { push, goBack } = useHistory();
  const { userData } = React.useContext(UserContext);

  const id = React.useMemo(() => (userData.id === Number(action) ? 'my' : Number(action) ? Number(action) : action), [
    userData,
    action,
  ]);

  const onSuccess = (): void => {
    const { page } = parseQuery<{ page: number }>(window.location.search);
    push('ProfilePage', { query: { page }, action });
  };

  return (
    <>
      <Head t={action !== 'add' ? 'editStudent' : 'registerStudent'} />

      <SessionLayout>
        <h1>{action !== 'add' ? t('editStudent') : t('registerStudent')}</h1>

        <EditAccount id={id} provider="session" role="student" onSuccess={onSuccess} onClose={goBack} />
      </SessionLayout>
    </>
  );
};
