import * as React from 'react';
import { useIntl } from 'estafette-intl';

import './EmptyList.scss';

export const EmptyList: React.FC<{ bordered?: boolean }> = ({ bordered }) => {
  const { t } = useIntl();

  return <div className={`zh-empty-list ${bordered ? 'zh-empty-list-bordered' : ''}`}>{t('empty')}</div>;
};
