import * as React from 'react';
import { Head } from 'ui/organisms';
import { InstituteWithoutPlanTemplate } from '../templates';

import { SessionsLayout } from '../organisms';

export const InstituteWithoutPlanPage: React.FC = () => (
  <>
    <Head t="plannedSessions" />

    <SessionsLayout>
      <InstituteWithoutPlanTemplate />
    </SessionsLayout>
  </>
);
