import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'estafette-intl';

interface Props {
  t?: string;
  title?: string;
}

export const Head: React.FC<Props> = ({ title, t: $t }) => {
  const { t } = useIntl();

  return <Helmet>{title || t ? <title>{title || t($t || '')}</title> : null}</Helmet>;
};
