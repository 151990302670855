import * as React from 'react';
import { useHistory } from 'estafette-router';
import { notify } from 'react-notify-toast';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { UserContext } from 'contexts';
import { useModules, useStateHandlers } from 'hooks';
import { users } from 'libs/http/api';
import { EurasiaRestoreConfirm } from 'libs/http/api/users/users.types';
import { Button, Alert } from 'ui/atoms';
import { Form, FormItem, InputPassword } from 'ui/organisms';

import './Restore.scss';

export const ConfirmRestore: React.FC<{ token: string }> = ({ token }) => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { indexModule } = useModules();
  const { onVerifyUser, userData } = React.useContext(UserContext);
  const [state, setState] = useStateHandlers({
    password: '',
    confirm_password: '',
  });

  const { request, data, errors, loading } = useRequest<EurasiaRestoreConfirm>({ data: {} });

  React.useEffect(() => {
    return () => {
      users.restoreConfirm.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (data.access) {
      onVerifyUser({ token: data.access, refresh: data.refresh });
    }
  }, [data]);

  React.useEffect(() => {
    if (userData.role && indexModule) {
      push(indexModule.route, indexModule.params);
    }
  }, [userData.role]);

  React.useEffect(() => {
    if (errors.message === 'token is invalid') {
      notify.show(t('invalidToken'), 'error');
    }
  }, [errors]);

  const onSubmit = async (): Promise<void> => {
    await request(
      users.restoreConfirm.action({ token, password: state.password, confirm_password: state.confirm_password }),
    );

    notify.show(t('passwordChangedSuccessfully'), 'error');

    push('SignInPage');
  };

  return (
    <>
      <Alert message={data.message || errors.non_field_errors || errors.detail} form type="error" />

      <Form onSubmit={onSubmit} className="auth__block">
        <h3>{t('enterNewPassword')}</h3>

        <FormItem extra={errors.password}>
          <InputPassword
            value={state.password}
            onChange={(password: string): void => setState({ password })}
            type="password"
            placeholder={t('password')}
          />
        </FormItem>

        <FormItem extra={errors.confirm_password}>
          <InputPassword
            value={state.confirm_password}
            onChange={(confirm_password: string): void => setState({ confirm_password })}
            type="password"
            placeholder={t('confirm_password')}
          />
        </FormItem>

        <Button type="primary" className="restore-password" submit loading={loading}>
          {t('confirm')}
        </Button>
      </Form>
    </>
  );
};
