import * as React from 'react';

import './Fragment.scss';

interface Props {
  type?: 'grey' | 'white';
  className?: string;
  footer?: React.ReactNode;
  onClick?: () => void;
}

export const Fragment: React.FC<Props> = ({ type = 'grey', className = '', children, footer, onClick }) => (
  <div className={`zh-fragment zh-fragment-type-${type} ${className}`} onClick={onClick}>
    <div className="zh-fragment-content">{children}</div>

    {footer && <div className="zh-fragment-footer">{footer}</div>}
  </div>
);
