export const headerData = [
  // prettier-ignore
  { name: 'SessionsPage', breadcrumb: 'sessions', title: 'registerSession', subtitle: 'chooseCountryCityTeacher' },
  { name: 'SignInPage', breadcrumb: 'signin', title: 'signin', extra: 'noAccount' },
  { name: 'SignUpPage', breadcrumb: 'signup', title: 'signup', subtitle: 'pleaseFillForm', extra: 'haveAccount' },
  {
    name: 'RestorePage',
    breadcrumb: 'restorePassword',
    title: 'restorePassword',
    subtitle: 'pleaseFillForm',
    extra: 'noAccount',
  },
  {
    name: 'ConfirmRestorePage',
    breadcrumb: 'restorePassword',
    title: 'restorePassword',
    subtitle: 'pleaseFillForm',
    extra: 'haveAccount',
  },
  { name: 'PrivacyPolicyPage', breadcrumb: 'terms_of_use', title: 'terms_of_use' },
  { name: 'TermsOfUsePage', breadcrumb: 'privacy_policy', title: 'privacy_policy' },
];
