import * as React from 'react';
import { useRequest } from 'estafette';
import { Link, useHistory, useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { UserContext } from 'contexts';
import { useFilters } from 'hooks';
import { dateFormat, format } from 'libs/date';
import { institutions } from 'libs/http/api';
import { sessions } from 'libs/http/api/sessions/sessions';
import { Results } from 'libs/http/api/index.types';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { perPage } from 'libs/storage';
import { Actions, Head, InputSearch, SortBy } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { Info, Card, CardHeader, CardFooter, Table, Time, Icon, Button, Loader } from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';
import { Expande } from 'ui/atoms/Table/Expanded';

import { InstituteLayout } from '../organisms';

export const PrevSessionsinstitutePage: React.FC = () => {
  const { t } = useIntl();
  const { id } = useParams();
  const { push } = useHistory();
  const { userData } = React.useContext(UserContext);
  const { request, data, loading } = useRequest<Results<Session>>({ data: { results: [] } });
  const { request: requestDelete, loading: loadingDelete } = useRequest();
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    ordering: '' as Sorts,
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    return () => {
      institutions.getPreviousSessions.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (id && !loadingDelete) {
      request(institutions.getPreviousSessions.action({ id, ...filters }));
    }
  }, [id, filters, loadingDelete]);

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onDeleteSession = React.useCallback((id) => requestDelete(sessions.delete.action({ id })), []);
  const onManagementSession = (id: number): void =>
    push('AddSessionPage', { action: id, query: { page: filters.page } });

  const columns: Column<Session>[] = React.useMemo(
    () => [
      {
        title: t('dateAndTime'),
        render: ({ start_date: startDate }): React.ReactNode => <Time date={format(startDate, dateFormat)} noParse />,
      },
      {
        title: t('place'),
        render: ({ country, city }) =>
          country && country.title ? (
            <Info
              icon={<Icon type="pin" />}
              label={[country && country.title, city && city.title].filter((i) => i).join(', ')}
            />
          ) : (
            '---'
          ),
      },
      {
        title: t('institute'),
        render: ({ institution }) => (institution && institution.name) || '---',
      },
      {
        title: t('course'),
        render: ({ course }) => (course && course.name) || '---',
      },
      {
        title: t('numOfRegisteredStudentsShort'),
        width: 80,
        render: ({ number_of_students: students }) => (students && <b>{students}</b>) || 0,
      },
      {
        action: true,
        render: ({ id }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <Link className="nowrap" route="GeneralSessionPage" params={{ id }}>
              {t('details')}
            </Link>

            {['admin', 'coordinator'].includes(userData.role) && (
              <Actions
                onEdit={['admin', 'coordinator'].includes(userData.role) ? () => onManagementSession(id) : undefined}
                onDelete={userData.role === 'admin' ? () => onDeleteSession(id) : undefined}
              />
            )}
          </div>
        ),
        className: 'nowrap',
      },
    ],
    [t, userData.role],
  );

  const expandable = ({
    professor,
    start_date: startDate,
    end_date: endDate,
    country,
    city,
    info,
    course,
    institution,
    number_of_students: students,
    language,
  }: Session): Expande => [
    [
      {
        label: t('startAt'),
        value: startDate ? format(startDate, dateFormat) : '---',
        icon: <Icon type="calendar" />,
      },
      {
        label: t('finishAt'),
        value: startDate ? format(endDate, dateFormat) : '---',
        icon: <Icon type="calendar" />,
      },
    ],
    [
      { label: t('course'), value: (course && course.name) || '---', icon: <Icon type="requests" /> },
      {
        label: t('teacher'),
        value: professor ? [professor.first_name, professor.last_name].filter((i) => i).join(' ') : '---',
      },
    ],
    [
      {
        label: t('place'),
        value:
          country && country.title ? [country && country.title, city && city.title].filter((i) => i).join(', ') : '---',
        icon: <Icon type="pin" />,
      },
      {
        label: t('info_short'),
        value: info ? <span dangerouslySetInnerHTML={{ __html: info }} /> : '---',
        icon: <Icon type="info" />,
      },
    ],
    [{ label: t('institute'), value: (institution && institution.name) || '---', icon: <Icon type="location" /> }],
    [
      { label: t('numOfStudents'), value: students, icon: <Icon type="student" /> },
      { label: t('language'), value: (language && language.name) || '---' },
    ],
  ];

  return (
    <>
      <Head t="previousSessions" />

      <InstituteLayout>
        <Card>
          <CardHeader
            title={t('previousSessions')}
            count={data.count}
            leftSide={
              <InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />
            }
            rightSide={
              <SortBy
                options={[
                  {
                    title: t('name'),
                    value: 'name',
                  },
                  {
                    title: t('country'),
                    value: 'country',
                  },
                  {
                    title: t('city'),
                    value: 'city',
                  },
                  {
                    title: t('startDate'),
                    value: 'start_date',
                  },
                  {
                    title: t('endDate'),
                    value: 'end_date',
                  },
                ]}
                value={filters.ordering}
                onChange={(newValue) => onChangeFilters('ordering', newValue)}
              />
            }
          />

          <Loader loading={loading}>
            <Table page={filters.page} data={data.results} columns={columns} expandable={expandable} />

            <CardFooter
              onRefresh={onRefetch}
              onRefreshDisabled={!filters.filtered}
              page={filters.page}
              pages={data.total_pages}
              perPage={filters.per_page}
              onChangePerPage={onChangePerPage}
              rightSide={
                <>
                  <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                    {t('previous')}
                  </Button>

                  <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                    {t('next')}
                  </Button>
                </>
              }
            />
          </Loader>
        </Card>
      </InstituteLayout>
    </>
  );
};
