import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useFilters } from 'hooks';
import { me } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { User } from 'libs/http/api/me/me.types';
import { renderPhone } from 'libs/mask';
import { perPage } from 'libs/storage';
import { Head, InputSearch, SortBy } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import {
  Info,
  Card,
  CardHeader,
  CardFooter,
  CardAlert,
  Table,
  Icon,
  Button,
  Media,
  MediaGroup,
  Loader,
  AvatarInline,
} from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';

import { ContactsLayout } from '../organisms';

export const ContactsPage: React.FC = () => {
  const { t } = useIntl();
  const { request, data, loading } = useRequest<Results<User>>({ data: { results: [] } });
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    ordering: '' as Sorts,
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    return () => {
      me.getContacts.cancel();
    };
  }, []);

  React.useEffect(() => {
    request(me.getContacts.action(filters));
  }, [filters]);

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => {
    if (filters.filtered) {
      setFilters({ ...initialFilters.current });
    } else {
      request(me.getContacts.action(filters));
    }
  };
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const columns: Column<User>[] = React.useMemo(
    () => [
      {
        title: t('nameAndSurname'),
        render: ({ first_name: firstName, last_name: lastName, profile_picture: profilePictrue, role }) => (
          <AvatarInline
            img={profilePictrue}
            alt={[firstName, lastName].filter((i) => i).join(' ')}
            role={role}
            size="small"
          />
        ),
      },
      {
        title: t('contacts'),
        render: ({ email, phone_number: phoneNumber }) => (
          <div className="flex flex-start flex-column">
            <Info icon={<Icon type="mail-filled" />} label={email || '---'} />
            {phoneNumber && (
              <Info icon={<Icon type="phone" />} label={phoneNumber ? renderPhone(phoneNumber) : '---'} />
            )}
          </div>
        ),
      },
      {
        title: t('socialsNetworks'),
        render: ({ facebook_url: fbUrl, vkontakte_url: vkUrl, odnoklasniki_url: odUrl, linkedin_url: ldUrl }) => (
          <MediaGroup>
            {fbUrl && <Media type="facebook" link={fbUrl} />}

            {vkUrl && <Media type="vk" link={vkUrl} />}

            {odUrl && <Media type="odnoklasniki" link={odUrl} />}

            {ldUrl && <Media type="linkedin" link={ldUrl} />}

            {!fbUrl && !vkUrl && !odUrl && !ldUrl && '---'}
          </MediaGroup>
        ),
      },
      {
        title: t('otherContacts'),
        render: ({ telegram, skype, whatsup, viber }) => (
          <MediaGroup>
            {telegram && <Media type="telegram" link={telegram} />}

            {skype && <Media type="skype" link={skype} />}

            {whatsup && <Media type="whatsapp" link={whatsup} />}

            {viber && <Media type="viber" link={viber} />}

            {!telegram && !skype && !whatsup && !viber && '---'}
          </MediaGroup>
        ),
      },
      {
        title: t('lastActivity'),
        render: ({ last_login: lastLogin }) => (lastLogin ? <b>{lastLogin}</b> : '---'),
      },
    ],
    [t],
  );

  return (
    <>
      <Head title="contacts" />

      <ContactsLayout>
        <Card>
          <CardHeader
            title={t('contacts')}
            count={data.count}
            leftSide={
              <InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />
            }
            rightSide={
              <SortBy
                options={[
                  {
                    title: t('firstName'),
                    value: 'first_name',
                  },
                  {
                    title: t('lastName'),
                    value: 'last_name',
                  },
                  {
                    title: t('lastActivity'),
                    value: 'last_login',
                  },
                ]}
                value={filters.ordering}
                onChange={(newValue) => onChangeFilters('ordering', newValue)}
              />
            }
          />

          <Loader loading={loading}>
            <Table size="small" page={filters.page} data={data.results} columns={columns} />

            <CardFooter
              onRefresh={onRefetch}
              onRefreshDisabled={!filters.filtered}
              page={filters.page}
              pages={data.total_pages}
              perPage={filters.per_page}
              onChangePerPage={onChangePerPage}
              rightSide={
                <>
                  <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                    {t('previous')}
                  </Button>

                  <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                    {t('next')}
                  </Button>
                </>
              }
            />
          </Loader>

          <CardAlert label={t('didYouFindProblem')} t="reportContacts" />
        </Card>
      </ContactsLayout>
    </>
  );
};
