import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Tooltip, Button, Icon } from 'ui/atoms';

import './SortBy.scss';

export type Sorts = 'data' | 'relevant' | '';

interface Sort {
  title: React.ReactNode;
  value: string;
  type?: 'asc' | 'desc';
}

interface Props {
  value: Sorts;
  options: Sort[];
  onChange: (newValue: string) => void;
}

export const SortBy: React.FC<Props> = ({ options, value, onChange }) => {
  const { t } = useIntl();

  const [changed, setChanged] = React.useState(false);
  const [active, setActive] = React.useState('');
  const [type, setType] = React.useState(value.startsWith('-') ? 'desc' : 'asc');

  React.useEffect(() => {
    if (changed) {
      onChange(`${type === 'desc' ? '-' : ''}${active}`);
    }
  }, [changed, type, active]);

  const onChangeHandler = React.useCallback((newValue) => {
    setActive((prevActive) => (prevActive !== newValue ? newValue : ''));

    setChanged(true);
  }, []);

  const onChangeType = React.useCallback(() => {
    if (!active.length && options.length) {
      setActive(options[0].value);
    }

    setType((s) => (s === 'asc' ? 'desc' : 'asc'));

    setChanged(true);
  }, [options, active]);

  const selected = React.useMemo(
    () => (options ? options.filter(({ value: val }) => val === value || `-${val}` === value) : []),
    [value, type],
  );

  return (
    <div className={`zh-sort-by-wrapper zh-sort-${type}`}>
      <Button onClick={onChangeType}>
        <Icon type="sort" />
      </Button>

      <Tooltip
        getTooltipContainer={(trigger) => trigger.parentNode as HTMLElement}
        overlayClassName="z-10"
        className="zh-sort-by"
        placement="bottomRight"
        content={
          <>
            <div className="zh-sort-by-title">{t('sortBy')}</div>

            <div className="zh-sort-by-items">
              {options
                ? options.map(({ value: val, title }) => {
                    const active = value === `${type === 'desc' ? '-' : ''}${val}`;

                    return (
                      <div
                        key={val}
                        className={`zh-sort-by-item${active ? ' zh-sort-by-item-active' : ''}`}
                        onClick={() => onChangeHandler(val)}
                      >
                        {title}

                        {active && <Icon type="check" />}
                      </div>
                    );
                  })
                : null}
            </div>
          </>
        }
      >
        <Button className={`zh-sort-by-button zh-sort-${type}`}>
          {selected.length ? `${t('by')} ${selected[0].title}` : t('sortBy')}
        </Button>
      </Tooltip>
    </div>
  );
};
