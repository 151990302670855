import * as React from 'react';
import { useHistory, useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { UserContext } from 'contexts';
import { parseQuery } from 'libs/object';
import { EditAccount, Head } from 'ui/organisms';

import { MyStudentsLayout } from '../organisms';

export const MyStudentEditPage: React.FC = () => {
  const { t } = useIntl();
  const { action } = useParams();
  const { push, goBack } = useHistory();
  const { userData } = React.useContext(UserContext);
  const [name, setName] = React.useState('');

  const id = React.useMemo(() => (userData.id === Number(action) ? 'my' : Number(action) ? Number(action) : action), [
    userData,
    action,
  ]);

  const onSuccess = (): void => {
    const { page } = parseQuery<{ page: number }>(window.location.search);
    push('ProfilePage', { query: { page }, action });
  };

  // TODO: fix onLoadedData args on EditAccount
  const onLoadedDataHandler = ({ first_name: firstName, last_name: lastName }: any): void =>
    setName([firstName, lastName].filter((i) => i).join(' '));

  return (
    <>
      <Head t={name || action !== 'add' ? 'editStudent' : 'registerStudent'} />

      <MyStudentsLayout>
        <h1>{t(action !== 'add' ? 'editStudent' : 'registerStudent')}</h1>

        <EditAccount id={id} role="student" onSuccess={onSuccess} onLoadedData={onLoadedDataHandler} onClose={goBack} />
      </MyStudentsLayout>
    </>
  );
};
