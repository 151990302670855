import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { EurasiaCity, City } from './cities.types';

export const cities = {
  get: {
    action: ({ lang: lang = 'en', ...params }) =>
      axios
        .get<EurasiaCity[]>(`cities`, {
          params,
          cancelToken: new cancelToken((c: Canceler) => (cities.get.cancel = c)),
        })
        .then((response) => ({
          ...response,
          data: response.data.map(
            (item): City => ({
              value: item.id,
              title:
                item[`title_${lang}` as 'title_en' | 'title_ro' | 'title_ru'] || item.title || item.title_en || 'n/a',
              countryID: item.country,
            }),
          ),
        })),
    cancel: (() => null) as Canceler,
  },
  active: {
    action: ({ lang: lang = 'en', ...params }) =>
      axios
        .get<EurasiaCity[]>(`cities/active-list`, {
          params,
          cancelToken: new cancelToken((c: Canceler) => (cities.get.cancel = c)),
        })
        .then((response) => ({
          ...response,
          data: response.data.map(
            (item): City => ({
              value: item.id,
              title:
                item[`title_${lang}` as 'title_en' | 'title_ro' | 'title_ru'] || item.title || item.title_en || 'n/a',
              countryID: item.country,
            }),
          ),
        })),
    cancel: (() => null) as Canceler,
  },
};
