import * as React from 'react';
import { notify } from 'react-notify-toast';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useStateHandlers } from 'hooks';
import { NomenclaturesContext } from 'contexts';
import { resources, file as fileApi } from 'libs/http/api';
import { EurasiaFile } from 'libs/http/api/file/file.types';
import { DataTypes, Resource } from 'libs/http/api/resources/resources.types';
import { Save } from 'libs/http/api/index.types';
import { keys } from 'libs/object';
import { Form, FormItems, FormItem } from 'ui/organisms';
import { FileUpload } from 'ui/molecules';
import { Modal, ModalFooterButton, Button, Icon, Select, Input, Loader, Alert } from 'ui/atoms';

interface Props {
  id?: number | null;
  onRefetch?: () => void;
  onClose: () => void;
  type: number | null;
}

export const UploadModal: React.FC<Props> = ({ id, onRefetch, onClose, type: $type }) => {
  const { t } = useIntl();
  const { getLanguages, languages } = React.useContext(NomenclaturesContext);
  const { request: requestResource, loading: loadingResource } = useRequest<Resource>({ data: {} });
  const { request: requestFile, loading: loadingFile, errors: errorsFile } = useRequest<EurasiaFile>();
  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<Save>();
  const { request: requestTypes, data: resourcesTypes, loading: loadingTypes } = useRequest<DataTypes[]>({
    loading: true,
  });
  const { roles } = React.useContext(NomenclaturesContext);

  const [state, setState] = useStateHandlers({
    type: null as number | null,
    name_ru: '',
    name_en: '',
    name_ro: '',
    name_es: '',
    description: '',
    language: null as number | null,
    file: '',
    role: roles,
  });

  React.useEffect(() => setState({ role: roles }), [roles]);

  React.useEffect(() => {
    getLanguages();
    requestTypes(resources.getTypes.action());

    return () => {
      resources.getResource.cancel();
      resources.getTypes.cancel();
    };
  }, []);

  React.useEffect(() => {
    const onFetchResource = async (): Promise<void> => {
      if (id) {
        const data = await requestResource(resources.getResource.action({ id }));

        setState((prevState) =>
          keys(prevState).reduce((acc, current) => {
            const item = data[current];

            return {
              ...acc,
              [current]: !Array.isArray(item) && typeof item !== 'string' ? item?.id : item || prevState[current],
            };
          }, {}),
        );
      }
    };

    onFetchResource();
  }, [id]);

  React.useEffect(() => {
    if ($type) {
      setState({ type: $type });
    }
  }, [$type]);

  const onSubmit = async (): Promise<void> => {
    const { file: $file, ...options } = state;
    let file = $file;

    if (file && typeof file !== 'string') {
      const fileFormData: any = new FormData();
      fileFormData.append('file', file);

      const { url } = await requestFile(fileApi.upload.action(fileFormData));
      file = url;
    }

    if (id) {
      await requestSave(resources.editResource.action({ ...options, id, file }));
    } else {
      await requestSave(resources.add.action({ ...options, file }));
    }

    onClose();

    notify.show(
      `${t('successUploadResources')} ${roleOptions
        .filter((option) => options.role.includes(option.value))
        .map((i) => i.title)
        .join(', ')}`,
      'success',
    );

    if (onRefetch) {
      onRefetch();
    }
  };

  const onChange = React.useCallback((target: string, value: any): void => setState({ [target]: value }), []);

  const resourceOptions = React.useMemo(
    () =>
      resourcesTypes.map((item) => ({
        value: item.id,
        title: `${item.name ? t(item.name) : ''} (${item.resources_count})`,
      })),
    [resourcesTypes],
  );

  const roleOptions = React.useMemo(() => roles.map((role) => ({ value: role, title: t(role) })), [roles]);

  return (
    <Modal
      mask={false}
      title={t('addFile')}
      onClose={onClose}
      footer={
        <ModalFooterButton>
          <Button onClick={onClose}>{t('cancel')}</Button>

          <Button
            submit
            form="upload-modal"
            type="primary"
            prefix={<Icon type="check" />}
            loading={loadingFile || loadingSave}
          >
            {t('upload')}
          </Button>
        </ModalFooterButton>
      }
    >
      <Alert message={dataSave.message || errors.non_field_errors || errors.detail} form type="error" />

      <Loader loading={loadingResource} height={464}>
        <Form onSubmit={onSubmit} id="upload-modal">
          <FormItems>
            <FormItem label="Name" extra={errors.name_en}>
              <Input value={state.name_en} onChange={(value) => onChange('name_en', value)} />
            </FormItem>

            <FormItem label="Denumire" extra={errors.name_ro}>
              <Input value={state.name_ro} onChange={(value) => onChange('name_ro', value)} />
            </FormItem>

            <FormItem label="Название" extra={errors.name_ru}>
              <Input value={state.name_ru} onChange={(value) => onChange('name_ru', value)} />
            </FormItem>

            <FormItem label="Nombre" extra={errors.name_es}>
              <Input value={state.name_es} onChange={(value) => onChange('name_es', value)} />
            </FormItem>
          </FormItems>

          <FormItem required label={t('language')} error={errors.role}>
            <Select
              getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
              cancelable
              placeholder={t('selectLanguage2')}
              options={languages.map(({ key: value, label: title }) => ({ value, title }))}
              value={state.language}
              onChange={(value) => onChange('language', value)}
            />
          </FormItem>

          <FormItem required label={t('role')} error={errors.role}>
            <Select
              getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
              mode="multiple"
              cancelable
              placeholder={t('selectRole')}
              options={roleOptions}
              value={state.role}
              onChange={(value) => onChange('role', value)}
            />
          </FormItem>

          <FormItem required label={t('fileType')}>
            <Select
              getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
              placeholder={t('selectTypeOfFile')}
              loading={loadingTypes}
              options={resourceOptions}
              value={state.type}
              onChange={(value) => onChange('type', value)}
            />
          </FormItem>

          <FormItem required label={t('description')} extra={errors.description}>
            <textarea value={state.description} onChange={({ target }) => onChange('description', target.value)} />
          </FormItem>

          <FormItem required label={t('attachFile')} extra={errors.file || errorsFile.file}>
            <FileUpload onChange={(value) => onChange('file', value)} />
          </FormItem>
        </Form>
      </Loader>
    </Modal>
  );
};
