import * as React from 'react';
import { redirect } from 'libs/social';
import { Icon } from 'ui/atoms';

import './Media.scss';

interface Props {
  className?: string;
  type: string;
  link: string;
}

export const Media: React.FC<Props> = ({ className = '', type, link }) => {
  const [pulse, setPulse] = React.useState<boolean>(false);
  const timer = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    return (): void => {
      if (timer.current !== undefined) {
        clearTimeout(timer.current);
        clearTimeout(timer.current);
      }
    };
  }, []);

  const onClickHandler = (): void => {
    setPulse(true);

    timer.current = setTimeout(() => {
      setPulse(false);
    }, 1000);

    redirect({ type, link });
  };

  return (
    <a onClick={onClickHandler} target="_blank" rel="noopener noreferrer" className={`zh-media ${className}`}>
      <Icon type={type} />
      {pulse ? <div className="zh-button-pulse" /> : null}
    </a>
  );
};

export const MediaGroup: React.FC<{ className?: string }> = ({ children, className = '' }) => (
  <div className={`zh-media-group ${className}`}>{children}</div>
);
