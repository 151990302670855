import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Icon, Input } from 'ui/atoms';

interface Props {
  onSearch: (value: string) => void;
  value?: string;
}

export const InputSearch: React.FC<Props> = ({ onSearch, value: $value }) => {
  const { t } = useIntl();
  const [changedValue, setChangedValue] = React.useState(false);
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if ($value !== undefined) {
      setChangedValue(true);
    }
  }, [$value]);

  React.useEffect(() => {
    if (changedValue && $value !== undefined && $value !== value) {
      setValue($value);
    }
  }, [changedValue, $value, value]);

  const onSearchHandler = (ev?: React.FormEvent<HTMLFormElement>): void => {
    if (ev && ev.preventDefault !== undefined) {
      ev.preventDefault();
    }

    onSearch(value);
  };

  const onChangeHandler = (newValue: string): void => {
    setChangedValue(false);

    setValue(newValue);
  };

  return (
    <form onSubmit={onSearchHandler}>
      <Input
        onClickSuffix={onSearchHandler}
        suffix={<Icon type="search" className="cursor" />}
        placeholder={t('searchByKeyword')}
        value={value}
        onChange={onChangeHandler}
        width={240}
        styleType="grey"
      />
    </form>
  );
};
