import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { getRoute, Link } from 'estafette-router';
import { routes } from 'routes';
import { useFilters } from 'hooks';
import { me } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { UserOutstandings } from 'libs/http/api/me/me.types';

import { Button, Info, Card, CardHeader, CardFooter, Loader, Time, EmptyList } from 'ui/atoms';
import { SortBy, InputSearch } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { dateTimeFormat, format } from 'libs/date';

import './Outstandings.scss';

const initialFilters = {
  filtered: false,
  page: 1,
  search: '',
  ordering: '' as Sorts,
  per_page: 3,
};

export const Outstandings: React.FC = () => {
  const { t } = useIntl();
  const { request, data, loading } = useRequest<Results<UserOutstandings>>({ data: { results: [] } });
  const [filters, setFilters] = useFilters({ ...initialFilters });

  React.useEffect(() => {
    return () => {
      me.getOutstandings.cancel();
    };
  }, []);

  React.useEffect(() => {
    request(me.getOutstandings.action({ ...filters }));
  }, [filters]);

  const onRefetch = (): void => setFilters({ ...initialFilters });

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  // because id of data isn't always unique one
  const $data = React.useMemo(() => data.results.map((item, key) => ({ ...item, key })), [data.results]);

  return (
    <Card className="outstandings">
      <CardHeader
        title={t('outstandings')}
        count={data.count}
        leftSide={<InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />}
        rightSide={
          <SortBy
            options={[
              {
                title: t('name'),
                value: 'name',
              },
              {
                title: t('course'),
                value: 'course',
              },
            ]}
            value={filters.ordering}
            onChange={(newValue) => onChangeFilters('ordering', newValue)}
          />
        }
      />

      <Loader loading={loading}>
        {$data.length > 0 ? (
          <div className="flex-data">
            {$data.map((item) => (
              <div key={item.key} className="flex-item">
                <div className="flex-item-row">
                  <div className="flex-item-row-item">
                    <label>{t('name')}</label>
                    <Info primary label={item.course.name} />
                  </div>

                  <div className="flex-item-row-item">
                    <label>{t('timeLimit')}</label>
                    <Time date={`${format(item.end_date, dateTimeFormat)}`} noParse />
                  </div>
                </div>

                <div className="flex-item-row">
                  <Link to={getRoute(routes, 'SessionPage', { id: item.id })}>
                    <Button type="simple">{t('seeDetails')}</Button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex-data">
            <EmptyList />
          </div>
        )}

        <CardFooter
          onRefresh={onRefetch}
          onRefreshDisabled={!filters.filtered}
          page={filters.page}
          pages={data.total_pages}
          rightSide={
            <>
              <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                {t('previous')}
              </Button>

              <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                {t('next')}
              </Button>
            </>
          }
        />
      </Loader>
    </Card>
  );
};
