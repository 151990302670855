import * as React from 'react';
import { notify } from 'react-notify-toast';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useStateHandlers } from 'hooks';
import { NomenclaturesContext, UserContext } from 'contexts';
import { faculties, institutions, sessions, me } from 'libs/http/api';
import { List, ListUser, Save } from 'libs/http/api/index.types';
import { InviteUsersOptions } from 'libs/http/api/sessions/sessions.types';
import { Form, FormItems, FormItem } from 'ui/organisms';
import { Modal, ModalFooterButton, Button, Icon, RichText, Select, Alert } from 'ui/atoms';

import './InviteStudent.scss';

interface Props {
  id?: number | null;
  onRefetch: () => void;
  onClose: () => void;
}

export const InviteStudent: React.FC<Props> = ({ id, onRefetch, onClose }) => {
  const { t } = useIntl();
  const { userData } = React.useContext(UserContext);
  const { getCountries, countries, loadingCountries, getCities, cities, loadingCities } = React.useContext(
    NomenclaturesContext,
  );

  const [search, setSearch] = React.useState('');
  const [searchCountries, setSearchCountries] = React.useState('');
  const [searchCities, setSearchCities] = React.useState('');

  const { request: requestUsers, loading: loadingUsers, data: dataUsers } = useRequest<ListUser[]>({ data: [] });
  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<Save>();
  const { request: requestInstitutions, data: dataInstitutions, loading: loadingInstitutions } = useRequest<List[]>();
  const { request: requestFaculties, data: dataFaculties, loading: loadingFaculties } = useRequest<List[]>();
  const api = React.useMemo(() => (id ? sessions : sessions), [id]);

  const [state, setState] = useStateHandlers<InviteUsersOptions>({
    users: [],
    emails: [],
    description: '',

    country: null,
    city: null,
    institution: null,
    faculty: null,
  });

  const [userState, setUserState] = React.useState<{ value: any; title: JSX.Element }[]>([]);

  React.useEffect(() => {
    return () => {
      api.inviteUsers.cancel();
      faculties.list.cancel();
      institutions.list.cancel();
      sessions.nonInvitedUsersList.cancel();
      me.getInviteStudentsList.cancel();
    };
  }, []);

  React.useEffect(() => {
    requestFaculties(faculties.list.action());
    requestInstitutions(institutions.list.action({ is_active: true }));
  }, [id]);

  React.useEffect(() => {
    getCountries(searchCountries);
  }, [id, searchCountries]);

  React.useEffect(() => {
    getCities(state.country as number | null, searchCities);
  }, [state.country, searchCities]);

  React.useEffect(() => {
    if (id) {
      requestUsers(sessions.nonInvitedUsersList.action({ id, search }));
    }
  }, [id, search]);

  React.useEffect(() => {
    if (!id && userData.id) {
      requestUsers(
        me.getInviteStudentsList.action({
          id: userData.id,
          institutions: state.institution || '',
          faculties: state.faculty || '',
        }),
      );
    }
  }, [id, state.institution, state.faculty, userData]);

  const handleSearchUser = (input: string): void => {
    setSearch(input);
  };

  const onSubmit = async (): Promise<void> => {
    const { city, country, faculty, institution, ...rest } = state;

    const options: InviteUsersOptions = { ...rest };
    if (!id) {
      options.city = city;
      options.country = country;
      options.faculty = faculty;
      options.institution = institution;
    }

    const data = await requestSave(api.inviteUsers.action({ ...options, id }));

    if (data.success) {
      onClose();
      onRefetch();

      notify.show(t('inviteSended'), 'success');
    }
  };

  const onChange = React.useCallback(
    (target: string, value: any): void =>
      setState({
        [target]: value,
        ...(target === 'country' ? { city: null, institution: null, faculty: null } : {}),
        ...(target === 'city' ? { institution: null, faculty: null } : {}),
        ...(target === 'institution' ? { faculty: null } : {}),
      }),
    [],
  );

  const onChangeUser = (target: string, value: any): void => {
    const t = [...userState, ...usersOptions];
    setUserState(t.filter((i, k) => t.indexOf(i) === k).filter((i) => value.includes(i.value)));
    onChange(target, value);
  };

  const emailsOptions = React.useMemo(
    () => (state.emails ? state.emails.map((email) => ({ value: email, title: email })) : []),
    [state.emails],
  );

  const usersOptions = React.useMemo(
    () =>
      dataUsers.map((user: any) => ({
        value: user.key,
        title: (
          <>
            <div className="invite-student-tag">
              <div className="invite-student-tag-title">{user.label}</div>

              <div className="invite-student-tag-email">{user.email || 'n/a'}</div>
            </div>

            {user.phone_number && <span className="number">+{user.phone_number}</span>}
          </>
        ),
      })),
    [dataUsers],
  );

  const institutionsOptions = React.useMemo(
    () => dataInstitutions.map((institution) => ({ value: institution.key, title: institution.label })),
    [dataInstitutions],
  );

  const facultiesOptions = React.useMemo(
    () => dataFaculties.map((faculty) => ({ value: faculty.key, title: faculty.label })),
    [dataFaculties],
  );

  const handleCountriesSearch = (input: string): void => {
    setSearchCountries(input);
  };

  const handleCitiesSearch = (input: string): void => {
    setSearchCities(input);
  };

  return (
    <Modal
      size="large"
      mask={false}
      title={t('inviteStudentTitle')}
      onClose={onClose}
      footer={
        <ModalFooterButton>
          <Button onClick={onClose} disabled={loadingSave}>
            {t('cancel')}
          </Button>

          <Button submit form="invite-student" type="primary" prefix={<Icon type="check" />} loading={loadingSave}>
            {t('send')}
          </Button>
        </ModalFooterButton>
      }
    >
      <Alert message={dataSave.message || errors.non_field_errors || errors.detail} type="error" />

      <Form onSubmit={onSubmit} id="invite-student">
        {!id && (
          <>
            <FormItems>
              <FormItem label={t('country')}>
                <Select
                  className="invite-student-select"
                  placeholder={t('selectCountry')}
                  options={countries}
                  onSearch={handleCountriesSearch}
                  loading={loadingCountries}
                  value={state.country}
                  onChange={(value) => onChange('country', value)}
                />
              </FormItem>

              <div className="zh-invite-student-condition">
                <b>&</b>
              </div>

              <FormItem label={t('city')}>
                <Select
                  className="invite-student-select"
                  placeholder={t('selectCity')}
                  options={state.country ? cities.filter((city) => city.countryID === state.country) : []}
                  onSearch={handleCitiesSearch}
                  loading={loadingCities}
                  value={state.city}
                  onChange={(value) => onChange('city', value)}
                  disabled={!state.country}
                />
              </FormItem>
            </FormItems>

            <FormItems>
              <FormItem label={t('institute')}>
                <Select
                  className="invite-student-select"
                  placeholder={t('selectInstitute')}
                  options={institutionsOptions}
                  loading={loadingInstitutions}
                  value={state.institution}
                  onChange={(value) => onChange('institution', value)}
                />
              </FormItem>

              <div className="zh-invite-student-condition">
                <b>&</b>
              </div>

              <FormItem label={t('faculty')}>
                <Select
                  className="invite-student-select"
                  placeholder={t('selectFaculty')}
                  options={facultiesOptions}
                  loading={loadingFaculties}
                  value={state.faculty}
                  onChange={(value) => onChange('faculty', value)}
                />
              </FormItem>
            </FormItems>
          </>
        )}

        <FormItems>
          <FormItem label={t('findPersonByNameOrPhone')} error={errors.users}>
            <Select
              cancelable
              mode="multiple"
              className="invite-student-select"
              placeholder={t('selectStudents')}
              options={[...usersOptions, ...userState.filter((item) => !dataUsers.find((i) => i.key === item.value))]}
              onSearch={handleSearchUser}
              value={state.users}
              loading={loadingUsers}
              onChange={(value) => onChangeUser('users', value)}
              disabled={state.emails && state.emails.length > 0}
            />
          </FormItem>

          <div className="zh-invite-student-condition">
            <b>{t('or')}</b>
          </div>

          <FormItem label={t('inviteByEmailOrPhone')} error={errors.emails}>
            <Select
              mode="tags"
              className="invite-student-select"
              placeholder={t('selectStudents')}
              options={emailsOptions}
              value={state.emails || []}
              loading={false}
              onChange={(value) => onChange('emails', value)}
              disabled={state.users && state.users.length > 0}
            />
          </FormItem>
        </FormItems>

        <FormItem label={t('message')} extra={errors.description}>
          <RichText initialValue={state.description} onChange={(value) => onChange('description', value)} />
        </FormItem>
      </Form>
    </Modal>
  );
};
