import { Results } from '../index.types';
import { axios, cancelToken } from '../../axios';
import { Canceler } from 'axios';
import { $Object } from '../../../object';
import { CertificationLevel, Graduation, StudentGraduation } from './graduations.types';

export const graduations = {
  get: {
    action: (params: $Object): Promise<{ data: Results<Graduation> }> =>
      axios.get(`graduations`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (graduations.get.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  add: {
    action: (data: object) =>
      axios.post(`graduations`, data, {
        cancelToken: new cancelToken((c: Canceler) => (graduations.add.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  load: {
    action: (id: number): Promise<{ data: Graduation }> =>
      axios.get(`graduations/${id}`, {
        cancelToken: new cancelToken((c: Canceler) => (graduations.load.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  save: {
    action: (id: number, data: $Object) =>
      axios.patch(`graduations/${id}`, data, {
        cancelToken: new cancelToken((c: Canceler) => (graduations.save.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  delete: {
    action: (id: number): Promise<{ data: Results<Graduation> }> =>
      axios.delete(`graduations/${id}`, {
        cancelToken: new cancelToken((c: Canceler) => (graduations.delete.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getStudents: {
    action: (params = {}): Promise<{ data: Results<any> }> =>
      axios.get(`graduations/students`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (graduations.getStudents.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  addStudent: {
    action: (options: $Object) =>
      axios.post(`graduations/students`, options, {
        cancelToken: new cancelToken((c: Canceler) => (graduations.addStudent.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  loadStudent: {
    action: (id: number): Promise<{ data: StudentGraduation }> =>
      axios.get(`graduations/students/${id}`, {
        cancelToken: new cancelToken((c: Canceler) => (graduations.loadStudent.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  saveStudent: {
    action: (id: number, options: $Object) =>
      axios.patch(`graduations/students/${id}`, options, {
        cancelToken: new cancelToken((c: Canceler) => (graduations.saveStudent.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  deleteStudent: {
    action: (id: number): Promise<{ data: Results<Graduation> }> =>
      axios.delete(`graduations/students/${id}`, {
        cancelToken: new cancelToken((c: Canceler) => (graduations.deleteStudent.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getCertificationLevels: {
    action: (params = {}): Promise<{ data: CertificationLevel[] }> =>
      axios.get(`graduations/certification-level`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (graduations.getCertificationLevels.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
