import * as React from 'react';
import { $Object } from 'libs/object';
import { Icon } from 'ui/atoms';
import { Confirmation } from 'ui/organisms';

import './Roadmap.scss';

export interface Roadmap {
  target: string;
  childrenData?: $Object;
  children: React.ReactNode;
}

interface Props {
  data: Roadmap[];
  className?: string;
  onDelete?: (id: number) => void;
}

export const Roadmap: React.FC<Props> = ({ data, className = '', onDelete }) => (
  <div className="zh-roadmap-wrapper">
    {data.map(({ target, childrenData, children }) => (
      <div className={`zh-roadmap ${className}`} key={target}>
        <div className="zh-roadmap-left">
          <div className="zh-roadmap-target">{target}</div>
          <div className="zh-roadmap-dot" />
        </div>

        {Array.isArray(children) ? (
          <div className="zh-roadmap-right-wrapper">
            {children.map((child: any, key) => {
              const childData = childrenData ? childrenData[key] : {};

              return (
                <div key={key}>
                  {key > 0 && (
                    <div className="zh-roadmap-left">
                      <div className="zh-roadmap-dot" />
                    </div>
                  )}
                  <div className="zh-roadmap-right">
                    {child}

                    {(onDelete && childData.can_delete === undefined) || (onDelete && childData.can_delete === true) ? (
                      <Confirmation onConfirm={() => onDelete(child.key)}>
                        <Icon type="close" />
                      </Confirmation>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    ))}
  </div>
);
