import * as React from 'react';
import { useRequest } from 'estafette';
import { useHistory, useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { NomenclaturesContext, UserContext } from 'contexts';
import { useStateHandlers } from 'hooks';
import { faculties, courses, users, institutions } from 'libs/http/api';
import { sessions } from 'libs/http/api/sessions/sessions';
import { UserList } from 'libs/http/api/users/users.types';
import { FacultiesList } from 'libs/http/api/faculties/faculties.types';
import { CourseList } from 'libs/http/api/courses/courses.types';
import { Session, SessionState } from 'libs/http/api/sessions/sessions.types';
import { EurasiaInstitution } from 'libs/http/api/institutions/institutions.types';
import { List, Save } from 'libs/http/api/index.types';
import { keys, parseQuery } from 'libs/object';
import { toISO } from 'libs/date';
import { Form, FormItem, FormItems, Head } from 'ui/organisms';
import { DateRangePicker } from 'ui/molecules';
import { Loader, Button, Fragment, Icon, Input, RichText, Select, Checkbox, Alert } from 'ui/atoms';

import { SessionsLayout } from '../organisms';

export const AddSessionPage: React.FC = () => {
  const { t } = useIntl();
  const { action } = useParams();
  const { goBack } = useHistory();
  const { request: requestSession, data: dataSession, loading: loadingSession } = useRequest<Session>({ data: {} });
  const { request: requestInstitutions, data: dataInstitutions, loading: loadingInstitutions } = useRequest<List[]>();
  const { request: requestInstitute, data: dataInstitute, loading: loadingInstitute } = useRequest<EurasiaInstitution>({
    data: {},
  });
  const { request: requestCourses, data: dataCourses, loading: loadingCourses } = useRequest<CourseList[]>();
  const { request: requestFaculties, data: dataFaculties, loading: loadingFaculties } = useRequest<FacultiesList[]>();
  const { request: requestProfessors, data: dataProfessors, loading: loadingProfessors } = useRequest<UserList[]>();
  const { request: requestProfessorsAll, data: dataProfessorsAll, loading: loadingProfessorsAll } = useRequest<
    UserList[]
  >();
  const {
    request: requestInstituteCoordinators,
    data: dataInstituteCoordinators,
    loading: loadingInstituteCoordinators,
  } = useRequest<UserList[]>();
  const { request: requestCoordinator, data: dataCoordinator, loading: loadingCoordinator } = useRequest<UserList[]>();
  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<Save>();
  const {
    getActiveCountries,
    activeCountries,
    loadingActiveCountries,
    getActiveCities,
    activeCities,
    loadingActiveCities,
    getActiveLanguages,
    activeLanguages,
    loadingActiveLanguages,
  } = React.useContext(NomenclaturesContext);
  const { userData } = React.useContext(UserContext);

  const [state, setState] = useStateHandlers<SessionState>({
    coordinator: null,
    professor: null,
    institution: null,
    faculty: null,
    course: null,
    start_date: '',
    end_date: '',
    address: '',
    status: 'pending',
    info: '',
    city: null,
    country: null,
    language: null,
    facebook_url: '',
    vkontakte_url: '',
    odnoklasniki_url: '',
    linkedin_url: '',
    viber: '',
    whatsup: '',
    skype: '',
    telegram: '',
    is_advance_level: false,
    is_bible_group: false,
    is_hidden: false,
  });

  const [searchCountries, setSearchCountries] = React.useState('');
  const [searchCities, setSearchCities] = React.useState('');

  React.useEffect(() => {
    const onFetchSession = async (): Promise<void> => {
      if (action && action !== 'add') {
        const data = await requestSession(sessions.load.action({ id: action }));

        setState((prevState) =>
          keys(prevState).reduce((acc, current) => {
            const item = data[current];

            return {
              ...acc,
              [current]:
                !Array.isArray(item) && item instanceof Object
                  ? item.id
                  : typeof item !== 'boolean'
                  ? item || prevState[current]
                  : item,
            };
          }, {}),
        );
      }
    };

    onFetchSession();
  }, [action]);

  React.useEffect(() => {
    requestFaculties(faculties.list.action());
    requestInstitutions(institutions.list.action({ is_active: true }));
    requestCoordinator(users.coordinatorsList.action({}));

    getActiveLanguages();

    return () => {
      faculties.list.cancel();
      users.professorsList.cancel();
      users.coordinatorsList.cancel();
      courses.list.cancel();
      sessions.add.cancel();
      sessions.save.cancel();
      institutions.list.cancel();
    };
  }, []);

  React.useEffect(() => {
    getActiveCountries(searchCountries);
  }, [searchCountries]);

  React.useEffect(() => {
    getActiveCities(state.country, searchCities);
  }, [state.country, searchCities]);

  React.useEffect(() => {
    if (state.institution) {
      requestInstituteCoordinators(users.coordinatorsList.action({ institutions__in: state.institution.toString() }));
      requestInstitute(institutions.load.action({ id: state.institution }));
    }
  }, [state.institution]);

  React.useEffect(() => {
    if (state.start_date && state.end_date) {
      requestProfessors(
        users.professorsList.action({
          ...(action !== 'add' && { session: action }),
          ...(state.start_date ? { start_date: toISO(state.start_date, true) } : {}),
          ...(state.end_date ? { end_date: toISO(state.end_date, true) } : {}),
          ...(state.institution ? { institution: state.institution } : {}),
        }),
      );

      requestProfessorsAll(
        users.professorsList.action({
          ...(action !== 'add' && { session: action }),
          ...(state.start_date ? { start_date: toISO(state.start_date, true) } : {}),
          ...(state.end_date ? { end_date: toISO(state.end_date, true) } : {}),
        }),
      );
    }
  }, [state.start_date, state.end_date, state.institution, action]);

  React.useEffect(() => {
    if (state.faculty) {
      requestCourses(courses.list.action({ faculties: state.faculty.toString() }));
    }
  }, [state.faculty]);

  const onSubmit = async (force?: boolean): Promise<void> => {
    const query: { [key: string]: any } = {};

    if (action === 'add') {
      await requestSave(
        sessions.add.action({
          ...state,
          ...(state.start_date ? { start_date: toISO(`${state.start_date} 10:00`) } : {}),
          ...(state.end_date ? { end_date: toISO(`${state.end_date} 16:00`) } : {}),
        }),
      );
    } else {
      await requestSave(
        sessions.save.action({
          ...state,
          ...(state.start_date ? { start_date: toISO(`${state.start_date} 10:00`) } : {}),
          ...(state.end_date ? { end_date: toISO(`${state.end_date} 16:00`) } : {}),
          id: action,
          ...(force ? { professor_status: 'accepted' } : {}),
          ...(force ? { status: 'accepted' } : {}),
        }),
      );

      const { page } = parseQuery<{ page: number }>(window.location.search);
      query.page = page;
    }

    goBack();
  };

  const onChange = React.useCallback(
    (target: string, value: any): void =>
      setState({
        [target]: value,
        ...(target === 'country' ? { city: null, professor: null } : {}),
        ...(target === 'city' ? { professor: null } : {}),
      }),
    [],
  );
  const onCancel = (): void => goBack();

  const institutionsOptions = React.useMemo(
    () => dataInstitutions.map((institution) => ({ value: institution.key, title: institution.label })),
    [dataInstitutions],
  );

  const languageOptions = React.useMemo(
    () => activeLanguages.map((language) => ({ value: language.key, title: language.label })),
    [activeLanguages],
  );

  const professorOptions = React.useMemo(
    () =>
      (dataSession.professor
        ? [
            {
              value: dataSession.professor.id,
              title: (
                <>
                  {`${dataSession.professor.first_name} ${dataSession.professor.last_name}`}
                  <span className="grade" aria-experience={dataSession.professor.experience}>
                    {dataSession.professor.experience}
                  </span>
                </>
              ),
              recommended: true,
            },
          ]
        : []
      )
        .concat(
          dataProfessors
            .filter((item) => (dataSession.professor ? dataSession.professor.id !== item.key : true))
            .map((professor) => ({
              value: professor.key,
              title: (
                <>
                  {professor.label}
                  <div className="info-grade">
                    {professor.session_count !== undefined && (
                      <span className="grade square">{professor.session_count}</span>
                    )}
                    <span className="grade" aria-experience={professor.experience}>
                      {professor.experience}
                    </span>
                  </div>
                </>
              ),
              recommended: true,
            })),
        )
        .concat(
          dataProfessorsAll
            .filter((item) => {
              const alreadyExist = dataProfessors.filter((professor) => professor.key === item.key).length;

              return dataSession.professor ? !alreadyExist && dataSession.professor.id !== item.key : !alreadyExist;
            })
            .map((professor) => ({
              value: professor.key,
              title: (
                <>
                  {professor.label}
                  <span className="grade" aria-experience={professor.experience}>
                    {professor.experience}
                  </span>
                </>
              ),
              recommended: false,
            })),
        ),
    [dataProfessors, dataProfessorsAll, dataSession],
  );

  const coordinatorInstituteOptions = React.useMemo(
    () =>
      dataInstituteCoordinators && dataInstituteCoordinators.length
        ? dataInstituteCoordinators.map((professor) => ({
            value: professor.key,
            title: professor.label,
            recommended: true,
          }))
        : [],
    [dataInstituteCoordinators],
  );

  React.useEffect(() => {
    if (coordinatorInstituteOptions && !state.coordinator && coordinatorInstituteOptions.length) {
      setState({ coordinator: coordinatorInstituteOptions[0].value });
    }
  }, [coordinatorInstituteOptions]);

  React.useEffect(() => {
    if (dataInstitute.country) {
      setState({ country: dataInstitute.country.id });
    }

    if (dataInstitute.city) {
      setState({ city: dataInstitute.city.id });
    }
  }, [dataInstitute]);

  const coordinatorOptions = React.useMemo(
    () =>
      dataCoordinator.map((coordinator) => ({ value: coordinator.key, title: coordinator.label, recommended: false })),
    [dataCoordinator],
  );

  const facultiesOptions = React.useMemo(
    () => dataFaculties.map((faculty) => ({ value: faculty.key, title: faculty.label })),
    [dataFaculties],
  );

  const coursesOptions = React.useMemo(
    () => dataCourses.map((course) => ({ value: course.key, title: course.label })),
    [dataCourses],
  );

  const coordinatorList = React.useMemo(
    () =>
      coordinatorInstituteOptions.concat(
        coordinatorOptions.filter((item) => !coordinatorInstituteOptions.filter((i) => i.value === item.value).length),
      ),
    [coordinatorInstituteOptions, coordinatorOptions],
  );

  const isEdit = React.useMemo(() => action !== 'add', [action]);

  const handleCountriesSearch = (input: string): void => {
    setSearchCountries(input);
  };

  const handleCitiesSearch = (input: string): void => {
    setSearchCities(input);
  };

  return (
    <>
      <Head t={action ? 'editSession' : 'addSession'} />

      <SessionsLayout showTabs={false}>
        <h1>{t(action ? 'editSession' : 'addSession')}</h1>

        <Alert message={dataSave.message || errors.non_field_errors || errors.detail} form type="error" />

        <Form onSubmit={onSubmit}>
          <Fragment
            footer={
              <>
                <Button onClick={onCancel}>{t('cancel')}</Button>

                <Button submit type="primary" prefix={<Icon type="check" />} loading={loadingSave}>
                  {t('confirm')}
                </Button>

                {userData.role === 'admin' && state.status !== 'accepted' && (
                  <Button
                    onClick={() => onSubmit(true)}
                    type="invert"
                    prefix={<Icon type="arrow-right-strict" />}
                    loading={loadingSave}
                  >
                    {t('forcePublish')}
                  </Button>
                )}
              </>
            }
          >
            <Loader loading={isEdit && loadingSession} height="100vh">
              <FormItems className="mx-w-660">
                <FormItem required label={t('institute')} extra={errors.institution}>
                  <Select
                    placeholder={t('selectInstitute')}
                    options={institutionsOptions}
                    loading={loadingInstitutions}
                    value={state.institution}
                    onChange={(value): void => onChange('institution', value)}
                  />
                </FormItem>

                {userData.role !== 'coordinator' && (
                  <FormItem label={t('status')} extra={errors.status}>
                    <Select
                      placeholder={t('selectStatus')}
                      options={[
                        { value: 'pending', title: t('pending') },
                        { value: 'accepted', title: t('accepted') },
                        { value: 'declined', title: t('declined') },
                      ]}
                      value={['pending', 'accepted', 'declined'].indexOf(state.status) > -1 ? state.status : 'pending'}
                      onChange={(value): void => onChange('status', value)}
                    />
                  </FormItem>
                )}
              </FormItems>

              <FormItems className="mx-w-660">
                <FormItem required label={t('country')} extra={errors.country}>
                  <Select
                    placeholder={t('selectCountry')}
                    options={activeCountries}
                    onSearch={handleCountriesSearch}
                    loading={loadingActiveCountries || loadingInstitute}
                    value={state.country}
                    onChange={(value): void => onChange('country', value)}
                  />
                </FormItem>

                <FormItem
                  required
                  label={t('city')}
                  extra={errors.city ? errors.city : !state.country ? t('selectCountryError') : ''}
                  extraStatus={errors.city ? 'error' : 'regular'}
                >
                  <Select
                    placeholder={t('selectCity')}
                    options={state.country ? activeCities.filter((city) => city.countryID === state.country) : []}
                    loading={loadingActiveCities || loadingInstitute}
                    onSearch={handleCitiesSearch}
                    value={state.city}
                    onChange={(value): void => onChange('city', value)}
                    disabled={!state.country}
                  />
                </FormItem>
              </FormItems>

              <FormItem className="mx-w-660" label={t('fullAddress')} extra={errors.address}>
                <Input value={state.address} onChange={(value) => onChange('address', value)} />
              </FormItem>

              <hr className="hr-2" />

              <FormItems className="mx-w-660">
                <FormItem required label={t('faculty')} extra={errors.faculty}>
                  <Select
                    placeholder={t('selectFaculty')}
                    options={facultiesOptions}
                    loading={loadingFaculties}
                    value={state.faculty}
                    onChange={(value) => onChange('faculty', value)}
                  />
                </FormItem>

                <FormItem
                  required
                  label={t('course')}
                  extra={errors.course ? errors.course : !state.faculty ? t('selectFacultyError') : ''}
                  extraStatus={errors.course ? 'error' : 'regular'}
                >
                  <Select
                    placeholder={t('selectCourse')}
                    options={coursesOptions}
                    loading={loadingCourses}
                    value={state.course}
                    onChange={(value) => onChange('course', value)}
                    disabled={!state.faculty}
                  />
                </FormItem>
              </FormItems>

              <hr className="hr-2" />

              <h2 className="medium mb-20">{t('deploymentPeriod')}</h2>

              <FormItems className="mx-w-660">
                <FormItem required label={t('startDate')} extra={errors.start_date}>
                  <DateRangePicker
                    minDate={userData.role === 'admin' ? undefined : new Date()}
                    type="date"
                    date={state.start_date}
                    onChange={({ date__gte: value }) => onChange('start_date', value)}
                  />
                </FormItem>

                <FormItem required label={t('endDate')} extra={errors.end_date}>
                  <DateRangePicker
                    minDate={(state.start_date && new Date(toISO(state.start_date, true))) || new Date()}
                    type="date"
                    date={state.end_date}
                    onChange={({ date__gte: value }) => onChange('end_date', value)}
                  />
                </FormItem>
              </FormItems>

              {userData.role !== 'coordinator' && (
                <FormItems className="mx-w-660">
                  <FormItem required extra={errors.is_advance_level}>
                    <Checkbox
                      checked={state.is_advance_level}
                      onChange={() => onChange('is_advance_level', !state.is_advance_level)}
                      label={t('isAdvanceLevel')}
                    />
                  </FormItem>

                  <FormItem extra={errors.is_bible_group}>
                    <Checkbox
                      checked={state.is_bible_group}
                      onChange={() => onChange('is_bible_group', !state.is_bible_group)}
                      label={t('isBibleGroup')}
                    />
                  </FormItem>
                </FormItems>
              )}

              {userData.role !== 'coordinator' && (
                <>
                  <hr className="hr-2" />

                  <FormItems className="mx-w-660">
                    <FormItem
                      required
                      label={t('coordinator')}
                      extra={errors.coordinator ? errors.coordinator : !state.country ? t('selectCountryError') : ''}
                      extraStatus={errors.coordinator ? 'error' : 'regular'}
                    >
                      <Select
                        placeholder={t('selectCoordinator')}
                        options={coordinatorList}
                        value={state.coordinator}
                        loading={loadingCoordinator || loadingInstituteCoordinators}
                        onChange={(value) => onChange('coordinator', value)}
                        disabled={!state.country && !coordinatorInstituteOptions.length}
                      />
                    </FormItem>

                    <FormItem
                      label={t('teacher')}
                      extra={
                        !state.start_date || !state.end_date
                          ? t('selectPeriodError')
                          : errors.professor
                          ? errors.professor
                          : ''
                      }
                      extraStatus={errors.professor ? 'error' : 'regular'}
                    >
                      <Select
                        placeholder={t('selectProfessor')}
                        options={professorOptions}
                        value={state.professor}
                        loading={loadingProfessors || loadingProfessorsAll}
                        onChange={(value) => onChange('professor', value)}
                        disabled={!state.start_date || !state.end_date}
                        cancelable
                      />
                    </FormItem>
                  </FormItems>

                  <hr className="hr-2" />
                </>
              )}

              <FormItem required className="mx-w-660" label={t('language')} extra={errors.language}>
                <Select
                  placeholder={t('selectLanguage')}
                  loading={loadingActiveLanguages}
                  options={languageOptions}
                  value={state.language}
                  onChange={(value): void => onChange('language', value)}
                />
              </FormItem>

              <h2 className="medium mb-20">{t('socialsNetworks')}</h2>

              <FormItems className="mx-w-660">
                <FormItem label="Facebook" extra={errors.facebook_url}>
                  <Input value={state.facebook_url} onChange={(value): void => onChange('facebook_url', value)} />
                </FormItem>

                <FormItem label="Vkontakte" extra={errors.vkontakte_url}>
                  <Input value={state.vkontakte_url} onChange={(value): void => onChange('vkontakte_url', value)} />
                </FormItem>
              </FormItems>

              <FormItems className="mx-w-660">
                <FormItem label="Odnoklassniki" extra={errors.odnoklasniki_url}>
                  <Input
                    value={state.odnoklasniki_url}
                    onChange={(value): void => onChange('odnoklasniki_url', value)}
                  />
                </FormItem>

                <FormItem label="Linkedin" extra={errors.linkedin_url}>
                  <Input value={state.linkedin_url} onChange={(value): void => onChange('linkedin_url', value)} />
                </FormItem>
              </FormItems>

              <hr className="hr-2" />

              <FormItem className="mx-w-660" label={t('othersInformation')} extra={errors.info}>
                <RichText initialValue={state.info} onChange={(value): void => onChange('info', value)} />
              </FormItem>

              <FormItem required extra={errors.is_hidden}>
                <Checkbox
                  checked={state.is_hidden}
                  onChange={() => onChange('is_hidden', !state.is_hidden)}
                  label={t('isHidden')}
                />
              </FormItem>
            </Loader>
          </Fragment>
        </Form>
      </SessionsLayout>
    </>
  );
};
