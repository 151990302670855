import * as React from 'react';
import { Head } from 'ui/organisms';

import { SessionApplicationsTemplate } from '../templates';
import { SessionsLayout } from '../organisms';

export const SessionApplicationsPage: React.FC = () => (
  <>
    <Head t="sessionRequests" />

    <SessionsLayout>
      <SessionApplicationsTemplate />
    </SessionsLayout>
  </>
);
