import * as React from 'react';
import { useRequest } from 'estafette';
import { Link } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { useFilters } from 'hooks';
import { UserContext } from 'contexts';
import { dateTimeFormat, format } from 'libs/date';
import { sessions } from 'libs/http/api/sessions/sessions';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { Results } from 'libs/http/api/index.types';
import { perPage } from 'libs/storage';
import { Invitation } from 'libs/http/api/events/events.types';
import { parseQuery } from 'libs/object';
import { SortBy, InputSearch, Confirmation } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { Status, Info, Card, CardHeader, CardFooter, Icon, Button, Table, Loader, Time, CardAlert } from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';
import { Expande } from 'ui/atoms/Table/Expanded';

import './SessionInvites.scss';

const { page = 1 } = parseQuery<{ page: number }>(window.location.search);

export const SessionInvitesTemplate: React.FC<{ onUpdate?: () => void }> = ({ onUpdate }) => {
  const { t } = useIntl();
  const { userData } = React.useContext(UserContext);

  const { request: requestRejectApplication, loading: loadingRejectApplication } = useRequest();
  const { request: requestAcceptApplication, loading: loadingAcceptApplication } = useRequest();
  const { request, data, loading } = useRequest<Results<Invitation>>({ data: { results: [] } });
  const initialFilters = {
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    ordering: '' as Sorts,
  };

  const [selectedId, setSelectedId] = React.useState<number | null>(null);
  const [filters, setFilters] = useFilters({ ...initialFilters, page });

  React.useEffect(() => {
    return () => {
      sessions.invitations.cancel();
      sessions.save.cancel();
    };
  }, []);

  React.useEffect(() => {
    request(sessions.invitations.action(filters));
  }, [filters]);

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onRejectApplication = React.useCallback(async (id: number): Promise<void> => {
    setSelectedId(id);

    await requestRejectApplication(sessions.respondToInvitations.action({ id, status: 'declined' }));
    await onRefetch();

    setSelectedId(null);

    if (onUpdate !== undefined) {
      onUpdate();
    }
  }, []);

  const onAcceptApplication = React.useCallback(async (id: number): Promise<void> => {
    setSelectedId(id);

    await requestAcceptApplication(sessions.respondToInvitations.action({ id, status: 'accepted' }));
    await onRefetch();

    setSelectedId(null);

    if (onUpdate !== undefined) {
      onUpdate();
    }
  }, []);

  const columns: Column<Invitation>[] = React.useMemo(
    () => [
      {
        title: t('period'),
        width: 240,
        render: (record) => (
          <Time date={`${format(record.start_date)} - ${format(record.end_date)}`} noParse className="nowrap" />
        ),
        className: 'zh-time-period-cell',
      },
      {
        title: t('place'),
        className: 'zh-place-cell',
        render: ({ country, city }) =>
          country && country.title ? (
            <Info
              icon={<Icon type="pin" />}
              label={[country && country.title, city && city.title].filter((i) => i).join(', ')}
            />
          ) : (
            '---'
          ),
      },
      {
        title: t('institute'),
        render: ({ institution }) => (institution && institution.name) || '---',
      },
      {
        title: t('course'),
        className: 'zh-course-cell',
        render: ({ course }) => (course && course.name) || '---',
      },
      {
        title: t('numOfRegisteredStudentsShort'),
        width: 80,
        render: ({ number_of_students: students }) => (students && <b>{students}</b>) || 0,
      },
      {
        action: true,
        render: ({ id, status, is_bible_group: isBibleGroup, ...session }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap nowrap">
            <Link className="nowrap" route="GeneralSessionPage" params={{ id }}>
              {t('details')}
            </Link>

            {userData.role !== 'student' ? (
              <>
                <Confirmation onConfirm={() => onRejectApplication(id)}>
                  <Button
                    size="small"
                    prefix={<Icon type="cancel" />}
                    loading={loadingRejectApplication && selectedId === id}
                  >
                    {t('reject')}
                  </Button>
                </Confirmation>

                <Confirmation
                  isBibleGroup={isBibleGroup}
                  expanded={expandable(session as Session)}
                  onConfirm={() => onAcceptApplication(id)}
                >
                  <Button
                    size="small"
                    type="primary"
                    prefix={<Icon type="check" />}
                    loading={loadingAcceptApplication && selectedId === id}
                  >
                    {t('accept')}
                  </Button>
                </Confirmation>
              </>
            ) : (
              <Status label={t(status)} status={status} />
            )}
          </div>
        ),
        className: 'nowrap',
      },
    ],
    [t, userData.role],
  );

  const expandable = ({
    professor,
    start_date: startDate,
    end_date: endDate,
    country,
    city,
    course,
    institution,
    language,
    faculty,
  }: Session): Expande => [
    [
      { label: t('course'), value: (course && course.name) || '---', icon: <Icon type="requests" /> },
      {
        label: t('startAt'),
        value: startDate ? format(startDate, dateTimeFormat) : '---',
        icon: <Icon type="calendar" />,
      },
      {
        label: t('finishAt'),
        value: startDate ? format(endDate, dateTimeFormat) : '---',
        icon: <Icon type="calendar" />,
      },
    ],
    [
      {
        label: t('teacher'),
        value: professor ? [professor.first_name, professor.last_name].filter((i) => i).join(' ') : '---',
      },
      {
        label: t('place'),
        value:
          country && country.title ? [country && country.title, city && city.title].filter((i) => i).join(', ') : '---',
        icon: <Icon type="pin" />,
      },
      {
        label: t('faculty'),
        value: faculty ? faculty.name : '---',
      },
    ],
    [
      { label: t('institute'), value: (institution && institution.name) || '---', icon: <Icon type="location" /> },
      { label: t('language'), value: (language && language.name) || '---' },
    ],
  ];

  return (
    <Card>
      <CardHeader
        title={t('sessionRequests')}
        count={data.count}
        leftSide={<InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />}
        rightSide={
          <SortBy
            options={[
              {
                title: t('name'),
                value: 'name',
              },
              {
                title: t('country'),
                value: 'country',
              },
              {
                title: t('city'),
                value: 'city',
              },
            ]}
            value={filters.ordering}
            onChange={(newValue) => onChangeFilters('ordering', newValue)}
          />
        }
      />

      <Loader loading={loading}>
        <Table size="small" page={filters.page} data={data.results} columns={columns} />

        <CardFooter
          onRefresh={onRefetch}
          onRefreshDisabled={!filters.filtered}
          page={filters.page}
          pages={data.total_pages}
          perPage={filters.per_page}
          onChangePerPage={onChangePerPage}
          rightSide={
            <>
              <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                {t('previous')}
              </Button>

              <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                {t('next')}
              </Button>
            </>
          }
        />
      </Loader>

      <CardAlert label={t('didYouFindProblem')} t="reportApplicationsSessions" />
    </Card>
  );
};
