import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { Link } from 'estafette-router';
import { useFilters } from 'hooks';
import { UserContext } from 'contexts';
import { dateTimeFormat, dateFormat, toISO, format } from 'libs/date';
import { grade } from 'libs/mask';
import { perPage } from 'libs/storage';
import { issues } from 'libs/http/api/issues/issues';
import { Results } from 'libs/http/api/index.types';
import { Issue } from 'libs/http/api/issues/issues.types';
import { SortBy, InputSearch, Head, Confirmation, FormItem, FormItems, Form } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { DateRangePicker } from 'ui/molecules';
import {
  AvatarInline,
  Button,
  Icon,
  Info,
  Card,
  CardHeader,
  CardFooter,
  Loader,
  Time,
  EmptyList,
  Modal,
  ModalFooterButton,
  Input,
} from 'ui/atoms';

import { ApplicationsLayout } from '../../organisms';

import './ApplicationsPage.scss';

export const ApplicationsPage: React.FC = () => {
  const { t } = useIntl();
  const { userData } = React.useContext(UserContext);
  const { request, data, loading } = useRequest<Results<Issue>>({ data: { results: [] } });
  const { request: requestAccept, loading: loadingAccept } = useRequest();
  const { request: requestReject, loading: loadingReject } = useRequest();
  const { request: requestArchive, loading: loadingArchive } = useRequest();
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    ordering: '' as Sorts,
    created_at__gte: '',
    created_at__lte: '',
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  const [selectedIssue, setSelectedIssue] = React.useState<Issue>();

  React.useEffect(() => {
    return () => {
      issues.get.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (!loadingAccept && !loadingReject && !loadingArchive) {
      request(
        issues.get.action({
          ...filters,
          created_at__gte: toISO(filters.created_at__gte),
          created_at__lte: toISO(filters.created_at__lte),
        }),
      );
    }
  }, [filters, loadingAccept, loadingReject, loadingArchive]);

  const onChangePeriod = (period: any): void =>
    (period.start_date__gte || period.end_date__lte) &&
    setFilters({
      filtered: true,
      page: 1,
      ...(period.start_date__gte ? { created_at__gte: period.start_date__gte } : {}),
      ...(period.end_date__lte ? { created_at__lte: period.end_date__lte } : {}),
    });
  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetchInvites = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onUpdateIssue = async ({
    id,
    status,
    session,
    student,
    details,
  }: {
    id: number;
    status: 'accepted' | 'declined';
    session: number;
    student?: number;
    details?: any;
  }): Promise<void> => {
    const data = {
      id,
      status,
      session,
      student,
      details,
    };

    if (status === 'accepted') await requestAccept(issues.update.action(data));
    else await requestReject(issues.update.action(data));
  };

  const handleArchive = (id: number): void => {
    requestArchive(issues.delete.action(id));
  };

  const handleApprove = (item: Issue): void => {
    if (item.type === 'recover_grade') {
      setSelectedIssue(item); //todo rename to setOpenRecoveryGradeModal(item)
    } else if (item.type === 'change_grade') {
      //todo setOpenChangeGradeModal(item)
      setSelectedIssue(item);
    } else {
      if (item.session) {
        onUpdateIssue({
          id: item.id,
          status: 'accepted',
          session: item.session.id,
          student: item.student,
        });
      }
    }
  };

  const resetSelectedIssue = (): void => setSelectedIssue(undefined);

  const handleChangeGrade = (value: string, field: string): void =>
    setSelectedIssue((s) => {
      if (!s) return s;
      const details = { ...s.details, [field]: grade(value) };
      return { ...s, details };
    });

  const handleApproveRecoveryGrades = (): void => {
    const sessionId = selectedIssue?.session?.id;
    if (selectedIssue && sessionId) {
      onUpdateIssue({
        id: selectedIssue.id,
        session: sessionId,
        details: selectedIssue.details,
        status: 'accepted',
      }).then(() => resetSelectedIssue());
    }
  };

  return (
    <>
      <Head t="applications" />

      <ApplicationsLayout title={t('applications')}>
        <Card className="applications">
          <CardHeader
            title={t('applications')}
            count={data.count}
            leftSide={
              <InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />
            }
            rightSide={
              <>
                <div className="picker-wrapper">
                  <div className="hideTablet">{t('period')}</div>

                  <DateRangePicker
                    from={filters.created_at__gte}
                    to={filters.created_at__lte}
                    type="period"
                    onChange={onChangePeriod}
                  />
                </div>

                <SortBy
                  options={[
                    {
                      title: t('title'),
                      value: 'title',
                    },
                  ]}
                  value={filters.ordering}
                  onChange={(newValue) => onChangeFilters('ordering', newValue)}
                />
              </>
            }
          />

          {selectedIssue && (
            <Modal
              title={t('recoveryGrades')}
              footer={
                <ModalFooterButton align="center">
                  <Button onClick={resetSelectedIssue}>{t('cancel')}</Button>
                  <Button submit type="primary" prefix={<Icon type="check" />} onClick={handleApproveRecoveryGrades}>
                    {t('confirm')}
                  </Button>
                </ModalFooterButton>
              }
              size="small"
            >
              <Form onSubmit={handleApproveRecoveryGrades} id="recovery-grades">
                <FormItems>
                  <FormItem>
                    {selectedIssue.details?.old && (
                      <FormItem label={t('old_grade')}>
                        <div className="mb-10">
                          <Input
                            value={
                              selectedIssue.details?.old?.course_grade ? selectedIssue.details.old.course_grade : '--'
                            }
                            disabled
                          />
                        </div>
                      </FormItem>
                    )}
                    <div>
                      <FormItem label={t('course_grade')}>
                        <Input
                          value={selectedIssue.details.course_grade}
                          onChange={(value) => handleChangeGrade(value, 'course_grade')}
                        />
                      </FormItem>
                    </div>
                  </FormItem>
                  <FormItem className="mr-10">
                    {selectedIssue.details?.old && (
                      <FormItem label={t('old_grade')}>
                        <div className="mb-10">
                          <Input
                            value={
                              selectedIssue.details?.old?.individual_work_grade
                                ? selectedIssue.details.old.individual_work_grade
                                : '--'
                            }
                            disabled
                          />
                        </div>
                      </FormItem>
                    )}
                    <div>
                      <FormItem label={t('individual_work_grade')}>
                        <Input
                          value={selectedIssue.details.individual_work_grade}
                          onChange={(value) => handleChangeGrade(value, 'individual_work_grade')}
                        />
                      </FormItem>
                    </div>
                  </FormItem>
                </FormItems>
                <FormItems>
                  <div className="mr-10">
                    {selectedIssue.details?.old && (
                      <div className="mb-10">
                        <FormItem label={t('old_grade')}>
                          <Input
                            value={
                              selectedIssue.details?.old?.practise_sub_grade_1
                                ? selectedIssue.details.old.practise_sub_grade_1
                                : '--'
                            }
                            disabled
                          />
                        </FormItem>
                      </div>
                    )}
                    <div>
                      <FormItem label={t('practise_sub_grade_1')}>
                        <Input
                          value={selectedIssue.details.practise_sub_grade_1}
                          onChange={(value) => handleChangeGrade(value, 'practise_sub_grade_1')}
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="mr-10">
                    {selectedIssue.details?.old && (
                      <div className="mb-10">
                        <FormItem label={t('old_grade')}>
                          <Input
                            value={
                              selectedIssue.details?.old?.practise_sub_grade_2
                                ? selectedIssue.details.old.practise_sub_grade_2
                                : '--'
                            }
                            disabled
                          />
                        </FormItem>
                      </div>
                    )}
                    <div>
                      <FormItem label={t('practise_sub_grade_2')}>
                        <Input
                          value={selectedIssue.details.practise_sub_grade_2}
                          onChange={(value) => handleChangeGrade(value, 'practise_sub_grade_2')}
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="mr-10">
                    {selectedIssue.details?.old && (
                      <div className="mb-10">
                        <FormItem label={t('old_grade')}>
                          <Input
                            value={
                              selectedIssue.details?.old?.practise_sub_grade_3
                                ? selectedIssue.details.old.practise_sub_grade_3
                                : '--'
                            }
                            disabled
                          />
                        </FormItem>
                      </div>
                    )}
                    <div>
                      <FormItem label={t('practise_sub_grade_3')}>
                        <Input
                          value={selectedIssue.details.practise_sub_grade_3}
                          onChange={(value) => handleChangeGrade(value, 'practise_sub_grade_3')}
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="mr-10">
                    {selectedIssue.details?.old && (
                      <div className="mb-10">
                        <FormItem label={t('old_grade')}>
                          <Input
                            value={
                              selectedIssue.details?.old?.practise_sub_grade_4
                                ? selectedIssue.details.old.practise_sub_grade_4
                                : '--'
                            }
                            disabled
                          />
                        </FormItem>
                      </div>
                    )}
                    <div>
                      <FormItem label={t('practise_sub_grade_4')}>
                        <Input
                          value={selectedIssue.details.practise_sub_grade_4}
                          onChange={(value) => handleChangeGrade(value, 'practise_sub_grade_4')}
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="mr-10">
                    {selectedIssue.details?.old && (
                      <div className="mb-10">
                        <FormItem label={t('old_grade')}>
                          <Input
                            value={
                              selectedIssue.details?.old?.practise_sub_grade_5
                                ? selectedIssue.details.old.practise_sub_grade_5
                                : '--'
                            }
                            disabled
                          />
                        </FormItem>
                      </div>
                    )}
                    <div>
                      <FormItem label={t('practise_sub_grade_5')}>
                        <Input
                          value={selectedIssue.details.practise_sub_grade_5}
                          onChange={(value) => handleChangeGrade(value, 'practise_sub_grade_5')}
                        />
                      </FormItem>
                    </div>
                  </div>
                </FormItems>
              </Form>
            </Modal>
          )}

          <Loader loading={loading}>
            <div className="flex-data">
              {!data.results.length && <EmptyList />}

              {data.results.map((item) => (
                <div className="flex-item" key={item.id}>
                  <div className="flex-item-row">
                    <div className="flex flex-item-row-col">
                      <div>
                        {item.title && <Info primary label={item.type === 'regular' ? t(item.title) : item.title} />}
                        <p className="text-transform-inherit" dangerouslySetInnerHTML={{ __html: item.description }} />
                      </div>

                      <div>
                        {item.session && !item.details ? (
                          <Link
                            route="GeneralSessionPage"
                            params={{ id: item.session.id }}
                            className="flex-item flex-item-map pointer"
                          >
                            {item.session.course?.name}
                          </Link>
                        ) : null}
                      </div>
                      <div className="flex flex-justify-end flex-nowrap">
                        {item.type === 'regular' && (
                          <Button
                            type="regular"
                            prefix={<Icon type="mail" />}
                            onClick={() =>
                              window.open(
                                `mailto:${item.created_by.email}?subject=${t(item.title).replace(' ', '%20')}`,
                              )
                            }
                          >
                            {t('sendMessage')}
                          </Button>
                        )}
                        {userData.role === 'admin' && (
                          <Confirmation onConfirm={() => handleArchive(item.id)}>
                            <Button className="archive" prefix={<Icon type="archive" />} loading={loadingArchive}>
                              {t('archive')}
                            </Button>
                          </Confirmation>
                        )}
                      </div>
                    </div>

                    {item.session && item.details ? (
                      <div className="flex-item-row-col card">
                        <div>
                          <label>{t('author')}</label>
                          <Link
                            route="ProfilePage"
                            params={{ action: item.created_by.id }}
                            className="flex-item flex-item-map pointer"
                          >
                            <AvatarInline
                              img={item.created_by?.profile_picture}
                              alt={[item.created_by?.first_name, item.created_by?.last_name].filter((i) => i).join(' ')}
                              role={item.created_by?.email}
                              size="small"
                            />
                          </Link>
                        </div>
                        {item.session.professor && (
                          <div>
                            <label>{t('teacher')}</label>
                            <AvatarInline
                              img={item.session.professor.profile_picture}
                              alt={[item.session.professor.first_name, item.session.professor.last_name]
                                .filter((i) => i)
                                .join(' ')}
                              role={item.session.professor.email}
                              size="small"
                            />
                          </div>
                        )}
                        <div>
                          <label>{t('course')}</label>
                          <Link
                            route="GeneralSessionPage"
                            params={{ id: item.session.id }}
                            className="flex-item flex-item-map pointer"
                          >
                            {item.session.course?.name}
                          </Link>
                        </div>
                        <div>
                          <label>{t('period')}</label>
                          <Time
                            date={`${format(item.session.start_date, dateFormat)} - ${format(
                              item.session.end_date,
                              dateFormat,
                            )}`}
                            noParse
                          />
                        </div>
                        {item.session.institution && (
                          <div>
                            <label>{t('institute')}</label>
                            <Info primary label={<b>{item.session.institution.name}</b>} />
                          </div>
                        )}
                        <div className="card-details">
                          <label className="card-title">{t('request_for_change')}</label>
                          <div className="flex-item-row-col details">
                            {item.details.course_grade && (
                              <div>
                                <label>{t('noteForCourse')}</label>

                                {item.details.old ? (
                                  <>
                                    {item.details.old.course_grade}
                                    <Icon type="arrow-right" />
                                  </>
                                ) : null}
                                {item.details.course_grade}
                              </div>
                            )}

                            {item.details.individual_work_grade && (
                              <div>
                                <label>{t('noteForIndividualWork')}</label>

                                {item.details.old ? (
                                  <>
                                    {item.details.old.individual_work_grade}
                                    <Icon type="arrow-right" />
                                  </>
                                ) : null}
                                {item.details.individual_work_grade}
                              </div>
                            )}

                            {item.details.practise_sub_grade_1 && (
                              <div>
                                <label>{t('practise_sub_grade_1')}</label>
                                {item.details.old ? (
                                  <>
                                    {item.details.old.practise_sub_grade_1}
                                    <Icon type="arrow-right" />
                                  </>
                                ) : null}
                                {item.details.practise_sub_grade_1}
                              </div>
                            )}
                            {item.details.practise_sub_grade_2 && (
                              <div>
                                <label>{t('practise_sub_grade_2')}</label>
                                {item.details.old ? (
                                  <>
                                    {item.details.old.practise_sub_grade_2}
                                    <Icon type="arrow-right" />
                                  </>
                                ) : null}
                                {item.details.practise_sub_grade_2}
                              </div>
                            )}
                            {item.details.practise_sub_grade_3 && (
                              <div>
                                <label>{t('practise_sub_grade_3')}</label>
                                {item.details.old ? (
                                  <>
                                    {item.details.old.practise_sub_grade_3}
                                    <Icon type="arrow-right" />
                                  </>
                                ) : null}
                                {item.details.practise_sub_grade_3}
                              </div>
                            )}
                            {item.details.practise_sub_grade_4 && (
                              <div>
                                <label>{t('practise_sub_grade_4')}</label>
                                {item.details.old ? (
                                  <>
                                    {item.details.old.practise_sub_grade_4}
                                    <Icon type="arrow-right" />
                                  </>
                                ) : null}
                                {item.details.practise_sub_grade_4}
                              </div>
                            )}
                            {item.details.practise_sub_grade_5 && (
                              <div>
                                <label>{t('practise_sub_grade_5')}</label>
                                {item.details.old ? (
                                  <>
                                    {item.details.old.practise_sub_grade_5}
                                    <Icon type="arrow-right" />
                                  </>
                                ) : null}
                                {item.details.practise_sub_grade_5}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="flex-item-row-col">
                      <div>
                        <label>{t('dateAndTime')}</label>

                        <Time date={item?.modified_at || item?.created_at} format={dateTimeFormat} />
                      </div>

                      {!item.details || !item.session ? (
                        <div>
                          <label>{t('author')}</label>
                          <Info
                            primary
                            icon={<Icon type="user-strict" />}
                            label={
                              <Link
                                route="ProfilePage"
                                params={{ action: item?.created_by?.id }}
                                className="flex-item flex-item-map pointer"
                              >
                                {[item?.created_by?.first_name, item?.created_by?.last_name].filter((i) => i).join(' ')}
                              </Link>
                            }
                          />
                        </div>
                      ) : null}

                      <div className="flex-item-actions">
                        {((userData.role === 'admin' &&
                          item.status === 'pending' &&
                          item.details &&
                          item.session &&
                          item.student) ||
                          (['professor', 'admin'].includes(userData.role) &&
                            item.type === 'recover_grade' &&
                            item.status === 'pending')) && (
                          <>
                            <Button
                              prefix={<Icon type="close" />}
                              onClick={() =>
                                item.session &&
                                onUpdateIssue({
                                  id: item?.id,
                                  status: 'declined',
                                  session: item?.session?.id,
                                  student: item?.student,
                                })
                              }
                              loading={loadingAccept}
                            >
                              {t('reject')}
                            </Button>

                            <Button
                              type="primary"
                              prefix={<Icon type="check" />}
                              onClick={() => handleApprove(item)}
                              loading={loadingReject}
                            >
                              {t('accept')}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <CardFooter
              onRefresh={onRefetchInvites}
              onRefreshDisabled={!filters.filtered}
              page={filters.page}
              pages={data.total_pages}
              perPage={filters.per_page}
              onChangePerPage={onChangePerPage}
              rightSide={
                <>
                  <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                    {t('previous')}
                  </Button>

                  <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                    {t('next')}
                  </Button>
                </>
              }
            />
          </Loader>
        </Card>
      </ApplicationsLayout>
    </>
  );
};
