import * as React from 'react';
import { useParams } from 'estafette-router';
import { Public } from 'features/public/organisms';
import { Confirm } from 'features/public/templates';
import { Content } from 'features/public/atoms';

export const ConfirmPage: React.FC = () => {
  const { token } = useParams();

  return (
    <Public>
      <Content className="container mini">{<Confirm token={token} />}</Content>
    </Public>
  );
};
