import * as React from 'react';
import { useRequest } from 'estafette';
import { useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { sessions } from 'libs/http/api';
import { Head } from 'ui/organisms';
import { Animated, Card, Icon, Info } from 'ui/atoms';
import { Graduation } from 'libs/http/api/graduations/graduations.types';
import { graduations } from 'libs/http/api/graduations/graduations';
import { GraduationLayout } from '../organisms/GraduationLayout';
import { GraduatesTable } from '../organisms';

import '../../session/pages/GeneralSessionPage/GeneralSessionPage.scss';

export const GeneralGraduationPage: React.FC = () => {
  const { t } = useIntl();
  const { id }: { id: number } = useParams();

  const { request, data, loading } = useRequest<Graduation>({ data: {} });

  React.useEffect(() => {
    return () => {
      sessions.load.cancel();
      sessions.report.cancel();
    };
  }, []);

  React.useEffect(() => {
    onFetch();
  }, [id]);

  const onFetch = (): void => {
    request(graduations.load.action(id));
  };

  return (
    <>
      <Head title={t('graduation') || 'n/a'} />

      <GraduationLayout>
        <Animated loading={loading} debounce={500}>
          <Card className="mt-20">
            <div className="zh-session-card">
              <div className="zh-session-card-item">
                <div className="zh-session-card-info">
                  <div className="zh-session-card-info-label">{t('date')}</div>
                  <div className="zh-session-card-info-text">{data.date}</div>
                </div>

                {data.institution && (
                  <div className="zh-session-card-info">
                    <div className="zh-session-card-info-label">{t('institute')}</div>
                    <div className="zh-session-card-info-text">{data.institution.name}</div>
                  </div>
                )}

                <div className="zh-session-card-info">
                  <div className="zh-session-card-info-label">{t('location')}</div>
                  <div className="zh-session-card-info-text">
                    <Info
                      icon={<Icon type="location-filled" />}
                      label={[data.country && data.country.title, data.city && data.city.title, data.address]
                        .filter((i) => i)
                        .join(', ')}
                    />
                  </div>
                </div>

                <div className="zh-session-card-info">
                  <div className="zh-session-card-info-label">{t('numOfRegisteredStudents')}</div>
                  <div className="zh-session-card-info-text">{data.students_count}</div>
                </div>
              </div>
            </div>
          </Card>
        </Animated>
        <GraduatesTable graduationId={id} />
      </GraduationLayout>
    </>
  );
};
