import * as React from 'react';
import { useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { Button, Loader, Modal, ModalFooterButton, Select, Table, Time } from 'ui/atoms';
import { Form, FormItem, FormItems } from 'ui/organisms';
import { courses, faculties, institutions, sessions } from 'libs/http/api';
import { useRequest } from 'estafette';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { List, Results, Save } from 'libs/http/api/index.types';
import { useStateHandlers } from 'hooks';
import { Column } from 'ui/atoms/Table/Table';
import { dateTimeFormat, format } from 'libs/date';
import { Pagination } from '../../../ui/molecules';

interface AddSessionProps {
  onSuccess: () => void;
  onCancel: () => void;
}

export const AddSession: React.FC<AddSessionProps> = ({ onSuccess, onCancel }) => {
  const { t } = useIntl();
  const { action } = useParams();
  const [state, setState] = useStateHandlers<{ [key: string]: any }>({
    institute: null,
    faculty: null,
    course: null,
    session: null,
    page: 1,
  });
  const [selectedSession, setSelectedSession] = React.useState<number[]>([]);

  const { request: requestSessions, data: dataSessions, loading: loadingSessions } = useRequest<Results<Session>>();
  const { request: requestInstitutions, data: dataInstitutions, loading: loadingInstitutions } = useRequest<List[]>();
  const { request: requestFaculties, data: dataFaculties, loading: loadingFaculties } = useRequest<List[]>();
  const { request: requestCourses, data: dataCourses, loading: loadingCourses } = useRequest<List[]>();
  const { request: requestSave } = useRequest<Save>();

  React.useEffect(() => {
    requestInstitutions(institutions.list.action({ is_active: true }));
  }, []);

  React.useEffect(() => {
    requestFaculties(faculties.list.action({ institution: state.institute }));
  }, [state.institute]);

  React.useEffect(() => {
    requestCourses(courses.list.action({ faculties: state.faculty }));
  }, [state.faculty]);

  React.useEffect(() => {
    requestSessions(
      sessions.get.action({
        course: state.course,
        institution: state.institute,
        faculties: state.faculty,
        page: state.page,
        exclude_user: action,
        ordering: '-start_date',
      }),
    );
  }, [state.course, state.institute, state.faculty, state.page]);

  const instituteOptions = React.useMemo(
    () => dataInstitutions.map((institute) => ({ value: institute.key, title: institute.label })),
    [dataInstitutions],
  );

  const facultiesOptions = React.useMemo(
    () => dataFaculties.map((faculty) => ({ value: faculty.key, title: faculty.label })),
    [dataFaculties],
  );

  const coursesOptions = React.useMemo(
    () => dataCourses.map((course) => ({ value: course.key, title: course.label })),
    [dataCourses],
  );

  const handleAddSession = async (id: number): Promise<void> => {
    setSelectedSession((s) => [...s, id]);
    requestSave(
      sessions.addUser.action({
        id,
        users: [action],
      }),
    ).then(() => {
      onSuccess();
    });
  };

  const onChange = React.useCallback(
    (target: string, value: any): void => {
      if (target !== 'page') {
        setState({ page: 1 });
      }
      setState({
        [target]: value,
      });
    },
    [state],
  );

  const columns = React.useMemo(
    (): Column<Session>[] => [
      {
        title: t('professor'),
        render: ({ professor }) => `${professor?.first_name || '-'} ${professor?.last_name || '-'}`,
      },
      {
        title: t('dateAndTime'),
        dataIndex: '',
        render: (item) => (
          <Time
            date={`${format(item.start_date, dateTimeFormat)} - ${format(item.end_date, dateTimeFormat)}`}
            noParse
          />
        ),
      },
      {
        render: ({ id }) => (
          <Button disabled={selectedSession.includes(id)} onClick={() => handleAddSession(id)}>
            {t('addSession')}
          </Button>
        ),
      },
    ],
    [t, dataSessions],
  );

  return (
    <Modal
      footer={
        <ModalFooterButton align="center">
          <Button onClick={onCancel}>{t('cancel')}</Button>
        </ModalFooterButton>
      }
      onClose={onCancel}
      size="large"
    >
      <Form id="add-session">
        <FormItems>
          <FormItem label={t('institute')}>
            <Select
              placeholder={t('selectInstitute')}
              options={instituteOptions}
              loading={loadingInstitutions}
              value={state.institute}
              onChange={(value): void => onChange('institute', value)}
            />
          </FormItem>
          <FormItem label={t('faculties')}>
            <Select
              placeholder={t('selectFaculty')}
              options={facultiesOptions}
              loading={loadingFaculties}
              value={state.faculty}
              onChange={(value): void => onChange('faculty', value)}
            />
          </FormItem>
          <FormItem label={t('courses')}>
            <Select
              placeholder={t('selectCourse')}
              options={coursesOptions}
              loading={loadingCourses}
              value={state.course}
              onChange={(value): void => onChange('course', value)}
            />
          </FormItem>
        </FormItems>
        <Loader loading={loadingSessions} height={300}>
          <Table size="small" columns={columns} data={dataSessions?.results} scroll={{ y: 400 }} />
        </Loader>

        <Pagination
          label={t('sessions').toLocaleLowerCase()}
          total={dataSessions?.count}
          current={state.page}
          onChange={(page) => onChange('page', page)}
        />
      </Form>
    </Modal>
  );
};
