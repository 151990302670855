import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { State } from '../../pages/AddFacultyPage/AddFacultyPage';
import { Optional, useStateHandlers } from 'hooks/useStateHandlers';

interface Props {
  item: number;
  label: string;
  dragStart: boolean;
  onStateChange: Dispatch<SetStateAction<Optional<State>>>;
  onDrop: (current: number, before: number) => void;
  staticState: any;
}

export const DragSelectItem: React.FC<Props> = ({ item, label, onStateChange, onDrop, staticState }) => {
  const timer = React.useRef<NodeJS.Timeout>();
  const [state, setState] = useStateHandlers({ onHover: false });

  const onDragOver = (e: React.DragEvent<HTMLSpanElement>): void => {
    e.preventDefault();
    setState({ onHover: true });
    if (timer.current !== undefined) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => setState({ onHover: false }), 100);
  };

  const onDragEnter = (): void => {
    setState({ onHover: true });
  };
  const onDragExit = (): void => {
    setState({ onHover: false });
  };
  const onDragEnd = (): void => {
    onStateChange({ dragStart: false });
    setState({ onHover: false });
  };

  return (
    <span
      key={item}
      id={item.toString()}
      draggable
      onDragStart={(e) => {
        staticState.current = e.currentTarget.id;
        onStateChange({ dragStart: true });
      }}
      onDrop={(e) => {
        e.preventDefault();
        const domElement = document.getElementById(staticState.current);
        if (domElement) {
          e?.currentTarget?.parentNode?.insertBefore(domElement, e.currentTarget.nextSibling);
        }
        onDrop(parseInt(staticState.current), parseInt(e.currentTarget.id));
        onDragExit();
        onDragEnd();
      }}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragExit={onDragExit}
      onDragEnd={onDragEnd}
      className={`${state.onHover ? 'drag-active' : ''} drag-select-item`}
    >
      {label}
    </span>
  );
};
