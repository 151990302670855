import * as React from 'react';
import { useRequest } from 'estafette';
import { Link, useHistory, useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { useStateHandlers } from 'hooks';
import { courses, faculties } from 'libs/http/api';
import { Faculty } from 'libs/http/api/faculties/faculties.types';
import { CourseList } from 'libs/http/api/courses/courses.types';
import { Save } from 'libs/http/api/index.types';
import { keys, parseQuery } from 'libs/object';
import { Form, FormItem, FormItems, Head } from 'ui/organisms';
import { Button, Fragment, Icon, Input, Loader, Scroll, Checkbox, Alert } from 'ui/atoms';
import { DragSelectItem } from '../../organisms/DragSelectItem/DragSelectItem';

import { FacultiesLayout } from '../../organisms';

import './AddFacultyPage.scss';

export interface State {
  name_ro: string;
  name_ru: string;
  name_en: string;
  name_es: string;
  courses: number[];
  search: string;
  dragStart: boolean;
}

export const AddFacultyPage: React.FC = () => {
  const { t } = useIntl();
  const { action } = useParams();
  const { push, goBack } = useHistory();
  const { request: requestCourses, data: dataCourses, loading: loadingCourses } = useRequest<CourseList[]>();
  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<Save>();
  const { request: requestFaculty, loading: loadingFaculty } = useRequest<Faculty>({ data: {} });

  const staticState = React.useRef({});

  const { current: facultyState } = React.useRef({
    name_ro: '',
    name_ru: '',
    name_en: '',
    name_es: '',
    courses: [],
  });

  const [state, setState] = useStateHandlers<State>({
    ...facultyState,
    search: '',
    dragStart: false,
  });

  React.useEffect(() => {
    return () => {
      courses.list.cancel();
      faculties.add.cancel();
      faculties.load.cancel();
      faculties.save.cancel();
    };
  }, []);

  React.useEffect(() => {
    requestCourses(courses.list.action());
  }, []);

  React.useEffect(() => {
    const onFetchFaculty = async (): Promise<void> => {
      if (action !== 'add') {
        const data = await requestFaculty(faculties.load.action({ id: action }));

        setState((prevState) =>
          keys(facultyState).reduce((acc, current) => {
            const item = data[current];

            return {
              ...acc,
              [current]: Array.isArray(item) ? item.map(({ id }) => id) : item || prevState[current],
            };
          }, {}),
        );
      }
    };

    onFetchFaculty();
  }, [action]);

  const onSubmit = async (): Promise<void> => {
    const query: { [key: string]: any } = {};

    if (action === 'add') {
      await requestSave(faculties.add.action(state));
    } else {
      await requestSave(faculties.save.action({ ...state, id: action }));

      const { page } = parseQuery<{ page: number }>(window.location.search);
      query.page = page;
    }

    push('FacultiesPage', { query });
  };

  const onChange = React.useCallback((target: string, value: any): void => setState({ [target]: value }), []);
  const onCancel = (): void => goBack();
  const onSearchChange = (value: string): void => setState({ search: value.toLowerCase() });
  const onCoursesChange = React.useCallback(
    (value): void => {
      if (state.courses.includes(value)) {
        setState((prevState) => ({
          courses: prevState.courses.filter((course) => course !== value),
        }));
      } else {
        setState({ courses: [...state.courses, value] });
      }
    },
    [state.courses],
  );

  const isEdit = React.useMemo(() => action !== 'add', [action]);

  const onSelectAll = React.useCallback(
    () =>
      setState((prevState) => ({
        courses:
          dataCourses.length !== prevState.courses.length
            ? dataCourses
                .filter(({ label }) => (state.search.length ? label.toLowerCase().includes(state.search) : true))
                .map((course) => course.key)
            : [],
      })),
    [dataCourses, state.search],
  );

  const coursesOptions = React.useMemo(
    () =>
      dataCourses
        .filter(({ label }) => (state.search.length ? label.toLowerCase().includes(state.search) : true))
        .map((course) => ({ value: course.key, title: course.label })),
    [dataCourses, state.search],
  );

  const onDrop = (current: number, before: number): void => {
    const index = state.courses.indexOf(current);
    if (index === -1) {
      return;
    }
    const result = [...state.courses];
    result.splice(index, 1);
    result.splice(result.indexOf(before) + 1, 0, current);
    setState({ courses: result });
  };

  return (
    <>
      <Head t={isEdit ? 'editFaculty' : 'addFaculty'} />

      <FacultiesLayout>
        <h1 className="mt-5">{t(isEdit ? 'editFaculty' : 'addFaculty')}</h1>

        <Alert message={dataSave.message || errors.non_field_errors || errors.detail} form type="error" />

        <Form onSubmit={onSubmit}>
          <Fragment
            footer={
              <>
                <Link route="FacultysPage">
                  <Button onClick={onCancel}>{t('cancel')}</Button>
                </Link>

                <Button submit type="primary" prefix={<Icon type="check" />} loading={loadingSave}>
                  {t('confirm')}
                </Button>
              </>
            }
          >
            <Loader loading={isEdit && loadingFaculty} height={180}>
              <h2 className="medium mb-20">{t('faculty')}</h2>

              <FormItems className="mx-w-660">
                <FormItem label="Name" extra={errors.name_en}>
                  <Input value={state.name_en} onChange={(value) => onChange('name_en', value)} />
                </FormItem>

                <FormItem label="Denumire" extra={errors.name_ro}>
                  <Input value={state.name_ro} onChange={(value) => onChange('name_ro', value)} />
                </FormItem>

                <FormItem label="Название" extra={errors.name_ru}>
                  <Input value={state.name_ru} onChange={(value) => onChange('name_ru', value)} />
                </FormItem>

                <FormItem label="Nombre" extra={errors.name_es}>
                  <Input value={state.name_es} onChange={(value) => onChange('name_es', value)} />
                </FormItem>
              </FormItems>
              <hr className="hr-2" />
              <FormItem required label={t('course')} className="mx-w-660" extra={errors.courses}>
                <div className="m-courses-select">
                  {state.courses.map((i) => (
                    <DragSelectItem
                      key={i}
                      item={i}
                      label={coursesOptions.find((a) => a.value === i)?.title || 'no label'}
                      dragStart={state.dragStart}
                      onDrop={onDrop}
                      onStateChange={setState}
                      staticState={staticState}
                    />
                  ))}
                </div>
                <Input
                  value={state.search}
                  className="zh-input-search"
                  placeholder={t('search')}
                  onChange={onSearchChange}
                  suffix={
                    <Button type={dataCourses.length !== state.courses.length ? 'regular' : 'simple'} size="small">
                      {t('selectAll')}
                    </Button>
                  }
                  onClickSuffix={onSelectAll}
                />
                <Loader loading={loadingCourses}>
                  <Scroll>
                    {coursesOptions.map(({ value, title }, i) => (
                      <Checkbox
                        key={i}
                        label={title}
                        checked={state.courses.includes(value)}
                        onChange={() => onCoursesChange(value)}
                      />
                    ))}
                  </Scroll>
                </Loader>
              </FormItem>
            </Loader>
          </Fragment>
        </Form>
      </FacultiesLayout>
    </>
  );
};
