import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Avatar, Button, Icon } from 'ui/atoms';

import './ImageUpload.scss';

interface Props {
  onChange?: (newValue: File) => void;
  defaultPreview?: string;
  alt?: string;
}

interface FileTarget {
  files: [File];
}

export const ImageUpload: React.FC<Props> = ({ alt, onChange, defaultPreview }) => {
  const { t } = useIntl();
  const [changedInitialPreview, setChangedInitialPreview] = React.useState(false);
  const [preview, setPreview] = React.useState<string>('');

  React.useEffect(() => {
    if (!changedInitialPreview && defaultPreview) {
      setChangedInitialPreview(true);
      setPreview(defaultPreview);
    }
  }, [changedInitialPreview, defaultPreview]);

  const onUploadFile = (event: any): void => {
    const target = event.target as FileTarget;

    const file = target.files[0];

    const reader = new FileReader();

    if (file instanceof Blob) {
      reader.readAsDataURL(file);
    }

    reader.onload = ({ target: $target }) => {
      if ($target && $target.result !== null) {
        setChangedInitialPreview(true);
        setPreview($target.result as string);
      }
    };

    reader.onloadend = async () => {
      if (onChange !== undefined) {
        onChange(file);
      }
    };
  };

  return (
    <div className="zh-upload">
      <input type="file" className="zh-upload-mask" onChange={onUploadFile} />

      {preview ? <Avatar img={preview} alt={alt} size="big" /> : <Icon type="file" />}

      <div className="zh-upload-info">
        <div className="zh-upload-title">{t('uploadPhoto')}</div>
        <div className="zh-upload-desc">{t('maxFilePhoto')}</div>

        <Button prefix={<Icon type="img-add" />} type="invert">
          {t('addPhoto')}
        </Button>
      </div>
    </div>
  );
};
