import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useFilters } from 'hooks';
import { me } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { Announcement } from 'libs/http/api/announces/announces.types';

import { Button, Info, Card, CardHeader, CardFooter, Loader, Calendar, EmptyList, Icon } from 'ui/atoms';
import { SortBy, InputSearch } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';

const initialFilters = {
  filtered: false,
  page: 1,
  per_page: 3,
  search: '',
  created_at__date__gte: '',
  created_at__date__lte: '',
  ordering: '' as Sorts,
};

export const Announces: React.FC = () => {
  const { t } = useIntl();
  const { request, data, loading } = useRequest<Results<Announcement>>({ data: { results: [] } });
  const [filters, setFilters] = useFilters({ ...initialFilters });

  React.useEffect(() => {
    request(me.getAnnounces.action({ ...filters }));
  }, [filters]);

  React.useEffect(() => {
    return () => {
      me.getAnnounces.cancel();
    };
  }, []);

  const onRefetch = (): void => setFilters({ ...initialFilters });

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  return (
    <Card className="dateCard">
      <CardHeader
        title={t('announcements')}
        count={data.count}
        leftSide={<InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />}
        rightSide={
          <SortBy
            options={[
              {
                title: t('subject'),
                value: 'subject',
              },
            ]}
            value={filters.ordering}
            onChange={(newValue) => onChangeFilters('ordering', newValue)}
          />
        }
      />

      <Loader loading={loading}>
        {data.results.length > 0 ? (
          <div className="flex-data">
            {data.results.map((item) => (
              <div key={item.id} className="flex-item">
                <div className="flex-item-row">
                  <div className="flex-item-row-calendar">
                    <Calendar date={item.created_at} type="date" />
                  </div>
                  <div className="flex-item-row-info">
                    <label>{t('announcement')}</label>
                    <Info primary label={item.subject} />
                    <div id="innerHTML" dangerouslySetInnerHTML={{ __html: item.description || '---' }} />
                  </div>
                  <div className="flex-item-row-info">
                    {item.file && (
                      <Button
                        prefix={<Icon type="download" />}
                        onClick={() => window.open(item.file?.toString(), '__blank')}
                      >
                        {t('unload')}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex-data">
            <EmptyList />
          </div>
        )}

        <CardFooter
          onRefresh={onRefetch}
          onRefreshDisabled={!filters.filtered}
          page={filters.page}
          pages={data.total_pages}
          rightSide={
            <>
              <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                {t('previous')}
              </Button>

              <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                {t('next')}
              </Button>
            </>
          }
        />
      </Loader>
    </Card>
  );
};
