import * as React from 'react';
import { Head } from 'ui/organisms';

import { SessionsLayout } from '../organisms';
import { ClosedSessionsTemplate } from '../templates';

export const ClosedSessionsPage: React.FC = () => (
  <>
    <Head t="sessionsClosed" />

    <SessionsLayout>
      <ClosedSessionsTemplate />
    </SessionsLayout>
  </>
);
