import * as React from 'react';
import { Head } from 'ui/organisms';

import { ClosedSessionsTemplate } from '../templates';
import { SessionsLayout } from '../organisms';

export const SingleClosedSessionsPage: React.FC = () => (
  <>
    <Head t="sessionsClosed" />

    <SessionsLayout showTabs={false}>
      <ClosedSessionsTemplate />
    </SessionsLayout>
  </>
);
