import * as React from 'react';
import { Icon } from 'ui/atoms';

import './Alert.scss';

interface Props {
  type?: 'success' | 'info' | 'warning' | 'error';
  className?: string;
  message?: string;
  form?: boolean;
}

export const Alert: React.FC<Props> = ({ type = 'success', form, message = '', className }) => {
  const ref = React.useRef<null | HTMLDivElement>(null);
  const getHeader = document.getElementsByClassName('zh-layout-top-bar');

  React.useEffect(() => {
    if (message.length && ref.current && getHeader.length) {
      window.scrollTo(0, ref.current.offsetTop - getHeader[0].clientHeight - 1);
    }
  }, [message, getHeader]);

  return message.length ? (
    <div ref={ref} className={`zh-alert zh-alert-type-${type} ${(form && 'form') || ''} ${className}`}>
      {type === 'success' && <Icon type="check" />}
      {type === 'info' && <Icon type="info" />}
      {type === 'warning' && <Icon type="warning" />}
      {type === 'error' && <Icon type="alert" />}

      {message}
    </div>
  ) : null;
};
