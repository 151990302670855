import * as React from 'react';
import { useIntl } from 'estafette-intl';
import SelectOld, { Option } from 'rc-select';
import { RenderDOMFunc } from 'rc-select/lib/interface';
import { cleanString } from 'libs/string';
import { Icon } from 'ui/atoms';

import 'rc-select/assets/index.less';
import './Select.scss';

const findTypeOfString = (children: any, i: number, iNext?: number): any =>
  children && children.props
    ? findTypeOfString(
        Array.isArray(children.props.children) ? children.props.children[i] : children.props.children,
        iNext !== undefined ? iNext : i,
      )
    : children;

export interface Option {
  value: any;
  title: any;
  recommended?: boolean;
}

interface Props {
  getPopupContainer?: (triggerNode?: Element) => HTMLElement | ParentNode | null;
  mode?: 'multiple' | 'tags' | 'combobox' | undefined;
  dropdownClassName?: string;
  className?: string;
  placeholder?: string;
  options: Option[];
  label?: boolean;
  styleType?: 'grey' | 'white';
  onChange?: (value: any) => void;
  value?: any;
  loading?: boolean;
  disabled?: boolean;
  cancelable?: boolean;
  cancelValue?: any;
  searchDisabled?: boolean;
  onSearch?: (input: string) => void;
}

export const Select: React.FC<Props> = ({
  mode,
  dropdownClassName = '',
  className = '',
  label,
  options,
  onChange,
  styleType = 'white',
  value = null,
  disabled,
  cancelable,
  searchDisabled = false,
  onSearch,
  ...props
}) => {
  const { t } = useIntl();
  const timer = React.useRef<NodeJS.Timeout>();
  const [search, setSearch] = React.useState('');

  React.useEffect(() => {
    timer.current = setTimeout(() => onSearch && onSearch(search), 400);

    return () => {
      if (timer.current !== undefined) {
        clearTimeout(timer.current);
      }
    };
  }, [search]);

  const onCancel = (): void => {
    if (onChange !== undefined) {
      onChange(mode === 'multiple' ? [] : props.cancelValue || null);
    }
  };

  const hasValue = React.useMemo(() => (Array.isArray(value) ? value.length > 0 : Boolean(value)), [value]);

  const filterOption = (input: string, option: any): boolean => {
    const value = option
      ? findTypeOfString(option, 0) || findTypeOfString(option.props.children, 0, 1) || findTypeOfString(option, 1)
      : '';

    return input.length ? cleanString(value.toLowerCase()).startsWith(cleanString(input.toLowerCase())) : false;
  };

  const handleBlur = (): void => {
    if (onSearch) {
      onSearch('');
      setSearch('');
    }
  };

  return (
    <div
      className={`select${!value ? ' select-empty' : ''}${
        cancelable && !disabled && value ? ' select-cancelable' : ''
      } select-${label ? 'labeled' : 'notlabeled'}`}
    >
      {onChange !== undefined && cancelable && !disabled && options.length > 0 && hasValue && (
        <div className="zh-select-close" onClick={onCancel}>
          <Icon type="close-2" />
        </div>
      )}

      {label && <label>{props.placeholder}</label>}

      <SelectOld
        getPopupContainer={props.getPopupContainer as RenderDOMFunc}
        showSearch={!searchDisabled}
        mode={mode}
        optionFilterProp="children"
        filterOption={onSearch ? false : filterOption}
        onSearch={setSearch}
        onBlur={handleBlur}
        dropdownClassName={`${dropdownClassName} ${mode !== 'multiple' ? 'hideCheck' : ''}`}
        className={`select-style-${styleType} ${className}`}
        placeholder={props.loading ? `${t('loading')} ...` : !label ? props.placeholder : t('notSelected')}
        onChange={onChange}
        value={props.loading ? undefined : value}
        disabled={disabled}
        notFoundContent={props.loading ? `${t('loading')} ...` : undefined}
      >
        {!props.loading &&
          options.map((option) => (
            <Option key={option.value} value={option.value} className={`${option.recommended ? 'recommended' : ''}`}>
              {option.title}
            </Option>
          ))}
      </SelectOld>
    </div>
  );
};
