import * as React from 'react';
import { useIntl } from 'estafette-intl';

export const TermsOfUse: React.FC = () => {
  const { locale } = useIntl();

  const Data = React.lazy(() => import(`./sources/${locale}`));

  return (
    <div className="document">
      <Data />
    </div>
  );
};
