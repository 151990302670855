import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Head } from 'ui/organisms';

import { CurrentSessionsTemplate, PlannedSessionsTemplate, SessionInvitesTemplate } from '../templates';
import { SessionsLayout } from '../organisms';

export const SingleCurrentSessionsPage: React.FC = () => {
  const { t } = useIntl();
  const [update, setUpdate] = React.useState(false);

  const onToggleUpdate = (): void => setUpdate((s) => !s);

  return (
    <>
      <Head t="sessionsCurrent" />

      <SessionsLayout showTabs={false}>
        <CurrentSessionsTemplate title={t('unclosedSessions')} update={update} />
        <SessionInvitesTemplate onUpdate={onToggleUpdate} />
        <PlannedSessionsTemplate update={update} />
      </SessionsLayout>
    </>
  );
};
