import * as React from 'react';
import { useRequest } from 'estafette';
import { useHistory, useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { NomenclaturesContext } from 'contexts';
import { useStateHandlers } from 'hooks';
import { faculties, courses, users, institutions } from 'libs/http/api';
import { EurasiaInstitution } from 'libs/http/api/institutions/institutions.types';
import { List, Save } from 'libs/http/api/index.types';
import { keys, parseQuery } from 'libs/object';
import { Form, FormItem, FormItems, Head } from 'ui/organisms';
import { Loader, Button, Fragment, Icon, Input, Select, Alert } from 'ui/atoms';
import { graduations } from 'libs/http/api/graduations/graduations';
import { Graduation, GraduationState } from 'libs/http/api/graduations/graduations.types';
import { DateRangePicker } from 'ui/molecules';
import { toISODate } from 'libs/date';
import { lowerFirstLetter } from 'libs/string';

import { GraduationsLayout } from '../organisms';

export const AddGraduationPage: React.FC = () => {
  const { t, locale } = useIntl();
  const { action } = useParams();
  const { goBack } = useHistory();
  const { request: requestGraduation, loading: loadingGraduation } = useRequest<Graduation>({
    data: {},
  });
  const { request: requestInstitutions, data: dataInstitutions, loading: loadingInstitutions } = useRequest<List[]>();
  const { request: requestInstitute, data: dataInstitute, loading: loadingInstitute } = useRequest<EurasiaInstitution>({
    data: {},
  });
  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<Save>();
  const {
    getActiveCountries,
    activeCountries,
    loadingActiveCountries,
    getActiveCities,
    activeCities,
    loadingActiveCities,
    getActiveLanguages,
  } = React.useContext(NomenclaturesContext);

  const [state, setState] = useStateHandlers<GraduationState>({
    institution: null,
    address: '',
    city: null,
    country: null,
    date: '',
    students_count: 0,
  } as GraduationState);

  const [searchCountries, setSearchCountries] = React.useState('');
  const [searchCities, setSearchCities] = React.useState('');

  React.useEffect(() => {
    const onFetchGraduation = async (): Promise<void> => {
      if (isEdit) {
        const data = await requestGraduation(graduations.load.action(action));

        setState((prevState) => {
          return keys(prevState).reduce((acc, current) => {
            const item = data[current];

            return {
              ...acc,
              [current]:
                !Array.isArray(item) && item instanceof Object
                  ? item.id
                  : typeof item !== 'boolean'
                  ? item || prevState[current]
                  : item,
            };
          }, {});
        });
      }
    };

    onFetchGraduation();
  }, [action]);

  React.useEffect(() => {
    requestInstitutions(institutions.list.action({ is_active: true }));

    getActiveLanguages();

    return () => {
      faculties.list.cancel();
      users.professorsList.cancel();
      courses.list.cancel();
      graduations.add.cancel();
      graduations.save.cancel();
      institutions.list.cancel();
    };
  }, []);

  React.useEffect(() => {
    getActiveCountries(searchCountries);
  }, [searchCountries]);

  React.useEffect(() => {
    getActiveCities(state.country, searchCities);
  }, [state.country, searchCities]);

  React.useEffect(() => {
    if (state.institution) {
      requestInstitute(institutions.load.action({ id: state.institution }));
    }
  }, [state.institution]);

  const onSubmit = async (): Promise<void> => {
    const query: { [key: string]: any } = {};

    if (!isEdit) {
      await requestSave(graduations.add.action(state));
    } else {
      await requestSave(graduations.save.action(action, state));

      const { page } = parseQuery<{ page: number }>(window.location.search);
      query.page = page;
    }

    goBack();
  };

  const onChange = React.useCallback(
    (target: string, value: any): void =>
      setState({
        [target]: value,
      }),
    [],
  );
  const onCancel = (): void => goBack();

  const institutionsOptions = React.useMemo(
    () => dataInstitutions.map((institution) => ({ value: institution.key, title: institution.label })),
    [dataInstitutions],
  );

  React.useEffect(() => {
    if (dataInstitute.country) {
      setState({ country: dataInstitute.country.id });
    }

    if (dataInstitute.city) {
      setState({ city: dataInstitute.city.id });
    }
  }, [dataInstitute]);

  const isEdit = React.useMemo(() => action !== 'add', [action]);

  const pageTitle = React.useMemo(
    () => t(isEdit ? 'editItem' : 'addItem', { item: lowerFirstLetter(t('graduation')) }),
    [isEdit, locale],
  );

  const handleCountriesSearch = (input: string): void => setSearchCountries(input);

  const handleCitiesSearch = (input: string): void => setSearchCities(input);
  const handleDatePickerChange = ({ date__gte: value }: { [key: string]: string | null }): void =>
    onChange('date', value ? toISODate(value, true) : value);

  return (
    <>
      <Head title={pageTitle} />

      <GraduationsLayout>
        <h1>{pageTitle}</h1>

        <Alert message={dataSave.message || errors.non_field_errors || errors.detail} form type="error" />

        <Form onSubmit={onSubmit}>
          <Fragment
            footer={
              <>
                <Button onClick={onCancel}>{t('cancel')}</Button>

                <Button submit type="primary" prefix={<Icon type="check" />} loading={loadingSave}>
                  {t('confirm')}
                </Button>
              </>
            }
          >
            <Loader loading={isEdit && loadingGraduation} height="100vh">
              <FormItems className="mx-w-660">
                <FormItem label={t('institute')} extra={errors.institution}>
                  <Select
                    placeholder={t('selectInstitute')}
                    options={institutionsOptions}
                    loading={loadingInstitutions}
                    value={state.institution}
                    onChange={(value): void => onChange('institution', value)}
                  />
                </FormItem>
              </FormItems>

              <FormItems className="mx-w-660">
                <FormItem label={t('country')} extra={errors.country}>
                  <Select
                    placeholder={t('selectCountry')}
                    options={activeCountries}
                    onSearch={handleCountriesSearch}
                    loading={loadingActiveCountries || loadingInstitute}
                    value={state.country}
                    onChange={(value): void => onChange('country', value)}
                  />
                </FormItem>

                <FormItem
                  label={t('city')}
                  extra={errors.city ? errors.city : !state.country ? t('selectCountryError') : ''}
                  extraStatus={errors.city ? 'error' : 'regular'}
                >
                  <Select
                    placeholder={t('selectCity')}
                    options={state.country ? activeCities.filter((city) => city.countryID === state.country) : []}
                    loading={loadingActiveCities || loadingInstitute}
                    onSearch={handleCitiesSearch}
                    value={state.city}
                    onChange={(value): void => onChange('city', value)}
                    disabled={!state.country}
                  />
                </FormItem>
              </FormItems>

              <FormItem className="mx-w-660" label={t('fullAddress')} extra={errors.address}>
                <Input value={state.address} onChange={(value) => onChange('address', value)} />
              </FormItem>

              <FormItems className="mx-w-660">
                <FormItem required label={t('date')} extra={errors.date}>
                  <DateRangePicker
                    type="date"
                    date={state.date}
                    onChange={handleDatePickerChange}
                    placeholder={t('date')}
                  />
                </FormItem>
              </FormItems>
            </Loader>
          </Fragment>
        </Form>
      </GraduationsLayout>
    </>
  );
};
