import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { UserContext } from 'contexts';
import { Layout } from 'ui/organisms';

export const MyStudentsLayout: React.FC<{ role?: string; children: React.ReactNode }> = ({ role, children }) => {
  const { t } = useIntl();
  const { userData } = React.useContext(UserContext);

  return <Layout title={role ? t(role) : userData.role !== 'admin' ? t('myStudents') : t('users')}>{children}</Layout>;
};
