import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Modal, ModalFooterButton, Icon, Button, Checkbox } from 'ui/atoms';
import { ModalSize } from 'ui/atoms/Modal/Modal';
import { Expanded, Expande } from 'ui/atoms/Table/Expanded';

import './Confirmation.scss';

interface Props {
  mask?: boolean;
  size?: ModalSize;
  title?: string;
  type?: string;
  onConfirm: () => void;
  disabled?: boolean;
  text?: string;
  content?: React.ReactNode;
  contentClass?: string;
  isBibleGroup?: boolean;
  expanded?: Expande;
  children: React.ReactElement;
}

export const Confirmation: React.FC<Props> = ({
  mask = true,
  size,
  content,
  contentClass = '',
  title,
  type,
  children,
  onConfirm,
  disabled,
  text: $text,
  isBibleGroup,
  expanded,
}) => {
  const { t } = useIntl();
  const mount = React.useRef<boolean>(false);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState('');
  const [visible, setVisibility] = React.useState(false);
  const [acceptBible, setAcceptBible] = React.useState(false);

  React.useEffect(() => {
    mount.current = true;

    return () => {
      mount.current = false;
    };
  }, []);

  React.useEffect(() => {
    let text = '';
    if (type === 'logout') {
      text = t('areYouSureLogout');
    } else if (type === 'download') {
      text = t('areYouSureDownload');
    } else if (type === 'delete') {
      text = t('areYouSureDelete');
    } else {
      text = t('areYouSureComplete');
    }

    if ($text) {
      text = $text;
    }

    setText(text);
  }, [type, $text]);

  const onConfirmHandler = async (): Promise<void> => {
    setLoading(true);

    try {
      await onConfirm();

      if (mount.current) {
        onClose();
      }
    } catch {
      if (mount.current) {
        setLoading(false);
      }
    }
  };

  const onClose = (): void => {
    setLoading(false);
    setVisibility(false);
  };

  const onOpenModal = (): void => {
    setLoading(false);
    setVisibility(true);
  };

  const onChildrenClick = (): void => {
    if (children.props.onClick) {
      children.props.onClick();
    }

    onOpenModal();
  };

  const onToggleAcceptBible = (): void => setAcceptBible((s) => !s);

  if (!onConfirm) {
    return children;
  }

  if (!children) {
    return null;
  }

  return (
    <>
      {React.cloneElement(children, {
        onClick: onChildrenClick,
      })}

      {visible && !disabled && (
        <Modal
          mask={mask}
          contentClass={`zh-confirmation-content ${contentClass}`}
          onClose={onClose}
          title={title || t('confirmAcceptance')}
          size={size}
          footer={
            <ModalFooterButton>
              {isBibleGroup && (
                <div className="mb-15">
                  <Checkbox label={t('agreeBiblicalGroup')} checked={acceptBible} onChange={onToggleAcceptBible} />
                </div>
              )}

              <Button onClick={onClose} disabled={loading}>
                {t('refuse')}
              </Button>

              <Button
                type="primary"
                prefix={<Icon type="check" />}
                loading={loading}
                disabled={isBibleGroup && !acceptBible}
                onClick={onConfirmHandler}
              >
                {t('confirm')}
              </Button>
            </ModalFooterButton>
          }
        >
          {content || (
            <>
              <Icon type="alert" size="big" />

              <h2>{t('careful')}</h2>

              <p>{text}</p>

              {expanded && <Expanded data={expanded} />}
            </>
          )}
        </Modal>
      )}
    </>
  );
};
