import * as React from 'react';
import { render } from 'react-dom';
import Notifications from 'react-notify-toast';
import { CreateRouter } from 'estafette-router';
import { CreateIntl } from 'estafette-intl';
import { messages } from 'locales';
import { BreadcrumbsProvider, NomenclaturesProvider, NotificationsProvider, UserProvider } from 'contexts';
import { routes } from 'routes';
import { history } from 'libs/history';

import { success, info, warning, error } from 'ui/colors.json';

import 'styles/index.scss';

const notificationsOptions = {
  colors: {
    success: {
      backgroundColor: success,
      color: '#000403',
    },
    info: {
      backgroundColor: info,
      color: '#fff',
    },
    warning: {
      backgroundColor: warning,
      color: '#000403',
    },
    error: {
      backgroundColor: error,
      color: '#fff',
    },
  },
};

render(
  <>
    <Notifications options={notificationsOptions} />

    <UserProvider>
      {({ language }) => (
        <NotificationsProvider>
          <NomenclaturesProvider>
            <CreateIntl defaultLocale={language} messages={messages}>
              <BreadcrumbsProvider type="regular">
                <CreateRouter routes={routes} history={history} />
              </BreadcrumbsProvider>
            </CreateIntl>
          </NomenclaturesProvider>
        </NotificationsProvider>
      )}
    </UserProvider>
  </>,
  document.getElementById('root'),
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    // returns installed service workers
    if (registrations.length) {
      for (const registration of registrations) {
        registration.unregister();
      }
    }
  });
}
