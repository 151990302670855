import * as React from 'react';
import { useHistory, useParams } from 'estafette-router';
import { UserContext } from 'contexts';
import { useIntl } from 'estafette-intl';
import { EditAccount, Head } from 'ui/organisms';

import { InstituteLayout } from '../organisms';

export const EditCoordinatorPage: React.FC = () => {
  const { t } = useIntl();
  const { action } = useParams();
  const { goBack } = useHistory();
  const { userData } = React.useContext(UserContext);

  const id = React.useMemo(() => (userData.id === Number(action) ? 'my' : Number(action)), [userData, action]);

  const onSuccess = (): void => goBack();

  return (
    <>
      <Head t="editCoordinator" />

      <InstituteLayout>
        <h1 className="mt-20">{t('editCoordinator')}</h1>

        <EditAccount id={id} onSuccess={onSuccess} onClose={onSuccess} />
      </InstituteLayout>
    </>
  );
};
