import * as React from 'react';
import { useIntl } from 'estafette-intl';
import OldTable from 'rc-table';
import { Icon } from 'ui/atoms';
import { Expanded } from './Expanded';

import 'rc-table/assets/index.css';

import './Table.scss';
import { Button } from '../Button/Button';

const types: ['desc', 'asc'] = ['desc', 'asc'];

export interface Column<T> {
  title?: React.ReactNode;
  dataIndex?: string;
  className?: string;
  width?: string | number;
  onFilter?: (type: 'asc' | 'desc') => void;
  render?: (value: T) => React.ReactNode;
  mobileRender?: (data: T) => React.ReactNode;
  action?: boolean;
}

interface Props<T> {
  className?: string;
  page?: number;
  data: T[];
  columns: Column<T>[];
  size?: 'small' | 'big' | 'regular';
  expandable?: any;
  expandColumn?: boolean;
  rowClassName?: string;
  scroll?: {
    x?: number | true | string;
    y?: number | string;
  };
}

export const Table = <T extends object>({
  page,
  columns: $columns,
  data: $data = [],
  className = '',
  size = 'regular',
  expandable,
  expandColumn,
  rowClassName = '',
  scroll,
}: Props<T>): React.ReactElement => {
  const { t } = useIntl();
  const [filters, setFilters] = React.useState<{ [key: number]: 'desc' | 'asc' }>({});
  const [expand, setExpand] = React.useState<null | number>(null);

  const onFilterHandler = React.useCallback(
    (key: number) => {
      const cloneFilters = { ...filters };

      if (cloneFilters[key]) {
        if (types[types.length - 1] === cloneFilters[key]) {
          delete cloneFilters[key];
        } else {
          // get next to
          cloneFilters[key] = types[types.indexOf(cloneFilters[key]) + 1];
        }
      } else {
        cloneFilters[key] = types[0];
      }

      const { onFilter } = $columns[key];
      if ($columns[key] && onFilter !== undefined) {
        onFilter(cloneFilters[key] || '');
      }

      setFilters(cloneFilters);
    },
    [$columns, filters],
  );

  const data = React.useMemo(
    () =>
      $data.map<{ key: number; [key: string]: any }>((item, key) => ({
        ...item,

        key: page !== undefined && page > 1 ? (page - 1) * 25 + key + 1 : key + 1,
      })),
    [$data],
  );

  const columns = React.useMemo(
    () =>
      $columns.map(({ title, onFilter, ...column }, key) => ({
        ...column,
        key,
        title: onFilter ? (
          <span
            className={`zh-table-th-filtered zh-table-th-filtered-${filters[key] || 'none'}`}
            onClick={(): void => onFilterHandler(key)}
          >
            {title} <Icon type="arrow-down" />
          </span>
        ) : (
          title
        ),
      })),
    [$columns, filters, onFilterHandler],
  );

  const onMobileClick = (id: number): void => setExpand((prevExpand) => (prevExpand === id ? null : id));

  return (
    <div className={`zh-table-wrapper ${className}`}>
      <OldTable
        rowClassName={({ status, is_deleted, is_active }) =>
          `${rowClassName} ${
            status || is_deleted || (typeof is_active === 'boolean' && !is_active)
              ? ` zh-table-row-status-${status || ((is_deleted || !is_active) && 'deleted')}`
              : ``
          }`
        }
        className={`zh-table zh-table-size-${size}`}
        scroll={scroll}
        data={data}
        columns={columns}
        emptyText={t('empty')}
        {...(expandable !== undefined
          ? {
              expandable: {
                expandedRowRender: (data) => <Expanded data={expandable(data)} column={expandColumn} />,
                expandIcon: ({ expanded, onExpand, record }) => (
                  <Icon
                    type="arrow-right"
                    className={`expand-icon ${expanded ? 'expanded' : ''}`}
                    onClick={(): void => onExpand(record, {} as React.MouseEvent<HTMLElement, MouseEvent>)}
                  />
                ),
                expandRowByClick: false,
              },
            }
          : {})}
      />

      <div className="zh-table-mobile">
        {!data.length && (
          <div className="flex-data">
            <div className="zh-empty-list ">{t('empty')}</div>
          </div>
        )}
        {data.map((item: any) => (
          <div key={item.key} className="zh-table-mobile-item">
            <div className="zh-table-mobile-item-key">{item.key}</div>
            {columns.map((column) => {
              const render =
                column.mobileRender !== undefined
                  ? column.mobileRender(item as T)
                  : column.render !== undefined
                  ? column.render(item as T)
                  : column.dataIndex !== undefined
                  ? (item[column.dataIndex] as T)
                  : '---';

              return (
                <React.Fragment key={column.key}>
                  {column.action !== true ? (
                    <div
                      className={
                        column.key > 1
                          ? 'zh-table-mobile-item-child'
                          : !column.key
                          ? 'zh-table-mobile-item-title'
                          : 'zh-table-mobile-item-desc'
                      }
                      key={column.key}
                    >
                      {column.key > 1 && <span className="zh-table-mobile-item-child-title">{column.title}:</span>}
                      {column.key > 1 ? <span className="zh-table-mobile-item-child-name">{render}</span> : render}
                    </div>
                  ) : (
                    render && (
                      <div className="zh-table-mobile-item-action" key={column.key}>
                        {render}
                      </div>
                    )
                  )}
                </React.Fragment>
              );
            })}

            {expandable && (
              <>
                <Button
                  type="primary"
                  onClick={() => onMobileClick(item.id)}
                  className={expand === item.id ? 'expand' : undefined}
                >
                  {t('viewMoreDetails')}
                </Button>
                {expand === item.id && <Expanded data={expandable(item)} column={expandColumn} />}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
