import * as React from 'react';
import { useRequest } from 'estafette';
import { useHistory, useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { useStateHandlers } from 'hooks';
import { faculties, institutions, users } from 'libs/http/api';
import { List, Results, Save } from 'libs/http/api/index.types';
import { keys, parseQuery } from 'libs/object';
import { Form, FormItem, FormItems, Head } from 'ui/organisms';
import { Loader, Button, Fragment, Icon, Select, Alert, Input } from 'ui/atoms';
import { graduations } from 'libs/http/api/graduations/graduations';
import {
  CertificationLevel,
  Graduation,
  GraduateState,
  StudentGraduation,
} from 'libs/http/api/graduations/graduations.types';
import { User, UserBaseSession } from 'libs/http/api/me/me.types';
import { Faculty } from 'libs/http/api/faculties/faculties.types';
import { lowerFirstLetter } from 'libs/string';
import { Option } from 'ui/atoms/Select/Select';

import { GraduationsLayout } from '../organisms';

interface State {
  diploma_number: string | null;
  usersSearch: string | null;
  certificationsSearch: string | null;
  selectedUser?: User;
}

export const AddGraduationGraduatePage: React.FC = () => {
  const { t, locale } = useIntl();
  const { id, action } = useParams();
  const { goBack } = useHistory();

  const { request: requestGraduation, data: dataGraduation, loading: loadingGraduation } = useRequest<Graduation>({
    data: {},
  });
  const { request: requestGraduate, data: dataGraduate, loading: loadingGraduate } = useRequest<StudentGraduation>({
    data: {},
  });
  const { request: requestUsers, data: dataUsers, loading: loadingUsers } = useRequest<Results<User>>({
    data: { results: [] },
  });
  const { request: requestFaculties, data: dataFaculties, loading: loadingFaculties } = useRequest<Results<Faculty>>({
    data: { results: [] },
  });
  const { request: requestCertifications, data: dataCertifications, loading: loadingCertifications } = useRequest<
    CertificationLevel[]
  >({ data: [] });
  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<Save>();
  const { request: requestInstitutions, data: dataInstitutions, loading: loadingInstitutions } = useRequest<List[]>();

  const graduateState = {
    student: null,
    institution: null,
    faculty: null,
    certification_level: null,
    graduation: null,
    diploma_number: null,
  };

  const [state, setState] = useStateHandlers<State & GraduateState>({
    ...graduateState,
    usersSearch: null,
    certificationsSearch: null,
  });

  React.useEffect(() => {
    const onFetchGraduation = async (): Promise<void> => {
      if (isEdit) {
        const data = await requestGraduate(graduations.loadStudent.action(action));

        setState((prevState) => {
          return keys(graduateState).reduce((acc, current) => {
            const item = data[current];

            return {
              ...acc,
              [current]:
                !Array.isArray(item) && item instanceof Object
                  ? item.id
                  : typeof item !== 'boolean'
                  ? item || prevState[current]
                  : item,
            };
          }, {});
        });
      }
    };

    onFetchGraduation();
  }, [action]);

  React.useEffect(() => {
    requestGraduation(graduations.load.action(id));
    requestFaculties(faculties.get.action({ per_page: 1000 }));
    requestInstitutions(institutions.list.action({ is_active: true }));

    return () => {
      faculties.get.cancel();
      graduations.load.cancel();
      graduations.loadStudent.cancel();
      institutions.list.cancel();
    };
  }, []);

  React.useEffect(() => {
    requestUsers(users.getAll.action({ search: state.usersSearch, per_page: 50 }));
  }, [state.usersSearch]);

  React.useEffect(() => {
    requestCertifications(graduations.getCertificationLevels.action({ search: state.certificationsSearch }));
  }, [state.certificationsSearch]);

  React.useEffect(() => {
    const setInstitution = (): void => {
      if (dataGraduation.institution) {
        setState({ institution: dataGraduation.institution.id });
      }
    };

    setInstitution();
  }, [dataGraduation]);

  React.useEffect(() => {
    if (state.student) {
      const selectedUser = dataUsers.results.find((i) => i.id === state.student);

      if (selectedUser) {
        setState({ selectedUser });
      }
    }
  }, [state.student]);

  const onSubmit = async (): Promise<void> => {
    const query: { [key: string]: any } = {};

    const formFields = { ...state, graduation: dataGraduation.id };

    if (!isEdit) {
      await requestSave(graduations.addStudent.action(formFields));
    } else {
      await requestSave(graduations.saveStudent.action(action, formFields));

      const { page } = parseQuery<{ page: number }>(window.location.search);
      query.page = page;
    }

    goBack();
  };

  const onChange = React.useCallback(
    (target: string, value: any): void =>
      setState({
        [target]: value,
      }),
    [],
  );
  const onCancel = (): void => goBack();

  const usersOptions = React.useMemo(() => {
    const localUsersOptions = dataUsers.results.map((user) => setSelectLabel(user));

    if (dataGraduate.student) {
      localUsersOptions.push(setSelectLabel(dataGraduate.student));
    }

    if (state.selectedUser) {
      localUsersOptions.push(setSelectLabel(state.selectedUser));
    }

    return localUsersOptions;
  }, [dataUsers, dataGraduate]);

  const facultiesOptions = React.useMemo(
    () => dataFaculties.results.map((faculty) => ({ value: faculty.id, title: faculty.name_en })),
    [dataFaculties],
  );
  const institutionsOptions = React.useMemo(
    () => dataInstitutions.map((institution) => ({ value: institution.key, title: institution.label })),
    [dataInstitutions],
  );

  const certificationsOptions = React.useMemo(
    () => dataCertifications.map((certification) => ({ value: certification.id, title: certification.name })),
    [dataCertifications],
  );

  const isEdit = React.useMemo(() => action !== 'add', [action]);

  const pageTitle = React.useMemo(() => t(isEdit ? 'editItem' : 'addItem', { item: t('graduate').toLowerCase() }), [
    isEdit,
    locale,
  ]);

  const handleUserSearch = (usersSearch: string): void => setState({ usersSearch });

  return (
    <>
      <Head title={pageTitle} />

      <GraduationsLayout>
        <h1>{pageTitle}</h1>

        <Alert message={dataSave.message || errors.non_field_errors || errors.detail} form type="error" />

        <Form onSubmit={onSubmit}>
          <Fragment
            footer={
              <>
                <Button onClick={onCancel}>{t('cancel')}</Button>

                <Button submit type="primary" prefix={<Icon type="check" />} loading={loadingSave}>
                  {t('confirm')}
                </Button>
              </>
            }
          >
            <Loader loading={(isEdit && loadingGraduate) || loadingGraduation} height="100vh">
              <FormItems className="mx-w-660">
                <FormItem label={t('diploma')} extra={errors.diploma_number}>
                  <Input
                    value={state.diploma_number || ''}
                    onChange={(value): void => onChange('diploma_number', value)}
                  />
                </FormItem>
              </FormItems>

              <FormItems className="mx-w-660">
                <FormItem label={t('users')} extra={errors.institution}>
                  <Select
                    placeholder={t('selectUser')}
                    options={usersOptions}
                    loading={loadingUsers}
                    value={state.student}
                    onChange={(value) => onChange('student', value)}
                    onSearch={handleUserSearch}
                  />
                </FormItem>
              </FormItems>

              <FormItems className="mx-w-660">
                <FormItem label={t('faculty')} extraStatus={errors.faculty ? 'error' : 'regular'}>
                  <Select
                    placeholder={t('selectItem', { item: lowerFirstLetter(t('faculty')) })}
                    options={facultiesOptions}
                    value={state.faculty}
                    loading={loadingFaculties}
                    onChange={(value) => onChange('faculty', value)}
                  />
                </FormItem>
              </FormItems>

              <FormItems className="mx-w-660">
                <FormItem label={t('institute')} extra={errors.institution}>
                  <Select
                    placeholder={t('selectInstitute')}
                    options={institutionsOptions}
                    loading={loadingInstitutions}
                    value={state.institution}
                    onChange={(value) => onChange('institution', value)}
                  />
                </FormItem>
              </FormItems>

              <FormItems className="mx-w-660">
                <FormItem label={t('certificationLevel')} extra={errors.certification_level}>
                  <Select
                    placeholder={t('selectItem', { item: lowerFirstLetter(t('certificationLevel')) })}
                    options={certificationsOptions}
                    loading={loadingCertifications}
                    value={state.certification_level}
                    onChange={(value): void => onChange('certification_level', value)}
                  />
                </FormItem>
              </FormItems>
            </Loader>
          </Fragment>
        </Form>
      </GraduationsLayout>
    </>
  );
};

const setSelectLabel = (user: User | UserBaseSession): Option => ({
  value: user.id,
  title: (
    <span>
      <b>
        {user.first_name} {user.last_name}
      </b>{' '}
      {user.email}
    </span>
  ),
});
