import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Info } from 'ui/atoms';

interface ExpandeType {
  label?: string;
  value: string | React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
}

export type Expande = ExpandeType[][];

export const Expanded: React.FC<{ data: Expande; column?: boolean }> = ({ data, column }) => {
  const { t } = useIntl();

  return (
    <div className="flex-data expanded">
      <div className={`flex-item ${column ? 'column' : ''}`}>
        {data.length ? (
          data
            .filter(
              (item) =>
                item.filter(
                  (col) =>
                    (col.value &&
                      (Number(col.value) ||
                        (typeof col.value === 'string' && col.value.length > 0) ||
                        React.isValidElement(col.value))) ??
                    true,
                ).length ?? true,
            )
            .map((col: any, i: number) => (
              <div key={`row-${i}`} className={`flex-item-row ${column ? 'column' : ''}`}>
                {col.map((item: any, y: number) =>
                  item.value
                    ? (Number(item.value) || item.value.length > 0 || typeof item.value === 'object') && (
                        <div
                          key={`col-${y}`}
                          className={`flex-item-row-col column ${item.className ? item.className : ''}`}
                        >
                          {item.label && <label>{item.label}</label>}
                          <Info icon={item.icon ? item.icon : undefined} label={item.value} />
                        </div>
                      )
                    : Array.isArray(item) &&
                      item.map(
                        (td: any, t: number) =>
                          td.value &&
                          (Number(td.value) || td.value.length > 0 || typeof td.value === 'object') && (
                            <div
                              key={`col-${t}`}
                              className={`flex-item-row flex-item-row-small  ${td.className ? td.className : ''}`}
                            >
                              {td.label && <label>{td.label}</label>}
                              <Info icon={td.icon ? td.icon : undefined} label={td.value} />
                            </div>
                          ),
                      ),
                )}
              </div>
            ))
        ) : (
          <div className="flex-item-empty">{t('empty')}</div>
        )}
      </div>
    </div>
  );
};
