import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { save, load } from 'react-cookies';
import { routes } from 'routes';
import { history } from 'libs/history';
import { random } from 'libs/number';
import { UserContext } from 'contexts';
import { Breadcrumb } from 'ui/atoms/Breadcrumbs/Breadcrumbs';

// Breadcrumbs Limit
const LIMIT = 5;

interface ExtendedBreadcrumb extends Breadcrumb {
  key: string;
}

interface ActiveRoute {
  url?: string;
  path?: string;
  params?: any;
  isExact?: boolean;
}

interface Props {
  readonly breadcrumbs: ExtendedBreadcrumb[];
  setRoute: React.Dispatch<any>;
}

export const BreadcrumbsContext = React.createContext<Props>({
  breadcrumbs: [],
  setRoute: (route) => route,
});

export const BreadcrumbsProvider = ({
  children,
  type = 'regular',
}: {
  children: React.ReactElement;
  type: 'regular' | 'history';
}): React.ReactElement => {
  const { t } = useIntl();
  const { logged } = React.useContext(UserContext);
  const [breadcrumbs, setBreadcrumbs] = React.useState<ExtendedBreadcrumb[]>(load('breadcrumbs') || []);
  const [activeRoute, setActiveRoute] = React.useState<ActiveRoute>({});
  const timer = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    if (type === 'regular' && activeRoute.path) {
      setBreadcrumbs(
        routes.reduce<ExtendedBreadcrumb[]>((acc, current) => {
          if (activeRoute.path === current.path) {
            if (current.parent) {
              acc = getParents(acc, current.parent);
            }

            return [
              ...acc,
              {
                key: current.path,
                href: replaceParams(current.path),
                label: current.label ? t(current.label) : '',
                loading: true,
              },
            ];
          }

          return acc;
        }, []),
      );
    }
  }, [activeRoute]);

  React.useEffect(() => {
    if (type === 'history') {
      history.listen(() => {
        if (timer.current !== undefined) {
          clearTimeout(timer.current);
        }

        setBreadcrumbs((prevBreadcrumbs) =>
          // we don't let to save in breadcrumbs a link twice
          prevBreadcrumbs.length === 0 ||
          (prevBreadcrumbs.length > 0 && prevBreadcrumbs[prevBreadcrumbs.length - 1].key !== history.location.pathname)
            ? [
                // check limits and if limits are reached remove first element from breadcrumbs
                ...prevBreadcrumbs.filter(
                  (_, key) =>
                    _.key !== '/' && !_.key.includes('public') && (prevBreadcrumbs.length < LIMIT || key !== 0),
                ),
                {
                  key: history.location.pathname,
                  href: `${history.location.pathname || ''}${history.location.search || ''}`,
                  label: '',
                  loading: true,
                },
              ]
            : prevBreadcrumbs,
        );
      });
    }

    return () => {
      if (timer.current !== undefined) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  React.useEffect(() => {
    if (logged && breadcrumbs.find(({ loading }) => loading)) {
      timer.current = setTimeout(() => {
        setBreadcrumbs((prevBreadcrumbs) => {
          const newBreadcrumbs = prevBreadcrumbs.map((breadcrumb) => {
            if (breadcrumb.loading) {
              return { ...breadcrumb, loading: false, label: document.title };
            }

            return breadcrumb;
          });

          // save all loaded crumbs
          save('breadcrumbs', newBreadcrumbs, { path: '/' });

          return newBreadcrumbs;
        });
      }, random(250, 1000));
    }
  }, [logged, breadcrumbs]);

  const replaceParams = React.useCallback(
    (href: string): string => {
      if (activeRoute.params) {
        const params = Object.keys(activeRoute.params);

        params.map((param) => {
          href = href.replace(`:${param}`, activeRoute.params[param]);
        });
      }

      return href || '';
    },
    [activeRoute.params],
  );

  const getParents = React.useCallback(
    (list: ExtendedBreadcrumb[], parent: string): ExtendedBreadcrumb[] => {
      const itsParent = routes.find(({ name }) => name === parent);

      if (itsParent) {
        list = [
          {
            key: itsParent.path,
            href: replaceParams(itsParent.path),
            label: itsParent.label ? t(itsParent.label) : '',
          },
          ...list,
        ];
      }

      return itsParent && itsParent.parent ? getParents(list, itsParent.parent) : list;
    },
    [t],
  );

  return (
    <BreadcrumbsContext.Provider
      value={{ breadcrumbs, setRoute: React.useCallback((route) => setActiveRoute(route), []) }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};
