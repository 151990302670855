import * as React from 'react';
import { useHistory } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { Button, Icon } from 'ui/atoms';
import { Layout } from 'ui/organisms';

interface Props {
  children: React.ReactElement;
}

export const PanelLayout: React.FC<Props> = ({ children }) => {
  const { t } = useIntl();
  const { push } = useHistory();

  const onClick = (): void => push('SessionsApplicationPage');

  return (
    <Layout
      title={t('informativePanel')}
      rightSide={
        <Button type="invert" prefix={<Icon type="plus" />} className="nowrap" onClick={onClick}>
          {t('registerSession')}
        </Button>
      }
    >
      {children}
    </Layout>
  );
};
