import * as React from 'react';
import { useRequest } from 'estafette';
import { Link, useHistory, useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { useStateHandlers } from 'hooks';
import { manuals } from 'libs/http/api';
import { Manual } from 'libs/http/api/manuals/manuals.types';
import { Save } from 'libs/http/api/index.types';
import { keys, parseQuery } from 'libs/object';
import { Form, FormItem, FormItems, Head } from 'ui/organisms';
import { Button, Fragment, Icon, Input, Loader, Alert } from 'ui/atoms';

import { ManualsLayout } from '../organisms';

export const AddManualPage: React.FC = () => {
  const { t } = useIntl();
  const { action } = useParams();
  const { push, goBack } = useHistory();
  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<Save>();
  const { request: requestCourse, loading: loadingCourse } = useRequest<Manual>({ data: {} });

  const [state, setState] = useStateHandlers({
    title_ro: '',
    title_ru: '',
    title_en: '',
    title_es: '',
  });

  React.useEffect(() => {
    return () => {
      manuals.list.cancel();
      manuals.add.cancel();
      manuals.load.cancel();
      manuals.save.cancel();
    };
  }, []);

  React.useEffect(() => {
    const onFetchCourse = async (): Promise<void> => {
      if (action !== 'add') {
        const data = await requestCourse(manuals.load.action({ id: action }));

        setState((prevState) =>
          keys(prevState).reduce(
            (acc, current) => ({
              ...acc,
              [current]: data[current] || prevState[current],
            }),
            {},
          ),
        );
      }
    };

    onFetchCourse();
  }, [action]);

  const onSubmit = async (): Promise<void> => {
    const query: { [key: string]: any } = {};

    if (action === 'add') {
      await requestSave(manuals.add.action(state));
    } else {
      await requestSave(manuals.save.action({ ...state, id: action }));

      const { page } = parseQuery<{ page: number }>(window.location.search);
      query.page = page;
    }

    push('ManualsPage', { query });
  };

  const onChange = React.useCallback((target: string, value: any): void => setState({ [target]: value }), []);
  const onCancel = (): void => goBack();

  const isEdit = React.useMemo(() => action !== 'add', [action]);

  return (
    <>
      <Head t={isEdit ? 'editManual' : 'addManual'} />

      <ManualsLayout>
        <h1 className="mt-5">{t(isEdit ? 'editManual' : 'addManual')}</h1>

        <Alert message={dataSave.message || errors.non_field_errors || errors.detail} form type="error" />

        <Form onSubmit={onSubmit}>
          <Fragment
            footer={
              <>
                <Link route="ManualsPage">
                  <Button onClick={onCancel}>{t('cancel')}</Button>
                </Link>

                <Button submit type="primary" prefix={<Icon type="check" />} loading={loadingSave}>
                  {t('confirm')}
                </Button>
              </>
            }
          >
            <Loader loading={isEdit && loadingCourse} height={90}>
              <h2 className="medium mb-20">{t('manual')}</h2>

              <FormItems className="mx-w-660">
                <FormItem label="Name" extra={errors.title_en}>
                  <Input value={state.title_en} onChange={(value) => onChange('title_en', value)} />
                </FormItem>

                <FormItem label="Denumire" extra={errors.title_ro}>
                  <Input value={state.title_ro} onChange={(value) => onChange('title_ro', value)} />
                </FormItem>

                <FormItem label="Название" extra={errors.title_ru}>
                  <Input value={state.title_ru} onChange={(value) => onChange('title_ru', value)} />
                </FormItem>

                <FormItem label="Nombre" extra={errors.title_es}>
                  <Input value={state.title_es} onChange={(value) => onChange('title_es', value)} />
                </FormItem>
              </FormItems>
            </Loader>
          </Fragment>
        </Form>
      </ManualsLayout>
    </>
  );
};
