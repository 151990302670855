import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { setPerPage } from 'libs/storage';
import { Icon, Animated, Select } from 'ui/atoms';
import { Report } from 'ui/organisms';

import './Card.scss';

interface Props {
  className?: string;
}

export const Card: React.FC<Props> = ({ children, className = '' }) => (
  <div className={`zh-card ${className}`}>{children}</div>
);

interface HeaderProps {
  className?: string;
  title?: React.ReactNode;
  leftSide?: React.ReactNode;
  rightSide?: React.ReactNode;
  count?: number;
}

export const CardHeader: React.FC<HeaderProps> = ({ className = '', count = 0, title, leftSide, rightSide }) => {
  const rightSideLength = React.useMemo(
    () =>
      (rightSide &&
        rightSide instanceof Object &&
        'props' in rightSide &&
        Array.isArray(rightSide.props.children) &&
        rightSide.props.children.length) ||
      0,
    [rightSide],
  );

  return (
    <div className={`zh-card-header ${className}`}>
      <div className="zh-card-header-side-left">
        {title && (
          <div className="zh-card-header-title">
            {title}
            {count > 0 ? ` (${count})` : ''}
          </div>
        )}

        {leftSide}
      </div>

      {rightSide && <div className={`zh-card-header-side-right length-${rightSideLength}`}>{rightSide}</div>}
    </div>
  );
};

const optionsPerPage = [
  { value: 10, title: 10 },
  { value: 20, title: 20 },
  { value: 50, title: 50 },
  { value: 100, title: 100 },
  { value: 500, title: 500 },
];

interface FooterProps {
  onRefresh?: () => void;
  onRefreshDisabled?: boolean;
  className?: string;
  leftSide?: React.ReactNode;
  rightSide?: React.ReactNode;
  currentLabel?: string;
  page?: number;
  pages?: number;
  perPage?: number;
  onViewMore?: () => void;
  onChangePerPage?: (per_page: number) => void;
}

export const CardFooter: React.FC<FooterProps> = ({
  onRefresh,
  onRefreshDisabled,
  className = '',
  page,
  perPage,
  pages,
  leftSide,
  rightSide,
  onViewMore,
  onChangePerPage,
}) => {
  const { t } = useIntl();
  const hasPage = React.useMemo(() => page !== undefined, [page]);
  const hasOnRefresh = React.useMemo(() => onRefresh !== undefined, [onRefresh]);

  const handleChangePerPage = (perPage: number): void => {
    if (onChangePerPage) {
      onChangePerPage(perPage);
      setPerPage(perPage);
    }
  };

  return (
    <div className={`zh-card-footer ${className}`}>
      {hasPage || hasOnRefresh ? (
        <div className="zh-card-footer-total">
          {hasOnRefresh && (
            <Icon
              type="refresh"
              className={onRefreshDisabled ? 'disabled grey-svg' : ''}
              {...(!onRefreshDisabled ? { onClick: onRefresh } : {})}
            />
          )}

          {hasOnRefresh && pages && page && (page !== pages || page > 1) && (
            <>
              {page} {t('from')} {pages} {t('pages')}
            </>
          )}
        </div>
      ) : null}

      {leftSide && <div className="zh-card-footer-side-left">{leftSide}</div>}

      {perPage && onChangePerPage && pages && (
        <Select options={optionsPerPage} value={perPage} onChange={handleChangePerPage} className="per_page" />
      )}

      {rightSide || onViewMore !== undefined ? (
        <div className="zh-card-footer-side-right">
          {rightSide}

          {onViewMore !== undefined && (
            <div className="zh-card-footer-view-more" onClick={onViewMore}>
              {t('seeMoreAds')} <Icon type="arrow-right-long" />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

interface AlertProps {
  className?: string;
  type?: 'warning' | 'info' | 'error';
  label?: string;
  t: string;
}

export const CardAlert: React.FC<AlertProps> = ({ type = 'warning', className = '', label, t, children }) => (
  <Animated className="zh-card-alert-wrapper">
    <div className={`zh-card-alert zh-card-alert-type-${type} ${className}`}>
      {label && (
        <div className="zh-card-alert-label">
          <Icon type="info" />
          {label}
        </div>
      )}

      {children}

      <div className="zh-card-alert-report">
        <Report type={t} />
      </div>
    </div>
  </Animated>
);
