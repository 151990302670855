import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Link } from 'estafette-router';
import { UserContext } from 'contexts';
import { Layout } from 'ui/organisms';
import { Button, Icon } from 'ui/atoms';

export const GraduationsLayout: React.FC = ({ children }) => {
  const { userData } = React.useContext(UserContext);
  const { t } = useIntl();

  return (
    <Layout
      title={t('graduations')}
      rightSide={
        userData.role && (
          <>
            {userData.role === 'admin' && (
              <Link route="AddGraduationPage" params={{ action: 'add' }}>
                <Button type="invert" prefix={<Icon type="plus" />}>
                  {t('addItem', { item: t('graduation').toLowerCase() })}
                </Button>
              </Link>
            )}
          </>
        )
      }
    >
      {children}
    </Layout>
  );
};
