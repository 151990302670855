import { AxiosResponse, Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { Results } from '../index.types';
import {
  Indicator,
  UserList,
  EurasiaRegister,
  EurasiaRestore,
  EurasiaRestoreConfirm,
  SwitchUserRoleParams,
  ProfessorsListParams,
} from './users.types';
import { User, UserState } from '../me/me.types';

export const users = {
  register: {
    action: (params = {}): Promise<AxiosResponse<EurasiaRegister>> => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { Authorization, ...headersExceptToken } = axios.defaults.headers;
      return axios.post<EurasiaRegister>(`users/register`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.register.cancel = c)),
        headers: headersExceptToken,
      });
    },
    cancel: (() => null) as Canceler,
  },

  confirm: {
    action: (params = {}): Promise<AxiosResponse<EurasiaRegister>> =>
      axios.post(`users/register-confirm`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.confirm.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  restore: {
    action: (params = {}): Promise<AxiosResponse<EurasiaRestore>> =>
      axios.post(`reset-password`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.restore.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  restoreConfirm: {
    action: (params = {}): Promise<AxiosResponse<EurasiaRestoreConfirm>> =>
      axios.post(`reset-password/confirm`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.restoreConfirm.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  restoreVerify: {
    action: (params = {}): Promise<AxiosResponse<EurasiaRestoreConfirm>> =>
      axios.post(`reset-password/verify`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.restoreVerify.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  verify: {
    action: (options = {}) =>
      axios.post(`users/verify`, options, {
        cancelToken: new cancelToken((c: Canceler) => (users.refresh.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  refresh: {
    action: (options = {}) =>
      axios.post(`users/refresh`, options, {
        cancelToken: new cancelToken((c: Canceler) => (users.refresh.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  login: {
    action: (options = {}) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { Authorization, ...headersExceptToken } = axios.defaults.headers;
      return axios.post(`users/login`, options, {
        cancelToken: new cancelToken((c: Canceler) => (users.login.cancel = c)),
        headers: headersExceptToken,
      });
    },
    cancel: (() => null) as Canceler,
  },

  studentsList: {
    action: (params?: {}): Promise<{ data: UserList[] }> =>
      axios.get(`users/students_list`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (users.studentsList.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  professorsList: {
    action: (params: ProfessorsListParams): Promise<{ data: UserList[] }> =>
      axios.get(`users/professors_list`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (users.professorsList.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  coordinatorsList: {
    action: (params: {
      country?: number | null;
      city?: number | null;
      student_sessions?: string;
      institutions__in?: string;
      search?: string;
    }): Promise<{ data: UserList[] }> =>
      axios.get(`users/coordinators_list`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (users.coordinatorsList.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  get: {
    action: (params = {}): Promise<{ data: Results<User> }> =>
      axios.get(`users`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (users.get.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getAll: {
    action: (params = {}): Promise<{ data: Results<User> }> =>
      axios.get(`users/all`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (users.getAll.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  load: {
    action: ({ id, ...params }: { id: number | string }) =>
      axios.get(`users/${id}`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (users.load.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  indicators: {
    action: ({ id, ...params }: { id: number }): Promise<{ data: Results<Indicator> }> =>
      axios.get(`users/${id}/indicators`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (users.indicators.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  save: {
    action: ({ id, ...params }: SwitchUserRoleParams | UserState) =>
      axios.patch(`users/${id}`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.save.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  create: {
    action: ({ ...options }: UserState) =>
      axios.post(`users`, options, {
        cancelToken: new cancelToken((c: Canceler) => (users.save.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  delete: {
    action: ({ id }: { id: number }) =>
      axios.delete(`users/${id}`, {
        cancelToken: new cancelToken((c: Canceler) => (users.delete.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
