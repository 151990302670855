import * as React from 'react';
import { useRequest } from 'estafette';
import { Link, useHistory, useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { useStateHandlers } from 'hooks';
import { courses, manuals } from 'libs/http/api';
import { Course } from 'libs/http/api/courses/courses.types';
import { ManualList } from 'libs/http/api/manuals/manuals.types';
import { Save } from 'libs/http/api/index.types';
import { keys, parseQuery } from 'libs/object';
import { Form, FormItem, FormItems, Head } from 'ui/organisms';
import { Select, Button, Fragment, Icon, Input, Loader, Checkbox, Alert } from 'ui/atoms';

import { CoursesLayout } from '../organisms';

export const AddCoursePage: React.FC = () => {
  const { t } = useIntl();
  const { action } = useParams();
  const { push, goBack } = useHistory();
  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<Save>();
  const { request: requestCourse, loading: loadingCourse } = useRequest<Course>({ data: {} });
  const { request: requestManuals, data: dataManuals, loading: loadingManuals } = useRequest<ManualList[]>();

  const [state, setState] = useStateHandlers({
    title_ro: '',
    title_ru: '',
    title_en: '',
    title_es: '',
    books: [],
    course_grade: true,
    individual_work_grade: true,
    practise_grade: true,
    average_grade: true,
  });

  React.useEffect(() => {
    requestManuals(manuals.list.action());

    return () => {
      courses.list.cancel();
      courses.add.cancel();
      courses.load.cancel();
      courses.save.cancel();
      manuals.list.cancel();
    };
  }, []);

  React.useEffect(() => {
    const onFetchCourse = async (): Promise<void> => {
      if (action !== 'add') {
        const data = await requestCourse(courses.load.action({ id: action }));

        setState((prevState) =>
          keys(prevState).reduce((acc, current) => {
            const item = data[current];

            return {
              ...acc,
              [current]: Array.isArray(item) ? item.map((i) => i.id) : item,
            };
          }, {}),
        );
      }
    };

    onFetchCourse();
  }, [action]);

  const onSubmit = async (): Promise<void> => {
    const query: { [key: string]: any } = {};

    if (action === 'add') {
      await requestSave(courses.add.action(state));
    } else {
      await requestSave(courses.save.action({ ...state, id: action }));

      const { page } = parseQuery<{ page: number }>(window.location.search);
      query.page = page;
    }

    push('CoursesPage', { query });
  };

  const manualsOptions = React.useMemo(
    () => dataManuals.map((manual) => ({ value: manual.key, title: manual.label })),
    [dataManuals],
  );

  const onChange = React.useCallback((target: string, value: any): void => setState({ [target]: value }), []);
  const onCancel = (): void => goBack();

  const isEdit = React.useMemo(() => action !== 'add', [action]);

  return (
    <>
      <Head t={isEdit ? 'editCourse' : 'addCourse'} />

      <CoursesLayout>
        <h1 className="mt-5">{t(isEdit ? 'editCourse' : 'addCourse')}</h1>

        <Alert
          message={errors.message || dataSave.message || errors.non_field_errors || errors.detail}
          form
          type="error"
        />

        <Form onSubmit={onSubmit}>
          <Fragment
            footer={
              <>
                <Link route="CoursesPage">
                  <Button onClick={onCancel}>{t('cancel')}</Button>
                </Link>

                <Button submit type="primary" prefix={<Icon type="check" />} loading={loadingSave}>
                  {t('confirm')}
                </Button>
              </>
            }
          >
            <Loader loading={isEdit && loadingCourse} height={90}>
              <h2 className="medium mb-20">{t('course')}</h2>

              <FormItems className="mx-w-660">
                <FormItem label="Name" extra={errors.title_en}>
                  <Input value={state.title_en} onChange={(value) => onChange('title_en', value)} />
                </FormItem>

                <FormItem label="Denumire" extra={errors.title_ro}>
                  <Input value={state.title_ro} onChange={(value) => onChange('title_ro', value)} />
                </FormItem>

                <FormItem label="Название" extra={errors.title_ru}>
                  <Input value={state.title_ru} onChange={(value) => onChange('title_ru', value)} />
                </FormItem>

                <FormItem label="Nombre" extra={errors.title_es}>
                  <Input value={state.title_es} onChange={(value) => onChange('title_es', value)} />
                </FormItem>
              </FormItems>

              <FormItem required label={t('manuals')} error={errors.books} className="mx-w-660">
                <Select
                  mode="multiple"
                  cancelable
                  placeholder={t('selectManual')}
                  options={manualsOptions}
                  loading={loadingManuals}
                  value={state.books}
                  onChange={(value) => onChange('books', value)}
                />
              </FormItem>

              <FormItems className="mx-w-660">
                <FormItem>
                  <Checkbox
                    label={t('noteForCourse')}
                    checked={state.course_grade}
                    onChange={() => onChange('course_grade', !state.course_grade)}
                  />
                  <Checkbox
                    label={t('noteForIndividualWork')}
                    checked={state.individual_work_grade}
                    onChange={() => onChange('individual_work_grade', !state.individual_work_grade)}
                  />
                </FormItem>
                <FormItem>
                  <Checkbox
                    label={t('practiceGradeShort')}
                    checked={state.practise_grade}
                    onChange={() => onChange('practise_grade', !state.practise_grade)}
                  />
                  <Checkbox
                    label={t('averageGrade')}
                    checked={state.average_grade}
                    onChange={() => onChange('average_grade', !state.average_grade)}
                  />
                </FormItem>
              </FormItems>
            </Loader>
          </Fragment>
        </Form>
      </CoursesLayout>
    </>
  );
};
