import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { issues } from 'libs/http/api';
import { Save } from 'libs/http/api/index.types';
import { Confirmation, Form, FormItem } from 'ui/organisms';
import { Info, Button, Icon, RichText, Alert } from 'ui/atoms';

import './Report.scss';

interface Props {
  type: string;
  onSuccess?: () => void;
}

export const Report: React.FC<Props> = ({ type, onSuccess }) => {
  const { t } = useIntl();
  const { request, data, errors, loading } = useRequest<Save>();

  const [description, setDescription] = React.useState('');

  const onSubmit = async (): Promise<void> => {
    await request(
      issues.add.action({
        title: type,
        description: description,
        type: 'regular',
      }),
    );

    setDescription('');
    if (onSuccess !== undefined) {
      onSuccess();
    }
  };

  const onChange = (value: string): void => setDescription(value);

  const button = React.useMemo(() => {
    if (t(type) === t('signin') || t(type) === t('signup')) {
      return <a className="zh-report-button__link">{t('send')}</a>;
    } else {
      return (
        <Button className="zh-report-button" prefix={<Icon type="warning" />} loading={loading}>
          {t('send')}
        </Button>
      );
    }
  }, [type, t]);

  return (
    <Confirmation
      mask={false}
      title={t('reportProblem')}
      onConfirm={onSubmit}
      content={
        <>
          <Alert message={data.message || errors.non_field_errors || errors.detail} form type="error" />

          <Form className="zh-report" onSubmit={(): null => null}>
            <FormItem label={t('problemType')}>
              <Info primary label={t(type)} />
            </FormItem>

            <FormItem label={t('message')} extra={errors.description}>
              <RichText initialValue={description} onChange={onChange} />
            </FormItem>
          </Form>
        </>
      }
    >
      {button}
    </Confirmation>
  );
};
