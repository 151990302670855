import * as React from 'react';
import { useRequest, useList } from 'estafette';
import { Link } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { useFilters } from 'hooks';
import { UserContext } from 'contexts';
import { groupBy } from 'libs/array/array';
import { perPage } from 'libs/storage';
import { events, me, sessions } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { EurasiaEvent, Invitation } from 'libs/http/api/events/events.types';
import { dateTimeFormat, format, getFullMonth, getYear, today, toISODate } from 'libs/date';
import { Expande } from 'ui/atoms/Table/Expanded';
import { Roadmap, SortBy, InputSearch, Head, Confirmation } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { DateRangePicker } from 'ui/molecules';
import {
  Button,
  Icon,
  Info,
  Card,
  CardHeader,
  CardFooter,
  Calendar,
  Loader,
  Animated,
  Time,
  EmptyList,
  LoaderInline,
} from 'ui/atoms';

import { EventLayout } from '../../organisms';

import './EventsPage.scss';

const initialFilters = {
  filtered: false,
  page: 1,
  per_page: perPage,
  search: '',
  ordering: '' as Sorts,
  start_date__date__gte: '',
  end_date__date__lte: '',
};

export const EventsPage: React.FC = () => {
  const { t } = useIntl();
  const { userData } = React.useContext(UserContext);

  const { request: requestRejectInvitation, loading: loadingRejectInvitation } = useRequest();
  const { request: requestAcceptInvitation, loading: loadingAcceptInvitation } = useRequest();

  const { request: requestEvents, data: dataEvents, loading: loadingEvents } = useRequest<Results<EurasiaEvent>>({
    loading: true,
    data: { results: [] },
  });
  const { request: requestDelete, loading: loadingDelete } = useRequest();

  const { request: requestInvites, data: dataInvites, loading: loadingInvites } = useRequest<Results<Invitation>>({
    loading: true,
    data: { results: [] },
  });

  const [selectedId, setSelectedId] = React.useState<number | null>(null);
  const [invitationsFilters, setInvitationsFilters] = useFilters({ ...initialFilters });
  const [eventsFilters, setEventsFilters] = useFilters({ ...initialFilters });

  React.useEffect(() => {
    setInvitationsFilters({ per_page: perPage, page: 1 });
    setEventsFilters({ per_page: perPage, page: 1 });

    return () => {
      me.getEvents.cancel();
      events.invitations.cancel();
      sessions.invitations.cancel();
      events.respondToInvitations.cancel();
      sessions.respondToInvitations.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (userData.role === 'professor') {
      requestInvites(
        sessions.invitations.action({
          ...invitationsFilters,
          start_date__date__gte: invitationsFilters.start_date__date__gte
            ? toISODate(invitationsFilters.start_date__date__gte, true)
            : '',
          end_date__date__lte: invitationsFilters.end_date__date__lte
            ? toISODate(invitationsFilters.end_date__date__lte, true)
            : '',
        }),
      );
    } else {
      requestInvites(
        events.invitations.action({
          ...invitationsFilters,
          start_date__date__gte: invitationsFilters.start_date__date__gte
            ? toISODate(invitationsFilters.start_date__date__gte, true)
            : '',
          end_date__date__lte: invitationsFilters.end_date__date__lte
            ? toISODate(invitationsFilters.end_date__date__lte, true)
            : '',
        }),
      );
    }
  }, [userData.role, invitationsFilters]);

  React.useEffect(() => {
    if (!loadingRejectInvitation && !loadingAcceptInvitation) {
      requestEvents(
        me.getEvents.action({
          ...eventsFilters,
          start_date__date__gte: eventsFilters.start_date__date__gte
            ? toISODate(eventsFilters.start_date__date__gte, true)
            : '',
          end_date__date__lte: eventsFilters.end_date__date__lte
            ? toISODate(eventsFilters.end_date__date__lte, true)
            : '',
          end_date__date__gte:
            !eventsFilters.start_date__date__gte && !eventsFilters.end_date__date__lte
              ? toISODate(today.format(dateTimeFormat))
              : '',
        }),
      );
    }
  }, [eventsFilters, loadingRejectInvitation, loadingAcceptInvitation]);

  const onChangeInvitationsPeriod = (period: any): void =>
    setInvitationsFilters({
      filtered: true,
      page: 1,
      ...(period.start_date__gte ? { start_date__date__gte: period.start_date__gte } : {}),
      ...(period.end_date__lte ? { end_date__date__lte: period.end_date__lte } : {}),
    });
  const onChangeInvitationsFilters = React.useCallback(
    (target, value: any): void => setInvitationsFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetchInvites = (): void => setInvitationsFilters({ ...initialFilters });
  const onRefetchEvents = (): void => setEventsFilters({ ...initialFilters });
  const onChangeInvitesPerPage = (per_page: number): void => setInvitationsFilters({ per_page });
  const onChangeEventsPerPage = (per_page: number): void => setEventsFilters({ per_page });

  const onChangeEventsPeriod = (period: any): void =>
    setEventsFilters({
      filtered: true,
      page: 1,
      ...(period.start_date__gte ? { start_date__date__gte: period.start_date__gte } : {}),
      ...(period.end_date__lte ? { end_date__date__lte: period.end_date__lte } : {}),
    });
  const onChangeEventsFilters = React.useCallback(
    (target, value: any): void => setEventsFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onIncreaseInvitationsPage = (): void =>
    setInvitationsFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreaseInvitationsPage = (): void =>
    setInvitationsFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onIncreaseEventsPage = (): void =>
    setEventsFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreaseEventsPage = (): void =>
    setEventsFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onRejectInvitation = React.useCallback(
    async (id: number): Promise<void> => {
      setSelectedId(id);

      if (userData.role === 'professor') {
        await requestRejectInvitation(sessions.respondToInvitations.action({ id, status: 'declined' }));
      } else {
        await requestRejectInvitation(events.respondToInvitations.action({ id, status: 'declined' }));
      }

      await onRefetchInvites();

      setSelectedId(null);
    },
    [userData.role],
  );

  const onAcceptInvitation = React.useCallback(
    async (id: number): Promise<void> => {
      setSelectedId(id);

      if (userData.role === 'professor') {
        await requestAcceptInvitation(sessions.respondToInvitations.action({ id, status: 'accepted' }));
      } else {
        await requestAcceptInvitation(events.respondToInvitations.action({ id, status: 'accepted' }));
      }

      onRefetchInvites();
      setSelectedId(null);
    },
    [userData.role],
  );

  const onDelete = React.useCallback(async (id: number) => {
    await requestDelete(events.delete.action({ id }));

    onRefetchEvents();
  }, []);

  const dataInvitesResults = useList(dataInvites.results, (item) => (
    <div className="flex-item">
      <Link
        route="InvitePage"
        params={{ id: item.id, status: 'pending', query: { session: userData.role === 'professor' } }}
        className="flex-item-row pointer"
      >
        {item.type !== 'session' && item.name && (
          <div className="flex-item-row-col column">
            <label>{t('invite')}</label>
            <Info primary label={item.name} />
          </div>
        )}
        {item.type === 'session' && item.course?.name && (
          <div className="flex-item-row-col column">
            <label>{t('inviteToSession')}</label>
            <Info primary label={item.course?.name} />
          </div>
        )}

        <div className="flex-item-row-col">
          <div>
            <label>{t('dateAndTime')}</label>
            <Time date={item.start_date} format={dateTimeFormat} />
          </div>

          <div>
            <label>{t('language')}</label>
            <Info primary label={(item.language && item.language.name) || item.language} />
          </div>

          <div>
            <label>{t('location')}</label>
            <Info
              icon={<Icon type="pin" />}
              label={[item.country && item.country.title, item.city && item.city.title, item.address]
                .filter((i) => i)
                .join(', ')}
            />
          </div>
        </div>

        <div className="flex-item-row-col">
          {item.type && (
            <div>
              <label>{t('typeOfEvent')}</label>
              <Info label={t(item.type)} />
            </div>
          )}

          <div>
            <label>{t('course')}</label>
            <Info label={(item.course && item.course.name) || '---'} />
          </div>

          <div>
            <label>{t('teacher')}</label>
            <Info
              primary
              icon={<Icon type="user-strict" />}
              label={
                item.professor
                  ? [item.professor.first_name, item.professor.last_name].filter((i) => i).join(' ')
                  : '---'
              }
            />
          </div>
        </div>
      </Link>

      <div className="flex-item-actions">
        <Confirmation onConfirm={() => onRejectInvitation(item.id)}>
          <Button prefix={<Icon type="close" />} loading={loadingRejectInvitation && selectedId === item.id}>
            {t('reject')}
          </Button>
        </Confirmation>

        <Confirmation
          isBibleGroup={item.is_bible_group}
          expanded={userData.role === 'professor' ? expandable(item as Session, item?.type) : undefined}
          onConfirm={() => onAcceptInvitation(item.id)}
        >
          <Button
            type="primary"
            prefix={<Icon type="check" />}
            loading={loadingAcceptInvitation && selectedId === item.id}
          >
            {t('accept')}
          </Button>
        </Confirmation>
      </div>
    </div>
  ));

  const expandable = (
    {
      name,
      professor,
      start_date: startDate,
      end_date: endDate,
      country,
      city,
      info,
      course,
      institution,
      language,
    }: Session,
    type?: string,
  ): Expande => [
    [
      { label: type !== 'session' ? t('name') : t('course'), value: type !== 'session' ? name : course?.name || '---' },
      {
        label: t('startAt'),
        value: startDate ? format(startDate, dateTimeFormat) : '---',
        icon: <Icon type="calendar" />,
      },
      {
        label: t('finishAt'),
        value: startDate ? format(endDate, dateTimeFormat) : '---',
        icon: <Icon type="calendar" />,
      },
    ],
    [
      { label: t('institute'), value: (institution && institution.name) || '---', icon: <Icon type="location" /> },
      {
        label: t('teacher'),
        value: professor ? [professor.first_name, professor.last_name].filter((i) => i).join(' ') : '---',
      },
      {
        label: t('place'),
        value:
          country && country.title ? [country && country.title, city && city.title].filter((i) => i).join(', ') : '---',
        icon: <Icon type="pin" />,
      },
    ],
    [
      { label: t('language'), value: (language && language.name) || '---' },
      {
        label: t('info_short'),
        value: info ? <span dangerouslySetInnerHTML={{ __html: info }} /> : '---',
        icon: <Icon type="info" />,
      },
    ],
  ];

  const dataEventsResults = React.useMemo(
    () =>
      groupBy(
        'calendar',
        dataEvents.results.map((item) => ({
          ...item,
          calendar: `${t(getFullMonth(item.start_date).toLowerCase())} ${getYear(item.start_date)}`,
        })),
      ).map((item) => ({
        key: item.target,
        target: item.target,
        childrenData: item.children,
        children: item.children.map((event) => (
          <Link
            key={event.id}
            route="InvitePage"
            params={{ id: event.id, status: 'accepted' }}
            className="flex-item flex-item-map pointer"
          >
            <div className="flex-item-actions">
              <Calendar date={event.start_date} type="date-time" />
            </div>

            <div className="flex-item-row">
              {event.name && event.type !== 'session' && (
                <div className="flex-item-row-col column">
                  <label>{t('invite')}</label>
                  <Info primary label={event.name} />
                </div>
              )}
              {event.course?.name && event.type === 'session' && (
                <div className="flex-item-row-col column">
                  <label>{t('course')}</label>
                  <Info primary label={event.course?.name || '---'} />
                </div>
              )}

              <div className="flex-item-row-col">
                <div>
                  <label>{t('dateAndTime')}</label>
                  <Time
                    date={`${format(event.start_date, dateTimeFormat)} - ${format(event.end_date, dateTimeFormat)}`}
                    noParse
                  />
                </div>

                <div>
                  <label>{t('language')}</label>
                  <Info primary label={event.language || '---'} />
                </div>

                <div>
                  <label>{t('location')}</label>
                  <Info
                    icon={<Icon type="pin" />}
                    label={[event.country && event.country.title, event.city && event.city.title, event.address]
                      .filter((i) => i)
                      .join(', ')}
                  />
                </div>
                <div>
                  <label>{t('typeOfEvent')}</label>
                  <Info label={t(event.type)} />
                </div>

                <div>
                  <label>{t('teacher')}</label>
                  <Info
                    primary
                    icon={<Icon type="user-strict" />}
                    label={
                      event.professor
                        ? [event.professor.first_name, event.professor.last_name].filter((i) => i).join(' ')
                        : '---'
                    }
                  />
                </div>
              </div>
            </div>
          </Link>
        )),
      })),
    [dataEvents.results],
  );

  return (
    <>
      <Head t={'invites'} />

      <EventLayout>
        <Card className="institute">
          <CardHeader
            title={t('invites')}
            count={dataInvites.count}
            leftSide={
              <InputSearch
                value={invitationsFilters.search}
                onSearch={(newValue) => onChangeInvitationsFilters('search', newValue)}
              />
            }
            rightSide={
              <>
                <div className="picker-wrapper">
                  <div className="hideTablet">{t('period')}</div>
                  <DateRangePicker
                    from={invitationsFilters.start_date__date__gte}
                    to={invitationsFilters.end_date__date__lte}
                    type="period"
                    onChange={onChangeInvitationsPeriod}
                  />
                </div>

                <SortBy
                  options={[
                    {
                      title: t('name'),
                      value: 'name',
                    },
                    {
                      title: t('country'),
                      value: 'country',
                    },
                    {
                      title: t('city'),
                      value: 'city',
                    },
                  ]}
                  value={invitationsFilters.ordering}
                  onChange={(newValue) => onChangeInvitationsFilters('ordering', newValue)}
                />

                {!['student', 'professor'].includes(userData.role) && (
                  <Link route="AddEventsPage">
                    <Button type="invert" prefix={<Icon type="add" />}>
                      {t('addEvent')}
                    </Button>
                  </Link>
                )}
              </>
            }
          />

          <Loader loading={loadingInvites}>
            <div className="flex-data">{dataInvites.results.length > 0 ? dataInvitesResults : <EmptyList />}</div>

            <CardFooter
              onRefresh={onRefetchInvites}
              onRefreshDisabled={!invitationsFilters.filtered}
              page={invitationsFilters.page}
              pages={dataInvites.total_pages}
              perPage={invitationsFilters.per_page}
              onChangePerPage={onChangeInvitesPerPage}
              rightSide={
                <>
                  <Button onClick={onDecreaseInvitationsPage} disabled={invitationsFilters.page === 1}>
                    {t('previous')}
                  </Button>

                  <Button
                    onClick={onIncreaseInvitationsPage}
                    disabled={invitationsFilters.page >= dataInvites.total_pages}
                  >
                    {t('next')}
                  </Button>
                </>
              }
            />
          </Loader>
        </Card>

        <Card>
          <CardHeader
            title={t('myEvents')}
            count={dataEvents.count}
            leftSide={
              <InputSearch
                value={eventsFilters.search}
                onSearch={(newValue) => onChangeEventsFilters('search', newValue)}
              />
            }
            rightSide={
              <>
                <div className="picker-wrapper">
                  <div className="hideTablet">{t('period')}</div>
                  <DateRangePicker
                    from={eventsFilters.start_date__date__gte}
                    to={eventsFilters.end_date__date__lte}
                    type="period"
                    onChange={onChangeEventsPeriod}
                  />
                </div>

                <SortBy
                  options={[
                    {
                      title: t('name'),
                      value: 'name',
                    },
                    {
                      title: t('country'),
                      value: 'country',
                    },
                    {
                      title: t('city'),
                      value: 'city',
                    },
                  ]}
                  value={eventsFilters.ordering}
                  onChange={(newValue) => onChangeEventsFilters('ordering', newValue)}
                />
              </>
            }
          />

          <Loader loading={loadingDelete}>
            <Animated loading={loadingInvites || (!loadingInvites && loadingEvents)} debounce={250}>
              <div className={!dataEvents.results.length ? 'flex-data' : undefined}>
                {dataEvents.results.length > 0 ? (
                  <Roadmap
                    data={dataEventsResults}
                    onDelete={['admin', 'coordinator'].includes(userData.role) ? onDelete : undefined}
                  />
                ) : (
                  <EmptyList />
                )}
              </div>
            </Animated>
          </Loader>

          <CardFooter
            onRefresh={onRefetchEvents}
            onRefreshDisabled={!eventsFilters.filtered}
            page={eventsFilters.page}
            pages={dataEvents.total_pages}
            perPage={eventsFilters.per_page}
            onChangePerPage={onChangeEventsPerPage}
            leftSide={loadingEvents && <LoaderInline />}
            rightSide={
              <>
                <Button onClick={onDecreaseEventsPage} disabled={eventsFilters.page === 1}>
                  {t('previous')}
                </Button>

                <Button onClick={onIncreaseEventsPage} disabled={eventsFilters.page >= dataEvents.total_pages}>
                  {t('next')}
                </Button>
              </>
            }
          />
        </Card>
      </EventLayout>
    </>
  );
};
