import { Canceler } from 'axios';
import env from '@beam-australia/react-env';
import { axios, cancelToken } from 'libs/http/axios';

import { EurasiaFile } from './file.types';

export const file = {
  upload: {
    action: (options = {}): Promise<{ data: EurasiaFile }> =>
      axios.post(`${env('FS_URL')}file/upload`, options, {
        cancelToken: new cancelToken((c: Canceler) => (file.upload.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
