import * as React from 'react';
import { getRoute, useHistory } from 'estafette-router';
import { Head } from 'ui/organisms';
import { UserContext } from 'contexts';
import { routes } from 'routes';
import { GraduationsTemplate } from '../templates';
import { GraduationsLayout } from '../organisms';

export const GraduationsPage: React.FC = () => {
  const { userData } = React.useContext(UserContext);
  const history = useHistory();

  React.useEffect(() => {
    if (!(userData && userData.role === 'admin')) {
      history.push(getRoute(routes, 'IndexEntry', { query: { permissions_guard: true } }));
    }
  }, [userData]);

  return (
    <>
      <Head t="graduations" />

      <GraduationsLayout>
        <GraduationsTemplate />
      </GraduationsLayout>
    </>
  );
};
