import * as React from 'react';
import { Head } from 'ui/organisms';

import { SessionsLayout } from '../organisms';
import { CurrentSessionsTemplate } from '../templates';

export const CurrentSessionsPage: React.FC = () => (
  <>
    <Head t="currentSessions" />

    <SessionsLayout>
      <CurrentSessionsTemplate />
    </SessionsLayout>
  </>
);
