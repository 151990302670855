import * as React from 'react';
import { useHistory, useParams } from 'estafette-router';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { UserContext } from 'contexts';
import { sessions } from 'libs/http/api';
import { dateFormat, format } from 'libs/date';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { Save } from 'libs/http/api/index.types';
import { Alert, Animated, Button, Card, CardHeader, Icon, Info, Time } from 'ui/atoms';
import { Confirmation } from 'ui/organisms';
import { Public } from 'features/public/organisms';
import { Content } from 'features/public/atoms';

import './PublicSessionPage.scss';

export const PublicSessionPage: React.FC = () => {
  const { t } = useIntl();
  const auth = React.useContext(UserContext);
  const { id } = useParams();
  const { push } = useHistory();
  const { request, data, loading } = useRequest<Session>({ data: {} });
  const { request: requestRegister, data: responseRegister, errors: errorsRegister } = useRequest<Save>();
  React.useEffect(() => {
    return () => {
      sessions.load.cancel();
    };
  }, []);

  React.useEffect(() => {
    request(sessions.load.action({ id }));
  }, [id]);

  React.useEffect(() => {
    if (responseRegister && responseRegister.success) push('PlannedSessionsPage');
  }, [responseRegister]);

  const goTo = (id: number): void => {
    if (auth.logged) {
      requestRegister(sessions.register.action({ id: id }));
    } else {
      push('SignUpPage', { query: { session: id } });
    }
  };

  return (
    <Public>
      <Content className="container">
        <Animated loading={loading} debounce={500}>
          <Alert
            message={errorsRegister.non_field_errors || errorsRegister.detail}
            form
            className="mt-15"
            type="error"
          />
          <Card>
            <CardHeader
              title={
                <>
                  <h2 className="zh-session-title">{data.course?.name}</h2>

                  {data.language && <h3 className="zh-session-subtitle">{data.language.name}</h3>}
                </>
              }
            />

            <div className="zh-session-card">
              <div className="zh-session-card-item">
                {data.institution && (
                  <div className="zh-session-card-info">
                    <div className="zh-session-card-info-label">{t('institute')}</div>
                    <div className="zh-session-card-info-text">{data.institution.name}</div>
                  </div>
                )}

                <div className="zh-session-card-info">
                  <div className="zh-session-card-info-label">{t('sessionDuration')}</div>
                  <div className="zh-session-card-info-text nowrap">
                    <Time
                      date={`${format(data.start_date, dateFormat)} - ${format(data.end_date, dateFormat)}`}
                      noParse
                    />
                  </div>
                </div>

                <div className="zh-session-card-info">
                  <div className="zh-session-card-info-label">{t('location')}</div>
                  <div className="zh-session-card-info-text">
                    <Info
                      icon={<Icon type="location-filled" />}
                      label={[data.country && data.country.title, data.city && data.city.title, data.address]
                        .filter((i) => i)
                        .join(', ')}
                    />
                  </div>
                </div>
              </div>

              <div className="zh-session-card-item">
                <h3 className="zh-session-card-item-title">{t('taughtCourses')}</h3>

                <ul className="zh-session-card-item-faculties">
                  <li>{(data.course && data.course.name) || '---'}</li>
                </ul>
                <div className="session-card-register">
                  {!data.is_advance_level &&
                    (data.is_bible_group ? (
                      <Confirmation isBibleGroup={true} onConfirm={(): void => goTo(id)}>
                        <Button type="border">{t('registerSession')}</Button>
                      </Confirmation>
                    ) : (
                      <Button type="border" onClick={(): void => goTo(id)}>
                        {t('registerSession')}
                      </Button>
                    ))}
                </div>
              </div>
            </div>
          </Card>
        </Animated>
      </Content>
    </Public>
  );
};
