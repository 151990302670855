import * as React from 'react';
import { useRequest } from 'estafette';
import { load } from 'react-cookies';
import env from '@beam-australia/react-env';
import { useIntl } from 'estafette-intl';
import { useHistory } from 'estafette-router';
import { useFilters } from 'hooks';
import { UserContext } from 'contexts';
import { format, toISODate, today, dateFormat } from 'libs/date';
import { me } from 'libs/http/api';
import { perPage } from 'libs/storage';
import { sessions } from 'libs/http/api/sessions/sessions';
import { Results } from 'libs/http/api/index.types';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { DateRangePicker } from 'ui/molecules';
import { SortBy, InputSearch } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { Card, CardHeader, CardFooter, Button, Loader, Icon, CardAlert, Select } from 'ui/atoms';
import { Expande } from 'ui/atoms/Table/Expanded';

import { PlannedSessionsTable } from '../organisms';

import './PlannedSessions.scss';

const statusOption = [
  'pending',
  'accepted',
  'declined',
  'request_accepted',
  'professor_invitation_pending',
  'professor_invitation_accepted',
  // 'professor_invitation_declined',
  // todo this is commented because we plane to remove this status.
];

const filterStatusOption: { [key: string]: string[] } = {
  request_accepted: ['professor_invitation_declined', 'request_accepted'],
  pending: ['pending'],
  accepted: ['accepted'],
  declined: ['declined'],
  professor_invitation_pending: ['professor_invitation_pending'],
  professor_invitation_accepted: ['professor_invitation_accepted'],
};

export const PlannedSessionsTemplate: React.FC<{ update?: boolean }> = ({ update }) => {
  const { t } = useIntl();
  const { userData } = React.useContext(UserContext);
  const { push } = useHistory();
  const { request, data, loading, setData } = useRequest<Results<Session>>({ data: { results: [] } });
  const { request: requestDelete, loading: loadingDelete } = useRequest();
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    status: null as string | null,
    start_date__date__gte: '',
    end_date__date__lte: '',
    ordering: '' as Sorts,
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    return () => {
      me.getSessions.cancel();
      sessions.delete.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (!loadingDelete) {
      request(
        me.getSessions.action({
          ...filters,
          status: filters.status && filterStatusOption[filters.status],
          type: 'planned',
          start_date__date__gte: filters.start_date__date__gte ? toISODate(filters.start_date__date__gte, true) : '',
          end_date__date__lte: filters.end_date__date__lte ? toISODate(filters.end_date__date__lte, true) : '',
        }),
      );
    }
  }, [filters, loadingDelete, update]);

  const onChangePeriod = (newValue: any): void => {
    setFilters({
      filtered: true,
      page: 1,
      ...(newValue.start_date__gte ? { start_date__date__gte: newValue.start_date__gte } : {}),
      ...(newValue.end_date__lte ? { end_date__date__lte: newValue.end_date__lte } : {}),
    });
  };

  const handleFilterByStatus = (value: string): void => {
    setFilters({ status: value });
  };

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onDeleteSession = React.useCallback((id) => requestDelete(sessions.delete.action({ id })), []);
  const onManagementSession = (id: number): void =>
    push('AddSessionPage', { action: id, query: { page: filters.page } });

  const handleDownload = async (): Promise<void> => {
    const token = load('jwt-token');
    const headers: Record<string, string> = {
      Authorization: `Bearer ${token}`,
    };

    const request = new Request(
      `${env('API_URL')}me/sessions/export?${new URLSearchParams({
        status: filters?.status || '',
        type: 'planned',
        start_date__date__gte: filters?.start_date__date__gte ? toISODate(filters.start_date__date__gte, true) : '',
        end_date__date__lte: filters?.end_date__date__lte ? toISODate(filters.end_date__date__lte, true) : '',
      }).toString()}`,
      {
        headers,
      },
    );
    const response = await window.fetch(request);
    const data = await response.blob();
    try {
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;

      // The filename
      a.download = `sessions_${today.toString()}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error('Error on downloading', e);
    }
  };

  const expandable = ({
    professor,
    coordinator,
    start_date: startDate,
    end_date: endDate,
    country,
    city,
    course,
    institution,
    language,
    faculty,
  }: Session): Expande => [
    [
      { label: t('course'), value: (course && course.name) || '---', icon: <Icon type="requests" /> },
      {
        label: t('startAt'),
        value: startDate ? format(startDate, dateFormat) : '---',
        icon: <Icon type="calendar" />,
      },
      {
        label: t('finishAt'),
        value: startDate ? format(endDate, dateFormat) : '---',
        icon: <Icon type="calendar" />,
      },
    ],
    [
      {
        label: t('teacher'),
        value: professor ? [professor.first_name, professor.last_name].filter((i) => i).join(' ') : '---',
      },
      {
        label: t('coordinator'),
        value: coordinator ? [coordinator.first_name, coordinator.last_name].filter((i) => i).join(' ') : '---',
      },
      {
        label: t('place'),
        value:
          country && country.title ? [country && country.title, city && city.title].filter((i) => i).join(', ') : '---',
        icon: <Icon type="pin" />,
      },
    ],
    [
      { label: t('institute'), value: (institution && institution.name) || '---', icon: <Icon type="location" /> },
      { label: t('faculty'), value: faculty ? faculty.name : '---' },
      { label: t('language'), value: (language && language.name) || '---' },
    ],
  ];

  return (
    <Card className="mb-30">
      <CardHeader
        title={`${t('plannedSessions')}`}
        count={data.count}
        leftSide={<InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />}
        rightSide={
          <>
            <div className="status-filter">
              <Select
                cancelable
                cancelValue={null}
                value={filters.status}
                onChange={handleFilterByStatus}
                options={statusOption.map((item) => ({ value: item, title: t(item) }))}
                placeholder={t('filterByStatus')}
              />
            </div>
            <div className="picker-wrapper">
              <div className="hideTablet">{t('period')}</div>
              <DateRangePicker
                from={filters.start_date__date__gte}
                to={filters.end_date__date__lte}
                type="period"
                onChange={onChangePeriod}
              />
            </div>
            <SortBy
              options={[
                {
                  title: t('name'),
                  value: 'name',
                },
                {
                  title: t('country'),
                  value: 'country',
                },
                {
                  title: t('city'),
                  value: 'city',
                },
                {
                  title: t('startAt'),
                  value: 'start_date',
                },
                {
                  title: t('finishAt'),
                  value: 'end_date',
                },
                {
                  title: t('created_at'),
                  value: 'created_at',
                },
              ]}
              value={filters.ordering}
              onChange={(newValue) => onChangeFilters('ordering', newValue)}
            />
          </>
        }
      />
      {userData.role === 'admin' && (
        <Button type="primary" onClick={handleDownload} className="download-report-mobile">
          {t('download')}
        </Button>
      )}

      <Loader loading={loading}>
        <PlannedSessionsTable
          page={filters.page}
          data={data.results}
          setData={setData}
          onManagementSession={onManagementSession}
          onDeleteSession={onDeleteSession}
          expandable={expandable}
          onRefetch={onRefetch}
          handleDownload={handleDownload}
        />

        <CardFooter
          onRefresh={onRefetch}
          onRefreshDisabled={!filters.filtered}
          page={filters.page}
          pages={data.total_pages}
          perPage={filters.per_page}
          onChangePerPage={onChangePerPage}
          rightSide={
            <>
              <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                {t('previous')}
              </Button>

              <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                {t('next')}
              </Button>
            </>
          }
        />
        <CardAlert label={t('didYouFindProblem')} t="reportPlannedSessions" />
      </Loader>
    </Card>
  );
};
