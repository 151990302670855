import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useRequest } from 'estafette';
import { UserContext } from 'contexts';
import { sessions } from 'libs/http/api';
import { Form, FormItems, FormItem } from 'ui/organisms';
import { Modal, ModalFooterButton, Button, Icon, Input } from 'ui/atoms';
import { grade } from 'libs/mask';
import { Student } from 'libs/http/api/sessions/sessions.types';
import { useStateHandlers } from 'hooks';
import { $Object } from 'libs/object';

interface ModalEditPracticeGradeProps {
  sessionId: number;
  studentId: number;
  data: $Object;
  setData: (row: number, practise_grades: { [key: string]: string | number | null | undefined }) => void;
  onClose: () => void;
}

export const ModalEditPracticeGrade: React.FC<ModalEditPracticeGradeProps> = ({
  sessionId,
  studentId,
  data,
  setData,
  onClose,
}) => {
  const { t } = useIntl();
  const { request: requestSave } = useRequest<Student>();
  const { userData } = React.useContext(UserContext);
  const [stateGrade, setEditGrade] = useStateHandlers<$Object>(data);
  const isProfessor = React.useMemo(() => userData.role === 'professor', [userData.role]);

  const checkGrade = React.useCallback(
    (value: number) =>
      !isProfessor || stateGrade[`practise_sub_grade_${value}`] !== data[`practise_sub_grade_${value}`],
    [isProfessor, stateGrade, data],
  );

  const onSaveGrade = React.useCallback((): void => {
    requestSave(
      sessions.saveStudent.action({
        id: sessionId,
        student: studentId,
        ...(checkGrade(1) && {
          practise_sub_grade_1: stateGrade.practise_sub_grade_1,
        }),
        ...(checkGrade(2) && {
          practise_sub_grade_2: stateGrade.practise_sub_grade_2,
        }),
        ...(checkGrade(3) && {
          practise_sub_grade_3: stateGrade.practise_sub_grade_3,
        }),
        ...(checkGrade(4) && {
          practise_sub_grade_4: stateGrade.practise_sub_grade_4,
        }),
        ...(checkGrade(5) && {
          practise_sub_grade_5: stateGrade.practise_sub_grade_5,
        }),
      }) as any,
    ).then((response) => {
      setData(data.id as number, {
        practise_grade: response.practise_grade,
        practise_sub_grade_1: response.practise_sub_grade_1,
        practise_sub_grade_2: response.practise_sub_grade_2,
        practise_sub_grade_3: response.practise_sub_grade_3,
        practise_sub_grade_4: response.practise_sub_grade_4,
        practise_sub_grade_5: response.practise_sub_grade_5,
      });
    });
    onClose();
  }, [sessionId, checkGrade, stateGrade, onClose]);

  return (
    <Modal
      onClose={onClose}
      footer={
        <ModalFooterButton>
          <Button onClick={onClose}>{t('cancel')}</Button>

          <Button submit form="practise_sub_grade" type="primary" prefix={<Icon type="check" />}>
            {t('send')}
          </Button>
        </ModalFooterButton>
      }
    >
      <Form onSubmit={onSaveGrade} id="practise_sub_grade">
        <FormItems>
          <div className="mr-10">
            <FormItem>
              <Input
                type="number"
                value={stateGrade.practise_sub_grade_1}
                onChange={(value) => setEditGrade({ practise_sub_grade_1: grade(value) })}
                disabled={!!data.practise_sub_grade_1 && isProfessor}
              />
            </FormItem>
          </div>

          <div className="mr-10">
            <FormItem>
              <Input
                type="number"
                value={stateGrade.practise_sub_grade_2}
                onChange={(value) => setEditGrade({ practise_sub_grade_2: grade(value) })}
                disabled={!!data.practise_sub_grade_2 && isProfessor}
              />
            </FormItem>
          </div>

          <div className="mr-10">
            <FormItem>
              <Input
                type="number"
                value={stateGrade.practise_sub_grade_3}
                onChange={(value) => setEditGrade({ practise_sub_grade_3: grade(value) })}
                disabled={!!data.practise_sub_grade_3 && isProfessor}
              />
            </FormItem>
          </div>

          <div className="mr-10">
            <FormItem>
              <Input
                type="number"
                value={stateGrade.practise_sub_grade_4}
                onChange={(value) => setEditGrade({ practise_sub_grade_4: grade(value) })}
                disabled={!!data.practise_sub_grade_4 && isProfessor}
              />
            </FormItem>
          </div>

          <div className="mr-10">
            <FormItem>
              <Input
                type="number"
                value={stateGrade.practise_sub_grade_5}
                onChange={(value) => setEditGrade((s) => ({ ...s, practise_sub_grade_5: grade(value) }))}
                disabled={!!data.practise_sub_grade_5 && isProfessor}
              />
            </FormItem>
          </div>
        </FormItems>
      </Form>
    </Modal>
  );
};
