import { PhoneNumberUtil, PhoneNumberFormat as PNF } from 'google-libphonenumber';

export const phoneUtil = PhoneNumberUtil.getInstance();

export const renderPhone = (value: string, code?: string): string => {
  try {
    const parse = phoneUtil.parseAndKeepRawInput(`+${code ? code : ''}${value}`) as any;

    return phoneUtil.format(parse, PNF.INTERNATIONAL);
  } catch {
    return value;
  }
};

export const phone = (value: string): string => value.replace(/\D/g, '').substring(0, 13);

export const grade = (value: string): number => {
  value = value.substring(0, 3).replace(/\D/g, '');
  const val = value.length ? parseFloat(value) : 0;

  return val >= 0 && val <= 100 ? val : 100;
};

interface Birthday {
  type: 'day' | 'month' | 'year';
  value?: string;
  full: string | null | undefined;
}

export const birth = ({ type, value, full }: Birthday): string => {
  const fullBirth = full?.split('/');

  const data = {
    day: '',
    month: '',
    year: '',
  };

  if (fullBirth) {
    data.day = fullBirth[0] ? fullBirth[0] : '';
    data.month = fullBirth[1] ? fullBirth[1] : '';
    data.year = fullBirth[2] ? fullBirth[2] : '';
  }

  if (value) {
    value = value.substring(0, type !== 'year' ? 2 : 4).replace(/\D/g, '');

    switch (type) {
      case 'day':
        data.day = value;
        break;
      case 'month':
        data.month = value;
        break;
      case 'year':
        data.year = value;
        break;
    }
  }

  return value ? `${data.day}/${data.month}/${data.year}` : data[type];
};
