import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Link } from 'estafette-router';
import { Announcement } from 'libs/http/api/announces/announces.types';
import { GroupBy } from 'libs/array/array.types';
import { Roadmap } from 'ui/organisms';
import { AvatarCard, Calendar, EmptyList } from 'ui/atoms';

import './Announcements.scss';

interface Props {
  viewEmpty?: boolean;
  data: GroupBy<Announcement>[];
}

export const Announcements: React.FC<Props> = ({ viewEmpty, data: $data }) => {
  const { t } = useIntl();

  const data = React.useMemo(
    () =>
      $data.map(({ target, children }) => ({
        key: target,
        target: target,
        children: children.map(({ id, created_at: createdAt, subject, description, users }) => [
          <Link key={id} route="AnnouncementsDetailsPage" params={{ id }} className="zh-announcement-items pointer">
            <div className="zh-announcement-calendar">
              <Calendar date={createdAt} type="date-time" />
            </div>

            <div className="zh-announcement-right">
              {subject ? <div className="zh-announcement-item-title">{subject}</div> : null}

              <div
                className="zh-announcement-item-content"
                dangerouslySetInnerHTML={{ __html: description || '---' }}
              />

              {users && users.length > 0 && (
                <div className="zh-announcement-item-addressed">
                  <div className="zh-announcement-item-addressed-title">{t('addressedFor')}</div>

                  <div className="zh-announcement-item-addressed-items">
                    {users.map(
                      ({ id, profile_picture: profilePicture, first_name: firstName, last_name: lastName }) => (
                        <AvatarCard
                          key={id}
                          img={profilePicture}
                          alt={[firstName, lastName].filter((i) => i).join(' ')}
                        />
                      ),
                    )}
                  </div>
                </div>
              )}
            </div>
          </Link>,
        ]),
      })),
    [$data],
  );

  if (viewEmpty && data.length === 0) {
    return (
      <div className="flex-data">
        <EmptyList />
      </div>
    );
  }

  return <Roadmap className="zh-announcement" data={data} />;
};
