import * as React from 'react';
import { useHistory } from 'estafette-router';
import { UserContext } from 'contexts';
import { useModules } from 'hooks';
import { parseQuery } from 'libs/object';
import { Loader } from 'ui/atoms';

interface Query {
  user_not_found?: boolean;
  permissions_guard?: boolean;
}

export const IndexEntry: React.FC = () => {
  const { push } = useHistory();
  const { commonModule, indexModule } = useModules();
  const { finished, logged, userData } = React.useContext(UserContext);

  React.useEffect(() => {
    const onRedirect = (): void => {
      if (finished) {
        const query = parseQuery<Query>(window.location.search);

        const newQuery: { [key: string]: boolean } = {};

        if (logged) {
          if (query.permissions_guard) {
            newQuery.permissions = true;
          }

          if (indexModule) {
            return push(indexModule.route, { ...indexModule.params, query: newQuery });
          }

          if (!indexModule && commonModule) {
            return push(commonModule.route, { query: newQuery });
          }
        }

        return push(query.user_not_found ? 'SignInPage' : 'SessionsPage', { query: newQuery });
      }
    };

    onRedirect();
  }, [finished, logged, userData]);

  return <Loader loading height="100vh" fixed />;
};
