import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useFilters } from 'hooks';
import { me } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { EurasiaEvent } from 'libs/http/api/events/events.types';

import { Button, Info, Card, CardHeader, CardFooter, Loader, Calendar, EmptyList } from 'ui/atoms';

import { SortBy, InputSearch } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';

const initialFilters = {
  filtered: false,
  page: 1,
  per_page: 3,
  search: '',
  ordering: '' as Sorts,
};

interface EventsProps {
  update: boolean;
}

export const Events: React.FC<EventsProps> = ({ update }) => {
  const { t } = useIntl();
  const { request, data, loading } = useRequest<Results<EurasiaEvent>>({
    loading: true,
    data: { results: [] },
  });
  const [filters, setFilters] = useFilters({ ...initialFilters });

  React.useEffect(() => {
    request(
      me.getEvents.action({
        ...filters,
      }),
    );
  }, [filters, update]);

  React.useEffect(() => {
    return () => {
      me.getEvents.cancel();
    };
  }, []);

  const onRefetch = (): void => setFilters({ ...initialFilters });

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  return (
    <Card className="dateCard">
      <CardHeader
        title={t('events')}
        count={data.count}
        leftSide={<InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />}
        rightSide={
          <SortBy
            options={[
              {
                title: t('subject'),
                value: 'subject',
              },
            ]}
            value={filters.ordering}
            onChange={(newValue) => onChangeFilters('ordering', newValue)}
          />
        }
      />

      <Loader loading={loading}>
        {data.results.length > 0 ? (
          <div className="flex-data">
            {data.results.map((item) => (
              <div key={item.id} className="flex-item">
                <div className="flex-item-row">
                  <div className="flex-item-row-calendar">
                    <Calendar date={item.start_date} type="date" />
                  </div>
                  <div className="flex-item-row-info">
                    <label>{t('events')}</label>
                    <Info primary label={item.type === 'session' ? item.course?.name : item.name} />
                    <div id="innerHTML" dangerouslySetInnerHTML={{ __html: item.description || '---' }} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex-data">
            <EmptyList />
          </div>
        )}

        <CardFooter
          onRefresh={onRefetch}
          onRefreshDisabled={!filters.filtered}
          page={filters.page}
          pages={data.total_pages}
          rightSide={
            <>
              <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                {t('previous')}
              </Button>

              <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                {t('next')}
              </Button>
            </>
          }
        />
      </Loader>
    </Card>
  );
};
