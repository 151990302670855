import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Link } from 'estafette-router';
import { UserContext } from 'contexts';
import { Button, Select } from 'ui/atoms';

import './Navbar.scss';

export const Navbar: React.FC = () => {
  const { t } = useIntl();
  const { language, onChangeLang } = React.useContext(UserContext);

  return (
    <div className="nav">
      <Link route="SessionsPage">
        <div className="logo" />
      </Link>

      <div className="switchLang">
        <Select
          searchDisabled
          options={[
            { value: 'en', title: 'Eng' },
            { value: 'ro', title: 'Rom' },
            { value: 'ru', title: 'Rus' },
            { value: 'es', title: 'Esp' },
          ]}
          value={language}
          onChange={(value) => onChangeLang(value, false)}
          dropdownClassName="hideChecked"
        />
      </div>

      <div className="group">
        <Link route="SignInPage">
          <Button>{t('signin')}</Button>
        </Link>

        <Link route="SignUpPage">
          <Button type="primary">{t('signup')}</Button>
        </Link>
      </div>
    </div>
  );
};
