import * as React from 'react';

import './Scroll.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const Scroll: React.FC<Props> = ({ className = '', children }) => (
  <div className={`zh-scroll ${className}`}>{children}</div>
);
