import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { List, ListUser, Results } from '../index.types';
import { AllowedRoles, InviteStudentsOptions, User, UserState } from './me.types';
import { Announcement, AnnouncementFilters } from 'libs/http/api/announces/announces.types';
import { EurasiaEvent } from 'libs/http/api/events/events.types';
import { Session, SessionListItem, SessionRegister, StudentRegister } from 'libs/http/api/sessions/sessions.types';
import { Users } from 'libs/http/api/users/users.types';

export const me = {
  get: {
    action: (params = {}) =>
      axios.get<User>(`me`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.get.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getAllowedRoles: {
    action: (params = {}): Promise<{ data: AllowedRoles }> =>
      axios.get(`me/allowed_roles`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getAllowedRoles.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getAnnounces: {
    action: (params: AnnouncementFilters): Promise<{ data: Results<Announcement> }> =>
      axios.get(`me/announces`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getAnnounces.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getContacts: {
    action: (params = {}) =>
      axios.get(`me/contacts`, { params, cancelToken: new cancelToken((c: Canceler) => (me.getContacts.cancel = c)) }),
    cancel: (() => null) as Canceler,
  },

  getEvents: {
    action: (params = {}): Promise<{ data: Results<EurasiaEvent> }> =>
      axios.get(`me/events`, { params, cancelToken: new cancelToken((c: Canceler) => (me.getEvents.cancel = c)) }),
    cancel: (() => null) as Canceler,
  },

  getInstitutions: {
    action: (params = {}) =>
      axios.get(`me/institutions/list`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getInstitutions.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getFaculties: {
    action: (params = {}) =>
      axios.get(`me/faculties`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getFaculties.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getFacultiesList: {
    action: (params = {}) =>
      axios.get(`me/faculties/list`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getFaculties.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getRecoveryGradeSessionList: {
    action: (params: {}): Promise<{ data: SessionListItem[] }> =>
      axios.get(`me/student-session/list`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getAnnounces.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getUnregisteredSessionList: {
    action: (params: {}): Promise<{ data: SessionListItem[] }> =>
      axios.get(`me/student-unregistered-session/list`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getAnnounces.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getOutstandings: {
    action: (params = {}) =>
      axios.get(`me/outstandings`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getOutstandings.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getProfessors: {
    action: (params = {}) =>
      axios.get<Users>(`me/professors`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getProfessors.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getResources: {
    action: (params = {}) =>
      axios.get(`me/resources`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getResources.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getSessions: {
    action: (params = {}): Promise<{ data: Results<Session> }> =>
      axios.get(`me/sessions`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getSessions.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getSession: {
    action: ({ id, ...params }: { id: number }): Promise<{ data: SessionRegister }> =>
      axios.get(`me/sessions/${id}`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getSession.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getStudentSessions: {
    action: (params: {}): Promise<{ data: Results<StudentRegister> }> =>
      axios.get(`me/student-session`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getSession.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getStudents: {
    action: ({ ...params }: { institutions: string; faculties: string }): Promise<{ data: Results<User> }> =>
      axios.get(`me/students`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getStudents.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getInviteStudentsList: {
    action: (params: InviteStudentsOptions): Promise<{ data: ListUser[] }> =>
      axios.get(`me/invite-students/list`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getInviteStudentsList.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getStudentsList: {
    action: (params = {}) =>
      axios.get<List[]>(`me/students/list`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (me.getStudentsList.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  changeMyAccount: {
    action: (option: UserState) =>
      axios.patch(`/me`, option, {
        cancelToken: new cancelToken((c: Canceler) => (me.changeMyAccount.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  changeMyLanguage: {
    action: (language: string) =>
      axios.patch(
        `/me`,
        { language },
        {
          cancelToken: new cancelToken((c: Canceler) => (me.changeMyLanguage.cancel = c)),
        },
      ),
    cancel: (() => null) as Canceler,
  },

  changeAccount: {
    action: ({ id, ...option }: UserState) =>
      axios.patch(`/me/${id}`, option, {
        cancelToken: new cancelToken((c: Canceler) => (me.changeAccount.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  switchRole: {
    action: ({ role = '' }) =>
      axios.patch(
        `me/switch_role`,
        { role },
        {
          cancelToken: new cancelToken((c: Canceler) => (me.switchRole.cancel = c)),
        },
      ),
    cancel: (() => null) as Canceler,
  },
};
