import * as React from 'react';
import { notify } from 'react-notify-toast';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { Link, useHistory } from 'estafette-router';
import { UserContext } from 'contexts';
import { useModules } from 'hooks';
import { Roles } from 'hooks/useModules';
import { me } from 'libs/http/api';
import { AllowedRoles } from 'libs/http/api/me/me.types';
import { Confirmation } from 'ui/organisms';
import { Icon, LoaderInline, Tooltip } from 'ui/atoms';

import './Profile.scss';

interface ProfileItemProps {
  onClick?: () => void;
  route?: string;
  params?: { [key: string]: any };
  active?: boolean;
  iconType?: string;
  icon?: string;
  label: string;
}

const ProfileItem: React.FC<ProfileItemProps> = ({ onClick, route, params, active, iconType, icon, label }) => {
  // types are not necessary
  const Component: React.FC<any> = (props) =>
    onClick !== undefined && route === undefined ? <div {...props} /> : <Link {...props} />;

  return (
    <Component className={`zh-profile-item ${active ? 'active' : ''}`} onClick={onClick} route={route} params={params}>
      {iconType === 'circle' ? (
        <div className="zh-profile-item-circle">{<Icon type={icon} />}</div>
      ) : icon ? (
        <Icon type={icon} />
      ) : null}

      <div className="zh-profile-item-label">{label}</div>
    </Component>
  );
};

interface Props {
  placement?: 'bottomRight' | 'rightBottom';
  children: React.ReactElement;
}

const icons: { [key: string]: string } = { professor: 'user-strict', coordinator: 'globe', admin: 'administrator' };

export const Profile: React.FC<Props> = ({ placement = 'bottomRight', children }) => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { allModules, commonModule } = useModules();
  const { request, data, loading } = useRequest<AllowedRoles>({ data: { allowed_roles: [] } });
  const { request: requestChange } = useRequest();
  const { onLogout, userData, setUserData, language, onChangeLang } = React.useContext(UserContext);

  const [languages, setLanguages] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    return () => {
      me.getAllowedRoles.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (visible && data.allowed_roles && data.allowed_roles.length === 0) {
      request(me.getAllowedRoles.action());
    }
  }, [visible]);

  const onChangeRole = async (newRole: Roles): Promise<void> => {
    const oldRole = `${userData.role}`;

    setUserData({ ...userData, role: newRole });

    try {
      await requestChange(me.switchRole.action({ role: newRole }));

      setVisible(false);

      const indexModule = allModules[newRole] && allModules[newRole].find(({ index }) => index);

      if (indexModule) {
        push(indexModule.route, indexModule.params);
      } else {
        push(commonModule.route);
      }

      notify.show(`${t('roleSwitchedTo')} ${t(newRole)}`, 'warning');
    } catch (err) {
      console.error(err);

      // set old role in case when the changing didn't happen
      setUserData({ ...userData, role: oldRole });
    }
  };

  const onToggleLanguage = (): void => setLanguages((s) => !s);

  return (
    <Tooltip
      fixed
      className="zh-profile"
      placement={placement}
      onVisibleChange={setVisible}
      content={
        <div id="js-profile">
          {loading || data.allowed_roles.length ? <div className="zh-profile-title">{t('switchRole')}</div> : null}

          <div className="zh-profile-items zh-profile-items-roles">
            {loading && <LoaderInline />}

            {!loading &&
              data.allowed_roles
                .sort((a, b) => a.localeCompare(b))
                .map((role) => (
                  <ProfileItem
                    key={role}
                    active={userData.role === role}
                    iconType="circle"
                    icon={icons[role] || role}
                    onClick={() => onChangeRole(role)}
                    label={t(role)}
                  />
                ))}
          </div>

          <div className="zh-profile-title">{t('accountManagement')}</div>
          <div className="zh-profile-items">
            <div className="zh-profile-item" onClick={onToggleLanguage}>
              <Icon type="language" />
              {t('selectLanguage2')}
            </div>

            {languages && (
              <div id="js-languages">
                <ProfileItem active={language === 'en'} onClick={() => onChangeLang('en')} label={t('en')} />
                <ProfileItem active={language === 'ro'} onClick={() => onChangeLang('ro')} label={t('ro')} />
                <ProfileItem active={language === 'ru'} onClick={() => onChangeLang('ru')} label={t('ru')} />
                <ProfileItem active={language === 'es'} onClick={() => onChangeLang('es')} label={t('es')} />
              </div>
            )}

            <ProfileItem route="PersonalAccountPage" icon="user-filled" label={t('personalAccount')} />

            <ProfileItem route="EditPersonalAccountPage" icon="gear" label={t('settings')} />

            <Confirmation onConfirm={onLogout}>
              <ProfileItem icon="logout" label={t('exit')} />
            </Confirmation>
          </div>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};
