import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useStateHandlers } from 'hooks';
import { grade } from 'libs/mask';
import { users as usersApi, sessions, issues } from 'libs/http/api';
import { User } from 'libs/http/api/me/me.types';
import { SessionStudent } from 'libs/http/api/sessions/sessions.types';
import { Grades } from 'libs/http/api/courses/courses.types';
import { Save } from 'libs/http/api/index.types';
import { Form, FormItem, FormItems } from 'ui/organisms';
import {
  Icon,
  Input,
  RichText,
  Button,
  AvatarInline,
  Animated,
  Loader,
  Alert,
  Modal,
  ModalFooterButton,
} from 'ui/atoms';

import './Grade.scss';

interface Props {
  onSuccess?: () => void;
  user: string | number | null;
  session: string | number | null;
}

export const Grade: React.FC<Props> = ({ onSuccess, user, session }) => {
  const { t } = useIntl();
  const { request: requestUser, data: dataUser, loading: loadingUser } = useRequest<User>({
    data: {},
  });
  const { request: requestSession, data: dataSession, loading: loadingSession } = useRequest<SessionStudent>({
    data: {},
  });
  const { request: requestGradeFields, data: dataGradeFields, loading: loadingGradeFields } = useRequest<Grades[]>();
  const { request, data: dataSave, loading, errors } = useRequest<Save>();

  const [open, setOpen] = React.useState(false);
  const [state, setState] = useStateHandlers<SessionStudent>({
    course_grade: undefined,
    individual_work_grade: undefined,
    practise_grade: undefined,
    description: '',
  });

  React.useEffect(() => {
    return () => {
      usersApi.get.cancel();
      sessions.getStudent.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (open) {
      if (user) {
        requestUser(usersApi.load.action({ id: user }));
      }

      if (session && user) {
        requestSession(sessions.getStudent.action({ id: session, student: user }));
        requestGradeFields(sessions.getGradeFields.action({ id: Number(session) }));
      }
    }
  }, [open, user, session]);

  React.useEffect(() => {
    const average =
      ((state.practise_sub_grade_1 || dataSession.practise_sub_grade_1 || 0) +
        (state.practise_sub_grade_2 || dataSession.practise_sub_grade_2 || 0) +
        (state.practise_sub_grade_3 || dataSession.practise_sub_grade_3 || 0) +
        (state.practise_sub_grade_4 || dataSession.practise_sub_grade_4 || 0) +
        (state.practise_sub_grade_5 || dataSession.practise_sub_grade_5 || 0)) /
      5;
    setState({ practise_grade: average });
  }, [
    state.practise_sub_grade_1,
    state.practise_sub_grade_2,
    state.practise_sub_grade_3,
    state.practise_sub_grade_4,
    state.practise_sub_grade_5,
  ]);

  const onSubmit = async (): Promise<void> => {
    if (!dataSession.course_grade && !dataSession.individual_work_grade && !dataSession.practise_grade) {
      await request(sessions.saveStudent.action({ ...state, id: session, student: user }));
    } else {
      const { description, ...details } = state;
      await request(
        issues.add.action({
          title: t('requestToChangeGrade'),
          description: description,
          details: {
            course_grade: details.course_grade ? details.course_grade : undefined,
            individual_work_grade: details.individual_work_grade ? details.individual_work_grade : undefined,
            practise_sub_grade_1: details.practise_sub_grade_1 ? details.practise_sub_grade_1 : undefined,
            practise_sub_grade_2: details.practise_sub_grade_2 ? details.practise_sub_grade_2 : undefined,
            practise_sub_grade_3: details.practise_sub_grade_3 ? details.practise_sub_grade_3 : undefined,
            practise_sub_grade_4: details.practise_sub_grade_4 ? details.practise_sub_grade_4 : undefined,
            practise_sub_grade_5: details.practise_sub_grade_5 ? details.practise_sub_grade_5 : undefined,
            old: {
              course_grade: dataSession.course_grade ? dataSession.course_grade : undefined,
              individual_work_grade: dataSession.individual_work_grade ? dataSession.individual_work_grade : undefined,
              practise_sub_grade_1: dataSession.practise_sub_grade_1 ? dataSession.practise_sub_grade_1 : undefined,
              practise_sub_grade_2: dataSession.practise_sub_grade_2 ? dataSession.practise_sub_grade_2 : undefined,
              practise_sub_grade_3: dataSession.practise_sub_grade_3 ? dataSession.practise_sub_grade_3 : undefined,
              practise_sub_grade_4: dataSession.practise_sub_grade_4 ? dataSession.practise_sub_grade_4 : undefined,
              practise_sub_grade_5: dataSession.practise_sub_grade_5 ? dataSession.practise_sub_grade_5 : undefined,
            },
          },
          status: 'pending',
          type: 'change_grade',
          session,
          student: user,
        }),
      );
    }

    onToggleOpen();

    if (onSuccess !== undefined) {
      onSuccess();
    }
  };

  const onChange = React.useCallback((target: string, value: any): void => setState({ [target]: value }), []);

  const onToggleOpen = (): void => setOpen((s) => !s);

  const allowedGradeFields = React.useMemo(() => dataGradeFields.filter((i) => i !== 'average_grade'), [
    dataGradeFields,
  ]);

  const averageGrade = React.useMemo(
    () =>
      (
        ((state.practise_grade || dataSession.practise_grade || 0) +
          (state.individual_work_grade || dataSession.individual_work_grade || 0) +
          (state.course_grade || dataSession.course_grade || 0)) /
        allowedGradeFields.length
      ).toFixed(2),
    [state.practise_grade, state.individual_work_grade, state.course_grade, allowedGradeFields],
  );

  const averageGradeOld = React.useMemo(
    () =>
      (
        ((dataSession.practise_grade && Number.isInteger(dataSession.practise_grade)
          ? (dataSession.practise_grade as number)
          : 0) +
          (dataSession.individual_work_grade && Number.isInteger(dataSession.individual_work_grade)
            ? (dataSession.individual_work_grade as number)
            : 0) +
          (dataSession.course_grade && Number.isInteger(dataSession.course_grade)
            ? (dataSession.course_grade as number)
            : 0)) /
        allowedGradeFields.length
      ).toFixed(2),
    [dataSession.practise_grade, dataSession.individual_work_grade, dataSession.course_grade, allowedGradeFields],
  );

  return (
    <>
      {open && (
        <Modal
          mask={false}
          contentClass="zh-send-message-content"
          onClose={onToggleOpen}
          title={t('setGrade')}
          footer={
            <ModalFooterButton>
              <Button onClick={onToggleOpen} disabled={loading}>
                {t('refuse')}
              </Button>

              <Button submit form="set-grade" type="primary" prefix={<Icon type="check" />} loading={loading}>
                {t('confirm')}
              </Button>
            </ModalFooterButton>
          }
        >
          <Alert message={dataSave.message || errors.non_field_errors || errors.detail} form type="error" />

          <Form id="set-grade" onSubmit={onSubmit}>
            <div className="zh-send-message-content-item">
              <Animated loading={loadingUser} debounce={250}>
                <FormItem label={<div className="text-transform-uppercase">{t('student')}</div>}>
                  {dataUser && (
                    <AvatarInline
                      key={dataUser.id}
                      img={dataUser.profile_picture}
                      alt={[dataUser.first_name, dataUser.last_name].filter((i) => i).join(' ')}
                    />
                  )}
                </FormItem>
              </Animated>
            </div>

            <Loader loading={loadingSession || loadingGradeFields} height={100}>
              <div className="zh-send-message-content-item">
                <FormItems>
                  {dataGradeFields.includes('course_grade') ? (
                    <FormItem className="grade-item" label={t('noteForCourse')} extra={errors.course_grade}>
                      {dataSession.course_grade ? (
                        <FormItem label={t('oldNote')}>
                          <Input className="grade-item-old" value={dataSession.course_grade} />
                        </FormItem>
                      ) : null}

                      <FormItem label={t('newNote')} className="ma-0">
                        <Input
                          value={state.course_grade}
                          onChange={(value) => onChange('course_grade', grade(value))}
                        />
                      </FormItem>
                    </FormItem>
                  ) : null}

                  {dataGradeFields.includes('individual_work_grade') ? (
                    <FormItem
                      className="grade-item"
                      label={t('noteForIndividualWork')}
                      extra={errors.individual_work_grade}
                    >
                      {dataSession.individual_work_grade ? (
                        <FormItem label={t('oldNote')}>
                          <Input className="grade-item-old" value={dataSession.individual_work_grade} />
                        </FormItem>
                      ) : null}

                      <FormItem label={t('newNote')} className="ma-0">
                        <Input
                          value={state.individual_work_grade}
                          onChange={(value) => onChange('individual_work_grade', grade(value))}
                        />
                      </FormItem>
                    </FormItem>
                  ) : null}

                  <FormItem className="grade-item" label={t('averageGrade')}>
                    {dataSession.practise_grade || dataSession.individual_work_grade || dataSession.course_grade ? (
                      <FormItem label={t('oldNote')}>
                        <Input className="grade-item-old" value={averageGradeOld} />
                      </FormItem>
                    ) : null}

                    <FormItem label={t('newNote')} className="ma-0">
                      <Input styleType="disabled" value={averageGrade} disabled />
                    </FormItem>
                  </FormItem>
                </FormItems>
              </div>
              {dataGradeFields.includes('practise_grade') && (
                <div className="zh-send-message-content-item">
                  <FormItems>
                    <FormItem
                      className="grade-item"
                      label={`${t('practiceGradeShort')} 1`}
                      extra={errors.practise_sub_grade_1}
                    >
                      {dataSession.practise_sub_grade_1 ? (
                        <FormItem label={t('oldNote')}>
                          <Input className="grade-item-old" value={dataSession.practise_sub_grade_1} />
                        </FormItem>
                      ) : null}

                      <FormItem label={t('newNote')} className="ma-0">
                        <Input
                          value={state.practise_sub_grade_1}
                          onChange={(value) => onChange('practise_sub_grade_1', grade(value))}
                        />
                      </FormItem>
                    </FormItem>
                    <FormItem
                      className="grade-item"
                      label={`${t('practiceGradeShort')} 2`}
                      extra={errors.practise_sub_grade_2}
                    >
                      {dataSession.practise_sub_grade_2 ? (
                        <FormItem label={t('oldNote')}>
                          <Input className="grade-item-old" value={dataSession.practise_sub_grade_2} />
                        </FormItem>
                      ) : null}

                      <FormItem label={t('newNote')} className="ma-0">
                        <Input
                          value={state.practise_sub_grade_2}
                          onChange={(value) => onChange('practise_sub_grade_2', grade(value))}
                        />
                      </FormItem>
                    </FormItem>
                    <FormItem
                      className="grade-item"
                      label={`${t('practiceGradeShort')} 3`}
                      extra={errors.practise_sub_grade_3}
                    >
                      {dataSession.practise_sub_grade_3 ? (
                        <FormItem label={t('oldNote')}>
                          <Input className="grade-item-old" value={dataSession.practise_sub_grade_3} />
                        </FormItem>
                      ) : null}

                      <FormItem label={t('newNote')} className="ma-0">
                        <Input
                          value={state.practise_sub_grade_3}
                          onChange={(value) => onChange('practise_sub_grade_3', grade(value))}
                        />
                      </FormItem>
                    </FormItem>
                    <FormItem
                      className="grade-item"
                      label={`${t('practiceGradeShort')} 4`}
                      extra={errors.practise_sub_grade_4}
                    >
                      {dataSession.practise_sub_grade_4 ? (
                        <FormItem label={t('oldNote')}>
                          <Input className="grade-item-old" value={dataSession.practise_sub_grade_4} />
                        </FormItem>
                      ) : null}

                      <FormItem label={t('newNote')} className="ma-0">
                        <Input
                          value={state.practise_sub_grade_4}
                          onChange={(value) => onChange('practise_sub_grade_4', grade(value))}
                        />
                      </FormItem>
                    </FormItem>
                    <FormItem
                      className="grade-item"
                      label={`${t('practiceGradeShort')} 5`}
                      extra={errors.practise_sub_grade_5}
                    >
                      {dataSession.practise_sub_grade_5 ? (
                        <FormItem label={t('oldNote')}>
                          <Input className="grade-item-old" value={dataSession.practise_sub_grade_5} />
                        </FormItem>
                      ) : null}

                      <FormItem label={t('newNote')} className="ma-0">
                        <Input
                          value={state.practise_sub_grade_5}
                          onChange={(value) => onChange('practise_sub_grade_5', grade(value))}
                        />
                      </FormItem>
                    </FormItem>
                  </FormItems>
                </div>
              )}
              <div className="zh-send-message-content-item">
                <FormItem label={t('message')} extra={errors.description}>
                  <RichText
                    initialValue={state.description}
                    onChange={(newValue) => onChange('description', newValue)}
                  />
                </FormItem>
              </div>
            </Loader>
          </Form>
        </Modal>
      )}

      <Button className="zh-send-message-button" prefix={<Icon type="edit" />} onClick={onToggleOpen}>
        {t('requestChangeGrade')}
      </Button>
    </>
  );
};
