import $axios from 'axios';
import env from '@beam-australia/react-env';
import { load } from 'react-cookies';
import { getRoute } from 'estafette-router';
import { routes } from 'routes';
import { history } from 'libs/history';

export const axios = $axios.create();

// eslint-disable-next-line import/no-named-as-default-member
export const cancelToken = $axios.CancelToken;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.baseURL = env('API_URL');

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if ($axios.isCancel(error)) {
      // eslint-disable-next-line
      return Promise.reject({ cancel: true, message: 'The endpoint was cancelled' });
    }

    if (error.response) {
      if (error.response.status === 401) {
        history.push(getRoute(routes, 'IndexEntry', { query: { token_not_valid: true } }));
      }

      if (
        error.response.status === 403 &&
        error.response.data.detail === 'You do not have permission to perform this action.'
      ) {
        history.push(getRoute(routes, 'IndexEntry', { query: { permissions_guard: true } }));
      }
    }

    return Promise.reject(error);
  },
);

export const axiosHeadersUpdater = (lang?: string): void => {
  const token = load('jwt-token');

  axios.defaults.headers['Accept-Language'] = lang || localStorage.getItem('lang') || 'en';

  if (token) {
    axios.defaults.headers.common.Authorization = `Token ${token}`;
  } else if (axios.defaults.headers.common.Authorization) {
    delete axios.defaults.headers.common.Authorization;
  }
};

axiosHeadersUpdater();
