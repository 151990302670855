import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { Results } from '../index.types';
import { Manual, ManualList } from './manuals.types';

export const manuals = {
  get: {
    action: (params = {}): Promise<{ data: Results<Manual> }> =>
      axios.get(`manual`, { params, cancelToken: new cancelToken((c: Canceler) => (manuals.get.cancel = c)) }),
    cancel: (() => null) as Canceler,
  },

  list: {
    action: (params?: { faculties?: string }): Promise<{ data: ManualList[] }> =>
      axios.get(`manual/list`, { params, cancelToken: new cancelToken((c: Canceler) => (manuals.list.cancel = c)) }),
    cancel: (() => null) as Canceler,
  },

  add: {
    action: (options = {}) =>
      axios.post(`manual`, options, {
        cancelToken: new cancelToken((c: Canceler) => (manuals.add.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  save: {
    action: ({ id, ...options }: { id: number }) =>
      axios.patch(`manual/${id}`, options, {
        cancelToken: new cancelToken((c: Canceler) => (manuals.save.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  load: {
    action: ({ id, ...params }: { id: number }): Promise<{ data: Manual }> =>
      axios.get(`manual/${id}`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (manuals.load.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  delete: {
    action: ({ id }: { id: number }) =>
      axios.delete(`manual/${id}`, {
        cancelToken: new cancelToken((c: Canceler) => (manuals.delete.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
