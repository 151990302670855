import * as React from 'react';
import { $Object, parseQuery } from 'libs/object';
import { useStateHandlers } from '.';

type Optional<T extends object> = {
  [K in keyof T]?: T[K];
};

export const useFilters = <T extends object>(
  initialFilters: T,
): [T, (newState: Optional<T> | ((prevState: T) => Optional<T>)) => void] => {
  const query = React.useMemo(() => parseQuery<$Object>(window.location.search), []);

  const [filters, setFilters] = useStateHandlers<T>({
    ...initialFilters,
    ...Object.keys(query).reduce((acc, current) => {
      const $initialFilters = initialFilters as $Object;

      if (typeof $initialFilters[current] === 'number') {
        return {
          ...acc,
          [current]: Number(query[current]) || $initialFilters[current],
        };
      }

      return {
        ...acc,
        [current]: query[current],
      };
    }, {} as $Object),
  });

  return [filters, setFilters];
};
