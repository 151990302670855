import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useHistory, useParams } from 'estafette-router';
import { parseQuery } from 'libs/object';
import { dateFormat, format } from 'libs/date';
import { events, sessions } from 'libs/http/api';
import { Invitation, Book } from 'libs/http/api/events/events.types';
import { renderPhone } from 'libs/mask';
import { Confirmation, Head } from 'ui/organisms';
import { Button, Card, Info, Icon, Loader, Time, MediaGroup, Media } from 'ui/atoms';

import { EventLayout } from '../organisms';

export const InvitePage: React.FC = () => {
  const { request, data, loading } = useRequest<Invitation>({ data: {} });
  const { request: requestRejectInvitation, loading: loadingRejectInvitation } = useRequest<void>();
  const { request: requestAcceptInvitation, loading: loadingAcceptInvitation } = useRequest<void>();

  const { t, locale } = useIntl();
  const { status, id } = useParams();
  const history = useHistory();
  const { push } = history;

  const { session } = parseQuery<{ session?: string }>(history.location.search);

  React.useEffect(() => {
    if (session === 'true') {
      request(sessions.load.action({ id }));
    } else {
      request(events.getById.action({ id }));
    }

    return () => {
      sessions.load.cancel();
      events.getById.cancel();
      events.respondToInvitations.cancel();
    };
  }, [session]);

  const onRejectInvitation = async (): Promise<void> => {
    if (session === 'true') {
      await requestRejectInvitation(sessions.respondToInvitations.action({ id, status: 'declined' }));
    } else {
      await requestRejectInvitation(events.respondToInvitations.action({ id, status: 'declined' }));
    }

    push('InvitePage', { id, status: 'declined' });
  };

  const onAcceptInvitation = async (): Promise<void> => {
    if (session === 'true') {
      await requestAcceptInvitation(sessions.respondToInvitations.action({ id, status: 'accepted' }));
    } else {
      await requestAcceptInvitation(events.respondToInvitations.action({ id, status: 'accepted' }));
    }
    push('InvitePage', { id, status: 'accepted' });
  };

  return (
    <>
      <Head title={data.type === 'session' ? data.course.name : data.name || 'N/A'} />

      <EventLayout>
        <Loader loading={loading}>
          <Card>
            <div className="flex-data">
              <div className="flex-item">
                {data.image && (
                  <div>
                    <img src={data.image} />
                  </div>
                )}

                <div className="flex-item-row">
                  {data.type !== 'session' && data.name && (
                    <div className="flex-item-row-col column">
                      <label>{t('nameOfEvent')}</label>
                      <Info primary label={data.name} />
                    </div>
                  )}

                  {data.type === 'session' && data.course?.name && (
                    <div className="flex-item-row-col column">
                      <label>{t('nameOfEvent')}</label>
                      <Info primary label={data.course.name} />
                    </div>
                  )}

                  <div className="flex-item-row-col">
                    {data.institution && (
                      <div>
                        <label>{t('institution')}</label>
                        <Info label={data.institution.name} />
                      </div>
                    )}

                    <div>
                      <label>{t('startDate')}</label>
                      <Time date={format(data.start_date, dateFormat)} noParse />
                    </div>

                    <div>
                      <label>{t('endDate')}</label>
                      <Time date={format(data.end_date, dateFormat)} noParse />
                    </div>

                    {data.type && (
                      <div>
                        <label>{t('typeOfEvent')}</label>
                        <Info label={t(data.type)} />
                      </div>
                    )}

                    {data.course && (
                      <div>
                        <label>{t('course')}</label>
                        <Info label={data.course.name} />
                      </div>
                    )}

                    {data.faculty && (
                      <div>
                        <label>{t('faculty')}</label>
                        <Info label={data.faculty.name} />
                      </div>
                    )}

                    <div>
                      <label>{t('location')}</label>
                      <Info
                        icon={<Icon type="pin" />}
                        label={[data.country && data.country.title, data.city && data.city.title, data.address]
                          .filter((i) => i)
                          .join(', ')}
                      />
                    </div>

                    <div>
                      <label>{t('email')}</label>
                      <Info label={data.email || '---'} />
                    </div>

                    <div>
                      <label>{t('phone')}</label>
                      <Info label={data.phone_number ? renderPhone(data.phone_number) : '---'} />
                    </div>

                    <div>
                      <label>{t('info')}</label>
                      <div dangerouslySetInnerHTML={{ __html: data.info || '---' }} />
                    </div>

                    <div>
                      <label>{t('language')}</label>
                      <Info
                        primary
                        label={(data.language instanceof Object && data.language.name) || data.language || '---'}
                      />
                    </div>

                    <div>
                      <label>{t('teacher')}</label>
                      <Info
                        primary
                        icon={<Icon type="user-strict" />}
                        label={
                          data.professor
                            ? [data.professor.first_name, data.professor.last_name].filter((i) => i).join(' ')
                            : '---'
                        }
                      />
                    </div>

                    <div>
                      <label>{t('organizer')}</label>
                      <Info
                        primary
                        icon={<Icon type="user-strict" />}
                        label={
                          data.organizer
                            ? [data.organizer.first_name, data.organizer.last_name].filter((i) => i).join(' ')
                            : data.coordinator_global
                            ? [data.coordinator_global.first_name, data.coordinator_global.last_name]
                                .filter((i) => i)
                                .join(' ')
                            : '---'
                        }
                      />
                    </div>
                    <div>
                      <label>{t('studiedManual')}</label>
                      <Info
                        label={
                          data.books && data.books.length
                            ? data.books.map(
                                (book: Book) =>
                                  book[`title_${locale as 'en' | 'ro'}` as 'title_en' | 'title_ro' | 'title_ru'],
                              )
                            : '---'
                        }
                      />
                    </div>

                    <div>
                      <label>{t('socialsNetworks')}</label>
                      <MediaGroup>
                        {data.facebook_url && <Media type="facebook" link={data.facebook_url} />}

                        {data.vkontakte_url && <Media type="vk" link={data.vkontakte_url} />}

                        {data.odnoklasniki_url && <Media type="odnoklasniki" link={data.odnoklasniki_url} />}

                        {data.linkedin_url && <Media type="linkedin" link={data.linkedin_url} />}

                        {!data.facebook_url &&
                          !data.vkontakte_url &&
                          !data.odnoklasniki_url &&
                          !data.linkedin_url &&
                          '---'}
                      </MediaGroup>
                    </div>

                    <div>
                      <label>{t('otherContacts')}</label>
                      <MediaGroup>
                        {data.telegram && <Media type="telegram" link={data.telegram} />}

                        {data.skype && <Media type="skype" link={data.skype} />}

                        {data.whatsup && <Media type="whatsapp" link={data.whatsup} />}

                        {data.viber && <Media type="viber" link={data.viber} />}

                        {!data.telegram && !data.skype && !data.whatsup && !data.viber && '---'}
                      </MediaGroup>
                    </div>
                  </div>
                </div>

                {status !== 'accepted' && (
                  <div className="flex-item-actions">
                    <Confirmation onConfirm={onRejectInvitation}>
                      <Button prefix={<Icon type="close" />} loading={loadingRejectInvitation}>
                        {t('reject')}
                      </Button>
                    </Confirmation>

                    <Confirmation isBibleGroup={data.is_bible_group} onConfirm={onAcceptInvitation}>
                      <Button type="primary" prefix={<Icon type="check" />} loading={loadingAcceptInvitation}>
                        {t('accept')}
                      </Button>
                    </Confirmation>
                  </div>
                )}
              </div>

              <div className="flex-item">
                <div className="mb-25">
                  <h3>{t('detailsOfEvent')}</h3>
                  <div className="mt-20" dangerouslySetInnerHTML={{ __html: data.description || '---' }} />
                </div>
              </div>
            </div>
          </Card>
        </Loader>
      </EventLayout>
    </>
  );
};
