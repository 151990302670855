import * as React from 'react';
import { useRequest } from 'estafette';
import { Link, useHistory, useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { UserContext } from 'contexts';
import { useFilters } from 'hooks';
import { today, format, dateTimeFormat, toISO, dateFormat } from 'libs/date';
import { sessions } from 'libs/http/api/sessions/sessions';
import { Results } from 'libs/http/api/index.types';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { perPage } from 'libs/storage';
import { Actions, Head, InputSearch, SortBy } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import {
  Animated,
  Info,
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Time,
  Icon,
  Loader,
  AvatarInline,
} from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';
import { Expande } from 'ui/atoms/Table/Expanded';

import { InstituteLayout } from '../organisms';

const initialFilters = {
  planned: {
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    ordering: '' as Sorts,
    start_date__gte: format(today.toString(), dateTimeFormat),
  },
  active: {
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    sort: '' as Sorts,
    start_date__lte: format(today.toString(), dateTimeFormat),
    end_date__gte: format(today.toString(), dateTimeFormat),
  },
};

export const SessionsinstitutePage: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { id } = useParams();
  const { userData } = React.useContext(UserContext);

  const [filters, setFilters] = useFilters<{ [key: string]: any }>({ ...initialFilters });

  const { request: requestPlanned, data: dataPlanned, loading: loadingPlanned } = useRequest<Results<Session>>({
    data: { results: [] },
  });
  const { request: requestActive, data: dataActive, loading: loadingActive } = useRequest<Results<Session>>({
    data: { results: [] },
  });
  const { request: requestDelete, loading: loadingDelete } = useRequest();

  React.useEffect(() => {
    setFilters({
      planned: { ...filters.planned, per_page: perPage, page: 1 },
      active: { ...filters.active, per_page: perPage, page: 1 },
    });

    return () => {
      sessions.get.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (id && !loadingDelete) {
      requestPlanned(
        sessions.get.action({
          ...filters.planned,
          start_date__gte: toISO(filters.planned.start_date__gte),
          institution__in: id,
        }),
      );
    }
  }, [id, filters.planned, filters.planned, loadingDelete]);

  React.useEffect(() => {
    if (id && !loadingDelete) {
      requestActive(
        sessions.get.action({
          ...filters.active,
          start_date__lte: toISO(filters.active.start_date__lte),
          end_date__gte: toISO(filters.active.end_date__gte),
          institution__in: id,
        }),
      );
    }
  }, [id, filters.active, filters.active, loadingDelete]);

  const onChangePlannedFilters = React.useCallback(
    (target, value: any): void =>
      setFilters({ planned: { ...filters.planned, filtered: true, page: 1, [target]: value } }),
    [],
  );
  const onChangeActiveFilters = React.useCallback(
    (target, value: any): void =>
      setFilters({ active: { ...filters.active, filtered: true, page: 1, [target]: value } }),
    [],
  );

  const onRefetchPlanned = (): void => setFilters({ planned: { ...filters.planned } });
  const onRefetchActive = (): void => setFilters({ active: { ...filters.active } });
  const onChangePlannedPerPage = (per_page: number): void => setFilters({ planned: { ...filters.planned, per_page } });
  const onChangeActivePerPage = (per_page: number): void => setFilters({ active: { ...filters.active, per_page } });

  const onIncreasePlannedPage = (): void =>
    setFilters((prevState) => ({ planned: { ...prevState.planned, page: prevState.planned.page + 1 } }));
  const onDecreasePlannedPage = (): void =>
    setFilters((prevState) => ({ planned: { ...prevState.planned, page: prevState.planned.page - 1 } }));
  const onIncreaseActivePage = (): void =>
    setFilters((prevState) => ({ active: { ...prevState.active, page: prevState.active.page + 1 } }));
  const onDecreaseActivePage = (): void =>
    setFilters((prevState) => ({ active: { ...prevState.active, page: prevState.active.page - 1 } }));

  const onDeleteSession = React.useCallback((id) => requestDelete(sessions.delete.action({ id })), []);
  const onManagementSession = (id: number, type: string): void =>
    push('AddSessionPage', { action: id, query: { page: filters[type].page } });

  const columnsActive: Column<Session>[] = React.useMemo(
    () => [
      {
        title: t('dateAndTime'),
        render: ({ start_date: startDate }): React.ReactNode => <Time date={startDate} format={dateFormat} />,
      },
      {
        title: t('teacher'),
        render: ({ professor }) =>
          professor ? (
            <AvatarInline
              img={professor.profile_picture}
              alt={[professor.first_name, professor.last_name].filter((i) => i).join(' ')}
              size="small"
            />
          ) : (
            '---'
          ),
      },
      {
        title: 'Locul',
        render: ({ country, city }) =>
          country && country.title ? (
            <Info
              icon={<Icon type="pin" />}
              label={[country && country.title, city && city.title].filter((i) => i).join(', ')}
            />
          ) : (
            '---'
          ),
      },
      {
        title: t('institute'),
        render: ({ institution }) => (institution && institution.name) || '---',
      },
      {
        title: t('course'),
        render: ({ course }) => (course && course.name) || '---',
      },
      {
        title: t('numOfRegisteredStudentsShort'),
        width: 80,
        render: ({ number_of_students: students }) => (students && <b>{students}</b>) || 0,
      },
      {
        action: true,
        render: ({ id }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <Link className="nowrap" route="GeneralSessionPage" params={{ id }}>
              {t('details')}
            </Link>

            {['admin', 'coordinator'].includes(userData.role) && (
              <Actions
                onEdit={
                  ['admin', 'coordinator'].includes(userData.role) ? () => onManagementSession(id, 'active') : undefined
                }
                onDelete={userData.role === 'admin' ? () => onDeleteSession(id) : undefined}
              />
            )}
          </div>
        ),
        className: 'nowrap',
      },
    ],
    [t, userData.role],
  );

  const columns: Column<Session>[] = React.useMemo(
    () => [
      {
        title: t('dateAndTime'),
        render: ({ start_date: startDate }): React.ReactNode => <Time date={startDate} format={dateFormat} />,
      },
      {
        title: t('teacher'),
        render: ({ professor }) =>
          professor ? (
            <AvatarInline
              img={professor.profile_picture}
              alt={[professor.first_name, professor.last_name].filter((i) => i).join(' ')}
              size="small"
            />
          ) : (
            '---'
          ),
      },
      {
        title: 'Locul',
        render: ({ country, city }) =>
          country && country.title ? (
            <Info
              icon={<Icon type="pin" />}
              label={[country && country.title, city && city.title].filter((i) => i).join(', ')}
            />
          ) : (
            '---'
          ),
      },
      {
        title: t('institute'),
        render: ({ institution }) => (institution && institution.name) || '---',
      },
      {
        title: t('course'),
        render: ({ course }) => (course && course.name) || '---',
      },
      {
        title: t('numOfRegisteredStudentsShort'),
        width: 80,
        render: ({ number_of_students: students }) => (students && <b>{students}</b>) || 0,
      },
      {
        action: true,
        render: ({ id }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <Link className="nowrap" route="GeneralSessionPage" params={{ id }}>
              {t('details')}
            </Link>

            {['admin', 'coordinator'].includes(userData.role) && (
              <Actions
                onEdit={
                  ['admin', 'coordinator'].includes(userData.role)
                    ? () => onManagementSession(id, 'planned')
                    : undefined
                }
                onDelete={userData.role === 'admin' ? () => onDeleteSession(id) : undefined}
              />
            )}
          </div>
        ),
        className: 'nowrap',
      },
    ],
    [t, userData.role],
  );

  const expandable = ({
    professor,
    start_date: startDate,
    end_date: endDate,
    country,
    city,
    info,
    course,
    institution,
    number_of_students: students,
    language,
  }: Session): Expande => [
    [
      { label: t('startAt'), value: format(startDate, dateFormat), icon: <Icon type="calendar" /> },
      { label: t('finishAt'), value: format(endDate, dateFormat), icon: <Icon type="calendar" /> },
    ],
    [
      { label: t('course'), value: (course && course.name) || '---', icon: <Icon type="requests" /> },
      {
        label: t('teacher'),
        value: professor ? [professor.first_name, professor.last_name].filter((i) => i).join(' ') : '---',
      },
    ],
    [
      {
        label: t('place'),
        value:
          country && country.title ? [country && country.title, city && city.title].filter((i) => i).join(', ') : '---',
        icon: <Icon type="pin" />,
      },
      {
        label: t('info_short'),
        value: info ? <span dangerouslySetInnerHTML={{ __html: info }} /> : '---',
        icon: <Icon type="info" />,
      },
    ],
    [
      { label: t('numOfStudents'), value: students, icon: <Icon type="student" /> },
      { label: t('language'), value: (language && language.name) || '---' },
    ],
    [{ label: t('institute'), value: (institution && institution.name) || '---', icon: <Icon type="location" /> }],
  ];

  return (
    <>
      <Head t="plannedSessions" />

      <InstituteLayout>
        <Card>
          <Animated loading={!filters.planned.filtered && loadingPlanned} debounce={250}>
            <CardHeader
              title={t('plannedSessions')}
              count={dataPlanned.count}
              leftSide={
                <InputSearch
                  value={filters.planned.search}
                  onSearch={(newValue) => onChangePlannedFilters('search', newValue)}
                />
              }
              rightSide={
                <SortBy
                  options={[
                    {
                      title: t('name'),
                      value: 'name',
                    },
                    {
                      title: t('country'),
                      value: 'country',
                    },
                    {
                      title: t('city'),
                      value: 'city',
                    },
                    {
                      title: t('startDate'),
                      value: 'start_date',
                    },
                    {
                      title: t('endDate'),
                      value: 'end_date',
                    },
                  ]}
                  value={filters.planned.ordering}
                  onChange={(newValue) => onChangePlannedFilters('ordering', newValue)}
                />
              }
            />

            <Loader loading={filters.planned.filtered && loadingPlanned}>
              <Table page={filters.planned.page} data={dataPlanned.results} columns={columns} expandable={expandable} />

              <CardFooter
                onRefresh={onRefetchPlanned}
                page={filters.planned.page}
                pages={dataPlanned.total_pages}
                perPage={filters.planned.per_page}
                onChangePerPage={onChangePlannedPerPage}
                rightSide={
                  <>
                    <Button onClick={onDecreasePlannedPage} disabled={filters.planned.page === 1}>
                      {t('previous')}
                    </Button>

                    <Button onClick={onIncreasePlannedPage} disabled={filters.planned.page >= dataPlanned.total_pages}>
                      {t('next')}
                    </Button>
                  </>
                }
              />
            </Loader>
          </Animated>
        </Card>

        <Card>
          <CardHeader
            title={t('unclosedSessions')}
            count={dataActive.count}
            leftSide={
              <InputSearch
                value={filters.planned.search}
                onSearch={(newValue) => onChangeActiveFilters('search', newValue)}
              />
            }
            rightSide={
              <SortBy
                options={[
                  {
                    title: t('name'),
                    value: 'name',
                  },
                  {
                    title: t('country'),
                    value: 'country',
                  },
                  {
                    title: t('city'),
                    value: 'city',
                  },
                  {
                    title: t('startDate'),
                    value: 'start_date',
                  },
                  {
                    title: t('endDate'),
                    value: 'end_date',
                  },
                ]}
                value={filters.planned.ordering}
                onChange={(newValue) => onChangeActiveFilters('ordering', newValue)}
              />
            }
          />

          <Loader loading={loadingActive}>
            <Table
              page={filters.active.page}
              data={dataActive ? dataActive.results : []}
              columns={columnsActive}
              expandable={expandable}
            />

            <CardFooter
              onRefresh={onRefetchActive}
              page={filters.active.page}
              pages={dataActive.total_pages}
              perPage={filters.active.per_page}
              onChangePerPage={onChangeActivePerPage}
              rightSide={
                <>
                  <Button onClick={onDecreaseActivePage} disabled={filters.active.page === 1}>
                    {t('previous')}
                  </Button>

                  <Button onClick={onIncreaseActivePage} disabled={filters.active.page >= dataActive.total_pages}>
                    {t('next')}
                  </Button>
                </>
              }
            />
          </Loader>
        </Card>
      </InstituteLayout>
    </>
  );
};
