import * as React from 'react';

import './Progressbar.scss';

interface Props {
  finished: boolean;
}

export const Progressbar: React.FC<Props> = ({ finished }) => {
  const [percentage, setPercentage] = React.useState<number>(0);

  React.useEffect(() => {
    if (finished) {
      setPercentage(100);
    }
  }, [finished]);

  return (
    <div className="zh-progressbar">
      <div className="zh-progressbar-percentage">{percentage}%</div>

      <div className="zh-progressbar-bar">
        <div
          style={{ width: `${percentage}%` }}
          className={`zh-progressbar-bar-line${percentage >= 25 ? ' over-25' : ''}${
            percentage >= 50 ? ' over-50' : ''
          }${percentage >= 75 ? ' over-75' : ''}`}
        />
      </div>
    </div>
  );
};
