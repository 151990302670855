import * as React from 'react';
import { useRequest } from 'estafette';
import { Link, useHistory } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { AvatarInline, Button, Card, CardFooter, CardHeader, Loader, Put, Table } from 'ui/atoms';
import { Actions, InputSearch, SortBy } from 'ui/organisms';
import { Column } from 'ui/atoms/Table/Table';
import { StudentGraduation } from 'libs/http/api/graduations/graduations.types';
import { UserContext } from 'contexts';
import { graduations } from 'libs/http/api/graduations/graduations';
import { Results } from 'libs/http/api/index.types';
import { perPage } from 'libs/storage';
import { dateFormat, format } from 'libs/date';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { useFilters } from 'hooks';

interface GraduatesTableProps {
  graduationId?: number;
  studentId?: number;
}

export const GraduatesTable: React.FC<GraduatesTableProps> = ({ graduationId, studentId }) => {
  const { userData } = React.useContext(UserContext);
  const { push } = useHistory();
  const { t } = useIntl();

  const { request: graduatesRequest, data: graduatesData, loading } = useRequest<Results<StudentGraduation>>({
    data: { results: [] },
  });
  const { request: requestDelete, loading: loadingDelete } = useRequest();

  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    ordering: '' as Sorts,
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    if ((graduationId || studentId) && !loadingDelete) {
      graduatesRequest(graduations.getStudents.action({ ...filters, graduation: graduationId, student: studentId }));
    }
  }, [graduationId, studentId, filters, loadingDelete]);

  const onOpenEditAccount = React.useCallback(
    (id, graduateId): void => push('AddGraduationGraduatePage', { id, action: graduateId }),
    [],
  );

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onDeleteStudent = React.useCallback((value) => requestDelete(graduations.deleteStudent.action(value)), []);

  const canEdit = React.useMemo(() => ['admin', 'coordinator'].includes(userData.role), [userData.role]);

  const columns: Column<StudentGraduation>[] = React.useMemo(
    () => [
      graduationId
        ? {
            title: t('nameAndSurname'),
            render: ({ student: { profile_picture, first_name, last_name, id } }): React.ReactNode => (
              <AvatarInline
                img={profile_picture}
                shortAlt={[first_name, last_name].filter((i) => i).join(' ')}
                alt={
                  <Link route="ProfilePage" params={{ action: id }}>
                    {[first_name, last_name].filter((i) => i).join(' ')}
                  </Link>
                }
                size="small"
              />
            ),
          }
        : {},
      {
        title: t('institute'),
        render: ({ institution }) => (
          <Put>
            {institution && (
              <Link route="GeneralInstitutePage" params={{ id: institution.id }}>
                {institution.name}
              </Link>
            )}
          </Put>
        ),
      },
      {
        title: t('faculties'),
        render: ({ faculty }) => <Put>{faculty?.name}</Put>,
      },
      {
        title: t('certificationLevel'),
        render: ({ certification_level }) => <Put>{certification_level.name}</Put>,
      },
      {
        title: t('diploma'),
        render: ({ diploma_number }) => (diploma_number ? diploma_number : '---'),
      },
      {
        title: t('date'),
        render: ({ graduation }) => (graduation ? format(graduation.date, dateFormat) : '---'),
      },
      {
        title: t('actions'),
        className: 'actions-col',
        render: ({ id: graduateId, graduation: { id } }) => (
          <div className={'flex flex-justify-end flex-margin-between flex-nowrap'}>
            <Actions
              onEdit={() => onOpenEditAccount(id, graduateId)}
              onDelete={() => onDeleteStudent(graduateId)}
              loadingDelete={loadingDelete}
            />
          </div>
        ),
      },
    ],
    [t, userData, canEdit],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  return (
    <>
      <Card>
        <CardHeader
          title={t('graduates')}
          count={graduatesData.count}
          leftSide={<InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />}
          rightSide={
            <>
              <SortBy
                options={[
                  {
                    title: t('firstName'),
                    value: 'first_name',
                  },
                  {
                    title: t('lastName'),
                    value: 'last_name',
                  },
                ]}
                value={filters.ordering}
                onChange={(newValue) => onChangeFilters('ordering', newValue)}
              />
            </>
          }
        />
        <Loader loading={loading}>
          <Table
            size="small"
            className="student-table"
            page={filters.page}
            data={graduatesData.results}
            columns={columns}
          />

          <CardFooter
            onRefresh={onRefetch}
            onRefreshDisabled={!filters.filtered}
            page={filters.page}
            pages={graduatesData.total_pages}
            perPage={filters.per_page}
            onChangePerPage={onChangePerPage}
            rightSide={
              <>
                <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                  {t('previous')}
                </Button>

                <Button onClick={onIncreasePage} disabled={filters.page >= graduatesData.total_pages}>
                  {t('next')}
                </Button>
              </>
            }
          />
        </Loader>
      </Card>
    </>
  );
};
