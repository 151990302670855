import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { Results, List } from '../index.types';
import { EurasiaInstitution, EurasiaInstitutionForPlan } from './institutions.types';
import { Announcement, AnnouncementFilters } from '../announces/announces.types';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { Users } from 'libs/http/api/users/users.types';

export const institutions = {
  get: {
    action: (params = {}): Promise<{ data: Results<EurasiaInstitution> }> =>
      axios.get(`institutions`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (institutions.get.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  add: {
    action: (params = {}) =>
      axios.post(`institutions`, params, {
        cancelToken: new cancelToken((c: Canceler) => (institutions.add.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  save: {
    action: ({ id, ...options }: { id: number }) =>
      axios.patch(`institutions/${id}`, options, {
        cancelToken: new cancelToken((c: Canceler) => (institutions.save.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  load: {
    action: ({ id, ...params }: { id: number }): Promise<{ data: EurasiaInstitution }> =>
      axios.get(`institutions/${id}`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (institutions.load.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  delete: {
    action: ({ id }: { id: number }) =>
      axios.delete(`institutions/${id}`, {
        cancelToken: new cancelToken((c: Canceler) => (institutions.delete.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  list: {
    action: (params = {}): Promise<{ data: List[] }> =>
      axios.get('institutions/list', {
        params,
        cancelToken: new cancelToken((c: Canceler) => (institutions.list.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getAnnounces: {
    action: ({ id, ...params }: AnnouncementFilters): Promise<{ data: Results<Announcement> }> =>
      axios.get(`institutions/${id}/announces`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (institutions.getAnnounces.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getCurrentSessions: {
    action: ({ id, ...params }: { id: number }): Promise<{ data: Results<Session> }> =>
      axios.get(`institutions/${id}/current-sessions`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (institutions.getCurrentSessions.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getPlannedSessions: {
    action: ({ id, ...params }: { id: number }): Promise<{ data: Results<Session> }> =>
      axios.get(`institutions/${id}/planned-sessions`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (institutions.getPlannedSessions.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getPreviousSessions: {
    action: ({ id, ...params }: { id: number }): Promise<{ data: Results<Session> }> =>
      axios.get(`institutions/${id}/previous-sessions`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (institutions.getPreviousSessions.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getProfessors: {
    action: ({ id, ...params }: { id: number }) =>
      axios.get<Users>(`institutions/${id}/professors`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (institutions.getProfessors.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getStudents: {
    action: ({ id, ...params }: { id: number }) =>
      axios.get<Users>(`institutions/${id}/students`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (institutions.getStudents.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getInstituteWithoutPlan: {
    action: (params = {}) =>
      axios.get<Results<EurasiaInstitutionForPlan>>(`institutions/planned`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (institutions.getStudents.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
