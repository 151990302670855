import * as React from 'react';
import { useRequest } from 'estafette';
import { useHistory } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { today, toISO, dateTimeFormat } from 'libs/date';
import { me } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { Animated } from 'ui/atoms';
import { Head, SeeOther } from 'ui/organisms';

import { SessionsLayout } from '../organisms';
import { SessionApplicationsTemplate, CurrentSessionsTemplate, PlannedSessionsTemplate } from '../templates';

export const SinglePlannedSessionsPage: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();

  const { request, data, loading } = useRequest<Results<Session>>({ data: { results: [] } });

  React.useEffect(() => {
    request(me.getSessions.action({ end_date__lte: toISO(today.format(dateTimeFormat)) }));
  }, []);

  const onClickHandler = (): void => push('SingleClosedSessionsPage');

  return (
    <>
      <Head t="plannedSessions" />

      <SessionsLayout showTabs={false}>
        <SessionApplicationsTemplate />

        <CurrentSessionsTemplate />

        <PlannedSessionsTemplate />

        <Animated loading={loading}>
          <SeeOther icon="lock" label={t('seeClosedSessions')} count={data.count} onClick={onClickHandler} />
        </Animated>
      </SessionsLayout>
    </>
  );
};
