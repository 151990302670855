import * as React from 'react';
import { Head } from 'ui/organisms';

import { UserContext } from 'contexts';

import { RegisterSessionsTemplate } from 'features/register/templates';
import { SessionsApplicationTemplate } from 'features/sessions/templates';

import { RegisterLayout } from '../organisms';

export const RegisterSessionsPage: React.FC = () => {
  const { userData } = React.useContext(UserContext);
  return (
    <>
      <Head t="register2" />

      <RegisterLayout>
        <RegisterSessionsTemplate />
        <SessionsApplicationTemplate hideSearchBar country={userData.country} />
      </RegisterLayout>
    </>
  );
};
