import * as React from 'react';
import { useRequest } from 'estafette';
import { useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { format, isBefore } from 'libs/date';
import { me } from 'libs/http/api';
import { SessionRegister } from 'libs/http/api/sessions/sessions.types';
import { Animated, Card, CardHeader, Icon, Info, Time, AvatarInline, Button } from 'ui/atoms';
import { Head, Report } from 'ui/organisms';

import { SessionsLayout } from '../../organisms';

import './SessionPage.scss';

export const SessionPage: React.FC = () => {
  const { t } = useIntl();
  const { id } = useParams();
  const { request, data, loading } = useRequest<SessionRegister>({ data: {} });

  React.useEffect(() => {
    request(me.getSession.action({ id }));
  }, [id]);

  const sessionFinished = React.useMemo(() => !isBefore(data.end_date), [data]);

  return (
    <>
      <Head title={(data.faculty && data.faculty.name) || 'n/a'} />

      <SessionsLayout showTabs={false}>
        <Animated loading={loading} debounce={500}>
          <Card>
            <CardHeader
              title={
                <>
                  {data.faculty && <h2 className="zh-session-title">{data.faculty.name}</h2>}

                  {data.course && <h3 className="zh-session-subtitle">{data.course.name}</h3>}
                </>
              }
              rightSide={
                data.final_grade ? (
                  <Report type="reportGrade" />
                ) : (
                  <Button onClick={() => window.open(`mailto:${data?.professor?.email}`)}>
                    {t('sendMessageToProfessor')}
                  </Button>
                )
              }
            />

            <div className="zh-session-card">
              <div className="zh-session-card-item">
                {data.institution && (
                  <div className="zh-session-card-info">
                    <div className="zh-session-card-info-label">{t('institute')}</div>
                    <div className="zh-session-card-info-text">
                      <Info primary label={<b>{data.institution.name}</b>} />
                    </div>
                  </div>
                )}

                <div className="zh-session-card-info">
                  <div className="zh-session-card-info-label">{t('period')}</div>
                  <div className="zh-session-card-info-text nowrap">
                    <Time date={`${format(data.start_date)} - ${format(data.end_date)}`} noParse />
                  </div>
                </div>

                <div className="zh-session-card-info">
                  <div className="zh-session-card-info-label">{t('location')}</div>
                  <div className="zh-session-card-info-text">
                    <Info
                      icon={<Icon type="location-filled" />}
                      label={[data.country && data.country.title, data.city && data.city.title, data.address]
                        .filter((i) => i)
                        .join(', ')}
                    />
                  </div>
                </div>

                {data.professor && (
                  <div className="zh-session-card-info">
                    <div className="zh-session-card-info-label">{t('teacher')}</div>
                    <div className="zh-session-card-info-text">
                      <AvatarInline
                        img={data.professor.profile_picture}
                        alt={[data.professor.first_name, data.professor.last_name].filter((i) => i).join(' ')}
                        size="small"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="zh-session-card-item zh-session-single">
                <div className="zh-session-card-info">
                  <div className="zh-session-single-title">{t('notes2')}</div>

                  <div className="zh-session-card-item">
                    <div className="zh-session-card-info">
                      <div className="zh-session-card-info-label">{t('noteForCourse')}</div>
                      <div className="zh-session-card-info-text">
                        {sessionFinished ? data.course_grade ? <b>{data.course_grade}</b> : t('arrears') : '---'}
                      </div>
                    </div>

                    <div className="zh-session-card-info">
                      <div className="zh-session-card-info-label">{t('noteForIndividualWork')}</div>
                      <div className="zh-session-card-info-text">
                        {sessionFinished ? (
                          data.individual_work_grade ? (
                            <b>{data.individual_work_grade}</b>
                          ) : (
                            t('arrears')
                          )
                        ) : (
                          '---'
                        )}
                      </div>
                    </div>

                    <div className="zh-session-card-info">
                      <div className="zh-session-card-info-label">{t('practiceGrade')}</div>
                      <div className="zh-session-card-info-text">
                        {sessionFinished ? data.practise_grade ? <b>{data.practise_grade}</b> : t('arrears') : '---'}
                        {/* todo */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="zh-session-card-info zh-session-single-total">
                  <div className="zh-session-card-info-label">{t('averageGrade')}</div>
                  <div className="zh-session-card-info-text mt-20">
                    <b>{sessionFinished ? data.final_grade ? <b>{data.final_grade}</b> : t('arrears') : '---'}</b>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Animated>
      </SessionsLayout>
    </>
  );
};
