import * as React from 'react';
import { useHistory } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { UserContext } from 'contexts';
import { EditAccount, Head, Layout } from 'ui/organisms';

import './EditPersonalAccountPage.scss';

export const EditPersonalAccountPage: React.FC = () => {
  const { t } = useIntl();
  const { onFetchUserData } = React.useContext(UserContext);
  const { push } = useHistory();

  const onClose = (): void => {
    push('PersonalAccountPage');
  };

  const onSuccess = (): void => {
    onFetchUserData();
    onClose();
  };

  return (
    <>
      <Head title={t('editProfile')} />

      <Layout className="zh-edit-account-page" title={t('personalAccount')}>
        <h1>{t('editProfile')}</h1>

        <EditAccount id="my" onSuccess={onSuccess} onClose={onClose} />
      </Layout>
    </>
  );
};
