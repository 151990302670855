import { IndexEntry } from 'ui/organisms/IndexEntry';

import {
  SessionsPage,
  SignUpPage,
  SignInPage,
  RestorePage,
  ConfirmPage,
  PublicSessionPage,
  TermsOfUsePage,
  PrivacyPolicyPage,
} from 'features/public/pages';

import {
  InstitutesPage,
  AddInstitutePage,
  GeneralInstitutePage,
  PrevSessionsinstitutePage,
  SessionsinstitutePage,
  StudentsInstitutePage,
  TeachersInstitutePage,
  TeacherManagementPage,
  AnnouncementsInstitutePage,
  EditCoordinatorPage,
  StudentManagementPage,
} from 'features/institutes/pages';

import { ApplicationsPage } from 'features/applications/pages';
import { ApplicationPage } from 'features/application/pages';

import { InvitePage, EventsPage, AddEventsPage } from 'features/events/pages';

import { PersonalAccountPage, EditPersonalAccountPage } from 'features/personal/pages';

import { AnnouncementsPage, AnnouncementsDetailsPage } from 'features/announcements/pages';

import {
  SessionPage,
  PlannedSessionsPage,
  InstituteWithoutPlanPage,
  CurrentSessionsPage,
  ClosedSessionsPage,
  SessionApplicationsPage,
  SessionsApplicationPage,
  SingleCurrentSessionsPage,
  SingleClosedSessionsPage,
  SinglePlannedSessionsPage,
  AddSessionPage,
  RequestSessionPage,
} from 'features/sessions/pages';

import { ResourcePage } from 'features/resources/pages';

import { FacultiesPage, AddFacultyPage } from 'features/faculties/pages';

import { CoursesPage, AddCoursePage } from 'features/courses/pages';

import { ManualsPage, AddManualPage } from 'features/manuals/pages';

import {
  CoordinatorsUsersPage,
  CoordinatorEditPage,
  StudentsUsersPage,
  StudentEditPage,
  TeachersUsersPage,
  TeacherEditPage,
} from 'features/users/pages';
import { CommunicationPage } from 'features/communication/pages';

import {
  GeneralSessionPage,
  StudentsSessionPage,
  AnnouncementsSessionPage,
  AddStudentSessionPage,
  ResourcesSessionPage,
} from 'features/session/pages';

import { MyStudentsUsersPage, ProfilePage, MyStudentEditPage } from 'features/mystudents/pages';

import { StudentPanel } from 'features/studentPanel/pages';

import { ContactsPage } from 'features/contacts/pages';

import { RegisterPage, RegisterSessionsPage } from 'features/register/pages';

import {
  GeneralGraduationPage,
  GraduationsPage,
  AddGraduationPage,
  AddGraduationGraduatePage,
} from './features/graduations/pages';

export interface Route {
  name: string;
  path: string;
  component: any;
  exact?: boolean;
  label?: string;
  parent?: string;
}

export const routes: Route[] = [
  // prettier-ignore
  { name: 'IndexEntry', path: '/', exact: true, component: IndexEntry },

  { name: 'SessionsPage', path: '/public', component: SessionsPage, exact: true },
  { name: 'SignInPage', path: '/public/sign-in', component: SignInPage },
  { name: 'SignUpPage', path: '/public/sign-up', component: SignUpPage },
  { name: 'RestorePage', path: '/public/restore', component: RestorePage, exact: true },
  { name: 'ConfirmRestorePage', path: '/public/restore/:token', component: RestorePage },
  { name: 'ConfirmPage', path: '/public/confirm/:token', component: ConfirmPage, exact: true },
  { name: 'PublicSession', path: '/public-session/:id', component: PublicSessionPage, exact: true },
  { name: 'PrivacyPolicyPage', path: '/public/privacy-policy', component: PrivacyPolicyPage },
  { name: 'TermsOfUsePage', path: '/public/terms-of-use', component: TermsOfUsePage },

  { name: 'EventsPage', path: '/events', component: EventsPage, exact: true, label: 'calendarOfEvents' },
  { name: 'AddEventsPage', path: '/events/add', component: AddEventsPage, parent: 'EventsPage' },
  { name: 'InvitePage', path: '/events/invite/:status/:id', component: InvitePage, parent: 'EventsPage' },

  { name: 'InstitutesPage', path: '/institutes', component: InstitutesPage, label: 'institutes' },
  {
    name: 'AddInstitutePage',
    path: '/institute/management/:action',
    component: AddInstitutePage,
    parent: 'InstitutesPage',
  },
  {
    name: 'GeneralInstitutePage',
    path: '/institute/:id/general',
    component: GeneralInstitutePage,
    parent: 'InstitutesPage',
    label: 'generalInformation',
  },
  {
    name: 'EditCoordinatorPage',
    path: '/institute/:id/edit-coordonator/:action',
    component: EditCoordinatorPage,
    parent: 'GeneralInstitutePage',
  },
  {
    name: 'SessionsinstitutePage',
    path: '/institute/:id/current-sessions',
    component: SessionsinstitutePage,
    parent: 'InstitutesPage',
  },
  {
    name: 'PrevSessionsinstitutePage',
    path: '/institute/:id/previous-sessions',
    component: PrevSessionsinstitutePage,
    parent: 'InstitutesPage',
  },
  {
    name: 'TeachersInstitutePage',
    path: '/institute/:id/teachers',
    component: TeachersInstitutePage,
    parent: 'InstitutesPage',
    label: 'teachers',
  },
  {
    name: 'StudentsInstitutePage',
    path: '/institute/:id/students',
    component: StudentsInstitutePage,
    parent: 'InstitutesPage',
    label: 'students',
  },
  {
    name: 'StudentManagementPage',
    path: '/institute/:id/student/management/:action',
    component: StudentManagementPage,
    parent: 'StudentsInstitutePage',
  },
  {
    name: 'TeacherManagementPage',
    path: '/institute/:id/teacher/management/:action',
    component: TeacherManagementPage,
    parent: 'TeachersInstitutePage',
  },
  {
    name: 'AnnouncementsInstitutePage',
    path: '/institute/:id/announcements',
    component: AnnouncementsInstitutePage,
    parent: 'InstitutesPage',
  },

  { name: 'ApplicationsPage', path: '/applications', component: ApplicationsPage, exact: true },

  { name: 'ApplicationPage', path: '/application', component: ApplicationPage, exact: true },

  {
    name: 'PersonalAccountPage',
    path: '/personal/my-account',
    component: PersonalAccountPage,
    label: 'personalAccount',
  },
  {
    name: 'EditPersonalAccountPage',
    path: '/personal/edit/my-account',
    component: EditPersonalAccountPage,
    parent: 'PersonalAccountPage',
  },

  { name: 'AnnouncementsPage', path: '/announcements', component: AnnouncementsPage, label: 'announcements' },
  {
    name: 'AnnouncementsDetailsPage',
    path: '/announcement/:id',
    component: AnnouncementsDetailsPage,
    parent: 'AnnouncementsPage',
  },

  { name: 'ResourcePage', path: '/resources/:type', component: ResourcePage, exact: true },

  { name: 'SessionsApplicationPage', path: '/sessions', component: SessionsApplicationPage, exact: true },
  { name: 'SessionPage', path: '/session/:id', component: SessionPage, exact: true },
  { name: 'PlannedSessionsPage', path: '/sessions/planned', component: PlannedSessionsPage, label: 'plannedSessions' },
  {
    name: 'InstituteWithoutPlanPage',
    path: '/sessions/institute',
    component: InstituteWithoutPlanPage,
    label: 'plannedSessions',
  },
  { name: 'CurrentSessionsPage', path: '/sessions/current', component: CurrentSessionsPage },
  { name: 'ClosedSessionsPage', path: '/sessions/closed', component: ClosedSessionsPage },
  { name: 'SessionApplicationsPage', path: '/sessions/applications', component: SessionApplicationsPage },
  {
    name: 'AddSessionPage',
    path: '/sessions/management/:action',
    component: AddSessionPage,
    parent: 'PlannedSessionsPage',
  },
  { name: 'RequestSessionPage', path: '/sessions/request', component: RequestSessionPage },

  { name: 'GeneralSessionPage', path: '/session/:id/general', component: GeneralSessionPage },
  { name: 'StudentsSessionPage', path: '/session/:id/students', component: StudentsSessionPage },
  { name: 'AddStudentSessionPage', path: '/session/:id/student/:action', component: AddStudentSessionPage },
  { name: 'AnnouncementsSessionPage', path: '/session/:id/announcements', component: AnnouncementsSessionPage },
  { name: 'ResourcesSessionPage', path: '/session/:id/resources', component: ResourcesSessionPage },

  { name: 'FacultiesPage', path: '/faculties', component: FacultiesPage, label: 'faculties' },
  { name: 'AddFacultyPage', path: '/faculty/management/:action', component: AddFacultyPage, parent: 'FacultiesPage' },

  { name: 'CoursesPage', path: '/courses', component: CoursesPage, label: 'courses' },
  { name: 'AddCoursePage', path: '/course/management/:action', component: AddCoursePage, parent: 'CoursesPage' },

  { name: 'ManualsPage', path: '/manuals', component: ManualsPage, label: 'manuals' },
  { name: 'AddManualPage', path: '/manual/management/:action', component: AddManualPage, parent: 'ManualsPage' },

  {
    name: 'CoordinatorsUsersPage',
    path: '/users/coordinators',
    component: CoordinatorsUsersPage,
    label: 'coordinators',
  },
  {
    name: 'CoordinatorEditPage',
    path: '/user/coordinator/:action',
    component: CoordinatorEditPage,
    parent: 'CoordinatorsUsersPage',
  },
  { name: 'TeachersUsersPage', path: '/users/teachers', component: TeachersUsersPage, label: 'teachers' },
  { name: 'TeacherEditPage', path: '/user/teacher/:action', component: TeacherEditPage, parent: 'TeachersUsersPage' },
  { name: 'StudentsUsersPage', path: '/users/students', component: StudentsUsersPage, label: 'students' },
  { name: 'StudentEditPage', path: '/user/student/:action', component: StudentEditPage, parent: 'StudentsUsersPage' },

  { name: 'CommunicationPage', path: '/communication', component: CommunicationPage },
  { name: 'MyStudentsUsersPage', path: '/students', component: MyStudentsUsersPage, label: 'students' },
  {
    name: 'ProfilePage',
    path: '/profile/:action',
    component: ProfilePage,
    exact: true,
  },
  {
    name: 'MyStudentEditPage',
    path: '/student/:action/edit',
    component: MyStudentEditPage,
    parent: 'MyStudentsUsersPage',
  },

  { name: 'SingleCurrentSessionsPage', path: '/current-sessions', component: SingleCurrentSessionsPage },
  { name: 'SingleClosedSessionsPage', path: '/closed-sessions', component: SingleClosedSessionsPage },
  { name: 'SinglePlannedSessionsPage', path: '/planned-sessions', component: SinglePlannedSessionsPage },

  { name: 'StudentPanel', path: '/panel', component: StudentPanel },

  { name: 'ContactsPage', path: '/contacts', component: ContactsPage, exact: true },
  { name: 'RegisterPage', path: '/register', component: RegisterPage, exact: true },

  { name: 'RegisterSessionsPage', path: '/register/sessions', component: RegisterSessionsPage },

  { name: 'GraduationsPage', path: '/graduations', component: GraduationsPage, exact: true },
  { name: 'GeneralGraduationPage', path: '/graduations/:id/general', component: GeneralGraduationPage, exact: true },
  {
    name: 'AddGraduationPage',
    path: '/graduations/management/:action',
    component: AddGraduationPage,
    exact: true,
  },
  {
    name: 'AddGraduationGraduatePage',
    path: '/graduation/:id/graduate/management/:action',
    component: AddGraduationGraduatePage,
    exact: true,
  },
];
