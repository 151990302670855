import * as React from 'react';
import { notify } from 'react-notify-toast';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useRequest } from 'estafette';
import { getRoute, useHistory } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { NomenclaturesContext } from 'contexts';
import { routes } from 'routes';
import { useStateHandlers } from 'hooks';
import { users } from 'libs/http/api';
import { EurasiaRestore } from 'libs/http/api/users/users.types';
import { Input, Button, Alert } from 'ui/atoms';
import { InputPhone } from 'ui/molecules';
import { Form, FormItem } from 'ui/organisms';

import './Restore.scss';

export const Restore: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const [sended, setSended] = React.useState(false);

  const [state, setState] = useStateHandlers({ value: '' });

  const [use, setUse] = useStateHandlers({
    type: 'email',
    recommend: false,
  });

  const { request, data, errors, loading } = useRequest<EurasiaRestore>({ data: {} });

  const { countries, getCountries, getGeoip, geoip } = React.useContext(NomenclaturesContext);

  const [country, setCountry] = React.useState<number>();

  const phoneUtil = PhoneNumberUtil.getInstance();
  const isEmail = React.useMemo(() => !!state.value.match(/@/), [state.value]);
  const isPhone = React.useMemo(
    () => state.value.length && (state.value[0] === '+' || !isNaN(parseInt(state.value[0]))),
    [state.value],
  );

  const handleSubmit = (): Promise<EurasiaRestore> => {
    let username = state.value;

    if (!isEmail) {
      username = username.replace('+', '');

      try {
        const values = (phoneUtil.parseAndKeepRawInput(`+${username}`) as any).values_;

        username = `${values[1]}${values[2]}`;
      } catch {
        //
      }
    }

    return request(
      users.restore.action({
        username,
        redirect_url: `${window.location.origin}${getRoute(routes, 'ConfirmRestorePage', { token: '' })}`,
      }),
    );
  };

  const onUseClick = (): void => {
    setState({ value: '' });
    setUse((prevState) => ({ ...prevState, type: prevState.type === 'email' ? 'phone' : 'email', recommend: true }));
  };

  React.useEffect(() => {
    getGeoip();
    getCountries();
  }, []);

  React.useEffect(() => {
    if (geoip.country_code2) {
      setCountry(countries?.filter((item) => item.code === geoip.country_code2.toLowerCase())[0]?.value);
    }
  }, [geoip, countries]);

  React.useEffect(() => {
    if (isPhone && use.type !== 'phone' && !use.recommend) {
      setState({ value: '' });
      setUse({ type: 'phone' });
    }
  }, [isPhone]);

  React.useEffect(() => {
    if (data.success && !sended) {
      notify.show(`${isEmail ? t('emailWasSent') : t('smsWasSent')} ${state.value}`, 'success');

      if (!isEmail) {
        push('SessionsPage', { query: { smsconfirm: true, restore: true } });
      }

      setState({ value: '' });
      setSended(true);
    }
  }, [data, isEmail, sended]);

  return (
    <>
      <Form onSubmit={handleSubmit} className="auth__block">
        <h3>{t('enterAccountEmailOrPhone')}</h3>
        <FormItem extra={errors.username}>
          {use.type === 'email' ? (
            <Input
              value={state.value}
              onChange={(value: string): void => setState({ value })}
              placeholder={`${t('email')} ${t('or')} ${t('phone')}`}
            />
          ) : (
            <InputPhone
              country={countries?.filter((item) => item.value === country)[0]?.code}
              value={state.value}
              onChange={(value: string): void => setState({ value })}
            />
          )}

          {use.type === 'phone' || use.recommend ? (
            <span className="useEmail" onClick={onUseClick}>
              {use.type === 'phone' ? t('restoreByEmail') : t('restoreByPhone')}
            </span>
          ) : null}
        </FormItem>

        <Alert message={!data.success ? data.message : errors.non_field_errors || errors.detail} type="error" />

        <Button type="primary" className="restore-password" submit loading={loading} disabled={state.value.length < 3}>
          {t('restore')}
        </Button>
      </Form>
    </>
  );
};
