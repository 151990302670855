import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { NomenclaturesContext } from 'contexts';
import { useStateHandlers } from 'hooks';
import { faculties } from 'libs/http/api';
import { FacultiesList } from 'libs/http/api/faculties/faculties.types';
import { Select, Button, Icon, Input } from 'ui/atoms';

import './Searchbar.scss';

export interface FiltersProps {
  filtered?: boolean;
  start_date__gte?: string;
  end_date__lte?: string;
  search?: string;
  city?: number;
  country?: number;
  faculty__in?: number;
}

interface Props {
  state: FiltersProps;
  className?: string;
  onChange?: (state: object) => void;
}

export const Searchbar: React.FC<Props> = ({ state: filters, className = '', onChange }) => {
  const { t } = useIntl();
  const { request: requestFaculties, data: dataFaculties, loading: loadingFaculties } = useRequest<FacultiesList[]>();
  const {
    getActiveCountries,
    activeCountries,
    loadingActiveCountries,
    getActiveCities,
    activeCities,
    loadingActiveCities,
  } = React.useContext(NomenclaturesContext);

  const [state, setState] = useStateHandlers({ ...filters });

  const [searchCountries, setSearchCountries] = React.useState('');
  const [searchCities, setSearchCities] = React.useState('');

  React.useEffect(() => {
    return () => {
      faculties.list.cancel();
    };
  }, []);

  React.useEffect(() => {
    requestFaculties(faculties.list.action());
  }, []);

  React.useEffect(() => {
    getActiveCountries(searchCountries);
  }, [searchCountries]);

  React.useEffect(() => {
    getActiveCities(state.country as number | null, searchCities);
  }, [state.country, searchCities]);

  const onSubmit = (): void => {
    if (JSON.stringify(state) !== JSON.stringify(filters) && onChange !== undefined) {
      onChange(state);
    }
  };

  const facultiesOptions = React.useMemo(
    () => dataFaculties.map((faculty) => ({ value: faculty.key, title: faculty.label })),
    [dataFaculties],
  );

  const handleCountriesSearch = (input: string): void => {
    setSearchCountries(input);
  };

  const handleCitiesSearch = (input: string): void => {
    setSearchCities(input);
  };

  return (
    <div className={`searchbar ${className}`}>
      <Input
        prefix={<Icon type="search" />}
        value={state.search || ''}
        onChange={(value) => setState({ search: value })}
        placeholder={t('rapidSearch')}
      />

      <Select
        cancelable
        cancelValue={null}
        label
        dropdownClassName="hideCheck"
        placeholder={t('country')}
        loading={loadingActiveCountries}
        options={activeCountries}
        onSearch={handleCountriesSearch}
        value={state.country ? state.country : null}
        onChange={(value) => setState({ country: value, city: undefined })}
      />

      <Select
        cancelable
        cancelValue={null}
        label
        disabled={!state.country}
        dropdownClassName="hideCheck"
        placeholder={t('city')}
        onSearch={handleCitiesSearch}
        loading={loadingActiveCities}
        value={state.city ? state.city : null}
        options={state.country ? activeCities.filter((city) => city.countryID === state.country) : []}
        onChange={(value) => setState({ city: value })}
      />

      <Select
        cancelable
        cancelValue={null}
        placeholder={t('selectFaculty')}
        value={state.faculty__in ? state.faculty__in : null}
        options={facultiesOptions}
        loading={loadingFaculties}
        onChange={(value) => setState({ faculty__in: value })}
      />

      <Button type="primary" onClick={onSubmit}>
        {t('apply')}
      </Button>
    </div>
  );
};
