import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { Results, Save } from '../index.types';
import { Issue, IssuesCount } from './issues.types';

export const issues = {
  get: {
    action: (params = {}): Promise<{ data: Results<Issue> }> =>
      axios.get(`issues`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (issues.get.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  add: {
    action: (params = {}): Promise<{ data: Save }> =>
      axios.post(`issues`, params, {
        cancelToken: new cancelToken((c: Canceler) => (issues.add.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  update: {
    action: ({
      id,
      ...params
    }: {
      id: number;
      status: 'accepted' | 'declined';
      session: number;
      student?: number;
      details?: any;
    }): Promise<{ data: Issue }> =>
      axios.patch(`issues/${id}`, params, {
        cancelToken: new cancelToken((c: Canceler) => (issues.update.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  count: {
    action: (params = {}): Promise<{ data: IssuesCount }> =>
      axios.get(`counts`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (issues.count.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  delete: {
    action: (id: number): Promise<{ data: Results<Issue> }> =>
      axios.delete(`issues/${id}`, { cancelToken: new cancelToken((c: Canceler) => (issues.count.cancel = c)) }),
    cancel: (() => null) as Canceler,
  },
};
