import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { Question } from './questions.types';

export const questions = {
  get: {
    action: (params = {}): Promise<{ data: Question[] }> =>
      axios.get(`questions`, { params, cancelToken: new cancelToken((c: Canceler) => (questions.get.cancel = c)) }),
    cancel: (() => null) as Canceler,
  },
};
