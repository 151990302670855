import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Link, useRouterHelpers } from 'estafette-router';
import { save, load } from 'react-cookies';
import { useModules } from 'hooks';
import { BreadcrumbsContext, NotificationsContext, UserContext } from 'contexts';
import { Icon, Avatar, Breadcrumbs, Status } from 'ui/atoms';
import { Profile } from 'ui/organisms';

import './Layout.scss';

interface TabProps {
  className?: string;
  iconType?: string;
  invert?: boolean;
  label?: string;
  route?: string;
  disabled?: boolean;
  active?: boolean;
  line?: any;
  count?: number;
  attention?: number;
  onClick?: () => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: { [key: string]: any };
}

const Tab: React.FC<TabProps> = ({
  className = '',
  active,
  iconType,
  invert,
  label,
  route,
  params,
  count,
  attention,
  disabled,
  onClick,
}) => {
  const Component: React.FC<TabProps> = ({ children }) => {
    const classNames = React.useMemo(
      () =>
        `zh-layout-left-bar-item${invert ? ' zh-layout-left-bar-item-invert' : ''}${
          disabled ? ' zh-layout-left-bar-item-disabled' : ''
        }${active ? ' active' : ''} ${className}`,
      [disabled, invert],
    );

    return route ? (
      <Link onClick={onClick} className={classNames} route={route} params={params}>
        {children}
        {count ? <Status label={count} status="active" className="count" /> : null}
      </Link>
    ) : (
      <div className={classNames} onClick={onClick}>
        {children}
        {count ? <Status label={count} status="active" className="count" /> : null}
      </div>
    );
  };

  return (
    <Component>
      <Icon type={iconType} />
      {attention ? <Icon type="info" className="attention" /> : null}

      <span>{label}</span>

      <Icon type="arrow-right" />
    </Component>
  );
};

interface Props {
  showBreadcrumbs?: boolean;
  contentClass?: string;
  className?: string;
  title?: React.ReactNode;
  leftSide?: React.ReactNode;
  rightSide?: React.ReactNode;
}

export const Layout: React.FC<Props> = ({
  showBreadcrumbs = true,
  contentClass = '',
  className = '',
  title,
  leftSide,
  rightSide,
  children,
}) => {
  const { t } = useIntl();
  const { isRouteActive } = useRouterHelpers();
  const { indexModule, modules } = useModules();
  const { userData } = React.useContext(UserContext);
  const { breadcrumbs } = React.useContext(BreadcrumbsContext);
  const { invitations: invitationsCount, issues: issuesCount, requests: requestsCount } = React.useContext(
    NotificationsContext,
  );

  const [toggled, setToggled] = React.useState(`${load('toggled')}` === 'true');
  const [mobileOpened, setMobileOpened] = React.useState(false);

  const onToggle = (): void =>
    setToggled((s) => {
      save('toggled', `${!s}`, { path: '/' });

      return !s;
    });

  const onToggleMobile = (): void => setMobileOpened((s) => !s);

  const userName = React.useMemo(() => [userData.first_name, userData.last_name].filter((i) => i).join(' '), [
    userData,
  ]);

  const onClickGuide = React.useCallback(
    () => window.open('https://www.youtube.com/playlist?list=PLcV0I3HArfdsMuQpxHiHKZ-tgDzYBr6_z'),
    [],
  );

  return (
    <div
      className={`zh-layout zh-layout-mobile-${mobileOpened ? 'opened' : 'closed'} zh-layout-state-${
        toggled ? 'toggled' : 'untoggled'
      } ${className}`}
    >
      <div className="zh-layout-top-bar">
        {/* mobile part */}
        <div className="zh-layout-top-bar-mobile">
          <div className="zh-layout-top-bar-mobile-toggler" onClick={onToggleMobile}>
            <Icon type={mobileOpened ? 'close' : 'menu'} />
          </div>

          <div className="zh-layout-top-bar-mobile-logo" />
        </div>
        {/* mobile part */}

        {title && <div className="zh-layout-top-bar-title">{title}</div>}

        {leftSide && <div className="zh-layout-top-bar-left">{leftSide}</div>}

        <div className="zh-layout-top-bar-right">
          {rightSide}

          <div className="zh-layout-top-bar-right-item">
            {/*<Icon type="chat" disabled />*/}

            {/*<Notifications /> todo hide notifications*/}

            <Profile>
              <div className="zh-profile-avatar">
                {userName || userData.id ? (
                  <div className="zh-profile-avatar-left">
                    {userName && <div className="zh-profile-avatar-left-name">{userName}</div>}

                    {userData.id && <div className="zh-profile-avatar-left-code">#{userData.id}</div>}
                  </div>
                ) : null}

                <div className="zh-profile-avatar-right">
                  <Avatar alt={userName} img={userData.profile_picture} />

                  <Icon type="arrow-up-filled" />
                </div>
              </div>
            </Profile>
          </div>
        </div>
      </div>

      <div className="zh-layout-left-bar">
        <div className="zh-layout-left-bar-top">
          {indexModule ? (
            <Link route={indexModule.route} params={indexModule.params}>
              <div className="zh-layout-left-bar-logo" />
            </Link>
          ) : (
            <div className="zh-layout-left-bar-logo" />
          )}

          <Tab
            className="zh-layout-left-bar-toggler"
            invert
            iconType="menu-fold"
            label={t('hideTitles')}
            onClick={onToggle}
          />

          {[...modules].map(($module) => (
            <Tab
              key={$module.iconType}
              iconType={$module.iconType}
              label={$module.label}
              route={$module.route}
              params={$module.params}
              active={$module.active}
              count={
                ($module.route === 'ApplicationsPage' ? issuesCount : undefined) ||
                ($module.route === 'PlannedSessionsPage' ? requestsCount : undefined)
              }
              attention={$module.route === 'SingleCurrentSessionsPage' ? invitationsCount : undefined}
            />
          ))}
        </div>

        <div className="zh-layout-left-bar-bottom">
          <Profile placement="rightBottom">
            <Tab
              className="zh-user-settings"
              invert
              iconType="user"
              label={t('accountSettings')}
              active={isRouteActive(['PersonalAccountPage', 'EditPersonalAccountPage'])}
            />
          </Profile>

          <Tab invert iconType="question" label={t('userGuide')} onClick={onClickGuide} />
        </div>
      </div>

      {/* mobile part */}
      {mobileOpened && <div className="zh-layout-mobile-mask" onClick={onToggleMobile} />}
      {/* mobile part */}

      <div className="zh-layout-content-wrapper">
        <div className={`zh-layout-content ${contentClass}`}>
          {showBreadcrumbs && <Breadcrumbs data={breadcrumbs} />}

          {children}
        </div>
      </div>

      <div className="zh-layout-footer">
        <span>
          Designed by <b>EBS Integrator</b>
        </span>
      </div>
    </div>
  );
};
