import * as React from 'react';
import { Public } from 'features/public/organisms';
import { Content } from 'features/public/atoms';

import { TermsOfUse } from '../templates';

export const TermsOfUsePage: React.FC = () => {
  return (
    <Public>
      <Content className="container">
        <TermsOfUse />
      </Content>
    </Public>
  );
};
