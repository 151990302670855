import * as React from 'react';
import { stringify, parse } from 'query-string';
import { useLocation } from 'estafette-router';
import { history } from 'libs/history';

export const useQueryParams = (): { [key: string]: any } => {
  const { search } = useLocation();
  return React.useMemo(() => parse(search, { arrayFormat: 'comma' }), [search]);
};

export const useQueryUpdate = (): { [key: string]: any } => {
  const { push, location } = history;

  const updateQuery = React.useCallback(
    (filters) =>
      push(
        `${location.pathname}?${stringify(filters, {
          skipEmptyString: true,
          skipNull: true,
          arrayFormat: 'comma',
        })}`,
      ),
    [push, location.pathname],
  );

  return {
    updateQuery,
  };
};
