import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useParams } from 'estafette-router';
import { announces } from 'libs/http/api';
import { Announcement } from 'libs/http/api/announces/announces.types';
import { Head } from 'ui/organisms';
import { Card, Loader, AvatarCard, Calendar } from 'ui/atoms';

import { AnnouncementsLayout } from '../organisms';

export const AnnouncementsDetailsPage: React.FC = () => {
  const { request, data, loading } = useRequest<Announcement>({ data: {} });

  const { t } = useIntl();
  const { id } = useParams();

  React.useEffect(() => {
    request(announces.load.action({ id }));

    return () => {
      announces.load.cancel();
    };
  }, []);

  return (
    <>
      <Head title={data.subject || 'N/A'} />

      <AnnouncementsLayout title={data.subject || 'N/A'}>
        <Loader loading={loading}>
          <Card>
            <div className="flex-data">
              <div className="flex-item">
                <div>
                  <Calendar date={data.created_at} type="date" />
                </div>

                <div className="flex-item-row">
                  {data.institutions && data.institutions.length ? (
                    <div className="flex-item-row-col column">
                      <div>
                        <label>{t('institutions')}</label>
                        {data.institutions.map((institution) => institution.name).join(', ')}
                      </div>
                    </div>
                  ) : null}
                  <div className="flex-item-row-col">
                    {data.faculties && data.faculties.length ? (
                      <div>
                        <label>{t('faculties')}</label>
                        {data.faculties.map((faculty) => faculty.name).join(', ')}
                      </div>
                    ) : null}
                    <div className="zh-announcement-items">
                      <div className="zh-announcement-right">
                        <label>{t('users')}</label>
                        {data.users && data.users.length ? (
                          <div className="zh-announcement-item-addressed">
                            <div className="zh-announcement-item-addressed-items">
                              {data.users.map(
                                ({
                                  id,
                                  profile_picture: profilePicture,
                                  first_name: firstName,
                                  last_name: lastName,
                                }) => (
                                  <AvatarCard
                                    key={id}
                                    img={profilePicture}
                                    alt={[firstName, lastName].filter((i) => i).join(' ')}
                                  />
                                ),
                              )}
                            </div>
                          </div>
                        ) : (
                          '---'
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-item-row-col">
                    {data.countries && data.countries.length ? (
                      <div>
                        <label>{t('countries')}</label>
                        {data.countries.map((country) => country.title).join(', ')}
                      </div>
                    ) : null}
                    {data.language ? (
                      <div>
                        <label>{t('language')}</label>
                        {data.language.name}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="flex-item">
                <div>
                  <h3>{t('detailsOfAnnouncement')}</h3>
                  <div className="mt-20 mb-20" dangerouslySetInnerHTML={{ __html: data.description || '---' }} />
                </div>
              </div>
            </div>
          </Card>
        </Loader>
      </AnnouncementsLayout>
    </>
  );
};
