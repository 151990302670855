import * as React from 'react';
import { Link, useHistory, useRouteMatch } from 'estafette-router';
import { BreadcrumbsContext } from 'contexts';
import { LoaderSpinner, Icon } from 'ui/atoms';

import './Breadcrumbs.scss';

export interface Breadcrumb {
  href?: string;
  route?: string;
  params?: { [key: string]: any };
  label?: string | JSX.Element;
  disabled?: boolean;
  loading?: boolean;
}

interface Props {
  className?: string;
  data: Breadcrumb[];
}

export const Breadcrumbs: React.FC<Props> = ({ className = '', data: $data }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { setRoute } = React.useContext(BreadcrumbsContext);

  const data = React.useMemo(() => $data.filter((i) => Object.keys(i).length).map((item, key) => ({ ...item, key })), [
    $data,
  ]);

  React.useEffect(() => {
    setRoute(match);

    history.listen(() => setRoute(match));
  }, []);

  return (
    <div className={`zh-breadcrumbs-wrapper ${className}`}>
      <ul className="zh-breadcrumbs">
        {data.map(({ key, href, route, params, label, disabled, loading }) => (
          <li key={key} className="zh-breadcrumb-item">
            {loading ? (
              <span className="zh-breadcrumbs-item-loading">
                <LoaderSpinner size="small" />
                Loading ...
              </span>
            ) : !disabled ? (
              route || href ? (
                <Link
                  {...(href ? { to: href } : { route, params })}
                  route={route}
                  params={params}
                  className="zh-breadcrumbs-item-link"
                >
                  {label}
                </Link>
              ) : (
                <span className="zh-breadcrumbs-item-regular">{label}</span>
              )
            ) : (
              <span className="zh-breadcrumbs-item-disabled">{label}</span>
            )}

            {data[key + 1] ? (
              <span className="zh-breadcrumbs-devider">
                <Icon type="arrow-right" />
              </span>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
};
