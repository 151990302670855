import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useParams, useRouterHelpers } from 'estafette-router';
import { Layout } from 'ui/organisms';
import { Button, Icon, Tabs } from 'ui/atoms';

interface Props {
  onOpenUpload?: () => void;
  showTabs?: boolean;
}

export const SessionLayout: React.FC<Props> = ({ onOpenUpload, showTabs = true, children }) => {
  const { t } = useIntl();
  const { id } = useParams();
  const { isRouteActive } = useRouterHelpers();

  const tabsData = [
    {
      route: 'GeneralSessionPage',
      params: { id },
      label: t('generalInformation'),
      active: isRouteActive(['GeneralSessionPage']),
    },
    {
      route: 'StudentsSessionPage',
      params: { id },
      label: t('students'),
      active: isRouteActive(['StudentsSessionPage']),
    },
    {
      route: 'AnnouncementsSessionPage',
      params: { id },
      label: t('announcements'),
      active: isRouteActive(['AnnouncementsSessionPage']),
    },
    {
      route: 'ResourcesSessionPage',
      params: { id },
      label: t('resources'),
      active: isRouteActive(['ResourcesSessionPage']),
    },
  ];

  return (
    <Layout
      title={t('labelSession')}
      rightSide={
        onOpenUpload && (
          <Button type="invert" prefix={<Icon type="plus" />} className="nowrap" onClick={onOpenUpload}>
            {t('addFile')}
          </Button>
        )
      }
    >
      {showTabs && <Tabs data={tabsData} className="mb-0" />}

      {children}
    </Layout>
  );
};
