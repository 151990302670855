import * as React from 'react';
import { useRequest } from 'estafette';
import { UserContext } from 'contexts';
import { issues, sessions } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { Invitation } from 'libs/http/api/events/events.types';
import { IssuesCount } from 'libs/http/api/issues/issues.types';
import { RequestsCount } from 'libs/http/api/sessions/sessions.types';

interface Props {
  readonly invitations: number;
  readonly issues: number;
  readonly requests: number;
  readonly sessionRequests: number;
  readonly institutionWithoutPlanCount: number;

  readonly fetchedSessionRequestsCounts: boolean;

  readonly onFetchSessionRequestsCounts: () => void;
  readonly onFetchIssuesCounts: () => void;
  readonly onFetchInvitationsCounts: () => void;
}

export const NotificationsContext = React.createContext<Props>({
  invitations: 0,
  issues: 0,
  requests: 0,
  sessionRequests: 0,
  institutionWithoutPlanCount: 0,

  fetchedSessionRequestsCounts: false,

  onFetchSessionRequestsCounts: (): null => null,
  onFetchIssuesCounts: (): null => null,
  onFetchInvitationsCounts: (): null => null,
});

export const NotificationsProvider = ({ children }: { children: React.ReactElement }): React.ReactElement => {
  const { userData } = React.useContext(UserContext);
  const { request: requestCount, data: dataCount } = useRequest<IssuesCount>({ data: {} });
  const { request: requestInvitations, data: dataInvitations } = useRequest<Results<Invitation>>({ data: {} });
  const { request: requestSessionRequestsCounts, data: dataSessionRequestsCount } = useRequest<RequestsCount>({
    data: {},
  });

  const [fetchedSessionRequestsCounts, setFetchedSessionRequestsCounts] = React.useState(false);

  React.useEffect(() => {
    return () => {
      issues.count.cancel();
      sessions.invitations.cancel();
      sessions.requestsCount.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (['admin', 'professor'].includes(userData.role)) {
      onFetchIssuesCounts();
    }

    if (userData.role === 'professor') {
      onFetchInvitationsCounts();
    }
  }, [userData.role]);

  const onFetchSessionRequestsCounts = async (): Promise<void> => {
    await requestSessionRequestsCounts(sessions.requestsCount.action());

    setFetchedSessionRequestsCounts(true);
  };

  const onFetchIssuesCounts = (): Promise<IssuesCount> => requestCount(issues.count.action());
  const onFetchInvitationsCounts = (): Promise<Results<Invitation>> =>
    requestInvitations(sessions.invitations.action({ page: 1, per_page: 1 }));

  return (
    <NotificationsContext.Provider
      value={{
        invitations: dataInvitations.count || 0,
        issues: dataCount.issue_count || 0,
        requests: dataCount.request_count || 0,
        institutionWithoutPlanCount: dataCount.planned_institutions_count || 0,
        sessionRequests: dataSessionRequestsCount.request_count || 0,

        fetchedSessionRequestsCounts,

        onFetchSessionRequestsCounts,
        onFetchIssuesCounts,
        onFetchInvitationsCounts,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
