import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useRequest } from 'estafette';
import { UserContext } from 'contexts';
import { useFilters } from 'hooks';
import { resources } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { DataTypes, Resource } from 'libs/http/api/resources/resources.types';
import { dateTimeFormat } from 'libs/date';
import { perPage } from 'libs/storage';
import { Actions } from 'ui/organisms';
import { CardFooter, Button, Table, Info, Icon, Loader, Time } from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';

interface Props {
  opened: boolean;
  resourceType: DataTypes;
  onEdit: (params?: { id?: number; type?: number }) => void;
  onDelete: (id: number) => void;
  loadingDelete: boolean;
  search?: string;
  ordering?: string;
}

export const ResourceDetails: React.FC<Props> = ({
  onEdit,
  opened,
  resourceType,
  onDelete,
  loadingDelete,
  search,
  ordering,
}) => {
  const { locale, t } = useIntl();
  const { userData } = React.useContext(UserContext);
  const { request, data, loading } = useRequest<Results<Resource>>({ loading: true, data: { results: [] } });
  const initialFilters = React.useRef({
    page: 1,
    per_page: perPage,
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    return () => {
      resources.get.cancel();
    };
  }, []);

  React.useEffect(() => {
    onFetch();
  }, [opened, resourceType, filters, search, ordering]);

  const onFetch = (): void => {
    if (opened && resourceType.name) {
      request(
        resources.get.action({
          type: resourceType.name,
          search,
          ordering,
          page: filters.page,
          per_page: 5,
        }),
      );
    }
  };

  const onDeleteResource = async (id: number): Promise<void> => {
    await onDelete(id);
    onFetch();
  };

  const onRefetch = (): void => setFilters({ page: 1 });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ page: prevState.page - 1 }));

  const columns = React.useMemo(
    (): Column<Resource>[] => [
      {
        title: t('name'),
        render: (record): React.ReactNode => {
          const index = `name_${locale}` as 'name_en' | 'name_ro' | 'name_ru';

          return <Info primary label={record[index] || '---'} icon={<Icon type="file" />} />;
        },
      },
      {
        title: t('language'),
        render: ({ language }) => language?.name || '---',
      },
      {
        title: t('description'),
        render: ({ description }) => description,
      },
      {
        title: t('addingDate'),
        render: ({ created_at: date }) => <Time date={date} format={dateTimeFormat} className="nowrap" />,
      },
      {
        title: '',
        width: 123,
        render: ({ id, type, file }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <a href={file} target="_blank" rel="noopener noreferrer">
              <Button className="zh-send-message-button primary" prefix={<Icon type="download" />}>
                {t('unload')}
              </Button>
            </a>

            {['student', 'professor'].indexOf(userData.role) < 0 && (
              <Actions
                onEdit={() => onEdit({ id, type: type.id })}
                loadingDelete={loadingDelete}
                onDelete={() => onDeleteResource(id)}
              />
            )}
          </div>
        ),
      },
    ],
    [t, userData.role],
  );

  return (
    <>
      <Loader loading={loading} height={200}>
        <Table size="small" page={1} data={data.results} columns={columns} />

        <CardFooter
          onRefresh={onRefetch}
          onRefreshDisabled={filters.page === 1}
          page={filters.page}
          pages={data.total_pages}
          perPage={filters.per_page}
          onChangePerPage={onChangePerPage}
          rightSide={
            <>
              <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                {t('previous')}
              </Button>

              <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                {t('next')}
              </Button>
            </>
          }
        />
      </Loader>
    </>
  );
};
