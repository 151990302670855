import * as React from 'react';
import { useParams } from 'estafette-router';
import { Head } from 'ui/organisms';
import { AnnouncementsTemplate } from 'features/announcements/templates';

import { InstituteLayout } from '../organisms';

export const AnnouncementsInstitutePage: React.FC = () => {
  const { id } = useParams();

  return (
    <>
      <Head t="announcements" />

      <InstituteLayout>
        <AnnouncementsTemplate id={id} provider="institute" />
      </InstituteLayout>
    </>
  );
};
