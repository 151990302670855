export const common = {
  home: {
    en: 'Home',
    ro: 'Acasă',
    ru: 'Главная',
    es: 'Página principal',
  },
  login: {
    en: 'Log In',
    ro: 'Intră',
    ru: 'Войти',
    es: 'Inician sesión',
  },
  signup: {
    en: 'Sign Up',
    ro: 'Înregistrare',
    ru: 'Регистрация',
    es: 'Registrarse',
  },
  signin: {
    en: 'Sign In',
    ro: 'Autentificare',
    ru: 'Авторизация',
    es: 'Ingreso',
  },
  registerSession: {
    en: 'Registration to the session',
    ro: 'Înscriere la sesiune',
    ru: 'Записаться на сессию',
    es: 'Registrarse en la sesión',
  },
  registration: {
    en: 'Sign Up',
    ro: 'Înscriere',
    ru: 'Записаться',
    es: 'Registrarse',
  },
  pleaseFillForm: {
    en: 'Please fill in the form below',
    ro: 'Te rugăm să completezi formularul de mai jos',
    ru: 'Пожалуйста, заполните форму',
    es: 'Por favor llene el formulario',
  },
  mustAccepted: {
    en: 'The agreement must be accepted',
    ro: 'Acordul trebuie acceptat',
    ru: 'Необходимо принять соглашение',
    es: 'Debe ser aceptado',
  },
  wantRegisterLocalInstitute: {
    en: 'I want to register for a session at the local institute in the city listed above.',
    ro: 'Doresc să mă înregistrez la o sesiune de la institutul local din orașul indicat mai sus.',
    ru: 'Я хочу записаться на сессию в местный институт в городе, указанном выше.',
    es: 'Quiero registrarme en la sesión',
  },
  chooseCountryCityTeacher: {
    en: 'To find an institute please choose the country, city and teacher',
    ro: 'Pentru a găsi institutul, vă rugăm să alegeți țara, orașul și/sau profesor',
    ru: 'Чтобы найти институт, выберите страну, город и преподавателя',
    es: 'Para encontrar un instituto, por favor escoja el país, ciudad y maestro',
  },
  forgotPassword: {
    en: 'Forgot your password?',
    ro: 'Ai uitat parola?',
    ru: 'Забыли пароль?',
    es: '¿Olvidó su contraseña?',
  },
  keepLogged: {
    en: 'Keep me authenticated',
    ro: 'Păstrează-mă autentificat',
    ru: 'Не выходить из системы',
    es: 'mantenerme registrado',
  },
  loginDetails: {
    en: 'Enter login details',
    ro: 'Introduceți datele de autentificare',
    ru: 'Введите данные для входа',
    es: 'Ingrese los detalles de inicio de sesión',
  },
  recovery: {
    en: 'Recovery',
    ro: 'Recuperare',
    ru: 'Восстановление пароля',
    es: 'Recuperar',
  },
  email: {
    en: 'Email',
    ro: 'Email',
    ru: 'Эл. почта',
    es: 'Correo electrónico',
  },
  password: {
    en: 'Password',
    ro: 'Parola',
    ru: 'Пароль',
    es: 'Contraseña',
  },
  confirm_password: {
    en: 'Confirm the password',
    ro: 'Confirmați parola',
    ru: 'Подтвердите пароль',
    es: 'Confirme su contraseña',
  },
  personalData: {
    en: 'Personal data',
    ro: 'Date personale',
    ru: 'Личные данные',
    es: 'Información personal',
  },
  name: {
    en: 'Name',
    ro: 'Denumirea',
    ru: 'Название',
    es: 'Nombre',
  },
  firstName: {
    en: 'First Name',
    ro: 'Nume',
    ru: 'Имя',
    es: 'Nombre',
  },
  lastName: {
    en: 'Last Name',
    ro: 'Prenume',
    ru: 'Фамилия',
    es: 'Apellido',
  },
  sex: {
    en: 'Sex',
    ro: 'Sex',
    ru: 'Пол',
    es: 'Sexo',
  },
  contactData: {
    en: 'Contact data',
    ro: 'Date de contact',
    ru: 'Контактные данные',
    es: 'Información de contacto',
  },
  otherContacts: {
    en: 'Other contacts',
    ro: 'Alte contacte',
    ru: 'Другие контакты',
    es: 'Otros contactos',
  },
  contacts: {
    en: 'Contacts',
    ro: 'Contacte',
    ru: 'Контакты',
    es: 'Contactos',
  },
  selectInstitute: {
    en: 'Select the institute',
    ro: 'Alegeți institutul',
    ru: 'Выберите институт',
    es: 'Elija el instituto',
  },
  selectStudents: {
    en: 'Select the students',
    ro: 'Selectați studenții',
    ru: 'Выберите студентов',
    es: 'Elija los estudiantes',
  },
  continue: {
    en: 'Continue',
    ro: 'Continuă',
    ru: 'Продолжить',
    es: 'Continúe',
  },
  country: {
    en: 'Country',
    ro: 'Țara',
    ru: 'Страна',
    es: 'País',
  },
  countries: {
    en: 'Countries',
    ro: 'Țări',
    ru: 'Страны',
    es: 'Países',
  },
  city: {
    en: 'City',
    ro: 'Oraș',
    ru: 'Город',
    es: 'Ciudad',
  },
  female: {
    en: 'Female',
    ro: 'Femeie',
    ru: 'Женский',
    es: 'Mujer',
  },
  male: {
    en: 'Male',
    ro: 'Bărbat',
    ru: 'Мужской',
    es: 'Hombre',
  },
  phone: {
    en: 'Phone',
    ro: 'Telefon',
    ru: 'Телефон',
    es: 'Teléfono',
  },
  email_phone: {
    en: 'E-mail/Phone',
    ro: 'E-mail/Telefon',
    ru: 'E-mail/Телефон',
    es: 'Correo electrónico/teléfono',
  },
  cancel: {
    en: 'Cancel',
    ro: 'Anulare',
    ru: 'Отменить',
    es: 'Cancelar',
  },
  refuse: {
    en: 'Refuse',
    ro: 'Refuză',
    ru: 'Отказаться',
    es: 'Cechazar',
  },
  subscribe: {
    en: 'Subscribe to our newsletter',
    ro: 'Aboneaza-te la buletinul nostru informativ',
    ru: 'Подписаться на рассылку новостей',
    es: 'Suscribirse a nuestro boletín informativo',
  },
  agree: {
    en: 'I agree with the',
    ro: 'Sunt de acord cu',
    ru: 'Я согласен с',
    es: 'Estoy de acuerdo con',
  },
  and: {
    en: 'and',
    ro: 'și',
    ru: 'и',
    es: 'y',
  },
  apply: {
    en: 'Apply',
    ro: 'Aplică',
    ru: 'Применить',
    es: 'Aplicar',
  },
  notSelected: {
    en: 'Not selected',
    ro: 'Nu ați selectat',
    ru: 'Не выбран',
    es: 'No seleccionado',
  },
  selectProfessor: {
    en: 'Select teacher',
    ro: 'Alegeți profesorul',
    ru: 'Выберите преподавателя',
    es: 'Elegir maestro',
  },
  rapidSearch: {
    en: 'Quick search',
    ro: 'Căutare rapidă',
    ru: 'Быстрый поиск',
    es: 'Búsqueda rápida',
  },
  search: {
    en: 'Search',
    ro: 'Căutare',
    ru: 'Поиск',
    es: 'Búsqueda',
  },
  period: {
    en: 'Period',
    ro: 'Perioada',
    ru: 'Период',
    es: 'Período',
  },
  sessionsList: {
    en: 'List of sessions',
    ro: 'Lista sesiunilor',
    ru: 'Список сессий',
    es: 'Lista de sesiones',
  },
  course: {
    en: 'Course',
    ro: 'Curs',
    ru: 'Курс',
    es: 'Curso',
  },
  courses: {
    en: 'Courses',
    ro: 'Cursuri',
    ru: 'Курсы',
    es: 'Cursos',
  },
  group: {
    en: 'Nr. Group',
    ro: 'Nr. grupei',
    ru: '№ группы',
    es: 'Grupo',
  },
  groupName: {
    en: 'Group',
    ro: 'Grupul',
    ru: 'Группа',
    es: 'Nombre del grupo',
  },
  startAt: {
    en: 'Started at',
    ro: 'Început la',
    ru: 'Начат',
    es: 'Iniciado en',
  },
  finishAt: {
    en: 'Ended at',
    ro: 'Încheiat la',
    ru: 'Завершен',
    es: 'Terminado en',
  },
  sessions: {
    en: 'Sessions',
    ro: 'Sesiuni',
    ru: 'Сессии',
    es: 'Sesiones',
  },
  register: {
    en: 'Register now',
    ro: 'Înregistrează-te',
    ru: 'Зарегистрируйтесь сейчас',
    es: 'Regístrate ahora',
  },
  register2: {
    en: 'Gradebook',
    ro: 'Registru',
    ru: 'Журнал',
    es: 'Libro de calificaciones',
  },
  noAccount: {
    en: "Don't have an account?",
    ro: 'Nu ai un cont?',
    ru: 'Нет учетной записи?',
    es: '¿No tienes una cuenta?',
  },
  haveLogin: {
    en: 'Log in',
    ro: 'Autentifică-te',
    ru: 'Войти',
    es: 'Ingresar',
  },
  haveAccount: {
    en: 'Are you registered?',
    ro: 'Ești înregistrat?',
    ru: 'Вы зарегистрированы?',
    es: '¿Estás registrado?',
  },
  wrongCredentials: {
    en: 'Wrong credentials, try again',
    ro: 'Date de logare greșite, încercați din nou',
    ru: 'Неверные учетные данные. Попробуйте еще раз.',
    es: 'Credenciales incorrectas, vuelve a intentar',
  },
  blanks: {
    en: 'This group of fields is required',
    ro: 'Acest grupuri sunt obligatorii',
    ru: 'Эта группа полей обязательна к заполнению',
    es: 'Este grupo de espacios son obligatorios',
  },
  previous: {
    en: 'Previous',
    ro: 'Înapoi',
    ru: 'Назад',
    es: 'Anterior',
  },
  next: {
    en: 'Next',
    ro: 'Următor',
    ru: 'Вперед',
    es: 'Siguiente',
  },
  announcements: {
    en: 'Announcements',
    ro: 'Anunțuri',
    ru: 'Объявления',
    es: 'Anuncios',
  },
  announcement: {
    en: 'Announcement',
    ro: 'Anunț',
    ru: 'Объявление',
    es: 'Anuncio',
  },
  searchByKeyword: {
    en: 'Search by keyword',
    ro: 'Caută după cuvântul cheie',
    ru: 'Поиск по ключевому слову',
    es: 'Búsqueda por palabra clave',
  },
  calendarOfEvents: {
    en: 'Calendar of events',
    ro: 'Calendarul evenimentelor',
    ru: 'Календарь мероприятий',
    es: 'Calendario de eventos',
  },
  addEvent: {
    en: 'Add event',
    ro: 'Adaugă eveniment',
    ru: 'Добавить мероприятие',
    es: 'Añadir evento',
  },
  typeOfEvent: {
    en: 'Event type',
    ro: 'Tipul evenimentului',
    ru: 'Тип мероприятия',
    es: 'Tipo de evento',
  },
  type: {
    en: 'Type',
    ro: 'Tipul',
    ru: 'Тип',
    es: 'Tipo',
  },
  selectTypeOfEvent: {
    en: 'Select the event type',
    ro: 'Selectaţi tip evenimentului',
    ru: 'Выберите тип мероприятия',
    es: 'Elija el tipo de evento',
  },
  nameOfEvent: {
    en: 'Event name',
    ro: 'Denumirea evenimentului',
    ru: 'Название мероприятия',
    es: 'Nombre del evento',
  },
  nameOfSession: {
    en: 'Session name',
    ro: 'Denumirea sesiunii',
    ru: 'Название сессии',
    es: 'Nombre de la sesión',
  },
  nameOfSessionCourse: {
    en: 'Session name (course)',
    ro: 'Denumirea sesiunii (cursului)',
    ru: 'Название сессии (курс)',
    es: 'Nombre de la sesión (curso)',
  },
  nameOfFaculty: {
    en: 'Faculty name',
    ro: 'Denumirea facultății',
    ru: 'Название факультета',
    es: 'Nombre de los maestros',
  },
  langOfInstruction: {
    en: 'The language of instruction',
    ro: 'Limba predării',
    ru: 'Язык обучения',
    es: 'El idioma de enseñanza',
  },
  selectLanguage: {
    en: 'Select the language',
    ro: 'Alegeți limba',
    ru: 'Выберите язык',
    es: 'Elija el idioma',
  },
  selectLanguage2: {
    en: 'Select language',
    ro: 'Alege limba',
    ru: 'Выбрать язык',
    es: 'Elija el idioma',
  },
  dateTime: {
    en: 'Date and time',
    ro: 'Data și ora',
    ru: 'Дата и время',
    es: 'Fecha y hora',
  },
  startDateTime: {
    en: 'Start date and time',
    ro: 'Data și ora începerii',
    ru: 'Дата и время начала',
    es: 'Fecha y hora de inicio',
  },
  startDate: {
    en: 'Start date',
    ro: 'Data începerii',
    ru: 'Дата начала',
    es: 'Fecha de inicio',
  },
  startTime: {
    en: 'Start time',
    ro: 'Ora începerii',
    ru: 'Время начала',
    es: 'Hora de inicio',
  },
  day: {
    en: 'Day',
    ro: 'Ziua',
    ru: 'День',
    es: 'Día',
  },
  month: {
    en: 'Month',
    ro: 'Luna',
    ru: 'Месяц',
    es: 'Mes',
  },
  year: {
    en: 'Year',
    ro: 'Anul',
    ru: 'Год',
    es: 'Año',
  },
  hours: {
    en: 'Hours',
    ro: 'Ore',
    ru: 'Часы',
    es: 'Horas',
  },
  minute: {
    en: 'Minutes',
    ro: 'Minute',
    ru: 'Минуты',
    es: 'Minutos',
  },
  endDateTime: {
    en: 'End date and time',
    ro: 'Data și ora încheierii',
    ru: 'Дата и время окончания',
    es: 'Fecha y hora de terminación',
  },
  endDate: {
    en: 'End date',
    ro: 'Data încheierii',
    ru: 'Дата окончания',
    es: 'Fecha de terminación',
  },
  endTime: {
    en: 'End time',
    ro: 'Ora încheierii',
    ru: 'Время окончания',
    es: 'Hora de terminación',
  },
  venue: {
    en: 'Place',
    ro: 'Locul desfășurării',
    ru: 'Место проведения',
    es: 'Iugar',
  },
  selectCountry: {
    en: 'Select the country',
    ro: 'Alegeți țara',
    ru: 'Выберите страну',
    es: 'Elija el país',
  },
  selectCountries: {
    en: 'Select the countries',
    ro: 'Alegeți țările',
    ru: 'Выберите страны',
    es: 'Elija los países',
  },
  selectCity: {
    en: 'Select city',
    ro: 'Alegeți oraș',
    ru: 'Выберите город',
    es: 'Elija la ciudad',
  },
  fullAddress: {
    en: 'Full address (Street, house number, apartment number, postal code)',
    ro: 'Adresa completă',
    ru: 'Полный адрес (улица, № дома, № квартиры, почтовый индекс)',
    es: 'Dirección completa',
  },
  address: {
    en: 'Full address',
    ro: 'Adresa completă',
    ru: 'Полный адрес',
    es: 'Dirección completa',
  },
  short_address: {
    en: 'Adress',
    ro: 'Adresa',
    ru: 'Адрес',
    es: 'Dirección',
  },
  studiedManual: {
    en: 'The textbook to be studied',
    ro: 'Manualul ce va fi studiat',
    ru: 'Учебник, который будет изучаться',
    es: 'Libro a ser estudiado',
  },
  teacher: {
    en: 'Teacher',
    ro: 'Profesor',
    ru: 'Преподаватель',
    es: 'Maestro',
  },
  teachers: {
    en: 'Teachers',
    ro: 'Profesori',
    ru: 'Преподаватели',
    es: 'Maestros',
  },
  selectTeacher: {
    en: 'Select teacher',
    ro: 'Selectati profesorul',
    ru: 'Выберите преподавателя',
    es: 'Elija el maestro',
  },
  organizer: {
    en: 'Organizer',
    ro: 'Organizatorul',
    ru: 'Организатор',
    es: 'Organizador',
  },
  selectOrganizer: {
    en: 'Select the organizer',
    ro: 'Selectati organizatorul',
    ru: 'Выберите организатора',
    es: 'Elija el organizador',
  },
  socialsNetworks: {
    en: 'Social networks',
    ro: 'Rețelele de socializare',
    ru: 'Социальные сети',
    es: 'Medios sociales',
  },
  othersInformation: {
    en: 'Other informations',
    ro: 'Alte informații',
    ru: 'Дополнительная информация',
    es: 'Otra información',
  },
  otherInformation: {
    en: 'Other information',
    ro: 'Altă informaţie',
    ru: 'Укажите дополнительную информацию',
    es: 'Otra información',
  },
  info: {
    en: 'Info',
    ro: 'Informaţii',
    ru: 'Информация',
    es: 'Información',
  },
  confirm: {
    en: 'Confirm',
    ro: 'Confirmă',
    ru: 'Подтвердить',
    es: 'Confirmación',
  },
  currentInvites: {
    en: 'invites',
    ro: 'invitații',
    ru: 'приглашений',
    es: 'Invitados',
  },
  invites: {
    en: 'Invites',
    ro: 'Invitații',
    ru: 'Приглашения',
    es: 'Invitados',
  },
  invite: {
    en: 'Invite',
    ro: 'Invitație',
    ru: 'Приглашение',
    es: 'Invitar',
  },
  inviteToSession: {
    en: 'Invite to the session',
    ro: 'Invitație la sesiune',
    ru: 'Приглашение на сессию',
    es: 'Invitar a la sesión',
  },
  list: {
    en: 'List',
    ro: 'Listă',
    ru: 'Список',
    es: 'Lista',
  },
  language: {
    en: 'Language',
    ro: 'Limba',
    ru: 'Язык',
    es: 'Idioma',
  },
  location: {
    en: 'Location',
    ro: 'Locație',
    ru: 'Расположение',
    es: 'Ubicación',
  },
  place: {
    en: 'Place',
    ro: 'Locul',
    ru: 'Место',
    es: 'lugar',
  },
  reject: {
    en: 'Reject',
    ro: 'Respinge',
    ru: 'Отклонить',
    es: 'Rechazar',
  },
  accept: {
    en: 'Accept',
    ro: 'Acceptă',
    ru: 'Принять',
    es: 'Aceptar',
  },
  finalConfirmation: {
    en: 'Final confirmation',
    ro: 'Confirmare finală',
    ru: 'Окончательное подтверждение',
    es: 'Confirmación final',
  },
  sendForConfirmation: {
    en: 'Send for confirmation',
    ro: 'Trimite spre confirmare',
    ru: 'Отправить для подтверждения',
    es: 'Enviar confirmación',
  },
  viewAll: {
    en: 'View all',
    ro: 'Vezi toate',
    ru: 'Посмотреть все',
    es: 'Ver todo',
  },
  showAll: {
    en: 'Show all',
    ro: 'Arată toate',
    ru: 'Показать все',
    es: 'Mostrar todo',
  },
  myEvents: {
    en: 'My events',
    ro: 'Evenimentele mele',
    ru: 'Мои мероприятия',
    es: 'Mis eventos',
  },
  sortBy: {
    en: 'Sort by',
    ro: 'Sortare după',
    ru: 'Сортировать по',
    es: 'Ordenar por',
  },
  by: {
    en: 'By',
    ro: 'După',
    ru: 'По',
    es: 'Por',
  },
  detailsOfEvent: {
    en: 'Details about the event',
    ro: 'Detalii despre eveniment',
    ru: 'Подробности о мероприятии',
    es: 'Detalles acerca del evento',
  },
  readMore: {
    en: 'Read more',
    ro: 'Citește mai mult',
    ru: 'Читать далее',
    es: 'Leer más',
  },
  viewMoreDetails: {
    en: 'View more details',
    ro: 'Vezi mai multe detalii',
    ru: 'Посмотреть больше подробностей',
    es: 'Ver más detalles',
  },
  from: {
    en: 'from',
    ro: 'din',
    ru: 'из',
    es: 'de',
  },
  resources: {
    en: 'Resources',
    ro: 'Resurse',
    ru: 'Ресурсы',
    es: 'Recursos',
  },
  addFile: {
    en: 'Add file',
    ro: 'Adaugă fișier',
    ru: 'Добавить файл',
    es: 'Añadir archivo',
  },
  upload: {
    en: 'Upload',
    ro: 'Încarcă',
    ru: 'Загрузить',
    es: 'Subir',
  },
  fileType: {
    en: 'File type',
    ro: 'Tip fișier',
    ru: 'Тип файла',
    es: 'Tipo de archivo',
  },
  resourceType: {
    en: 'Resource type',
    ro: 'Tipul de resursă',
    ru: 'Тип ресурса',
    es: 'Tipo de recurso',
  },
  selectTypeOfFile: {
    en: 'Select type of file',
    ro: 'Alegeți tipul de fișier',
    ru: 'Выберите тип файла',
    es: 'Elija el tipo de archivo',
  },
  selectTypeOfResource: {
    en: 'Select type of resource',
    ro: 'Alegeți tipul de resursă',
    ru: 'Выберите тип ресурса',
    es: 'Elija el tipo de recurso',
  },
  photo: {
    en: 'Photo',
    ro: 'Fotografie',
    ru: 'Фотография',
    es: 'Foto',
  },
  info_short: {
    en: 'Information',
    ro: 'Informații',
    ru: 'Информация',
    es: 'Información',
  },
  description: {
    en: 'Description',
    ro: 'Descriere',
    ru: 'Описание',
    es: 'Descripción',
  },
  attachFile: {
    en: 'Attach the file',
    ro: 'Atașați fișierul',
    ru: 'Прикрепите файл',
    es: 'Adjuntar un archivo',
  },
  searchFile: {
    en: 'Search for a file',
    ro: 'Caută fișier',
    ru: 'Искать файл',
    es: 'Buscar un archivo',
  },
  didacticMaterials: {
    en: 'Teaching materials',
    ro: 'Materiale didactice',
    ru: 'Учебные материалы',
    es: 'Materiales de enseñanza',
  },
  all: {
    en: 'All',
    ro: 'Toate',
    ru: 'Все',
    es: 'Todo',
  },
  formulation: {
    en: 'Forms',
    ro: 'Formulare',
    ru: 'Формы',
    es: 'Formularios',
  },
  manual: {
    en: 'Textbook',
    ro: 'Manual',
    ru: 'Учебник',
    es: 'Libro',
  },
  manuals: {
    en: 'Textbooks',
    ro: 'Manuale',
    ru: 'Учебники',
    es: 'Libros',
  },
  requests: {
    en: 'Requests',
    ro: 'Cereri',
    ru: 'Запросы',
    es: 'Solicitudes',
  },
  guides: {
    en: 'Guides',
    ro: 'Ghiduri',
    ru: 'Руководства',
    es: 'Guías',
  },
  presentations: {
    en: 'Presentations',
    ro: 'Prezentari',
    ru: 'Презентации',
    es: 'Presentaciones',
  },
  other: {
    en: 'Other',
    ro: 'Altele',
    ru: 'Другое',
    es: 'Otros contactos',
  },
  file: {
    en: 'File',
    ro: 'fișier',
    ru: 'файл',
    es: 'Archivo',
  },
  files: {
    en: 'Files',
    ro: 'fișiere',
    ru: 'файлы',
    es: 'Archivos',
  },
  unload: {
    en: 'Download',
    ro: 'Descarcă',
    ru: 'Скачать',
    es: 'Descargar',
  },
  download: {
    en: 'Download',
    ro: 'Descarcă',
    ru: 'Скачать',
    es: 'Descargar',
  },
  addingDate: {
    en: 'Date added',
    ro: 'Data adăugării',
    ru: 'Дата добавления',
    es: 'Fecha añadida',
  },
  mb: {
    en: 'MB',
    ro: 'MB',
    ru: 'мб',
    es: 'MB',
  },
  currentSessions: {
    en: 'Current sessions',
    ro: 'Sesiuni curente',
    ru: 'Текущие сессии',
    es: 'Sesiones actuales',
  },
  institutes: {
    en: 'Institutes',
    ro: 'Institute',
    ru: 'Институты',
    es: 'Institutos',
  },
  institute: {
    en: 'Institute',
    ro: 'Institutul',
    ru: 'Институт',
    es: 'Instituto',
  },
  hideTitles: {
    en: 'Hide titles',
    ro: 'Ascundeți titlurile',
    ru: 'Скрыть заголовки',
    es: 'Ocultar títulos',
  },
  hideAll: {
    en: 'Hide all',
    ro: 'Ascunde toate',
    ru: 'Скрыть все',
    es: 'Ocultar todo',
  },
  accountSettings: {
    en: 'Account settings',
    ro: 'Setările contului',
    ru: 'Настройки учетной записи',
    es: 'Configuración de la cuenta',
  },
  userGuide: {
    en: 'User guide',
    ro: 'Ghid de utilizare',
    ru: 'Руководство по использованию',
    es: 'Guía del usuario',
  },
  users: {
    en: 'Users',
    ro: 'Utilizatori',
    ru: 'Пользователи',
    es: 'Usuarios',
  },
  accountManagement: {
    en: 'Account management',
    ro: 'Gestionarea contului',
    ru: 'Управление аккаунтом',
    es: 'Manejo de la cuenta',
  },
  personalAccount: {
    en: 'Personal account',
    ro: 'Cont Personal',
    ru: 'Личный аккаунт',
    es: 'Cuenta personal',
  },
  settings: {
    en: 'Settings',
    ro: 'Setări',
    ru: 'Настройки',
    es: 'Configuración',
  },
  exit: {
    en: 'Exit',
    ro: 'Ieșire',
    ru: 'Выход',
    es: 'Salir',
  },
  dateAndTime: {
    en: 'Date and time',
    ro: 'Data și ora',
    ru: 'Дата и время',
    es: 'Fecha y hora',
  },
  date: {
    en: 'Date',
    ro: 'Data',
    ru: 'Дата',
    es: 'Fecha',
  },
  author: {
    en: 'Author',
    ro: 'Autor',
    ru: 'Автор',
    es: 'Autor',
  },
  applications: {
    en: 'Requests',
    ro: 'Cereri',
    ru: 'Заявки',
    es: 'Solicitudes',
  },
  application: {
    en: 'Request',
    ro: 'Cerere',
    ru: 'Заявка',
    es: 'Solicitud',
  },
  currentApplications: {
    en: 'requests',
    ro: 'cereri',
    ru: 'заявки',
    es: 'Solicitudes',
  },
  previousAnnouncements: {
    en: 'Previous announcements',
    ro: 'Anunțuri precedente',
    ru: 'Предыдущие объявления',
    es: 'Anuncios previos',
  },
  coordinatorDetails: {
    en: 'Coordinator details',
    ro: 'Detaliile coordonatorului',
    ru: 'Детали координатора',
    es: 'Detalles del coordinador',
  },
  studentDetails: {
    en: 'Student details',
    ro: 'Detaliile studentului',
    ru: 'Детали студента',
    es: 'Detalles del estudiante',
  },
  editCoordinator: {
    en: 'Edit coordinator',
    ro: 'Editare coordonator',
    ru: 'Редактировать координатора',
    es: 'Editar coordinador',
  },
  editStudent: {
    en: 'Edit student',
    ro: 'Editare student',
    ru: 'Редактировать студента',
    es: 'Editar estudiante',
  },
  editInstitute: {
    en: 'Edit institute',
    ro: 'Editează institut',
    ru: 'Редактировать институт',
    es: 'Editar instituto',
  },
  addInstitute: {
    en: 'Add institute',
    ro: 'Adaugă institut',
    ru: 'Добавить институт',
    es: 'Añadir instituto',
  },
  addInstitute2: {
    en: 'Add institute',
    ro: 'Adaugă institutul',
    ru: 'Добавить институт',
    es: 'Añadir instituto',
  },
  addCourse: {
    en: 'Add course',
    ro: 'Adăugă curs',
    ru: 'Добавить курс',
    es: 'Añadir curso',
  },
  addCourse2: {
    en: 'Add course',
    ro: 'Adaugă cursul',
    ru: 'Добавить курс',
    es: 'Añadir curso',
  },
  editCourse: {
    en: 'Edit course',
    ro: 'Editare curs',
    ru: 'Редактировать курс',
    es: 'Editar curso',
  },
  editFaculty: {
    en: 'Edit faculty',
    ro: 'Editare facultate',
    ru: 'Редактировать факультет',
    es: 'Editar facultad',
  },
  addFaculty: {
    en: 'Add faculty',
    ro: 'Adăugare facultate',
    ru: 'Добавить факультет',
    es: 'Añadir facultad',
  },
  addFaculty2: {
    en: 'Add faculty',
    ro: 'Adaugă facultatea',
    ru: 'Добавить факультет',
    es: 'Añadir facultad',
  },
  labelSession: {
    en: 'Session',
    ro: 'Sesiune',
    ru: 'Сессия',
    es: 'Sesión',
  },
  session2: {
    en: 'Session',
    ro: 'Sesiunea',
    ru: 'Сессия',
    es: 'Sesión',
  },
  currentSession: {
    en: 'session',
    ro: 'sesiune',
    ru: 'сессия',
    es: 'Sesión',
  },
  selectSession: {
    en: 'Select session',
    ro: 'Alegeți sesiunea',
    ru: 'Выберите сессию',
    es: 'Elegir sesión',
  },
  numOfRegisteredStudents: {
    en: 'Nr. of registered students',
    ro: 'Nr. de studenți înregistrați',
    ru: 'Кол-во зарегистрированных студентов',
    es: 'Número de estudiantes registrados',
  },
  numOfRegisteredStudentsShort: {
    en: 'Qty.',
    ro: 'Nr.',
    ru: 'Кол.',
    es: 'Cantidad',
  },
  numOfStudents: {
    en: 'Nr. of students',
    ro: 'Nr. de studenți',
    ru: 'Кол-во студентов',
    es: 'Número de estudiantes',
  },
  generalInformation: {
    en: 'General information',
    ro: 'Informație generală',
    ru: 'Общая информация',
    es: 'Información general',
  },
  needToPlan: {
    en: 'Need to plan',
    ro: 'Necesită planificare',
    ru: 'Требуется планирование',
    es: 'Necesita planificar',
  },
  findPerson: {
    en: 'Find a person',
    ro: 'Caută persoană',
    ru: 'Найти человека',
    es: 'Encontrar a una persona',
  },
  findPersonByNameOrPhone: {
    en: 'Find a person by name or phone',
    ro: 'Caută persoană după nume sau telefon',
    ru: 'Найдите человека по имени или телефону',
    es: 'Encontrar a una persona por nombre o teléfono',
  },
  findStudent: {
    en: 'Find a student',
    ro: 'Caută student',
    ru: 'Найти студента',
    es: 'Encontrar a un estudiante',
  },
  findSession: {
    en: 'Find a session',
    ro: 'Caută sesiune',
    ru: 'Найти сессию',
    es: 'Encontrar una sesión',
  },
  nameAndSurname: {
    en: 'Name and surname',
    ro: 'Nume și prenume',
    ru: 'Имя и фамилия',
    es: 'Nombre y apellido',
  },
  age: {
    en: 'Age',
    ro: 'Vârsta',
    ru: 'Возраст',
    es: 'Edad',
  },
  students: {
    en: 'Students',
    ro: 'Studenții',
    ru: 'Студенты',
    es: 'Estudiantes',
  },
  sessionRequests: {
    en: 'Session requests',
    ro: 'Cereri de sesiune',
    ru: 'Заявки на сессию',
    es: 'Solicitudes de sesión',
  },
  sessionRequest: {
    en: 'Session request',
    ro: 'Cerere de sesiune',
    ru: 'Заявка на сессию',
    es: 'Solicitud de sesión',
  },
  plannedSessions: {
    en: 'Planned sessions',
    ro: 'Sesiuni planificate',
    ru: 'Запланированные сессии',
    es: 'Sesiones planeadas',
  },
  sessionsClosed: {
    en: 'Sessions closed',
    ro: 'Sesiuni încheiate',
    ru: 'Завершенные сессии',
    es: 'Sesiones cerradas',
  },
  sessionsCurrent: {
    en: 'Current sessions',
    ro: 'Sesiunile curente',
    ru: 'Текущие сессии',
    es: 'Sesiones actuales',
  },
  addSession: {
    en: 'Add session',
    ro: 'Adaugă sesiune',
    ru: 'Добавить сессию',
    es: 'Añadir sesión',
  },
  editSession: {
    en: 'Edit session',
    ro: 'Editarea sesiunei',
    ru: 'Редактирование сессии',
    es: 'Editar sesión',
  },
  selectCoordinator: {
    en: 'Select coordinator',
    ro: 'Alegeți coordonator',
    ru: 'Выберите координатора',
    es: 'Elegir coordinador',
  },
  selectFaculty: {
    en: 'Select the faculty',
    ro: 'Alegeți facultatea',
    ru: 'Выберите факультет',
    es: 'Elegir la facultad',
  },
  selectManual: {
    en: 'Select the textbook',
    ro: 'Alegeți manualul',
    ru: 'Выберите учебник',
    es: 'Elegir el libro',
  },
  selectCourse: {
    en: 'Select the course',
    ro: 'Alegeți cursul',
    ru: 'Выберите курс',
    es: 'Elegir el curso',
  },
  deploymentPeriod: {
    en: 'Session duration',
    ro: 'Perioada de desfășurare',
    ru: 'Период проведения',
    es: 'Duración de la sesión',
  },
  coordinator: {
    en: 'Coordinator',
    ro: 'Coordonator',
    ru: 'Координатор',
    es: 'Coordinador',
  },
  coordinators: {
    en: 'Coordinators',
    ro: 'Coordonatori',
    ru: 'Координаторы',
    es: 'Coordinadores',
  },
  faculty: {
    en: 'Faculty',
    ro: 'Facultatea',
    ru: 'Факультет',
    es: 'Maestros',
  },
  searchByName: {
    en: 'Search by name',
    ro: 'Caută după nume',
    ru: 'Поиск по имени',
    es: 'Buscar por nombre',
  },
  searchByNameOrEmail: {
    en: 'Search by name or email',
    ro: 'Caută după numele sau email',
    ru: 'Поиск по имени или почте',
    es: 'Buscar por nombre o correo electrónico',
  },
  notes: {
    en: 'Notes',
    ro: 'Notițe',
    ru: 'Заметки',
    es: 'Notas',
  },
  notes2: {
    en: 'Grade',
    ro: 'Note',
    ru: 'Оценки',
    es: 'Calificación',
  },
  noteForCourse: {
    en: 'Grade for the course',
    ro: 'Nota de curs',
    ru: 'Оценка за курс',
    es: 'Calificación del curso',
  },
  noteForIndividualWork: {
    en: 'Grade for individual work',
    ro: 'Nota pentru teorie',
    ru: 'Оценка за индивидуальную работу',
    es: 'Calificación de trabajo individual',
  },
  practiceGradeShort: {
    en: 'Practice grade',
    ro: 'Nota pentru practică',
    ru: 'Оценка за практику',
    es: 'Calificación de la práctica',
  },
  practiceGrade: {
    en: 'Practice grade (Average grade for textbooks)',
    ro: 'Nota pentru practică (Nota medie pentru manualele prezentate)',
    ru: 'Оценка за практику (средняя оценка за учебники)',
    es: 'Calificación de la práctica (nota promedio del libro)',
  },
  averageGrade: {
    en: 'Average grade for course',
    ro: 'Nota medie pentru curs',
    ru: 'Средняя оценка за курс',
    es: 'Calificación promedio del curso',
  },
  recoveryGrades: {
    en: 'Recovery grades',
    ro: 'Recuperare notelor',
    ru: 'Восстановление оценок',
    es: 'Calificaciones de recuperación',
  },
  actions: {
    en: 'Actions',
    ro: 'Acțiuni',
    ru: 'Действия',
    es: 'Acciones',
  },
  createAd: {
    en: 'Create notice',
    ro: 'Crează anunț',
    ru: 'Создать объявление',
    es: 'Crear notificación',
  },
  sendToEveryone: {
    en: 'Send to everyone',
    ro: 'Trimite la toți',
    ru: 'Отправить всем',
    es: 'Enviar a todos',
  },
  role: {
    en: 'Role',
    ro: 'Rol',
    ru: 'Роль',
    es: 'Rol',
  },
  selectRole: {
    en: 'Select the role',
    ro: 'Alegeți rolul',
    ru: 'Выберите роль',
    es: 'Elegir el rol',
  },
  allCountry: {
    en: 'All countries',
    ro: 'Toate țările',
    ru: 'Все страны',
    es: 'Todos los países',
  },
  allRoles: {
    en: 'All roles',
    ro: 'Toate rolurile',
    ru: 'Все роли',
    es: 'Todos los roles',
  },
  allFaculties: {
    en: 'All faculties',
    ro: 'Toate facultățile',
    ru: 'Все факультеты',
    es: 'Todas las facultades',
  },
  faculties: {
    en: 'Faculties',
    ro: 'Facultăți',
    ru: 'Факультеты',
    es: 'Facultades',
  },
  dateOfBirth: {
    en: 'Date of birth',
    ro: 'Data nașterii',
    ru: 'Дата рождения',
    es: 'Fecha de nacimiento',
  },
  choose: {
    en: 'Choose',
    ro: 'Alegeți',
    ru: 'Выберите',
    es: 'Elegir',
  },
  nativeLanguage: {
    en: 'Native language',
    ro: 'Limba maternă',
    ru: 'Родной язык',
    es: 'Lengua materna',
  },
  foreignLanguages: {
    en: 'Foreign languages',
    ro: 'Limbi străine',
    ru: 'Иностранные языки',
    es: 'Idiomas extranjeros',
  },
  selectForeignLanguages: {
    en: 'Select foreign languages',
    ro: 'Selectați limbile străine',
    ru: 'Выберите иностранные языки',
    es: 'Elegir idiomas extranjeros',
  },
  addPhoto: {
    en: 'Add photo',
    ro: 'Adaugă fotografie',
    ru: 'Добавить фото',
    es: 'Añadir foto',
  },
  maxFilePhoto: {
    en: 'Maximum file size of 20 MB, supported formats are: PNG, JPG, TIFF, GIF, BMP',
    ro: 'Dimensiunea maximă a fişierului este de 20 MB, formatele acceptate sunt: PNG, JPG, TIFF, GIF, BMP',
    ru: 'Максимальный размер файла: 20 МБ. Поддерживаемые форматы: PNG, JPG, TIFF, GIF, BMP.',
    es: 'Tamaño máximo del archivo de 20 MB formatos compatibles: PNG, JPG, TIFF, GIF, BMP',
  },
  uploadPhoto: {
    en: 'Upload a photo',
    ro: 'Încarcă fotografia',
    ru: 'Загрузить фото',
    es: 'Subir una foto',
  },
  sendMessage: {
    en: 'Send message',
    ro: 'Trimite mesaj',
    ru: 'Отправить сообщение',
    es: 'Enviar un mensaje',
  },
  archive: {
    en: 'Archive',
    ro: 'Arhivează',
    ru: 'Архивировать',
    es: 'Archivar',
  },
  message: {
    en: 'Message',
    ro: 'Mesaj',
    ru: 'Сообщение',
    es: 'Mensaje',
  },
  topic: {
    en: 'Topic',
    ro: 'Subiect',
    ru: 'Тема',
    es: 'Tema',
  },
  messagerFor: {
    en: 'Message for',
    ro: 'Mesaj pentru',
    ru: 'Сообщение для',
    es: 'Mensaje para',
  },
  institutions: {
    en: 'Institutions',
    ro: 'Instituții',
    ru: 'Учреждения',
    es: 'Instituciones',
  },
  previousSessions: {
    en: 'Previous sessions',
    ro: 'Sesiuni anterioare',
    ru: 'Предыдущие сессии',
    es: 'Sesiones previas',
  },
  deleteCourse: {
    en: 'Delete course',
    ro: 'Șterge cursul',
    ru: 'Удалить курс',
    es: 'Borrar curso',
  },
  deleteManual: {
    en: 'Delete textbook',
    ro: 'Șterge manualul',
    ru: 'Удалить учебник',
    es: 'Borrar libro',
  },
  deleteFaculty: {
    en: 'Delete faculty',
    ro: 'Șterge faculate',
    ru: 'Удалить факультет',
    es: 'Borrar facultad',
  },
  deleteInstitute: {
    en: 'Delete institute',
    ro: 'Șterge institutul',
    ru: 'Удалить институт',
    es: 'Borrar instituto',
  },
  deleteTeacher: {
    en: 'Delete teacher',
    ro: 'Șterge profesor',
    ru: 'Удалить преподавателя',
    es: 'Borrar profesor',
  },
  delete: {
    en: 'Delete',
    ro: 'Șterge',
    ru: 'Удалить',
    es: 'Borrar',
  },
  editProfile: {
    en: 'Profile editing',
    ro: 'Editarea profilului',
    ru: 'Редактировать профиль',
    es: 'Editar el perfil',
  },
  pendingInvitation: {
    en: 'Pending invitation',
    ro: 'Invitație în așteptare',
    ru: 'Ожидающие приглашения',
    es: 'Invitación pendiente',
  },
  seeMoreAds: {
    en: 'See more ads',
    ro: 'Vezi mai multe anunțuri',
    ru: 'Посмотреть больше объявлений',
    es: 'Ver más avisos',
  },
  seeDetails: {
    en: 'See details',
    ro: 'Vezi detalii',
    ru: 'Смотрите подробности',
    es: 'Ver detalles',
  },
  active: {
    en: 'Active',
    ro: 'Activ',
    ru: 'В сети',
    es: 'Activo',
  },
  isActive: {
    en: 'Is active',
    ro: 'Activ',
    ru: 'Активен',
    es: 'Está activo',
  },
  edit: {
    en: 'Edit',
    ro: 'Editează',
    ru: 'Редактировать',
    es: 'Editar',
  },
  careful: {
    en: 'Careful!',
    ro: 'Atenție!',
    ru: 'Внимание!',
    es: 'Icuidado!',
  },
  confirmAcceptance: {
    en: 'Confirm acceptance',
    ro: 'Confirmă acceptare',
    ru: 'Принять',
    es: 'Confirmar aceptación',
  },
  areYouSureLogout: {
    en: 'Are you sure you want to log out?',
    ro: 'Sigur doriți să vă deconectați?',
    ru: 'Вы действительно хотите выйти?',
    es: '¿Estás seguro que deseas salir?',
  },
  areYouSureDownload: {
    en: 'Are you sure you want to download?',
    ro: 'Sigur doriți să descărcați?',
    ru: 'Вы действительно хотите скачать?',
    es: '¿Estás seguro que deseas descargar?',
  },
  areYouSureDelete: {
    en: 'Are you sure you want to delete?',
    ro: 'Ești sigur că vrei să ștergi?',
    ru: 'Вы действительно хотите удалить?',
    es: '¿Estás seguro que deseas borrar?',
  },
  areYouSureComplete: {
    en: 'Are you sure you want to complete this action?',
    ro: 'Sigur doriți să finalizați această acțiune?',
    ru: 'Вы действительно хотите завершить это действие?',
    es: '¿Estás seguro que deseas continuar con esta acción?',
  },
  addressedFor: {
    en: 'Addressed to',
    ro: 'Adresat către',
    ru: 'Адресовано',
    es: 'Enviado a',
  },
  results: {
    en: 'Results',
    ro: 'Rezultate',
    ru: 'Результаты',
    es: 'Resultados',
  },
  empty: {
    en: "There're no results, yet",
    ro: 'Nu există nici un rezultat',
    ru: 'Результатов пока нет',
    es: 'Aún no hay resultados',
  },
  placeholderTime: {
    en: 'Select time',
    ro: 'Selectați timpul',
    ru: 'Выберите время',
    es: 'Elegir el tiempo',
  },
  selectCountryError: {
    en: 'You should select country first',
    ro: 'Ar trebui să selectați țara în primul rând',
    ru: 'Сначала выберите страну',
    es: 'Deberías elegir primero el país',
  },
  selectPeriodError: {
    en: 'You should select period first',
    ro: 'Ar trebui să selectați mai întâi perioada',
    ru: 'Сначала выберите период',
    es: 'Deberías elegir primero el período',
  },
  selectFacultyError: {
    en: 'You should select faculty first',
    ro: 'Ar trebui să selectați facultate în primul rând',
    ru: 'Сначала выберите факультет',
    es: 'Deberías elegir el maestro primero',
  },
  permissions: {
    en: 'You do not have permission to perform this action',
    ro: 'Nu aveți permisiunea de a efectua această acțiune',
    ru: 'У вас нет разрешения выполнить это действие',
    es: 'No tienes autorización para realizar esta acción',
  },
  status: {
    en: 'Status',
    ro: 'Stare',
    ru: 'Статус',
    es: 'Estado',
  },
  selectStatus: {
    en: 'Status',
    ro: 'Stare',
    ru: 'Статус',
    es: 'Estado',
  },
  pending: {
    en: 'Pending',
    ro: 'In asteptarea',
    ru: 'В ожидании',
    es: 'Pendiente',
  },
  accepted: {
    en: 'Published',
    ro: 'Publicat',
    ru: 'Опубликовано',
    es: 'Publicado',
  },
  declined: {
    en: 'Declined',
    ro: 'Declinat',
    ru: 'Отклонено',
    es: 'Denegado',
  },
  addManual: {
    en: 'Add textbook',
    ro: 'Adaugă manual',
    ru: 'Добавить учебник',
    es: 'Añadir libro',
  },
  editManual: {
    en: 'Edit textbook',
    ro: 'Editează manual',
    ru: 'Изменить учебник',
    es: 'Editar libro',
  },
  addCoordinator: {
    en: 'Add coordinator',
    ro: 'Adaugă coordonator',
    ru: 'Добавить координатора',
    es: 'Añadir coordinador',
  },
  addStudent: {
    en: 'Add student',
    ro: 'Adaugă student',
    ru: 'Добавить студента',
    es: 'Añadir estudiante',
  },
  addTeacher: {
    en: 'Add teacher',
    ro: 'Adaugă profesor',
    ru: 'Добавить преподавателя',
    es: 'Añadir maestro',
  },
  lastLogin: {
    en: 'Last login',
    ro: 'Ultima logare',
    ru: 'Последний вход',
    es: 'Última vez que ingresaste',
  },
  lastActivity: {
    en: 'Last activity',
    ro: 'Ultima activitate',
    ru: 'Последняя активность',
    es: 'Última actividad',
  },
  last_session_date: {
    en: 'Last session date',
    ro: 'Ultima sesiune',
    ru: 'Последняя сессия',
    es: 'Fecha de la última sesión',
  },
  pages: {
    en: 'pages',
    ro: 'pagini',
    ru: 'страниц',
    es: 'Páginas',
  },
  restorePassword: {
    en: 'Restore password',
    ro: 'Restabiliți parola',
    ru: 'Восстановить пароль',
    es: 'Restaurar contraseña',
  },
  restore: {
    en: 'Restore',
    ro: 'Restabilește',
    ru: 'Восстановить',
    es: 'Restaurar',
  },
  enterAccountEmailOrPhone: {
    en: 'Enter account email or phone',
    ro: 'Introduceți adresa de e-mail sau telefon',
    ru: 'Введите адрес электронной почты или телефон аккаунта',
    es: 'Ingresar el correo electrónico de la cuenta o teléfono',
  },
  enterNewPassword: {
    en: 'Enter new password',
    ro: 'Introduceți parola nouă',
    ru: 'Введите новый пароль',
    es: 'Ingresar nueva contraseña',
  },
  title: {
    en: 'Title',
    ro: 'Titlu',
    ru: 'Заголовок',
    es: 'Título',
  },
  created_at: {
    en: 'Created at',
    ro: 'Creat la',
    ru: 'Создан',
    es: 'Creado en',
  },
  created_by: {
    en: 'Created by',
    ro: 'Creat de',
    ru: 'Создал',
    es: 'Creado por',
  },
  modified_at: {
    en: 'Modified by',
    ro: 'Modificat de',
    ru: 'Изменено',
    es: 'Modificado por',
  },
  writeMessage: {
    en: 'Write Message',
    ro: 'Scrie mesaj',
    ru: 'Написать сообщение',
    es: 'Escribir mensaje',
  },
  messageRecipient: {
    en: 'Message Recipient',
    ro: 'Destinatarul mesajului',
    ru: 'Получатель сообщения',
    es: 'Destinatario del mensaje',
  },
  selectRecipient: {
    en: 'Select recipient',
    ro: 'Alege destinatarul',
    ru: 'Выберите получателя',
    es: 'Elegir destinatario',
  },
  subject: {
    en: 'Subject',
    ro: 'Subiect',
    ru: 'Тема',
    es: 'Tema',
  },
  selectSubject: {
    en: 'Select Subject',
    ro: 'Alege subiectul',
    ru: 'Выберите тему',
    es: 'Elegir tema',
  },
  subjectOfApplication: {
    en: 'Subject of your request',
    ro: 'Subiectul cererii',
    ru: 'Тема заявки',
    es: 'Tema de tu solicitud',
  },
  communication: {
    en: 'Communication',
    ro: 'Comunicare',
    ru: 'Общение',
    es: 'Comunicación',
  },
  arrears: {
    en: 'Arrear',
    ro: 'Restanță',
    ru: 'Задолженность',
    es: 'Atraso',
  },
  myStudents: {
    en: 'My Students',
    ro: 'Studentii mei',
    ru: 'Мои студенты',
    es: 'Mis estudiantes',
  },
  inviteStudent: {
    en: 'Invite student',
    ro: 'Învită student',
    ru: 'Пригласить студента',
    es: 'Invitar estudiante',
  },
  registerStudent: {
    en: 'Register student',
    ro: 'Înregistrează student',
    ru: 'Зарегистрировать студента',
    es: 'Registrar estudiante',
  },
  searchStudent: {
    en: 'Student search',
    ro: 'Caută student',
    ru: 'Поиск студента',
    es: 'Búsqueda de estudiante',
  },
  searchSession: {
    en: 'Search for a session',
    ro: 'Caută sesiune',
    ru: 'Поиск сессии',
    es: 'Buscar una sesión',
  },
  numberOfSessionsCompleted: {
    en: 'Number of sessions completed',
    ro: 'Numărul de sesiuni parcurse',
    ru: 'Количество пройденных сессий',
    es: 'Número de sesiones completadas',
  },
  indicators: {
    en: 'Indicators',
    ro: 'Indicatori',
    ru: 'Показатели',
    es: 'Indicadores',
  },
  generalNote: {
    en: 'Average grade',
    ro: 'Nota generală',
    ru: 'Общая оценка',
    es: 'Calificación promedio',
  },
  finalNote: {
    en: 'Final grade',
    ro: 'Notă finală',
    ru: 'Итоговая оценка',
    es: 'Calificación final',
  },
  arrear: {
    en: 'Arrear',
    ro: 'Restanţă',
    ru: 'Задолженность',
    es: 'Atraso',
  },
  sessionDuration: {
    en: 'Duration of session',
    ro: 'Durata sesiunii',
    ru: 'Продолжительность сессии',
    es: 'Duración de la sesión',
  },
  editReport: {
    en: 'Edit report',
    ro: 'Editează raport',
    ru: 'Редактировать отчет',
    es: 'Editar reporte',
  },
  viewReport: {
    en: 'View report',
    ro: 'Vizualizează raport',
    ru: 'Посмотреть отчет',
    es: 'Ver reporte',
  },
  completeReport: {
    en: "Complete session's final report",
    ro: 'Completează raportul final de sesiune',
    ru: 'Заполнить итоговый отчет по сессии',
    es: 'Completar el reporte final de la sesión',
  },
  taughtCourses: {
    en: 'Courses taught',
    ro: 'Cursuri predate',
    ru: 'Преподаваемые курсы',
    es: 'Cursos enseñados',
  },
  localeCoordinators: {
    en: 'Local coordinators',
    ro: 'Coordonatori locali',
    ru: 'Местные координаторы',
    es: 'Coordinadores locales',
  },
  globalCoordinators: {
    en: 'Global coordinators',
    ro: 'Coordonatori globali',
    ru: 'Мировые координаторы',
    es: 'Coordinadores globales',
  },
  details: {
    en: 'Details',
    ro: 'Detalii',
    ru: 'Подробности',
    es: 'Detalles',
  },
  send: {
    en: 'Send',
    ro: 'Trimite',
    ru: 'Отправить',
    es: 'Enviar',
  },
  inviteStudentTitle: {
    en: 'Find or invite a student using email',
    ro: 'Caută sau invită student prin email',
    ru: 'Найти или пригласить студента по эл. почте',
    es: 'Encontrar o invitar a un estudiante usando correo electrónico',
  },
  events: {
    en: 'Events',
    ro: 'Evenimente',
    ru: 'Мероприятия',
    es: 'Eventos',
  },
  suggestedEvents: {
    en: 'Suggested Events',
    ro: 'Evenimente sugerate',
    ru: 'Предлагаемые мероприятия',
    es: 'eventos sugeridos',
  },
  or: {
    en: 'or',
    ro: 'sau',
    ru: 'или',
    es: 'o',
  },
  seeClosedSessions: {
    en: 'See closed sessions',
    ro: 'Vezi sesiuni încheiate',
    ru: 'Посмотреть завершенные сессии',
    es: 'Ver sesiones cerradas',
  },
  seeSessions: {
    en: 'See sessions',
    ro: 'Vezi sesiuni',
    ru: 'Посмотреть сессии',
    es: 'Ver sesiones',
  },
  fillOutApplication: {
    en: 'Fill out the application',
    ro: 'Completați cererea',
    ru: 'Заполнить заявку',
    es: 'Ilenar la aplicación',
  },
  courseTaught: {
    en: 'Course taught',
    ro: 'Cursul Predat',
    ru: 'Преподаваемый курс',
    es: 'Curso enseñado',
  },
  finalReport: {
    en: 'Final Session Report',
    ro: 'Raportul final de sesiune',
    ru: 'Итоговый отчет по сессии',
    es: 'Reporte de sesión final',
  },
  addQuestion: {
    en: 'Add question',
    ro: 'Adaugă întrebare',
    ru: 'Добавить вопрос',
    es: 'Añadir pregunta',
  },
  deleteQuestion: {
    en: 'Delete question',
    ro: 'Șterge întrebarea',
    ru: 'Удалить вопрос',
    es: 'Borrar pregunta',
  },
  question: {
    en: 'Question',
    ro: 'Întrebare',
    ru: 'Вопрос',
    es: 'Pregunta',
  },
  enterQuestion: {
    en: 'Enter question',
    ro: 'Introduceți întrebarea',
    ru: 'Введите вопрос',
    es: 'Ingresar pregunta',
  },
  answer: {
    en: 'Answer',
    ro: 'Răspuns',
    ru: 'Ответ',
    es: 'Respuesta',
  },
  enterAnswer: {
    en: 'Enter answer',
    ro: 'Introduceți răspunsul',
    ru: 'Введите ответ',
    es: 'Ingresar respuesta',
  },
  averageNote: {
    en: 'Average grade',
    ro: 'Notă medie',
    ru: 'Средняя оценка',
    es: 'Calificación promedio',
  },
  enterNote: {
    en: 'Enter grade',
    ro: 'Introduceți nota',
    ru: 'Введите оценку',
    es: 'Ingresar calificación',
  },
  note: {
    en: 'Grade',
    ro: 'Nota',
    ru: 'Оценка',
    es: 'Calificación',
  },
  setGrade: {
    en: 'Set grade',
    ro: 'Setare notă',
    ru: 'Поставить оценку',
    es: 'Establecer calificación',
  },
  object: {
    en: 'Object',
    ro: 'Obiect',
    ru: 'Предмет',
    es: 'Objetivo',
  },
  outstandings: {
    en: 'Arrears',
    ro: 'Restanțe',
    ru: 'Задолженность',
    es: 'Atrasos',
  },
  task: {
    en: 'Task',
    ro: 'Lucrarea',
    ru: 'Задание',
    es: 'Tarea',
  },
  timeLimit: {
    en: 'Deadline',
    ro: 'Termen limită',
    ru: 'Крайний срок',
    es: 'Fecha límite',
  },
  informativePanel: {
    en: 'Dashboard',
    ro: 'Panou Informativ',
    ru: 'Информационная панель',
    es: 'Panel informativo',
  },
  switchRole: {
    en: 'Change your role',
    ro: 'Treceți pe alt rol',
    ru: 'Перейти на другую роль',
    es: 'Cambiar tu rol',
  },
  didYouFindProblem: {
    en: 'Did you run into a problem?',
    ro: 'Ați găsit o problemă?',
    ru: 'Вы нашли проблему?',
    es: '¿Tuviste algún problema?',
  },
  unclosedSessions: {
    en: 'Incomplete sessions',
    ro: 'Sesiuni neîncheiate',
    ru: 'Незавершенные сессии',
    es: 'Sesiones incompletas',
  },
  oldNote: {
    en: 'Old note',
    ro: 'Nota veche',
    ru: 'Старая оценка',
    es: 'Notas antiguas',
  },
  newNote: {
    en: 'New note',
    ro: 'Nota nouă',
    ru: 'Новая оценка',
    es: 'Nueva nota',
  },
  requestChangeGrade: {
    en: 'Request change of a grade',
    ro: 'Depune cerere de schimbare a notelor',
    ru: 'Подать заявку на изменение оценок',
    es: 'Solicitar un cambio de calificación',
  },
  requestToChangeGrade: {
    en: 'Request for grade changes',
    ro: 'Cererea de modificare a notelor',
    ru: 'Заявка на изменение оценок',
    es: 'Solicitar cambios de calificación',
  },
  reportGrade: {
    en: 'Problem with grades',
    ro: 'Problemă cu notele',
    ru: 'Проблема с оценками',
    es: 'Problema con las calificaciones',
  },
  reportSessionsApplication: {
    en: 'Problem with session application',
    ro: 'Problema cu cereri de organizare a unei sessiune',
    ru: 'Проблема с заявками на сессию',
    es: 'Problema con la aplicación a la sesión',
  },
  reportPlannedSessionsApplication: {
    en: 'Problem with application for a planned sessions',
    ro: 'Problemă cu cereri la sesiunile planificate',
    ru: 'Проблема с заявками на запланированную сессию',
    es: 'problema con la aplicación a una sesión planificada',
  },
  reportPlannedSessions: {
    en: 'Problem with planned sessions',
    ro: 'Problemă cu sesiunile planificate',
    ru: 'Проблема с запланированными сессиями',
    es: 'Problema con sesiones planificadas',
  },
  reportCurrentSessions: {
    en: 'Problem with current sessions',
    ro: 'Problemă cu sesiunile curente',
    ru: 'Проблема с текущими сессиями',
    es: 'Problema con sesiones actuales',
  },
  reportClosedSessions: {
    en: 'Problem with closed sessions',
    ro: 'Problemă cu sesiunile încheiate',
    ru: 'Проблема с завершенными сессиями',
    es: 'Problema con sesiones cerradas',
  },
  reportContacts: {
    en: 'Problem with contacts',
    ro: 'Problemă cu contacte',
    ru: 'Проблема с контактами',
    es: 'Problema con contactos',
  },
  reportApplicationsSessions: {
    en: 'Problem with applications sessions',
    ro: 'Problemă cu sesiunile încheiate',
    ru: 'Проблема с завершенными сессиями',
    es: 'Problema con aplicaciones a las sesiones',
  },
  reportProblem: {
    en: 'Report the problem',
    ro: 'Raportează o problemă',
    ru: 'Сообщить о проблеме',
    es: 'Reportar un problema',
  },
  asc: {
    en: 'Ascending',
    ro: 'Crescător',
    ru: 'По возрастанию',
    es: 'Ascendente',
  },
  desc: {
    en: 'Descending',
    ro: 'Descrescator',
    ru: 'По убыванию',
    es: 'Descendente',
  },
  selectAll: {
    en: 'Select all',
    ro: 'Selectează tot',
    ru: 'Выбрать все',
    es: 'Seleccionar todo',
  },
  detailsOfAnnouncement: {
    en: 'Announcement details',
    ro: 'Detalii despre anunț',
    ru: 'Подробности об объявлении',
    es: 'Detalles del anuncio',
  },
  isAdvanceLevel: {
    en: 'Advanced level',
    ro: 'Nivel avansat',
    ru: 'Продвинутый уровень',
    es: 'Nivel avanzado',
  },
  isBibleGroup: {
    en: 'Bible Group',
    ro: 'Grupul biblic',
    ru: 'Библейская группа',
    es: 'Grupo bíblico',
  },
  isHidden: {
    en: 'Hide',
    ro: 'Ascunde',
    ru: 'Скрыть',
    es: 'Esconder',
  },
  agreeBiblicalGroup: {
    en: 'I agree to participate in biblical group',
    ro: 'Sunt de acord să particip la un grup biblic',
    ru: 'Я согласен участвовать в библейской группе',
    es: 'Estoy de acuerdo en particiar en un grupo bíblico',
  },
  loading: {
    en: 'Loading',
    ro: 'Se încarcă',
    ru: 'Загрузка',
    es: 'Cargando',
  },
  switchProfessor: {
    en: 'Switch professor',
    ro: 'Schimbă profesorul',
    ru: 'Сменить учителя',
    es: 'Cambiar profesor',
  },
  professorDetails: {
    en: 'Professor details',
    ro: 'Detaliile profesorului',
    ru: 'Детали учителя',
    es: 'Detalles del profesor',
  },
  successfullyVerified: {
    en: 'The user has been successfully verified, now you can log in using your details',
    ro: 'Utilizatorul a fost verificat cu succes, acum vă puteți conecta folosind datele dvs.',
    ru: 'Пользователь успешно подтвержден, теперь вы можете войти используя ваши данные',
    es: 'El usuario ha sido verificado exitosamente, ahora puede ingresar usando su información',
  },
  pleaseActivateAccount: {
    en:
      'In order to log in, you need to activate your account, to do this, follow the link from the letter that we sent you by email',
    ro:
      'Pentru a vă conecta, trebuie să activați contul, pentru a face această, urmați linkul din scrisoarea pe care v-am trimis-o prin e-mail',
    ru:
      'Для входа вам необходимо активировать свою учетную запись, для этого перейдите по ссылке из письма, которое мы отправили вам по электронной почте.',
    es:
      'Para poder ingresar, necesita activar su cuenta, para esto siga el enlace que se incluyó en el correo electrónico que le enviamos',
  },
  pleaseActivateAccountByPhone: {
    en:
      'To log in, you need to activate your account, for this enter the 6-digit code that we sent you to the specified phone number.',
    ro:
      'Pentru a vă conecta, trebuie să vă activați contul, pentru aceasta, introduceți codul din 6 cifre pe care vi l-am trimis la numărul de telefon specificat.',
    ru:
      'Для входа вам необходимо активировать свою учетную запись, для этого введите код из 6-ти цифр, который мы отправили вам на указанный номер телефона.',
    es:
      'Para ingresar, necesita activar su cuenta, para esto, ingrese el código de 6 dígitos que le enviamos al teléfono que especificó',
  },
  enterCodeFromSmsToSetNewPassword: {
    en: 'Enter the code from the SMS sent to the account phone number to set a new password.',
    ro: 'Introduceți codul din SMS-ul trimis la numărul de telefon al contului pentru a seta o nouă parolă.',
    ru: 'Введите код из SMS, отправленный на номер телефона аккаунта, чтобы установить новый пароль.',
    es:
      'Ingrese el código del mensaje de texto enviado a su número telefónico registrado para establecer una nueva contraseña',
  },
  allowedRoles: {
    en: 'Allowed roles',
    ro: 'Roluri permise',
    ru: 'Разрешенные роли',
    es: 'Roles permitidos',
  },
  invalidCode: {
    en: 'Invalid code',
    ro: 'Cod invalid',
    ru: 'Неверный код',
    es: 'Código inválido',
  },
  inviteByEmailOrPhone: {
    en: 'Invite by email or phone',
    ro: 'Invită după email sau telefon',
    ru: 'Пригласить по email или телефону',
    es: 'Invitar por correo electrónico o teléfono',
  },
  experienceGrade: {
    en: 'Grade of experience',
    ro: 'Gradul de experiență',
    ru: 'Степень опыта',
    es: 'Calificación de experiencia',
  },
  successUploadResources: {
    en: 'File has been successfully added and is now available for',
    ro: 'Fișierul a fost adăugat cu succes și este acum disponibil pentru',
    ru: 'Файл был успешно добавлен и теперь он доступен для',
    es: 'El archivo ha sido añadido exitosamente y ahora está disponible para',
  },
  successGrade: {
    en: 'Grade successfully updated',
    ro: 'Nota actualizată cu succes',
    ru: 'Оценка успешно обновлена',
    es: 'Calificación exitosamente actualizada',
  },
  emailWasSent: {
    en: 'Password recovery link has been sent to',
    ro: 'Link-ul de recuperare parolei a fost trimis la',
    ru: 'Ссылка для восстановления пароля отправлена на ',
    es: 'La contraseña de recuperación ha sido enviada a',
  },
  smsWasSent: {
    en: 'Password recovery code has been sent to',
    ro: 'Cod de recuperare parolei a fost trimis la',
    ru: 'Код для восстановления пароля отправлена на ',
    es: 'El código de recuperación de contraseña ha sido enviado a',
  },
  invalidToken: {
    en: 'Token is invalid',
    ro: 'Token este nevalid',
    ru: 'Токен недействителен',
    es: 'La ficha es inválida',
  },
  roleSwitchedTo: {
    en: 'Role switched to',
    ro: 'Rolul a trecut la',
    ru: 'Роль переключена на',
    es: 'Rol cambiado a',
  },
  deleted: {
    en: 'Deleted',
    ro: 'Token este nevalid',
    ru: 'Șters',
    es: 'Borrado',
  },
  inviteSended: {
    en: 'Invitation has been sent',
    ro: 'Invitația a fost trimisă',
    ru: 'Приглашение отправлено',
    es: 'La invitación ha sido enviada',
  },
  messageSended: {
    en: 'Message has been sent',
    ro: 'Mesajul a fost trimis',
    ru: 'Сообщение отправлено',
    es: 'El mensaje ha sido enviado',
  },
  problemType: {
    en: 'Problem type',
    ro: 'Tip problemă',
    ru: 'Тип проблемы',
    es: 'Tipo de problema',
  },
  professor_invitation_pending: {
    en: "Waiting for teacher's response",
    ro: 'Așteaptă răspunsul profesorului',
    ru: 'Ожидает ответа учителя',
    es: 'Esperando por respuesta el maestro',
  },
  professor_invitation_accepted: {
    en: 'Pending publication',
    ro: 'Așteaptă publicarea',
    ru: 'Ожидает публикации',
    es: 'Publicación pendiente',
  },
  professor_invitation_declined: {
    en: 'No teacher',
    ro: 'Lipsește profesor',
    ru: 'Нет учителя',
    es: 'Sin maestro',
  },
  forcePublish: {
    en: 'Force publish',
    ro: 'Publicare forțată',
    ru: 'Принудительно опубликовать',
    es: 'Forzar publicación',
  },
  publishSession: {
    en: 'Publish session',
    ro: 'Publică sesiunea',
    ru: 'Опубликовать сессию',
    es: 'Publicar sesión',
  },
  timezone: {
    en: 'Timezone',
    ro: 'Fus orar',
    ru: 'Часовой пояс',
    es: 'Zona horaria',
  },
  loginByEmail: {
    en: 'Login by email',
    ro: 'Autentificare prin e-mail',
    ru: 'Вход по электронной почте',
    es: 'Ingresar por correo electrónico',
  },
  loginByPhone: {
    en: 'Login by phone',
    ro: 'Autentificare prin telefon',
    ru: 'Вход по телефону',
    es: 'Ingresar por teléfono',
  },
  restoreByEmail: {
    en: 'Restore by email',
    ro: 'Restaurează prin e-mail',
    ru: 'Восстановить по электронной почте',
    es: 'Restaurar por correo electrónico',
  },
  restoreByPhone: {
    en: 'Login by phone',
    ro: 'Restaurează prin telefon',
    ru: 'Восстановить по телефону',
    es: 'Restaurar por teléfono',
  },
  examFailed: {
    en: 'Debtors',
    ro: 'Restanțieri',
    ru: 'Должники',
    es: 'Deudores',
  },
  examSuccess: {
    en: 'Graduates',
    ro: 'Absolvenți',
    ru: 'Выпускники',
    es: 'Graduados',
  },
  recommendsPassword: {
    en: 'Password should have at least one uppercase letter',
    ro: 'Parola trebuie să aibă cel puțin o literă mare',
    ru: 'Пароль должен содержать хотя бы одну заглавную букву.',
    es: 'La contraseña debe tener al menos una letra mayúscula',
  },
  sendMessageToProfessor: {
    en: 'Send Message to Teacher',
    ro: 'Trimite Mesaj Profesorului',
    ru: 'Отправить сообщение учителю',
    es: 'Enviar mensaje al maestro',
  },
  january: {
    en: 'january',
    ro: 'ianuarie',
    ru: 'Январь',
    es: 'enero',
  },
  february: {
    en: 'february',
    ro: 'februarie',
    ru: 'Февраль',
    es: 'febrero',
  },
  march: {
    en: 'march',
    ro: 'martie',
    ru: 'Март',
    es: 'marzo',
  },
  april: {
    en: 'april',
    ro: 'aprilie',
    ru: 'Апрель',
    es: 'abril',
  },
  may: {
    en: 'may',
    ro: 'mai',
    ru: 'Май',
    es: 'mayo',
  },
  june: {
    en: 'june',
    ro: 'iunie',
    ru: 'Июнь',
    es: 'junio',
  },
  july: {
    en: 'july',
    ro: 'iulie',
    ru: 'Июль',
    es: 'julio',
  },
  august: {
    en: 'august',
    ro: 'august',
    ru: 'Август',
    es: 'agosto',
  },
  september: {
    en: 'september',
    ro: 'septembrie',
    ru: 'Сентябрь',
    es: 'septiembre',
  },
  october: {
    en: 'october',
    ro: 'octombrie',
    ru: 'Октябрь',
    es: 'octubre',
  },
  november: {
    en: 'november',
    ro: 'noiembrie',
    ru: 'Ноябрь',
    es: 'noviembre',
  },
  december: {
    en: 'december',
    ro: 'decembrie',
    ru: 'Декабрь',
    es: 'diciembre',
  },
  passwordChangedSuccessfully: {
    en: 'Your password has beed changed successfully',
    ro: 'Parola dvs. a fost modificată cu succes',
    ru: 'Ваш пароль был успешно изменен',
    es: 'Su contraseña ha sido cambiada exitosamente',
  },
  cancelParticipation: {
    en: 'Cancel participation',
    ro: 'Anulează participarea',
    ru: 'Отменить участие',
    es: 'Cancelar participación',
  },
  registeredToSession: {
    en: 'I am registered for the session',
    ro: 'Sunt înregistrat la sesiune',
    ru: 'Я зарегистрирован на сессий',
    es: 'Estoy registrado para la sesión',
  },
  notRegisteredToSession: {
    en: 'I am not registered for the session',
    ro: 'Nu sunt înregistrat la sesiune',
    ru: 'Я не зарегистрирован на сессий',
    es: 'No estoy registrado para la sesión',
  },
  enterGrade: {
    en: 'Enter grades',
    ro: 'Indica Notele',
    ru: 'Введите оценки',
    es: 'Ingresar calificaciones',
  },
  copyLink: {
    en: 'Copy link',
    ro: 'Copie link-ul',
    ru: 'Копировать ссылку',
    es: 'Copiar enlace',
  },
  successfullyCopied: {
    en: 'Successfully copied',
    ro: 'Copiat cu succes',
    ru: 'Успешно скопировано',
    es: 'Copiado exitosamente',
  },
  filterByStatus: {
    en: 'Filter by Status',
    ro: 'Filtrează după stare',
    ru: 'Фильтр по статусу',
    es: 'Filtrar por estatus',
  },
  terms_of_use: {
    en: 'Terms of Use',
    ro: 'Termeni de utilizare',
    ru: 'Условия эксплуатации',
    es: 'Términos de uso',
  },
  privacy_policy: {
    en: 'Privacy Policy',
    ro: 'Politica de Confidențialitate',
    ru: 'Политика конфиденциальности',
    es: 'Política de privacidad',
  },
  terms_of_use_single: {
    en: 'Terms of Use',
    ro: 'Termeni de utilizare',
    ru: 'Условиями эксплуатации',
    es: 'Términos de uso',
  },
  privacy_policy_single: {
    en: 'Privacy Policy',
    ro: 'Politica de Confidențialitate',
    ru: 'Политикой конфиденциальности',
    es: 'Política de privacidad',
  },
  request_for_change: {
    en: 'Request for change',
    ro: 'Cerere de modificare',
    ru: 'Запрос на изменение',
    es: 'Solicitar cambio',
  },
  reset: {
    en: 'Reset',
    ro: 'Reseteaza',
    ru: 'Сброс',
    es: 'Reiniciar',
  },
  graduations: {
    en: 'Graduations',
    ro: 'Graduări',
    ru: 'Выпускные',
    es: 'Graduaciones',
  },
  graduation: {
    en: 'Graduation',
    ro: 'Absolvire',
    ru: 'Выпускной',
    es: 'Graduación',
  },
  graduates: {
    en: 'Graduates',
    ro: 'Absolventi',
    ru: 'Выпускники',
    es: 'Graduados',
  },
  graduate: {
    en: 'Graduate',
    ro: 'Absolvent',
    ru: 'Выпускника',
    es: 'Graduado',
  },
  diploma: {
    en: 'Diploma Number',
    ro: 'Numărul diplomei',
    ru: 'Номер диплома',
    es: 'Número de diploma',
  },
  addItem: {
    en: 'Add {item}',
    ro: 'Adauga {item}',
    ru: 'Добавить {item}',
    es: 'Añadir {artículo}',
  },
  editItem: {
    en: 'Edit {item}',
    ro: 'Editeaza {item}',
    ru: 'Редактировать {item}',
    es: 'Editar {artículo}',
  },
  selectUser: {
    en: 'Select the user',
    ro: 'Alegeți utilizatorul',
    ru: 'Выберите пользователя',
    es: 'Elegir el usuario',
  },
  selectItem: {
    en: 'Select the {item}',
    ro: 'Alegeți {item}',
    ru: 'Выберите {item}',
    es: 'Elegir el {artículo}',
  },
  certificationLevel: {
    en: 'Certificate Type',
    ro: 'Tipul certificatului',
    ru: 'Тип Сертификата',
    es: 'Tipo de certificado',
  },
  graduated: {
    en: 'Graduated',
    ro: 'Absolvent',
    ru: 'Выпускник',
    es: 'Graduado',
  },
  reportGraduations: {
    en: 'Problem with graduations',
    ro: 'Problemă cu absolviri',
    ru: 'Проблема с Выпускынми',
    es: 'Problema con las graduaciones',
  },
  studentsCount: {
    en: 'Number of graduates',
    ro: 'Număr graduanți',
    ru: 'Количество выпускников',
    es: 'Número de graduados',
  },
  institution: {
    en: 'Institution',
    ro: 'Instituție',
    ru: 'Учреждение',
    es: 'Institución',
  },
  has_faculties_graduated: {
    en: 'The faculty has graduates',
    ro: 'Facultatea are absolvenţi',
    ru: 'На факультете есть выпускники',
    es: 'La facultad tiene graduados',
  },
  selectFaculties: {
    en: 'Select your faculties',
    ro: 'Selectează facultatea',
    ru: 'Выберите факультеты',
    es: 'Elegir sus facultades',
  },
  graduate_level_1: {
    en: 'Only students who have graduated from the Faculty of Level I can register for this session.',
    ro: 'La această sesiune se pot înregistra doar studenții care au graduat Facultate de Nivelul I.',
    ru: 'На эту сессию могут зарегистрироваться только студенты, окончившие факультет I ступени.',
    es: 'Solo los estudiantes que se han graduado de la facultad del Nivel I pueden registrarse en esta sesión',
  },
};
