import * as React from 'react';
import { useHistory, useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { UserContext } from 'contexts';
import { parseQuery } from 'libs/object';
import { EditAccount, Head } from 'ui/organisms';

import { UsersLayout } from '../organisms';

export const TeacherEditPage: React.FC = () => {
  const { t } = useIntl();
  const { action } = useParams();
  const { push, goBack } = useHistory();
  const { userData } = React.useContext(UserContext);

  const id = React.useMemo(() => (userData.id === Number(action) ? 'my' : Number(action) ? Number(action) : action), [
    userData,
    action,
  ]);

  const onSuccess = (): void => {
    if (id === 'my') {
      goBack();
    } else {
      const { page } = parseQuery<{ page: number }>(window.location.search);
      push('TeachersUsersPage', { query: { page } });
    }
  };

  return (
    <>
      <Head t="editProfile" />

      <UsersLayout>
        <h1 className="mt-20">{t('editProfile')}</h1>

        <EditAccount id={id} role="professor" onSuccess={onSuccess} onClose={goBack} />
      </UsersLayout>
    </>
  );
};
