import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useStateHandlers } from 'hooks';
import { users as usersApi, announces } from 'libs/http/api';
import { User } from 'libs/http/api/me/me.types';
import { Results, Save } from 'libs/http/api/index.types';
import { SendAnnounceOptions } from 'libs/http/api/announces/announces.types';
import { Form, FormItem } from 'ui/organisms';
import { Icon, Input, RichText, Button, AvatarInline, Animated, Alert, Modal, ModalFooterButton } from 'ui/atoms';

import './SendMessage.scss';

interface Props {
  onRefetch?: () => void;
  users?: number[];
}

export const SendMessage: React.FC<Props> = ({ onRefetch, users = [] }) => {
  const { t } = useIntl();
  const { request: requestUsers, data: dataUsers, loading: loadingUsers } = useRequest<Results<User>>({
    data: { results: [] },
  });
  const { request, data: dataSave, loading, errors } = useRequest<Save>();

  const [open, setOpen] = React.useState(false);
  const [state, setState] = useStateHandlers<SendAnnounceOptions>({
    subject: '',
    description: '',
    user: null,
  });

  React.useEffect(() => {
    return () => {
      usersApi.get.cancel();
      announces.send.cancel();
      announces.sessionSend.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (open && Array.isArray(state.user)) {
      requestUsers(usersApi.get.action({ id__in: state.user.join(',') }));
    }
  }, [open, state.user]);

  React.useEffect(() => {
    if (JSON.stringify(users) !== JSON.stringify(state.user)) {
      setState({ user: users });
    }
  }, [state.user, users]);

  const onSubmit = async (): Promise<void> => {
    const singleUser = users.length === 0;
    const { user, ...options } = state;

    if (singleUser) {
      await request(announces.send.action({ ...options, user: Array.isArray(user) ? user[0] : null }));
    } else {
      await request(announces.sessionSend.action({ ...options, users: user }));
    }

    onToggleOpen();

    if (onRefetch !== undefined) {
      onRefetch();
    }
  };

  const onChange = React.useCallback((target: string, value: any): void => setState({ [target]: value }), []);

  const onToggleOpen = (): void => setOpen((s) => !s);

  return (
    <>
      {open && (
        <Modal
          mask={false}
          contentClass="zh-send-message-content"
          onClose={onToggleOpen}
          title={t('sendMessage')}
          footer={
            <ModalFooterButton>
              <Button onClick={onToggleOpen} disabled={loading}>
                {t('refuse')}
              </Button>

              <Button submit form="send-message" type="primary" prefix={<Icon type="check" />} loading={loading}>
                {t('confirm')}
              </Button>
            </ModalFooterButton>
          }
        >
          <Alert message={dataSave.message || errors.non_field_errors || errors.detail} form type="error" />

          <Form onSubmit={onSubmit} id="send-message">
            <div className="zh-send-message-content-item">
              <Animated loading={loadingUsers} debounce={250}>
                <FormItem label={<div className="text-transform-uppercase">{t('messagerFor')}</div>}>
                  {dataUsers.results.length
                    ? dataUsers.results.map(
                        ({ id, profile_picture: profilePicture, first_name: firstName, last_name: lastName }) => (
                          <AvatarInline
                            key={id}
                            img={profilePicture}
                            alt={[firstName, lastName].filter((i) => i).join(' ')}
                          />
                        ),
                      )
                    : null}
                </FormItem>
              </Animated>
            </div>

            <div className="zh-send-message-content-item">
              <FormItem required label={t('topic')} extra={errors.subject}>
                <Input value={state.subject} onChange={(newValue) => onChange('subject', newValue)} />
              </FormItem>

              <FormItem required label={t('message')} extra={errors.description}>
                <RichText initialValue={state.description} onChange={(newValue) => onChange('description', newValue)} />
              </FormItem>
            </div>
          </Form>
        </Modal>
      )}

      <Button className="zh-send-message-button" prefix={<Icon type="mail" />} onClick={onToggleOpen}>
        {t('sendMessage')}
      </Button>
    </>
  );
};
