import * as React from 'react';
import { LoaderSpinner } from 'ui/atoms';
import './Input.scss';

const types = ['phone', 'email', 'password', 'number'];

export type StyleType = 'grey' | 'white' | 'transparent' | 'disabled';

export interface InputProps {
  type?: 'regular' | 'text' | 'phone' | 'email' | 'password' | 'daterange' | 'number';
  value: string | number | null | undefined;
  onClick?: (e: any) => void;
  onChange?: (value: string) => void;
  onKeyDown?: (e: any) => void;
  onClickPrefix?: () => void;
  onClickSuffix?: () => void;
  onClickPostfix?: () => void;
  prefix?: React.ReactElement;
  suffix?: React.ReactElement;
  postfix?: React.ReactElement;
  name?: string;
  placeholder?: string;
  loading?: boolean;
  prefixClickable?: boolean;
  disabled?: boolean;
  width?: number | string;
  styleType?: StyleType;
  className?: string;
  autoFocus?: boolean;
}

export type Ref = any;

export const Input = React.forwardRef<Ref, InputProps>(
  (
    {
      type = 'regular',
      name,
      onClick,
      onChange,
      onKeyDown,
      value,
      prefix,
      prefixClickable,
      suffix,
      postfix,
      loading,
      width,
      styleType = 'white',
      className = '',
      autoFocus,
      ...props
    },
    ref,
  ) => {
    const onClickHandler = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
      if (onChange !== undefined) {
        onChange(target.value);
      }
    };

    const onClickPrefixHandler = (): void => {
      if (!loading && props.onClickPrefix !== undefined) {
        props.onClickPrefix();
      }
    };

    const onClickSuffixHandler = (): void => {
      if (!loading && props.onClickSuffix !== undefined) {
        props.onClickSuffix();
      }
    };

    const onClickPostfixHandler = (): void => {
      if (!loading && props.onClickPostfix !== undefined) {
        props.onClickPostfix();
      }
    };

    return (
      <div
        className={`zh-input-wrapper 
      ${value === '' ? 'zh-input-empty' : ''} 
      zh-input-wrapper-${value !== '' ? 'active' : 'unactive'} 
      zh-input-style-${styleType} 
      zh-input-type-${type}${prefix !== undefined ? ' has-prefix' : ''}
      ${suffix !== undefined ? ' has-suffix' : ''}${props.disabled ? ' disabled' : ''} ${className}`}
      >
        {loading || prefix ? (
          <div
            className={`zh-input-prefix ${
              !loading && (props.onClickPrefix !== undefined || prefixClickable) ? 'clickable' : 'not-clickable'
            }`}
            onClick={onClickPrefixHandler}
          >
            {loading ? <LoaderSpinner size="small" /> : prefix}
          </div>
        ) : null}

        {suffix ? (
          <div
            className={`zh-input-suffix ${
              !loading && props.onClickSuffix !== undefined ? 'clickable' : 'not-clickable'
            }`}
            onClick={onClickSuffixHandler}
          >
            {loading && !prefix ? <LoaderSpinner size="small" /> : suffix}
          </div>
        ) : null}

        <div className="zh-input-container">
          <input
            name={name}
            ref={ref}
            type={types.indexOf(type) > -1 ? type : 'text'}
            className="zh-input"
            value={value || ''}
            onChange={onClickHandler}
            placeholder={props.placeholder}
            disabled={props.disabled || loading || type === 'text'}
            onClick={onClick}
            onKeyDown={onKeyDown}
            readOnly={type === 'daterange'}
            style={{ minWidth: width }}
            autoFocus={autoFocus}
          />
        </div>

        {postfix ? (
          <div
            className={`zh-input-postfix ${
              !loading && props.onClickPostfix !== undefined ? 'clickable' : 'not-clickable'
            }`}
            onClick={onClickPostfixHandler}
          >
            {loading && !prefix ? <LoaderSpinner size="small" /> : postfix}
          </div>
        ) : null}
      </div>
    );
  },
);
