import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Link, useRouterHelpers } from 'estafette-router';
import { Layout } from 'ui/organisms';
import { Button, Icon, Tabs } from 'ui/atoms';

import { SwitchUserRole } from './';

interface Props {
  showTabs?: boolean;
  onRefetch?: () => void;
}

export const UsersLayout: React.FC<Props> = ({ showTabs = true, children, onRefetch }) => {
  const { t } = useIntl();
  const { isRouteActive } = useRouterHelpers();

  const [showSwitch, setShowSwitch] = React.useState(false);

  const tabsData = [
    {
      route: 'CoordinatorsUsersPage',
      label: t('coordinators'),
      addLabel: t('addCoordinator'),
      addRoute: 'CoordinatorEditPage',
      active: isRouteActive(['CoordinatorsUsersPage', 'CoordinatorEditPage']),
    },
    {
      route: 'TeachersUsersPage',
      label: t('teachers'),
      addLabel: t('addTeacher'),
      active: isRouteActive(['TeachersUsersPage', 'TeacherEditPage']),
    },
    {
      route: 'StudentsUsersPage',
      label: t('students'),
      active: isRouteActive(['StudentsUsersPage', 'StudentEditPage']),
    },
  ];

  const activeTab = tabsData.filter((tab) => tab.active)[0];
  const onToggle = (): void => setShowSwitch((s) => !s);
  const roleTo = React.useMemo(() => (activeTab.route === 'CoordinatorsUsersPage' ? 'coordinator' : undefined), [
    activeTab,
  ]);

  return (
    <Layout
      title={activeTab.label}
      rightSide={
        activeTab.addRoute ? (
          <>
            <Link route={activeTab.addRoute} params={{ action: 'add' }}>
              <Button type="invert" prefix={<Icon type="plus" />}>
                {activeTab.addLabel}
              </Button>
            </Link>
          </>
        ) : (
          activeTab.addLabel && (
            <Button type="invert" prefix={<Icon type="plus" />} onClick={onToggle}>
              {activeTab.addLabel}
            </Button>
          )
        )
      }
    >
      {showTabs && <Tabs data={tabsData} className="mb-0" />}

      {children}

      <SwitchUserRole
        to={roleTo}
        title={roleTo === 'coordinator' ? t('addCoordinator') : t('addTeacher')}
        open={showSwitch}
        onToggle={onToggle}
        onSave={onRefetch}
      />
    </Layout>
  );
};
