import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { NomenclaturesContext } from 'contexts';
import { useStateHandlers, useFilters } from 'hooks';
import { announces, faculties, institutions, sessions } from 'libs/http/api';
import { AnnouncementOptions } from 'libs/http/api/announces/announces.types';
import { FacultiesList } from 'libs/http/api/faculties/faculties.types';
import { List, ListUser, Save } from 'libs/http/api/index.types';
import { Form, FormItem, FormItems } from 'ui/organisms';
import { RichText, Button, Select, Icon, Input, Alert, Checkbox, ModalFooterButton, Modal } from 'ui/atoms';
import { Option } from 'ui/atoms/Select/Select';

import './CreateAnnouncement.scss';

interface Props {
  id?: number | string;
  onRefetch?: () => void;
  provider?: 'announcements' | 'session' | 'institute';
}

const initialState = {
  subject: '',
  countries: [],
  institutions: [],
  language: null,
  faculties: [],
  has_faculties_graduated: false,
  users: [],
  description: '',
};

const initialFilters = {
  userChoose: '',
  country: false,
};

export const CreateAnnouncement: React.FC<Props> = ({ provider = 'announcements', id, onRefetch }) => {
  const { t } = useIntl();
  const { request: requestFaculties, data: dataFaculties, loading: loadingFaculties } = useRequest<FacultiesList[]>();
  const { request: requestInstitutions, data: dataInstitutions, loading: loadingInstitutions } = useRequest<List[]>();
  const { request: requestUsers, data: dataUsers, loading: loadingUsers } = useRequest<ListUser[]>();
  const { request: requestSave, data: dataSave, errors, loading } = useRequest<Save>();
  const { getActiveCountries, activeCountries, loadingActiveCountries, getActiveLanguages } = React.useContext(
    NomenclaturesContext,
  );
  const api = React.useMemo(() => (provider === 'session' ? sessions : announces), [provider]);

  const [filters, setFilters] = useFilters({ ...initialFilters });
  const [state, setState] = useStateHandlers<AnnouncementOptions>({ ...initialState });
  const [create, setCreate] = React.useState(false);
  const [searchCountries, setSearchCountries] = React.useState('');

  React.useEffect(() => {
    if (id && provider !== 'session') {
      onChange('institutions', [id]);
    }
  }, [id, provider]);

  React.useEffect(() => {
    if (id && provider === 'session') {
      requestUsers(sessions.usersList.action({ id }));
    }
  }, [id, provider]);

  React.useEffect(() => {
    requestFaculties(faculties.list.action());

    getActiveLanguages();

    return () => {
      faculties.list.cancel();
      sessions.usersList.cancel();
      api.saveAnnounce.cancel();
    };
  }, []);

  React.useEffect(() => {
    requestInstitutions(
      institutions.list.action({
        ...(state.countries && state.countries.length ? { country__in: state.countries.join(',') } : undefined),
        is_active: true,
      }),
    );

    return () => {
      institutions.list.cancel();
    };
  }, [state.countries]);

  React.useEffect(() => {
    getActiveCountries(searchCountries);
  }, [searchCountries]);

  const onConfirm = async (): Promise<void> => {
    const options = { ...state };

    if (provider === 'session') {
      options.session = Number(id);
    }

    await requestSave(api.saveAnnounce.action(options));

    setState({ ...initialState, institutions: id ? [id] : [] });

    onToggleCreate();

    if (onRefetch !== undefined) {
      onRefetch();
    }
  };

  const onToggleCreate = (): void => setCreate((s) => !s);

  const onChange = React.useCallback((target: string, value: any): void => setState({ [target]: value }), []);

  const onSelectAll = React.useCallback(
    (target: keyof AnnouncementOptions, arr: Option[]): void =>
      setState((prevState) => {
        const item = prevState[target];

        return {
          [target]: Array.isArray(item) && item.length !== arr.length ? arr.map(({ value }) => value) : [],
        };
      }),
    [],
  );

  const instituteOptions = React.useMemo(
    () => dataInstitutions.map((institute) => ({ value: institute.key, title: institute.label })),
    [dataInstitutions],
  );

  const facultiesOptions = React.useMemo(
    () => dataFaculties.map((faculty) => ({ value: faculty.key, title: faculty.label })),
    [dataFaculties],
  );

  const usersOptions = React.useMemo(
    () =>
      dataUsers.map((user) => ({
        value: user.key,
        title: user.label,
        exam_failed: user.exam_failed,
      })),
    [dataUsers],
  );

  const onToggleFilters = (key: string): void => {
    switch (key) {
      case 'all':
        if (filters.userChoose !== 'all') {
          onSelectAll('users', usersOptions);
        }
        break;
      case 'examFailed':
        if (filters.userChoose !== 'examFailed') {
          onSelectAll(
            'users',
            usersOptions.filter((i) => i.exam_failed),
          );
        }
        break;
      case 'examPassed':
        if (filters.userChoose !== 'examPassed') {
          onSelectAll(
            'users',
            usersOptions.filter((i) => !i.exam_failed),
          );
        }
        break;
    }
    if (filters.userChoose === key) {
      setFilters({ userChoose: undefined });
      onSelectAll('users', []);
    } else {
      setFilters({ userChoose: key });
    }
  };

  const handleOnChangeUsers = (value: number[]): void => {
    setFilters({ userChoose: undefined });
    onChange('users', value);
  };

  const handleCountriesSearch = (input: string): void => {
    setSearchCountries(input);
  };

  return (
    <>
      <Button type="invert" prefix={<Icon type="plus" />} loading={loading} onClick={onToggleCreate}>
        {t('createAd')}
      </Button>

      {create && (
        <Modal
          mask={false}
          contentClass="zh-create-announcement-content"
          onClose={onToggleCreate}
          title={t('createAd')}
          footer={
            <ModalFooterButton>
              <Button onClick={onToggleCreate} disabled={loading}>
                {t('refuse')}
              </Button>

              <Button submit form="create-announcement" type="primary" prefix={<Icon type="check" />} loading={loading}>
                {t('confirm')}
              </Button>
            </ModalFooterButton>
          }
        >
          <Alert message={dataSave.message || errors.non_field_errors || errors.detail} form type="error" />

          <Form onSubmit={onConfirm} id="create-announcement">
            <FormItem required label={t('subject')} extra={errors.subject}>
              <Input value={state.subject} onChange={(value) => onChange('subject', value)} />
            </FormItem>

            {!['session', 'institute'].includes(provider) && (
              <FormItem
                required
                label={t('countries')}
                error={errors.countries}
                className="zh-create-announcement-multitool"
              >
                <Select
                  getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
                  mode="multiple"
                  cancelable
                  placeholder={t('selectCountries')}
                  options={activeCountries}
                  onSearch={handleCountriesSearch}
                  loading={loadingActiveCountries}
                  value={state.countries}
                  onChange={(value) => onChange('countries', value)}
                />
                <div
                  className={`zh-create-announcement-select-all${
                    state.countries && state.countries.length === activeCountries.length ? ' active' : ''
                  }`}
                  onClick={() => onSelectAll('countries', activeCountries)}
                >
                  <Icon type="check" />
                  <span>{t('allCountry')}</span>
                </div>
              </FormItem>
            )}

            {provider === 'session' && (
              <FormItems>
                <FormItem required label={t('students')} error={errors.users}>
                  <Select
                    getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
                    mode="multiple"
                    cancelable
                    placeholder={t('selectStudents')}
                    options={usersOptions}
                    loading={loadingUsers}
                    value={state.users}
                    onChange={handleOnChangeUsers}
                  />
                </FormItem>

                {!(filters.userChoose === 'all') && (
                  <FormItem required className="flex flex-align-end" itemClass="width-auto">
                    <Checkbox
                      checked={filters.userChoose === 'examFailed'}
                      onChange={() => onToggleFilters('examFailed')}
                      label={t('examFailed')}
                    />
                  </FormItem>
                )}
                {!(filters.userChoose === 'all') && (
                  <FormItem required className="flex flex-align-end" itemClass="width-auto">
                    <Checkbox
                      checked={filters.userChoose === 'examPassed'}
                      onChange={() => onToggleFilters('examPassed')}
                      label={t('examSuccess')}
                    />
                  </FormItem>
                )}

                <FormItem required className="flex flex-align-end" itemClass="width-auto">
                  <Checkbox
                    checked={filters.userChoose === 'all'}
                    onChange={() => onToggleFilters('all')}
                    label={t('all')}
                  />
                </FormItem>
              </FormItems>
            )}

            {!id && (
              <FormItem
                required
                label={t('institute')}
                className="zh-create-announcement-multitool"
                error={errors.institutions}
              >
                <Select
                  getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
                  mode="multiple"
                  cancelable
                  placeholder={t('selectInstitute')}
                  options={instituteOptions}
                  loading={loadingInstitutions}
                  value={state.institutions}
                  onChange={(value) => onChange('institutions', value)}
                />

                <div
                  className={`zh-create-announcement-select-all ${
                    state.institutions && state.institutions.length === instituteOptions.length ? ' active' : ''
                  }`}
                  onClick={() => onSelectAll('institutions', instituteOptions)}
                >
                  <Icon type="check" />
                  <span>{t('sendToEveryone')}</span>
                </div>
              </FormItem>
            )}

            {provider !== 'session' && (
              <>
                <FormItem
                  required
                  label={t('faculty')}
                  className="zh-create-announcement-multitool"
                  error={errors.faculties}
                >
                  <Select
                    getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
                    mode="multiple"
                    cancelable
                    placeholder={t('selectFaculty')}
                    options={facultiesOptions}
                    loading={loadingFaculties}
                    value={state.faculties}
                    onChange={(value) => onChange('faculties', value)}
                  />

                  <div
                    className={`zh-create-announcement-select-all${
                      state.faculties && state.faculties.length === facultiesOptions.length ? ' active' : ''
                    }`}
                    onClick={() => onSelectAll('faculties', facultiesOptions)}
                  >
                    <Icon type="check" />
                    <span>{t('allFaculties')}</span>
                  </div>
                </FormItem>
                <FormItem required label={t('has_faculties_graduated')} extra={errors.has_faculties_graduated}>
                  <Checkbox
                    checked={state.has_faculties_graduated}
                    onChange={() => onChange('has_faculties_graduated', !state.has_faculties_graduated)}
                    label={t('has_faculties_graduated')}
                  />
                </FormItem>
              </>
            )}

            <FormItem required label={t('description')} extra={errors.description}>
              <RichText initialValue={state.description} onChange={(value) => onChange('description', value)} />
            </FormItem>
          </Form>
        </Modal>
      )}
    </>
  );
};
