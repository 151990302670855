import * as React from 'react';
import { useRequest } from 'estafette';
import { Link, useHistory } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { useFilters } from 'hooks';
import { institutions } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { EurasiaInstitution } from 'libs/http/api/institutions/institutions.types';
import { perPage } from 'libs/storage';
import { Actions, Head, InputSearch, SortBy } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { Info, Card, CardHeader, CardFooter, Table, Icon, Button, Loader, AvatarInline } from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';

import { InstituteLayout } from '../organisms';

export const InstitutesPage: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { request, data, loading } = useRequest<Results<EurasiaInstitution>>({ data: { results: [] } });
  const { request: requestDelete, loading: loadingDelete } = useRequest();
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    ordering: '' as Sorts,
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    return () => {
      institutions.get.cancel();
      institutions.delete.cancel();
    };
  }, []);

  React.useEffect(() => {
    request(institutions.get.action(filters));
  }, [filters]);

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => {
    if (filters.filtered) {
      setFilters({ ...initialFilters.current });
    } else {
      request(institutions.get.action(filters));
    }
  };
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onManagement = (action = 'add'): void => push('AddInstitutePage', { action, query: { page: filters.page } });
  const onDelete = React.useCallback(async (id: number) => {
    await requestDelete(institutions.delete.action({ id }));

    onRefetch();
  }, []);

  const columns: Column<EurasiaInstitution>[] = React.useMemo(
    () => [
      {
        title: t('place'),
        render: ({ country, city, id }) => (
          <Link route="GeneralInstitutePage" params={{ id }}>
            {country && country.title ? (
              <Info
                icon={<Icon type="pin" />}
                label={[country && country.title, city && city.title].filter((i) => i).join(', ')}
              />
            ) : (
              '---'
            )}
          </Link>
        ),
      },
      {
        title: t('coordinator'),
        render: ({ coordinator, id }): React.ReactNode => (
          <Link route="GeneralInstitutePage" params={{ id }}>
            {coordinator ? (
              <AvatarInline
                img={coordinator.profile_picture}
                alt={[coordinator.first_name, coordinator.last_name].filter((i) => i).join(' ')}
                size="small"
              />
            ) : (
              '---'
            )}
          </Link>
        ),
      },
      {
        title: t('name'),
        render: ({ id, name }): React.ReactNode => (
          <Link route="GeneralInstitutePage" params={{ id }}>
            {name}
          </Link>
        ),
      },
      {
        title: t('numOfRegisteredStudentsShort'),
        width: 80,
        render: ({ number_of_students: students, id }) => (
          <Link route="GeneralInstitutePage" params={{ id }}>
            {(students && <b>{students}</b>) || 0}
          </Link>
        ),
      },
      {
        action: true,
        textAlign: 'center',
        render: ({ id }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <Link className="nowrap" route="GeneralInstitutePage" params={{ id }}>
              {t('details')}
            </Link>

            <Actions
              onEdit={() => onManagement(`${id}`)}
              onDelete={() => onDelete(id)}
              onDeleteLabel={t('deleteInstitute')}
              loadingDelete={loadingDelete}
            />
          </div>
        ),
        className: 'nowrap',
      },
    ],
    [t],
  );

  return (
    <>
      <Head t="institutions" />

      <InstituteLayout showTabs={false}>
        <Card>
          <CardHeader
            title={t('institutions')}
            count={data.count}
            leftSide={
              <InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />
            }
            rightSide={
              <SortBy
                options={[
                  {
                    title: t('name'),
                    value: 'name',
                  },
                  {
                    title: t('country'),
                    value: 'country',
                  },
                  {
                    title: t('city'),
                    value: 'city',
                  },
                  {
                    title: t('isActive'),
                    value: 'is_active',
                  },
                ]}
                value={filters.ordering}
                onChange={(newValue) => onChangeFilters('ordering', newValue)}
              />
            }
          />

          <Loader loading={loading}>
            <Table size="small" page={filters.page} data={data.results} columns={columns} />

            <CardFooter
              onRefresh={onRefetch}
              onRefreshDisabled={!filters.filtered}
              page={filters.page}
              pages={data.total_pages}
              perPage={filters.per_page}
              onChangePerPage={onChangePerPage}
              rightSide={
                <>
                  <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                    {t('previous')}
                  </Button>

                  <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                    {t('next')}
                  </Button>
                </>
              }
            />
          </Loader>
        </Card>
      </InstituteLayout>
    </>
  );
};
