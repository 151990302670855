import * as React from 'react';
import { useRequest } from 'estafette';
import { useHistory } from 'estafette-router';
import { users } from 'libs/http/api';
import { EurasiaRegister } from 'libs/http/api/users/users.types';
import { LoaderInline } from 'ui/atoms';

import './Confirm.scss';

export const Confirm: React.FC<{ token: string }> = ({ token }) => {
  const { push } = useHistory();
  const { request, data } = useRequest<EurasiaRegister>();

  React.useEffect(() => {
    request(users.confirm.action({ token }));
  }, []);

  React.useEffect(() => {
    if (data.success) {
      push('SignInPage', { query: { confirm: true } });
    }
  }, [data]);

  return (
    <div className="confirm-container">
      <LoaderInline />
    </div>
  );
};
