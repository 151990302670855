import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { Roles } from 'hooks/useModules';
import { grade } from 'libs/mask';
import { me, users, institutions, file, sessions, countries as countriesAPI, faculties } from 'libs/http/api';
import { User, UserState } from 'libs/http/api/me/me.types';
import { EurasiaFile } from 'libs/http/api/file/file.types';
import { Grades } from 'libs/http/api/courses/courses.types';
import { List, Save } from 'libs/http/api/index.types';
import { toISODate } from 'libs/date';
import { UserContext, NomenclaturesContext } from 'contexts';
import { useStateHandlers } from 'hooks';
import { Form, FormItem, FormItems, Grade, ImageUpload, InputPassword } from 'ui/organisms';
import { DateRangePicker, InputPhone } from 'ui/molecules';
import {
  Button,
  Icon,
  Input,
  Select,
  Loader,
  Animated,
  Modal,
  ModalFooterButton,
  RichText,
  Checkbox,
  Alert,
} from 'ui/atoms';
import { FacultiesList } from 'libs/http/api/faculties/faculties.types';

import './EditAccount.scss';

type Views = 'modal' | 'regular';
type Providers = 'session' | 'regular';

interface ContainerProps {
  onClose?: () => void;
  onSuccessGrade?: () => void;
  view: Views;
  provider?: Providers;
  loading: boolean;
  grades: boolean;
  children: React.ReactElement;
  id?: 'my' | 'add' | string | number | null;
  session?: string | number | null;
  role: Roles;
}

const Container: React.FC<ContainerProps> = ({
  view,
  role,
  provider,
  session,
  grades,
  id,
  onClose,
  onSuccessGrade,
  loading,
  children,
}) => {
  const { t } = useIntl();

  if (view === 'modal') {
    return (
      <Modal
        title={t('editProfile')}
        footer={
          <ModalFooterButton align="flex">
            <div>
              {provider === 'session' && session && id && id !== 'my' && id !== 'add' && grades && role !== 'admin' ? (
                <Grade user={id} session={session} onSuccess={onSuccessGrade} />
              ) : null}
            </div>

            <div>
              <Button disabled={loading} onClick={onClose}>
                {t('cancel')}
              </Button>

              <Button submit form="edit-account" type="primary" prefix={<Icon type="check" />} loading={loading}>
                {t('confirm')}
              </Button>
            </div>
          </ModalFooterButton>
        }
        size="large"
      >
        {children}
      </Modal>
    );
  }

  return children;
};

interface Props {
  onClose?: () => void;
  view?: Views;
  provider?: Providers;
  id?: 'my' | 'add' | string | number | null;
  session?: string | number | null;
  role?: 'admin' | 'student' | 'professor' | 'coordinator';
  onSuccess?: () => void;
  onLoadedData?: (data: any) => void;
}

export const EditAccount: React.FC<Props> = ({
  view = 'regular',
  provider = 'regular',
  id,
  session,
  onClose,
  onSuccess,
  role,
  onLoadedData,
}) => {
  const { t } = useIntl();

  const { request: requestInstitutions, data: dataInstitutions, loading: loadingInstitutions } = useRequest<List[]>();
  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<Save>();
  const { request: requestPhoto, loading: loadingPhoto, errors: errorsPhoto } = useRequest<EurasiaFile>();
  const { request: requestGradeFields, data: dataGradeFields, loading: loadingGradeFields } = useRequest<Grades[]>();
  const { request: requestUser, data: dataUser, loading: loadingUser } = useRequest<User>();
  const { request: requestTimeZones, data: dataTimeZones, loading: loadingTimeZones } = useRequest<{
    timezones: string[];
  }>({ data: { timezones: [] } });
  const { request: requestFaculties, data: dataFaculties, loading: loadingFaculties } = useRequest<FacultiesList[]>({
    data: [],
  });

  const { userData } = React.useContext(UserContext);
  const {
    getCountries,
    countries,
    loadingCountries,
    getCities,
    cities,
    loadingCities,
    getLanguages,
    languages,
    loadingLanguages,
    grades,
  } = React.useContext(NomenclaturesContext);

  const [state, setState] = useStateHandlers<UserState>({
    id: null,
    country: null,
    city: null,
    address: '',
    first_name: '',
    last_name: '',
    email: '',
    profile_picture: '',
    vkontakte_url: '',
    telegram: '',
    birth_date: '',
    gender: 'M',
    native_language: [],
    foreign_language: [],
    institutions: [],
    faculties: [],
    allowed_roles: [],
    last_login: '',
    phone_number: '',
    facebook_url: '',
    viber: '',
    skype: '',
    whatsup: '',
    odnoklasniki_url: '',
    linkedin_url: '',
    role: 'student',
    notes: '',
    experience: '1',
    timezone: '',

    course_grade: null,
    individual_work_grade: null,
    practise_grade: null,
  });

  const [signIn, setSignIn] = useStateHandlers({
    password: '',
    confirm_password: '',
  });

  const [gradeChanged, setGradeChanged] = useStateHandlers<{ [key: string]: boolean }>({
    course_grade: false,
    individual_work_grade: false,
    practise_grade: false,
  });

  const [searchCountries, setSearchCountries] = React.useState('');
  const [searchCities, setSearchCities] = React.useState('');

  React.useEffect(() => {
    requestInstitutions(institutions.list.action({ is_active: true }));
    requestTimeZones(countriesAPI.timezones.action({}));
    requestFaculties(faculties.list.action());

    getLanguages();

    return () => {
      sessions.getStudent.cancel();
      users.load.cancel();
      me.changeMyAccount.cancel();
      me.changeAccount.cancel();
      sessions.saveStudent.cancel();
      file.upload.cancel();
      faculties.list.cancel();
    };
  }, []);

  React.useEffect(() => {
    getCountries(searchCountries);
  }, [searchCountries]);

  React.useEffect(() => {
    getCities(state.country, searchCities);
  }, [state.country, searchCities]);

  React.useEffect(() => {
    if (id && id !== 'add' && id !== 'my') {
      if (provider === 'session' && session) {
        requestUser(
          sessions.getStudent.action<User>({ id: session, student: id }),
        );
        requestGradeFields(sessions.getGradeFields.action({ id: Number(session) }));
      }

      if (provider === 'regular') {
        requestUser(users.load.action({ id }));
      }
    }
  }, [provider, id, session]);

  React.useEffect(() => {
    if (role) {
      setState({ role });

      if (id === 'add') {
        setState({ allowed_roles: [role] });
      }
    } else if (id === 'add') {
      setState({ allowed_roles: ['student'] });
    }
  }, [role]);

  React.useEffect(() => {
    if (dataUser) {
      // the grade can be change directly only one time
      setGradeChanged({
        course_grade: dataUser.course_grade !== null,
        individual_work_grade: dataUser.individual_work_grade !== null,
        practise_grade: dataUser.practise_grade !== null,
      });
    }
  }, [dataUser]);

  React.useEffect(() => {
    if (id) {
      const isAccountMine = id === 'my';

      const newState: UserState = JSON.parse(JSON.stringify(isAccountMine ? userData : dataUser));

      if (isAccountMine && userData.country) {
        newState.country = userData.country.id;
      } else if (dataUser.country) {
        newState.country = dataUser.country.id;
      }

      if (isAccountMine && userData.city?.title) {
        newState.city = userData?.city?.title;
      } else if (dataUser.city) {
        newState.city = dataUser?.city?.title;
      }

      if (isAccountMine && Array.isArray(userData.native_language)) {
        newState.native_language = userData.native_language.map(({ id }) => id);
      } else if (Array.isArray(dataUser.native_language)) {
        newState.native_language = dataUser.native_language.map(({ id }) => id);
      }

      if (isAccountMine && Array.isArray(userData.foreign_language)) {
        newState.foreign_language = userData.foreign_language.map(({ id }) => id);
      } else if (Array.isArray(dataUser.foreign_language)) {
        newState.foreign_language = dataUser.foreign_language.map(({ id }) => id);
      }

      if (isAccountMine && Array.isArray(userData.institutions)) {
        newState.institutions = userData.institutions.map(({ id }) => id);
      } else if (Array.isArray(dataUser.institutions)) {
        newState.institutions = dataUser.institutions.map(({ id }) => id);
      }

      if (isAccountMine && Array.isArray(userData.faculties)) {
        newState.faculties = userData.faculties.map(({ id }) => id);
      } else if (Array.isArray(dataUser.faculties)) {
        newState.faculties = dataUser.faculties.map(({ id }) => id);
      }

      newState.phone_number = newState.phone_number ? `+${newState.phone_number.replace(/\+/g, '')}` : '';

      setState(newState);

      if (onLoadedData !== undefined && newState.first_name && newState.last_name) {
        onLoadedData(newState);
      }
    }
  }, [id, userData, dataUser]);

  const onChange = React.useCallback(
    (target: string, value: any): void =>
      setState({
        [target]: value,
        ...(target === 'country' ? { city: null, institutions: [], faculties: [] } : {}),
        ...(target === 'city' ? { institutions: [], faculties: [] } : {}),
      }),
    [],
  );
  const onChangeSignIn = React.useCallback((target: string, value: any): void => setSignIn({ [target]: value }), []);

  const onSubmit = async (): Promise<void> => {
    if (id) {
      const { id: userId, profile_picture: $profilePicture, ...options } = state;
      let profilePicture = $profilePicture;

      if (profilePicture && typeof profilePicture !== 'string') {
        const fileFormData: any = new FormData();
        fileFormData.append('file', profilePicture);

        const { url } = await requestPhoto(file.upload.action(fileFormData));
        profilePicture = url;

        setState({ profile_picture: url });
      }

      if (provider === 'session' && session && id !== 'add') {
        delete options.allowed_roles;
        delete options.address;
        delete options.timezone;
        delete options.role;
      }

      const dataOptions: UserState = {
        ...options,
        address: options.address && options.address.length ? options.address : null,
      };

      delete dataOptions.average_grade;

      if (id === 'my') {
        await requestSave(
          me.changeMyAccount.action({
            ...dataOptions,
            birth_date: dataOptions.birth_date ? toISODate(dataOptions.birth_date, true) : dataOptions.birth_date,
            ...signIn,
            profile_picture: profilePicture,
          }),
        );
      } else if (provider !== 'session' && id === 'add') {
        await requestSave(
          users.create.action({
            ...dataOptions,
            birth_date: dataOptions.birth_date ? toISODate(dataOptions.birth_date, true) : dataOptions.birth_date,
            ...signIn,
            profile_picture: profilePicture,
          }),
        );
      } else if (provider === 'session' && session && id === 'add') {
        await requestSave(
          users.create.action({
            ...dataOptions,
            birth_date: dataOptions.birth_date ? toISODate(dataOptions.birth_date, true) : dataOptions.birth_date,
            ...signIn,
            session: Number(session),
          }),
        );
      } else if (provider === 'session' && session && id !== 'add') {
        await requestSave(
          sessions.saveStudent.action({
            ...dataOptions,
            id: session,
            student: id,
          }),
        );
      } else if (userId) {
        await requestSave(
          users.save.action({
            ...dataOptions,
            birth_date: dataOptions.birth_date ? toISODate(dataOptions.birth_date, true) : dataOptions.birth_date,
            id: userId,
            profile_picture: profilePicture,
          }),
        );
      }

      if (onSuccess !== undefined) {
        onSuccess();
      }
    }
  };

  const onSuccessGrade = (): void => {
    if (onSuccess !== undefined) {
      onSuccess();
    }
  };

  const institutionsOptions = React.useMemo(
    () => dataInstitutions.map((institution) => ({ value: institution.key, title: institution.label })),
    [dataInstitutions],
  );

  const facultiesOptions = React.useMemo(
    () => dataFaculties.map((faculty) => ({ value: faculty.key, title: faculty.label })),
    [dataFaculties],
  );

  const languageOptions = React.useMemo(
    () => languages.map((language) => ({ value: language.key, title: language.label })),
    [languages],
  );

  const timezoneOptions = React.useMemo(
    () => dataTimeZones.timezones.map((timezone) => ({ value: timezone, title: timezone })),
    [dataTimeZones],
  );

  const onChangeAllowedRole = React.useCallback(
    (role: Roles) => {
      if (state.allowed_roles) {
        if (state.role !== 'professor') {
          // set default grade when user is not professor
          onChange('professor', '1');
        }

        onChange(
          'allowed_roles',
          state.allowed_roles.includes(role)
            ? state.allowed_roles.filter((item) => item !== role)
            : [...state.allowed_roles, role],
        );
      }
    },
    [state.allowed_roles],
  );

  const averageGrade = React.useMemo(
    () =>
      (
        ((state.practise_grade && Number.isInteger(state.practise_grade) ? (state.practise_grade as number) : 0) +
          (state.individual_work_grade && Number.isInteger(state.individual_work_grade)
            ? (state.individual_work_grade as number)
            : 0) +
          (state.course_grade && Number.isInteger(state.course_grade) ? (state.course_grade as number) : 0)) /
        Object.keys(gradeChanged).filter((index) => dataGradeFields.includes(index as Grades)).length
      ).toFixed(2),
    [state.practise_grade, state.individual_work_grade, state.course_grade, gradeChanged, dataGradeFields],
  );

  const handleCountriesSearch = (input: string): void => {
    setSearchCountries(input);
  };

  const handleCitiesSearch = (input: string): void => {
    setSearchCities(input);
  };

  return (
    <Container
      onSuccessGrade={onSuccessGrade}
      id={id}
      session={session}
      provider={provider}
      grades={!!Object.keys(gradeChanged).filter((i) => gradeChanged[i]).length}
      view={view}
      loading={loadingSave || loadingPhoto}
      onClose={onClose}
      role={userData.role}
    >
      <>
        <Alert message={dataSave.message || errors.non_field_errors || errors.detail} form type="error" />

        <Form onSubmit={onSubmit} id="edit-account" autoComplete={false}>
          <Loader loading={loadingUser || loadingGradeFields}>
            <div className={`zh-edit-account zh-edit-account-${view}`}>
              <div className="zh-edit-account-left">
                <h2>{t('personalData')}</h2>

                <FormItems>
                  <FormItem required label={t('firstName')} extra={errors.first_name}>
                    <Input
                      styleType="grey"
                      value={state.first_name}
                      onChange={(value) => onChange('first_name', value)}
                    />
                  </FormItem>

                  <FormItem required label={t('lastName')} extra={errors.last_name}>
                    <Input
                      styleType="grey"
                      value={state.last_name}
                      onChange={(value) => onChange('last_name', value)}
                    />
                  </FormItem>
                </FormItems>

                <FormItems>
                  <FormItem flex label={t('dateOfBirth')} extra={errors.birth_date}>
                    <DateRangePicker
                      type="date"
                      date={state.birth_date ? state.birth_date : ''}
                      onChange={({ date__gte: value }) => onChange('birth_date', value)}
                    />
                  </FormItem>

                  <FormItem label={t('sex')} extra={errors.gender}>
                    <Select
                      styleType="grey"
                      placeholder={t('choose')}
                      options={[
                        { value: 'M', title: t('male') },
                        { value: 'F', title: t('female') },
                      ]}
                      value={state.gender}
                      onChange={(value) => onChange('gender', value)}
                      dropdownClassName="hideChecked"
                    />
                  </FormItem>
                </FormItems>

                {provider !== 'session' && (
                  <FormItems>
                    <FormItem label={t('nativeLanguage')} error={errors.native_language}>
                      <Select
                        mode="multiple"
                        cancelable
                        styleType="grey"
                        placeholder={t('selectLanguage')}
                        loading={loadingLanguages}
                        options={languageOptions}
                        value={state.native_language}
                        onChange={(value) => onChange('native_language', value)}
                      />
                    </FormItem>

                    <FormItem label={t('foreignLanguages')} error={errors.foreign_language}>
                      <Select
                        mode="multiple"
                        cancelable
                        styleType="grey"
                        placeholder={t('selectForeignLanguages')}
                        loading={loadingLanguages}
                        options={languageOptions}
                        value={state.foreign_language}
                        onChange={(value) => onChange('foreign_language', value)}
                      />
                    </FormItem>
                  </FormItems>
                )}

                {state.allowed_roles && id !== 'my' && userData.role === 'admin' && (
                  <>
                    <h2>{t('allowedRoles')}</h2>

                    <FormItems>
                      <FormItem>
                        <Checkbox
                          checked={state.allowed_roles.includes('admin')}
                          onChange={() => onChangeAllowedRole('admin')}
                          label={t('admin')}
                        />
                      </FormItem>

                      <FormItem>
                        <Checkbox
                          checked={state.allowed_roles.includes('coordinator')}
                          onChange={() => onChangeAllowedRole('coordinator')}
                          label={t('coordinator')}
                        />
                      </FormItem>

                      <FormItem>
                        <Checkbox
                          checked={state.allowed_roles.includes('professor')}
                          onChange={() => onChangeAllowedRole('professor')}
                          label={t('professor')}
                        />
                      </FormItem>

                      <FormItem>
                        <Checkbox
                          checked={state.allowed_roles.includes('student')}
                          onChange={() => onChangeAllowedRole('student')}
                          label={t('student')}
                        />
                      </FormItem>
                    </FormItems>
                  </>
                )}

                {userData.role === 'admin' &&
                (state.role === 'professor' || state.allowed_roles?.includes('professor')) ? (
                  <FormItem itemClass="mt-25" label={t('experienceGrade')} extra={errors.experience}>
                    <Select
                      getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
                      placeholder={t('note')}
                      options={grades.map((grade) => ({ title: grade, value: grade }))}
                      value={state.experience}
                      onChange={(value) => onChange('experience', value)}
                    />
                  </FormItem>
                ) : null}

                {['professor', 'coordinator'].includes(state.role) && (
                  <FormItem label={t('info')} extra={errors.notes}>
                    <RichText initialValue={state.notes} onChange={(value) => onChange('notes', value)} />
                  </FormItem>
                )}

                <h2>{t('location')}</h2>

                <FormItems>
                  <FormItem label={t('country')} extra={errors.country}>
                    <Select
                      styleType="grey"
                      placeholder={t('selectCountry')}
                      options={countries}
                      onSearch={handleCountriesSearch}
                      loading={loadingCountries}
                      value={state.country}
                      onChange={(value): void => onChange('country', value)}
                    />
                  </FormItem>

                  <FormItem
                    label={t('city')}
                    extra={errors.city ? errors.city : !state.country ? t('selectCountryError') : ''}
                    extraStatus={errors.city ? 'error' : 'regular'}
                  >
                    <Select
                      styleType="grey"
                      placeholder={t('selectCity')}
                      options={state.country ? cities.filter((city) => city.countryID === state.country) : []}
                      onSearch={handleCitiesSearch}
                      loading={loadingCities}
                      value={state.city}
                      onChange={(value): void => onChange('city', value)}
                      disabled={!state.country}
                    />
                  </FormItem>
                </FormItems>

                {id === 'my' && (
                  <FormItem label={t('timezone')} extra={errors.timezone}>
                    <Select
                      styleType="grey"
                      options={timezoneOptions}
                      loading={loadingTimeZones}
                      value={state.timezone}
                      onChange={(value): void => onChange('timezone', value)}
                    />
                  </FormItem>
                )}

                {provider !== 'session' && ((id !== 'add' && role === 'coordinator') || role !== 'coordinator') && (
                  <>
                    <FormItem label={t('institute')} className="mb-20" error={errors.institutions}>
                      <Select
                        styleType="grey"
                        cancelable
                        mode="multiple"
                        placeholder={t('selectInstitute')}
                        options={institutionsOptions}
                        loading={loadingInstitutions}
                        value={state.institutions}
                        onChange={(value) => onChange('institutions', value)}
                      />
                    </FormItem>

                    <FormItem label={t('faculties')} className="mb-20" error={errors.faculties}>
                      <Select
                        styleType="grey"
                        cancelable
                        mode="multiple"
                        placeholder={t('selectFaculties')}
                        options={facultiesOptions}
                        loading={loadingFaculties}
                        value={state.faculties}
                        onChange={(value) => onChange('faculties', value)}
                      />
                    </FormItem>
                  </>
                )}

                {provider !== 'session' && session && id !== 'add' && (
                  <FormItem label={t('fullAddress')} extra={errors.address}>
                    <Input
                      styleType="grey"
                      value={state.address || ''}
                      onChange={(value) => onChange('address', value)}
                    />
                  </FormItem>
                )}

                <h2>{t('contactData')} *</h2>
                <FormItems>
                  <FormItem flex label="E-mail" extra={errors.email}>
                    <Input styleType="grey" value={state.email} onChange={(value) => onChange('email', value)} />
                  </FormItem>

                  <FormItem flex label={t('phone')} extra={errors.phone_number}>
                    <InputPhone
                      value={state.phone_number}
                      onChange={(value: string): void => onChange('phone_number', value)}
                    />
                  </FormItem>
                </FormItems>

                {provider === 'session' && id !== 'my' && id !== 'add' ? (
                  <>
                    <h3>{t('notes2')}</h3>

                    <FormItems>
                      {dataGradeFields.includes('course_grade') && (
                        <FormItem flex label={t('noteForCourse')} extra={errors.course_grade}>
                          <Input
                            styleType="disabled"
                            value={state.course_grade}
                            {...(!gradeChanged.course_grade || userData.role === 'admin'
                              ? {
                                  onChange: (value) => onChange('course_grade', grade(value)),
                                }
                              : {
                                  disabled: true,
                                })}
                          />
                        </FormItem>
                      )}

                      {dataGradeFields.includes('individual_work_grade') && (
                        <FormItem flex label={t('noteForIndividualWork')} extra={errors.individual_work_grade}>
                          <Input
                            styleType="disabled"
                            value={state.individual_work_grade}
                            {...(!gradeChanged.individual_work_grade || userData.role === 'admin'
                              ? {
                                  onChange: (value) => onChange('individual_work_grade', grade(value)),
                                }
                              : {
                                  disabled: true,
                                })}
                          />
                        </FormItem>
                      )}

                      {dataGradeFields.includes('practise_grade') && (
                        <FormItem flex label={t('practiceGradeShort')} extra={errors.practise_grade}>
                          <Input
                            styleType="disabled"
                            value={state.practise_grade}
                            {...(!gradeChanged.practise_grade || userData.role === 'admin'
                              ? {
                                  onChange: (value) => onChange('practise_grade', grade(value)),
                                }
                              : {
                                  disabled: true,
                                })}
                          />
                        </FormItem>
                      )}

                      <FormItem flex label={t('averageGrade')}>
                        <Input styleType="disabled" value={averageGrade} disabled />
                      </FormItem>
                    </FormItems>
                  </>
                ) : null}

                {provider !== 'session' && (
                  <>
                    <h2>{t('otherContacts')}</h2>
                    <FormItems>
                      <FormItem flex label="Telegram" extra={errors.telegram}>
                        <Input
                          styleType="grey"
                          value={state.telegram}
                          onChange={(value) => onChange('telegram', value)}
                        />
                      </FormItem>

                      <FormItem flex label="Skype" extra={errors.skype}>
                        <Input styleType="grey" value={state.skype} onChange={(value) => onChange('skype', value)} />
                      </FormItem>
                    </FormItems>

                    <FormItems>
                      <FormItem flex label="WhatsApp" extra={errors.whatsup}>
                        <Input
                          styleType="grey"
                          value={state.whatsup}
                          onChange={(value) => onChange('whatsup', value)}
                        />
                      </FormItem>

                      <FormItem flex label="Viber" extra={errors.viber}>
                        <Input styleType="grey" value={state.viber} onChange={(value) => onChange('viber', value)} />
                      </FormItem>
                    </FormItems>

                    <h2>{t('socialsNetworks')}</h2>
                    <FormItems>
                      <FormItem flex label="Facebook" extra={errors.facebook_url}>
                        <Input
                          styleType="grey"
                          value={state.facebook_url}
                          onChange={(value) => onChange('facebook_url', value)}
                        />
                      </FormItem>

                      <FormItem flex label="Vkontakte" extra={errors.vkontakte_url}>
                        <Input
                          styleType="grey"
                          value={state.vkontakte_url}
                          onChange={(value) => onChange('vkontakte_url', value)}
                        />
                      </FormItem>
                    </FormItems>

                    <FormItems>
                      <FormItem flex label="Odnoklassniki" extra={errors.odnoklasniki_url}>
                        <Input
                          styleType="grey"
                          value={state.odnoklasniki_url}
                          onChange={(value) => onChange('odnoklasniki_url', value)}
                        />
                      </FormItem>

                      <FormItem flex label="Linkedin" extra={errors.linkedin_url}>
                        <Input
                          styleType="grey"
                          value={state.linkedin_url}
                          onChange={(value) => onChange('linkedin_url', value)}
                        />
                      </FormItem>
                    </FormItems>
                  </>
                )}

                {typeof id === 'string' && ['add', 'my'].includes(id) ? (
                  <>
                    <h2 className="block">{t('password')} *</h2>
                    <FormItems>
                      <FormItem flex label={t('password')} extra={errors.password}>
                        <InputPassword
                          styleType="grey"
                          value={signIn.password}
                          onChange={(value) => onChangeSignIn('password', value)}
                        />
                      </FormItem>

                      <FormItem
                        flex
                        label={t('confirm_password')}
                        required={signIn.password ? true : undefined}
                        extra={errors.confirm_password}
                      >
                        <InputPassword
                          styleType="grey"
                          value={signIn.confirm_password}
                          onChange={(value) => onChangeSignIn('confirm_password', value)}
                        />
                      </FormItem>
                    </FormItems>
                  </>
                ) : null}
              </div>

              {provider !== 'session' && (
                <div className="zh-edit-account-right">
                  <FormItem extra={errorsPhoto.file || errors.profile_picture}>
                    <Animated loading={loadingUser}>
                      <ImageUpload
                        alt={`${state.first_name} ${state.last_name}`}
                        defaultPreview={state.profile_picture}
                        onChange={(value) => onChange('profile_picture', value)}
                      />
                    </Animated>
                  </FormItem>
                </div>
              )}
            </div>

            {view !== 'modal' && (
              <div className="zh-edit-account-bottom">
                <Button disabled={loadingSave || loadingPhoto} onClick={onClose}>
                  {t('cancel')}
                </Button>

                <Button submit type="primary" prefix={<Icon type="check" />} loading={loadingSave || loadingPhoto}>
                  {t('confirm')}
                </Button>
              </div>
            )}
          </Loader>
        </Form>
      </>
    </Container>
  );
};
