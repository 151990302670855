import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { Report } from './reports.types';

export const reports = {
  add: {
    action: (params: Report) =>
      axios.post(`reports`, params, {
        cancelToken: new cancelToken((c: Canceler) => (reports.add.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  patch: {
    action: ({ id, ...params }: Report) =>
      axios.patch(`reports/${id}`, params, {
        cancelToken: new cancelToken((c: Canceler) => (reports.patch.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getById: {
    action: ({ id }: { id: number }): Promise<{ data: Report }> =>
      axios.get(`reports/${id}`, { cancelToken: new cancelToken((c: Canceler) => (reports.getById.cancel = c)) }),
    cancel: (() => null) as Canceler,
  },
};
