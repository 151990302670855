export const nomenclatures = {
  session: {
    en: 'Session',
    ro: 'Sesiune',
    ru: 'Сессия',
    es: 'Sesión',
  },
  conference: {
    en: 'Conference',
    ro: 'Conferinţă',
    ru: 'Конференция',
    es: 'Conferencia',
  },
  camp: {
    en: 'Camp',
    ro: 'tabără',
    ru: 'кэмп',
    es: 'Campamento',
  },
  meeting: {
    en: 'Meeting',
    ro: 'Întâlnire',
    ru: 'Собрание',
    es: 'Reunión',
  },
  professor_training: {
    en: 'Prof. training',
    ro: 'Formare profesională',
    ru: 'Проф. обучение',
    es: 'Entrenamiento de maestro',
  },
  forms: {
    en: 'Forms',
    ro: 'Forme',
    ru: 'Формы',
    es: 'Formularios',
  },
  student: {
    en: 'Student',
    ro: 'Student',
    ru: 'Студент',
    es: 'Estudiante',
  },
  professor: {
    en: 'Professor',
    ro: 'Profesor',
    ru: 'Профессор',
    es: 'Maestro',
  },
  admin: {
    en: 'Administrator',
    ro: 'Administrator',
    ru: 'Администратор',
    es: 'Administrador',
  },
  ro: {
    en: 'Romanian',
    ro: 'Română',
    ru: 'Румынский',
    es: 'Rumano',
  },
  en: {
    en: 'English',
    ro: 'Engleză',
    ru: 'Английский',
    es: 'Inglés',
  },
  ru: {
    en: 'Russian',
    ro: 'Rusă',
    ru: 'Русский',
    es: 'Ruso',
  },
  es: {
    en: 'Spanish',
    ro: 'Spaniolă',
    ru: 'Испанский',
    es: 'Español',
  },
  course_grade: {
    en: 'Course',
    ro: 'Curs',
    ru: 'Курс',
    es: 'Calificación del curso',
  },
  individual_work_grade: {
    en: 'Individual Work',
    ro: 'Lucrare individuală',
    ru: 'Индивидуальная работа',
    es: 'Calificación del trabajo individual',
  },
  practise_grade: {
    en: 'Practise',
    ro: 'Practică',
    ru: 'Практика',
    es: 'Calificación de la práctica',
  },
  practise_sub_grade_1: {
    en: 'Practise 1',
    ro: 'Practică 1',
    ru: 'Практика 1',
    es: 'Calificación de la práctica 1',
  },
  practise_sub_grade_2: {
    en: 'Practise 2',
    ro: 'Practică 2',
    ru: 'Практика 2',
    es: 'Calificación de la práctica 2',
  },
  practise_sub_grade_3: {
    en: 'Practise 3',
    ro: 'Practică 3',
    ru: 'Практика 3',
    es: 'Calificación de la práctica 3',
  },
  practise_sub_grade_4: {
    en: 'Practise 4',
    ro: 'Practică 4',
    ru: 'Практика 4',
    es: 'Calificación de la práctica 4',
  },
  practise_sub_grade_5: {
    en: 'Practise 5',
    ro: 'Practică 5',
    ru: 'Практика 5',
    es: 'Calificación de la práctica 5',
  },
  request_accepted: {
    en: 'No teacher',
    ro: 'Lipsește profesor',
    ru: 'Нет учителя',
    es: 'Solicitud aceptada',
  },
  change_grade: {
    en: 'Change',
    ro: 'Schimbați',
    ru: 'Изменить',
    es: 'Cambio de calificación',
  },
  old_grade: {
    en: 'Old grade',
    ro: 'Grad vechi',
    ru: 'Старая оценка',
    es: 'Calificación antigua',
  },
};
