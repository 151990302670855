import * as React from 'react';
import { Head } from 'ui/organisms';

import { SessionsApplicationTemplate } from '../templates';
import { SessionsLayout } from '../organisms';

export const SessionsApplicationPage: React.FC = () => (
  <>
    <Head t="registerSession" />

    <SessionsLayout showTabs={false}>
      <SessionsApplicationTemplate />
    </SessionsLayout>
  </>
);
