import * as React from 'react';
import { notify } from 'react-notify-toast';
import { useRequest } from 'estafette';
import { Link, useHistory } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { useFilters } from 'hooks';
import { NotificationsContext, UserContext } from 'contexts';
import { format, dateTimeFormat, toISODate } from 'libs/date';
import { sessions } from 'libs/http/api/sessions/sessions';
import { Results } from 'libs/http/api/index.types';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { parseQuery } from 'libs/object';
import { perPage } from 'libs/storage';
import { SortBy, InputSearch, Confirmation, Actions } from 'ui/organisms';
import { DateRangePicker } from 'ui/molecules';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import {
  Status,
  Info,
  Card,
  CardHeader,
  CardFooter,
  Icon,
  Button,
  Table,
  Loader,
  Time,
  CardAlert,
  AvatarInline,
} from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';
import { Expande } from 'ui/atoms/Table/Expanded';

const { page = 1 } = parseQuery<{ page: number }>(window.location.search);

export const SessionApplicationsTemplate: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { userData } = React.useContext(UserContext);
  const { onFetchSessionRequestsCounts } = React.useContext(NotificationsContext);
  const { request: requestRejectApplication, loading: loadingRejectApplication } = useRequest();
  const {
    request: requestAcceptApplication,
    errors: errorsAcceptApplication,
    loading: loadingAcceptApplication,
  } = useRequest();
  const { request, data, loading } = useRequest<Results<Session>>({ data: { results: [] } });
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    start_date__date__gte: '',
    end_date__date__lte: '',
    ordering: '' as Sorts,
  });

  const [selectedId, setSelectedId] = React.useState<number | null>(null);
  const [filters, setFilters] = useFilters({ ...initialFilters.current, page });

  React.useEffect(() => {
    return () => {
      sessions.requests.cancel();
      sessions.save.cancel();
    };
  }, []);

  React.useEffect(() => {
    request(
      sessions.requests.action({
        ...filters,
        start_date__date__gte: filters.start_date__date__gte ? toISODate(filters.start_date__date__gte, true) : '',
        end_date__date__lte: filters.end_date__date__lte ? toISODate(filters.end_date__date__lte, true) : '',
      }),
    );
  }, [filters]);

  React.useEffect(() => {
    if (errorsAcceptApplication.status) {
      notify.show(errorsAcceptApplication.status, 'error');
    }
  }, [errorsAcceptApplication]);

  const onChangePeriod = (newValue: any): void => {
    setFilters({
      filtered: true,
      page: 1,
      ...(newValue.start_date__gte ? { start_date__date__gte: newValue.start_date__gte } : {}),
      ...(newValue.end_date__lte ? { end_date__date__lte: newValue.end_date__lte } : {}),
    });
  };

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onRejectApplication = React.useCallback(async (id: number): Promise<void> => {
    setSelectedId(id);

    await requestRejectApplication(sessions.save.action({ id, status: 'declined' }));
    await onRefetch();

    onFetchSessionRequestsCounts();

    setSelectedId(null);
  }, []);

  const onAcceptApplication = React.useCallback(async (id: number): Promise<void> => {
    setSelectedId(id);

    await requestAcceptApplication(sessions.save.action({ id, status: 'request_accepted' }));
    await onRefetch();

    onFetchSessionRequestsCounts();

    setSelectedId(null);
  }, []);

  const onManagementSession = (id: number): void =>
    push('AddSessionPage', { action: id, query: { page: filters.page } });

  const columns: Column<Session>[] = React.useMemo(
    () =>
      userData.role
        ? ['admin', 'professor', 'student'].includes(userData.role)
          ? [
              {
                title: t('period'),
                width: 240,
                render: (record) => (
                  <Time
                    date={`${format(record.start_date, dateTimeFormat)} - ${format(record.end_date, dateTimeFormat)}`}
                    noParse
                    className="nowrap"
                  />
                ),
                className: 'zh-time-period-cell nowrap',
              },
              {
                title: t('teacher'),
                render: ({ professor }) =>
                  professor ? (
                    <AvatarInline
                      img={professor.profile_picture}
                      alt={[professor.first_name, professor.last_name].filter((i) => i).join(' ')}
                      size="small"
                    />
                  ) : (
                    '---'
                  ),
              },
              {
                title: t('place'),
                render: ({ country, city }) =>
                  country && country.title ? (
                    <Info
                      icon={<Icon type="pin" />}
                      label={[country && country.title, city && city.title].filter((i) => i).join(', ')}
                    />
                  ) : (
                    '---'
                  ),
              },
              {
                title: t('institute'),
                render: ({ institution }) => (institution && institution.name) || '---',
              },
              {
                title: t('course'),
                render: ({ course }) => (course && course.name) || '---',
              },
              {
                action: true,
                render: ({ id, status, is_bible_group: isBibleGroup, ...session }) => (
                  <div className="flex flex-justify-end flex-margin-between flex-nowrap nowrap">
                    <Link className="nowrap" route="GeneralSessionPage" params={{ id }}>
                      {t('details')}
                    </Link>

                    {userData.role === 'admin' ? (
                      <>
                        <Confirmation onConfirm={() => onRejectApplication(id)}>
                          <Button
                            size="small"
                            prefix={<Icon type="cancel" />}
                            loading={loadingRejectApplication && selectedId === id}
                          >
                            {t('reject')}
                          </Button>
                        </Confirmation>

                        <Confirmation
                          isBibleGroup={isBibleGroup}
                          expanded={expandable(session as Session)}
                          onConfirm={() => onAcceptApplication(id)}
                        >
                          <Button
                            size="small"
                            type="primary"
                            prefix={<Icon type="check" />}
                            loading={loadingAcceptApplication && selectedId === id}
                          >
                            {t('accept')}
                          </Button>
                        </Confirmation>
                      </>
                    ) : (
                      <Status label={t(status)} status={status} />
                    )}
                  </div>
                ),
                className: 'nowrap',
              },
            ]
          : [
              {
                title: t('period'),
                width: 240,
                render: ({ start_date: startDate, end_date: endDate }) => (
                  <Time
                    date={[startDate && format(startDate, dateTimeFormat), endDate && format(endDate, dateTimeFormat)]
                      .filter((i) => i)
                      .join(' - ')}
                    noParse
                  />
                ),
                className: 'zh-time-period-cell nowrap',
              },
              {
                title: t('language'),
                render: ({ language }) => (language && language.name) || '---',
              },
              {
                title: t('institute'),
                render: ({ institution }) => (institution && institution.name) || '---',
              },
              {
                title: t('faculty'),
                render: ({ faculty }) => (faculty && faculty.name) || '---',
              },
              {
                title: t('course'),
                render: ({ course }) => (course && course.name) || '---',
              },
              {
                title: t('status'),
                width: 120,
                render: ({ status }) => <Status status={status} label={t(status)} />,
              },
              {
                action: true,
                render: ({ id, status }) => (
                  <div className="flex flex-justify-end flex-margin-between flex-nowrap">
                    <Link className="nowrap" route="GeneralSessionPage" params={{ id }}>
                      {t('details')}
                    </Link>

                    {['admin', 'coordinator'].includes(userData.role) && status === 'pending' && (
                      <Actions onEdit={() => onManagementSession(id)} />
                    )}
                  </div>
                ),
                className: 'nowrap',
              },
            ]
        : [],
    [t, userData.role],
  );

  const expandable = ({
    professor,
    start_date: startDate,
    end_date: endDate,
    country,
    city,
    course,
    institution,
    language,
    faculty,
  }: Session): Expande => [
    [
      { label: t('course'), value: (course && course.name) || '---', icon: <Icon type="requests" /> },
      {
        label: t('startAt'),
        value: startDate ? format(startDate, dateTimeFormat) : '---',
        icon: <Icon type="calendar" />,
      },
      {
        label: t('finishAt'),
        value: startDate ? format(endDate, dateTimeFormat) : '---',
        icon: <Icon type="calendar" />,
      },
    ],
    [
      {
        label: t('teacher'),
        value: professor ? [professor.first_name, professor.last_name].filter((i) => i).join(' ') : '---',
      },
      {
        label: t('place'),
        value:
          country && country.title ? [country && country.title, city && city.title].filter((i) => i).join(', ') : '---',
        icon: <Icon type="pin" />,
      },
      {
        label: t('faculty'),
        value: faculty ? faculty.name : '---',
      },
    ],
    [
      { label: t('institute'), value: (institution && institution.name) || '---', icon: <Icon type="location" /> },
      { label: t('language'), value: (language && language.name) || '---' },
    ],
  ];

  return (
    <Card>
      <CardHeader
        title={t('sessionRequests')}
        count={data.count}
        leftSide={<InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />}
        rightSide={
          <>
            <div className="picker-wrapper">
              <div className="hideTablet">{t('period')}</div>
              <DateRangePicker
                from={filters.start_date__date__gte}
                to={filters.end_date__date__lte}
                type="period"
                onChange={onChangePeriod}
              />
            </div>
            <SortBy
              options={
                userData.role &&
                (userData.role === 'admin'
                  ? [
                      {
                        title: t('name'),
                        value: 'name',
                      },
                      {
                        title: t('country'),
                        value: 'country',
                      },
                      {
                        title: t('city'),
                        value: 'city',
                      },
                      {
                        title: t('startAt'),
                        value: 'start_date',
                      },
                      {
                        title: t('finishAt'),
                        value: 'end_date',
                      },
                      {
                        title: t('created_at'),
                        value: 'created_at',
                      },
                    ]
                  : userData.role === 'coordinator'
                  ? [
                      {
                        title: t('language'),
                        value: 'language',
                      },
                      {
                        title: t('institute'),
                        value: 'institution',
                      },
                      {
                        title: t('faculty'),
                        value: 'faculty',
                      },
                    ]
                  : [])
              }
              value={filters.ordering}
              onChange={(newValue) => onChangeFilters('ordering', newValue)}
            />
          </>
        }
      />

      <Loader loading={loading}>
        <Table size="small" page={filters.page} data={data.results} columns={columns} />

        <CardFooter
          onRefresh={onRefetch}
          onRefreshDisabled={!filters.filtered}
          page={filters.page}
          pages={data.total_pages}
          perPage={filters.per_page}
          onChangePerPage={onChangePerPage}
          rightSide={
            <>
              <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                {t('previous')}
              </Button>

              <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                {t('next')}
              </Button>
            </>
          }
        />
      </Loader>

      <CardAlert label={t('didYouFindProblem')} t="reportApplicationsSessions" />
    </Card>
  );
};
