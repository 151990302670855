import * as React from 'react';
import { format } from 'libs/date';
import { Info, Icon } from 'ui/atoms';

interface Props {
  className?: string;
  loading?: boolean;
  date: string;
  format?: string;
  noParse?: boolean;
}

export const Time: React.FC<Props> = ({ className = '', loading, date, format: $format, noParse }) => (
  <Info
    className={className}
    loading={loading}
    icon={<Icon type="clock" />}
    label={date ? (noParse ? date : format(date, $format)) : '---'}
  />
);
