import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { Link } from 'estafette-router';
import { useFilters } from 'hooks';
import { NomenclaturesContext } from 'contexts';
import { me, institutions, faculties } from 'libs/http/api';
import { User } from 'libs/http/api/me/me.types';
import { Results, List } from 'libs/http/api/index.types';
import { FacultiesList } from 'libs/http/api/faculties/faculties.types';
import { renderPhone } from 'libs/mask';
import { perPage } from 'libs/storage';
import { SendMessage, Form, FormItem, FormItems, Head } from 'ui/organisms';
import { Select, Card, CardHeader, CardFooter, Table, AvatarInline, Input, Icon, Button, Info, Loader } from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';
import { Expande } from 'ui/atoms/Table/Expanded';

import { MyStudentsLayout } from '../organisms';

const initialSearch = {
  search: '',
  country: null,
  city: null,
  institutions: [],
  faculties: [],
};

export const MyStudentsUsersPage: React.FC = () => {
  const { t } = useIntl();
  const { request, data, loading } = useRequest<Results<User>>({ data: { results: [] } });
  const { getCountries, countries, loadingCountries, getCities, cities, loadingCities } = React.useContext(
    NomenclaturesContext,
  );
  const { request: requestInstitutions, data: dataInstitutions, loading: loadingInstitutions } = useRequest<List[]>();
  const { request: requestFaculties, data: dataFaculties, loading: loadingFaculties } = useRequest<FacultiesList[]>();
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    ...initialSearch,
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });
  const [search, setSearch] = useFilters({ ...initialSearch });

  const [searchCountries, setSearchCountries] = React.useState('');
  const [searchCities, setSearchCities] = React.useState('');

  React.useEffect(() => {
    requestFaculties(faculties.list.action());
    requestInstitutions(institutions.list.action({ is_active: true }));

    return () => {
      faculties.list.cancel();
      institutions.list.cancel();
      me.getStudents.cancel();
    };
  }, []);

  React.useEffect(() => {
    getCountries(searchCountries);
  }, [searchCountries]);

  React.useEffect(() => {
    getCities(search.country, searchCities);
  }, [search.country, searchCities]);

  React.useEffect(() => {
    request(
      me.getStudents.action({
        ...filters,
        institutions: filters.institutions.join(','),
        faculties: filters.faculties.join(','),
      }),
    );
  }, [filters]);

  const onChangeFilters = React.useCallback(
    (searchFilters): void => setFilters({ filtered: true, page: 1, ...searchFilters }),
    [],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const handleCountriesSearch = (input: string): void => {
    setSearchCountries(input);
  };

  const handleCitiesSearch = (input: string): void => {
    setSearchCities(input);
  };

  const onChangeSearch = React.useCallback(
    (target: string, value: any): void =>
      setSearch({
        [target]: value,
        ...(target === 'country' ? { city: null, institutions: [], faculties: [] } : {}),
        ...(target === 'city' ? { institutions: [], faculties: [] } : {}),
        ...(target === 'institution' ? { faculties: [] } : {}),
      }),
    [],
  );

  const columns: Column<User>[] = React.useMemo(
    () => [
      {
        title: t('nameAndSurname'),
        render: ({ first_name: firstName, last_name: lastName, profile_picture: profilePictrue }): React.ReactNode => (
          <AvatarInline img={profilePictrue} alt={[firstName, lastName].filter((i) => i).join(' ')} size="small" />
        ),
      },
      {
        title: 'E-mail',
        render: ({ email }): React.ReactNode => (
          <div className="nowrap">
            <Info icon={<Icon type="mail-filled" />} label={email || '---'} />
          </div>
        ),
      },
      {
        title: t('phone'),
        render: ({ phone_number: phoneNumber }): React.ReactNode => (
          <div className="nowrap">
            <Info icon={<Icon type="phone" />} label={phoneNumber ? renderPhone(phoneNumber) : '---'} />
          </div>
        ),
      },
      {
        title: t('numberOfSessionsCompleted'),
        width: 265,
        render: ({ passed_sessions: passedSessions }) => (passedSessions && <b>{passedSessions}</b>) || '---',
      },
      {
        action: true,
        render: ({ id, is_deleted }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <Link className="nowrap" route="ProfilePage" params={{ action: id }}>
              {t('details')}
            </Link>

            {!is_deleted && <SendMessage users={id ? [id] : []} />}
          </div>
        ),
        className: 'nowrap',
      },
    ],
    [t],
  );

  const institutionsOptions = React.useMemo(
    () => dataInstitutions.map((institution) => ({ value: institution.key, title: institution.label })),
    [dataInstitutions],
  );

  const facultiesOptions = React.useMemo(
    () => dataFaculties.map((faculty) => ({ value: faculty.key, title: faculty.label })),
    [dataFaculties],
  );

  const expandable = ({
    institutions,
    vkontakte_url: vkUrl,
    telegram,
    gender,
    native_language: nativeLanguage,
    foreign_language: foreignLanguage,
    last_login: lastLogin,
    phone_number: phoneNumber,
    facebook_url: fbUrl,
    viber,
    skype,
    whatsup,
    odnoklasniki_url: odnklUrl,
    linkedin_url: linkedinUrl,
    address,
    birth_date,
  }: User): Expande => [
    [
      {
        label: t('institutes'),
        value:
          institutions && institutions.length
            ? institutions.map((institute, i) => (
                <div key={i}>
                  <Link route="GeneralInstitutePage" params={{ id: institute.id }}>
                    {institute.name}
                  </Link>
                </div>
              ))
            : '---',
        icon: <Icon type="location" />,
      },
      { label: t('phone'), value: phoneNumber ? renderPhone(phoneNumber) : '---' },
      { label: t('lastLogin'), value: lastLogin ? lastLogin : '---' },
    ],
    [
      {
        label: t('sex'),
        value: gender === 'M' ? t('male') : t('female'),
        icon: <Icon type={`gender-${gender === 'M' ? 'male' : 'female'}`} />,
      },
      { label: t('dateOfBirth'), value: birth_date ? birth_date : '---', icon: <Icon type="calendar" /> },
      { label: t('address'), value: address ? address : '---', icon: <Icon type="pin" /> },
      {
        label: t('nativeLanguage'),
        value: nativeLanguage.length ? nativeLanguage.map(({ name }) => name).join(', ') : '---',
      },
      {
        label: t('foreignLanguages'),
        value: foreignLanguage.length ? foreignLanguage.map(({ name }) => name).join(', ') : '---',
      },
    ],
    [
      { icon: <Icon type="facebook" />, value: fbUrl },
      { icon: <Icon type="linkedin" />, value: linkedinUrl },
      { icon: <Icon type="odnoklasniki" />, value: odnklUrl },
      { icon: <Icon type="vk" />, value: vkUrl },
      { icon: <Icon type="telegram" />, value: telegram },
      { icon: <Icon type="whatsapp" />, value: whatsup },
      { icon: <Icon type="skype" />, value: skype },
      { icon: <Icon type="viber" />, value: viber },
    ],
  ];

  return (
    <>
      <Head t="myStudents" />

      <MyStudentsLayout>
        <h1>{t('searchStudent')}</h1>

        <Form onSubmit={() => onChangeFilters(search)}>
          <FormItems>
            <FormItem label={t('rapidSearch')} className="mb-20">
              <Input
                suffix={<Icon type="search" />}
                placeholder={t('findStudent')}
                value={search.search}
                onChange={(value) => onChangeSearch('search', value)}
                width={240}
                styleType="grey"
              />
            </FormItem>

            <FormItem label={t('country')} className="mb-20">
              <Select
                cancelable
                cancelValue={null}
                styleType="grey"
                placeholder={t('selectCountry')}
                options={countries}
                onSearch={handleCountriesSearch}
                loading={loadingCountries}
                value={search.country}
                onChange={(value) => onChangeSearch('country', value)}
              />
            </FormItem>

            <FormItem label={t('city')} className="mb-20">
              <Select
                cancelable
                cancelValue={null}
                styleType="grey"
                placeholder={t('selectCity')}
                onSearch={handleCitiesSearch}
                options={search.country ? cities.filter((city) => city.countryID === search.country) : []}
                loading={loadingCities}
                value={search.city}
                onChange={(value) => onChangeSearch('city', value)}
                disabled={!search.country}
              />
            </FormItem>

            <FormItem label={t('institute')} className="mb-20">
              <Select
                cancelable
                cancelValue={null}
                styleType="grey"
                mode="multiple"
                placeholder={t('selectInstitute')}
                options={institutionsOptions}
                loading={loadingInstitutions}
                value={search.institutions}
                onChange={(value) => onChangeSearch('institutions', value)}
              />
            </FormItem>

            <FormItem label={t('faculty')} className="mb-20">
              <Select
                cancelable
                cancelValue={null}
                styleType="grey"
                mode="multiple"
                placeholder={t('selectFaculty')}
                options={facultiesOptions}
                loading={loadingFaculties}
                value={search.faculties}
                onChange={(value) => onChangeSearch('faculties', value)}
              />
            </FormItem>

            <FormItem itemClass="flex flex-end" className="mb-20" style={{ width: 'auto' }}>
              <Button type="invert" submit>
                {t('search')}
              </Button>
            </FormItem>
          </FormItems>
        </Form>

        <hr />

        <Card>
          <CardHeader className="mb-5" title={t('students')} count={data.count} />

          <Loader loading={loading}>
            <Table size="small" page={filters.page} data={data.results} columns={columns} expandable={expandable} />

            <CardFooter
              onRefresh={onRefetch}
              onRefreshDisabled={!filters.filtered}
              page={filters.page}
              pages={data.total_pages}
              perPage={filters.per_page}
              onChangePerPage={onChangePerPage}
              rightSide={
                <>
                  <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                    {t('previous')}
                  </Button>

                  <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                    {t('next')}
                  </Button>
                </>
              }
            />
          </Loader>
        </Card>
      </MyStudentsLayout>
    </>
  );
};
