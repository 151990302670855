import * as React from 'react';
import { Link } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { useRequest } from 'estafette';
import { useFilters } from 'hooks';
import { perPage } from 'libs/storage';
import { Results } from 'libs/http/api/index.types';
import { EurasiaInstitutionForPlan } from 'libs/http/api/institutions/institutions.types';
import { institutions } from 'libs/http/api';
import { dateFormat, format } from 'libs/date';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { Column } from 'ui/atoms/Table/Table';
import { InputSearch, SortBy } from 'ui/organisms';
import { AvatarInline, Button, Card, CardAlert, CardFooter, CardHeader, Loader, Table } from 'ui/atoms';

import './InstituteWithoutPlan.scss';

export const InstituteWithoutPlanTemplate: React.FC = () => {
  const { t } = useIntl();
  const { request, data, loading } = useRequest<Results<EurasiaInstitutionForPlan>>({ data: { results: [] } });

  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    ordering: '' as Sorts,
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  React.useEffect(() => {
    request(
      institutions.getInstituteWithoutPlan.action({
        ...filters,
      }),
    );
  }, [filters]);

  const columns: Column<EurasiaInstitutionForPlan>[] = React.useMemo(
    () => [
      {
        title: t('name'),
        render: ({ id, name }): React.ReactNode => (
          <Link route="GeneralInstitutePage" params={{ id }}>
            {name}
          </Link>
        ),
      },
      {
        title: t('coordinator'),
        render: ({ coordinator, id }): React.ReactNode => (
          <Link route="GeneralInstitutePage" params={{ id }}>
            {coordinator ? (
              <AvatarInline
                img={coordinator.profile_picture}
                alt={[coordinator.first_name, coordinator.last_name].filter((i) => i).join(' ')}
                size="small"
              />
            ) : (
              '---'
            )}
          </Link>
        ),
      },
      {
        title: t('session'),
        render: ({ last_session }): React.ReactNode => (
          <Link route="GeneralSessionPage" params={{ id: last_session.id }}>
            {last_session.name}
          </Link>
        ),
      },
      {
        title: <div className="text-right">{t('last_session_date')}</div>,
        className: 'text-right',
        render: ({ last_session }): React.ReactNode =>
          last_session.end_date ? format(last_session.end_date, dateFormat) : '---',
      },
    ],
    [t],
  );

  return (
    <Card className="mb-30">
      <CardHeader
        title={`${t('needToPlan')}`}
        count={data.count}
        leftSide={<InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />}
        rightSide={
          <SortBy
            options={[
              {
                title: t('name'),
                value: 'name',
              },
            ]}
            value={filters.ordering}
            onChange={(newValue) => onChangeFilters('ordering', newValue)}
          />
        }
      />
      <Loader loading={loading}>
        <Table size="small" page={undefined} data={data.results} columns={columns} scroll={{ x: true }} />
        <CardFooter
          onRefresh={onRefetch}
          onRefreshDisabled={true}
          page={filters.page}
          pages={data.total_pages}
          perPage={filters.per_page}
          onChangePerPage={onChangePerPage}
          rightSide={
            <>
              <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                {t('previous')}
              </Button>

              <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                {t('next')}
              </Button>
            </>
          }
        />
        <CardAlert label={t('didYouFindProblem')} t="reportPlannedSessions" />
      </Loader>
    </Card>
  );
};
