import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useHistory, Link } from 'estafette-router';
import { useFilters } from 'hooks';
import { institutions, users } from 'libs/http/api';
import { Results, List } from 'libs/http/api/index.types';
import { User } from 'libs/http/api/me/me.types';
import { renderPhone } from 'libs/mask';
import { perPage } from 'libs/storage';
import { SortBy, SendMessage, Actions, Head } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { Select, Card, CardHeader, CardFooter, Table, AvatarInline, Input, Icon, Button, Info, Loader } from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';
import { Expande } from 'ui/atoms/Table/Expanded';

import { UsersLayout } from '../organisms';

export const TeachersUsersPage: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { request, data, loading } = useRequest<Results<User>>({ data: { results: [] } });
  const { request: requestDelete, loading: loadingDelete } = useRequest();
  const { request: requestInstitutions, data: dataInstitutions, loading: loadingInstitutions } = useRequest<List[]>();
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    institution: '',
    ordering: '' as Sorts,
    role: 'professor',
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    requestInstitutions(institutions.list.action({ is_active: true }));

    return () => {
      institutions.list.cancel();
      users.get.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (!loadingDelete) {
      request(users.get.action(filters));
    }
  }, [filters, loadingDelete]);

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onDelete = React.useCallback((id) => requestDelete(users.delete.action({ id })), []);
  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });
  const onManagement = (action = 'add'): void => push('TeacherEditPage', { action, query: { page: filters.page } });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const columns: Column<User>[] = React.useMemo(
    () => [
      {
        title: t('nameAndSurname'),
        render: ({ profile_picture: profilePicture, first_name: firstName, last_name: lastName }): React.ReactNode => (
          <AvatarInline img={profilePicture} alt={[firstName, lastName].filter((i) => i).join(' ')} size="small" />
        ),
      },
      {
        title: 'E-mail',
        render: ({ email }): React.ReactNode => (
          <div className="nowrap">
            <Info icon={<Icon type="mail-filled" />} label={email || '---'} />
          </div>
        ),
      },
      {
        title: t('phone'),
        render: ({ phone_number: phoneNumber }): React.ReactNode => (
          <div className="nowrap">
            {phoneNumber ? (
              <Info icon={<Icon type="phone" />} label={phoneNumber ? renderPhone(phoneNumber) : '---'} />
            ) : (
              '---'
            )}
          </div>
        ),
      },
      {
        title: t('place'),
        render: ({ country, city }) =>
          country && country.title ? (
            <Info
              icon={<Icon type="pin" />}
              label={[country && country.title, city && city.title].filter((i) => i).join(', ')}
            />
          ) : (
            '---'
          ),
      },
      {
        action: true,
        render: ({ id, is_deleted }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <Link className="nowrap" route="ProfilePage" params={{ action: id, query: { role: 'professor' } }}>
              {t('details')}
            </Link>

            {!is_deleted && <SendMessage users={id ? [id] : []} />}

            {!is_deleted && (
              <Actions
                onEdit={() => onManagement(`${id}`)}
                onDelete={() => onDelete(id)}
                onDeleteLabel={t('deleteTeacher')}
                loadingDelete={loadingDelete}
              />
            )}
          </div>
        ),
        className: 'nowrap',
      },
    ],
    [t],
  );

  const institutionsOptions = React.useMemo(
    () => dataInstitutions.map((institution) => ({ value: institution.key, title: institution.label })),
    [dataInstitutions],
  );

  const expandable = ({
    institutions,
    vkontakte_url: vkUrl,
    telegram,
    gender,
    native_language: nativeLanguage,
    foreign_language: foreignLanguage,
    last_login: lastLogin,
    phone_number: phoneNumber,
    facebook_url: fbUrl,
    viber,
    skype,
    whatsup,
    odnoklasniki_url: odnklUrl,
    linkedin_url: linkedinUrl,
    address,
    birth_date,
  }: User): Expande => [
    [
      {
        label: t('institutes'),
        value:
          institutions && institutions.length
            ? institutions.map((institute, i) => (
                <div key={i}>
                  <Link route="GeneralInstitutePage" params={{ id: institute.id }}>
                    {institute.name}
                  </Link>
                </div>
              ))
            : '---',
        icon: <Icon type="location" />,
      },
      { label: t('phone'), value: phoneNumber ? renderPhone(phoneNumber) : '---' },
      { label: t('lastLogin'), value: lastLogin ? lastLogin : '---' },
    ],
    [
      {
        label: t('sex'),
        value: gender === 'M' ? t('male') : t('female'),
        icon: <Icon type={`gender-${gender === 'M' ? 'male' : 'female'}`} />,
      },
      { label: t('dateOfBirth'), value: birth_date ? birth_date : '---', icon: <Icon type="calendar" /> },
      { label: t('address'), value: address ? address : '---', icon: <Icon type="pin" /> },
      {
        label: t('nativeLanguage'),
        value: nativeLanguage.length ? nativeLanguage.map(({ name }) => name).join(', ') : '---',
      },
      {
        label: t('foreignLanguages'),
        value: foreignLanguage.length ? foreignLanguage.map(({ name }) => name).join(', ') : '---',
      },
    ],
    [
      { icon: <Icon type="facebook" />, value: fbUrl },
      { icon: <Icon type="linkedin" />, value: linkedinUrl },
      { icon: <Icon type="odnoklasniki" />, value: odnklUrl },
      { icon: <Icon type="vk" />, value: vkUrl },
      { icon: <Icon type="telegram" />, value: telegram },
      { icon: <Icon type="whatsapp" />, value: whatsup },
      { icon: <Icon type="skype" />, value: skype },
      { icon: <Icon type="viber" />, value: viber },
    ],
  ];

  return (
    <>
      <Head t="teachers" />

      <UsersLayout onRefetch={onRefetch}>
        <Card>
          <CardHeader
            title={t('teachers')}
            count={data.count}
            leftSide={
              <Input
                suffix={<Icon type="search" />}
                placeholder={t('findPerson')}
                value={filters.search}
                onChange={(newValue) => onChangeFilters('search', newValue)}
                width={240}
                styleType="grey"
              />
            }
            rightSide={
              <>
                <Select
                  cancelable
                  styleType="grey"
                  placeholder={t('selectInstitute')}
                  options={institutionsOptions}
                  loading={loadingInstitutions}
                  value={Number(filters.institution) ? filters.institution : null}
                  onChange={(value): void => onChangeFilters('institution', value)}
                />

                <SortBy
                  options={[
                    {
                      title: t('firstName'),
                      value: 'first_name',
                    },
                    {
                      title: t('lastName'),
                      value: 'last_name',
                    },
                    {
                      title: t('city'),
                      value: 'city',
                    },
                  ]}
                  value={filters.ordering}
                  onChange={(newValue) => onChangeFilters('ordering', newValue)}
                />
              </>
            }
          />

          <Loader loading={loading}>
            <Table size="small" page={filters.page} data={data.results} columns={columns} expandable={expandable} />

            <CardFooter
              onRefresh={onRefetch}
              onRefreshDisabled={!filters.filtered}
              page={filters.page}
              pages={data.total_pages}
              perPage={filters.per_page}
              onChangePerPage={onChangePerPage}
              rightSide={
                <>
                  <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                    {t('previous')}
                  </Button>

                  <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                    {t('next')}
                  </Button>
                </>
              }
            />
          </Loader>
        </Card>
      </UsersLayout>
    </>
  );
};
