import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { Link, useHistory } from 'estafette-router';
import { notify } from 'react-notify-toast';
import { UserContext } from 'contexts';
import { useFilters } from 'hooks';
import { format, dateTimeFormat, toISODate } from 'libs/date';
import { me } from 'libs/http/api';
import { sessions } from 'libs/http/api/sessions/sessions';
import { Results } from 'libs/http/api/index.types';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { perPage } from 'libs/storage';
import { copySessionPublicLink } from 'libs/copy';
import { Actions, InputSearch, SortBy } from 'ui/organisms';
import { DateRangePicker } from 'ui/molecules';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { Table, Card, CardHeader, CardFooter, Button, Time, Loader, Icon, CardAlert, AvatarInline } from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';
import { Expande } from 'ui/atoms/Table/Expanded';

export const CurrentSessionsTemplate: React.FC<{ title?: string; update?: boolean }> = ({ title, update }) => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { userData } = React.useContext(UserContext);
  const { request, data, loading } = useRequest<Results<Session>>({
    data: { results: [] },
  });
  const { request: requestDelete, loading: loadingDelete } = useRequest();
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    start_date__date__gte: '',
    end_date__date__lte: '',
    ordering: '' as Sorts,
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    return () => {
      me.getSessions.cancel();
      sessions.delete.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (!loadingDelete) {
      request(
        me.getSessions.action({
          ...filters,
          start_date__date__gte: filters.start_date__date__gte ? toISODate(filters.start_date__date__gte, true) : '',
          end_date__date__lte: filters.end_date__date__lte ? toISODate(filters.end_date__date__lte, true) : '',
          type: 'current',
        }),
      );
    }
  }, [filters, loadingDelete, update]);

  const onChangePeriod = (newValue: any): void => {
    setFilters({
      filtered: true,
      page: 1,
      ...(newValue.start_date__gte ? { start_date__date__gte: newValue.start_date__gte } : {}),
      ...(newValue.end_date__lte ? { end_date__date__lte: newValue.end_date__lte } : {}),
    });
  };

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onDeleteSession = React.useCallback((id) => requestDelete(sessions.delete.action({ id })), []);
  const onManagementSession = (id: number): void =>
    push('AddSessionPage', { action: id, query: { page: filters.page } });

  const handleCopyLink = (session: Session): void => {
    copySessionPublicLink(session);
    notify.show(`${t('successfullyCopied')}`, 'success');
  };

  const columns: Column<Session>[] = React.useMemo(
    () => [
      {
        title: t('period'),
        width: 240,
        render: ({ start_date: startDate, end_date: endDate }): React.ReactNode => (
          <Time
            date={[startDate && format(startDate, dateTimeFormat), endDate && format(endDate, dateTimeFormat)]
              .filter((i) => i)
              .join(' - ')}
            noParse
          />
        ),
        className: 'nowrap',
      },
      {
        title: t('teacher'),
        render: ({ professor }) =>
          professor ? (
            <AvatarInline
              img={professor.profile_picture}
              alt={[professor.first_name, professor.last_name].filter((i) => i).join(' ')}
              size="small"
            />
          ) : (
            '---'
          ),
      },
      {
        title: t('institute'),
        render: ({ institution }) => (institution && institution.name) || '---',
      },
      {
        title: t('course'),
        render: ({ course }) => (course && course.name) || '---',
      },
      {
        title: t('numOfRegisteredStudentsShort'),
        width: 80,
        render: ({ number_of_students: students }) => (students && <b>{students}</b>) || 0,
      },
      {
        action: true,
        render: (session) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <Link className="nowrap" route="GeneralSessionPage" params={{ id: session.id }}>
              {t('details')}
            </Link>

            {['admin'].includes(userData.role) && (
              <Actions
                onConfirm={() => handleCopyLink(session)}
                onConfirmLabel={t('copyLink')}
                onEdit={['admin'].includes(userData.role) ? () => onManagementSession(session.id) : undefined}
                onDelete={userData.role === 'admin' ? () => onDeleteSession(session.id) : undefined}
              />
            )}
          </div>
        ),
        className: 'nowrap',
      },
    ],
    [t, userData.role],
  );

  const expandable = ({
    professor,
    coordinator,
    start_date: startDate,
    end_date: endDate,
    country,
    city,
    course,
    institution,
    language,
    faculty,
  }: Session): Expande => [
    [
      { label: t('course'), value: (course && course.name) || '---', icon: <Icon type="requests" /> },
      {
        label: t('startAt'),
        value: startDate ? format(startDate, dateTimeFormat) : '---',
        icon: <Icon type="calendar" />,
      },
      {
        label: t('finishAt'),
        value: startDate ? format(endDate, dateTimeFormat) : '---',
        icon: <Icon type="calendar" />,
      },
    ],
    [
      {
        label: t('teacher'),
        value: professor ? [professor.first_name, professor.last_name].filter((i) => i).join(' ') : '---',
      },
      {
        label: t('coordinator'),
        value: coordinator ? [coordinator.first_name, coordinator.last_name].filter((i) => i).join(' ') : '---',
      },
      {
        label: t('place'),
        value:
          country && country.title ? [country && country.title, city && city.title].filter((i) => i).join(', ') : '---',
        icon: <Icon type="pin" />,
      },
    ],
    [
      { label: t('institute'), value: (institution && institution.name) || '---', icon: <Icon type="location" /> },
      { label: t('faculty'), value: faculty ? faculty.name : '---' },
      { label: t('language'), value: (language && language.name) || '---' },
    ],
  ];

  return (
    <Card>
      <CardHeader
        title={title || `${t('currentSessions')}`}
        count={data.count}
        leftSide={<InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />}
        rightSide={
          <>
            <div className="picker-wrapper">
              <div className="hideTablet">{t('period')}</div>
              <DateRangePicker
                from={filters.start_date__date__gte}
                to={filters.end_date__date__lte}
                type="period"
                onChange={onChangePeriod}
              />
            </div>
            <SortBy
              options={[
                {
                  title: t('name'),
                  value: 'name',
                },
                {
                  title: t('country'),
                  value: 'country',
                },
                {
                  title: t('city'),
                  value: 'city',
                },
                {
                  title: t('startAt'),
                  value: 'start_date',
                },
                {
                  title: t('finishAt'),
                  value: 'end_date',
                },
                {
                  title: t('created_at'),
                  value: 'created_at',
                },
              ]}
              value={filters.ordering}
              onChange={(newValue) => onChangeFilters('ordering', newValue)}
            />
          </>
        }
      />

      <Loader loading={loading}>
        <Table page={filters.page} data={data.results} columns={columns} expandable={expandable} />

        <CardFooter
          onRefresh={onRefetch}
          onRefreshDisabled={!filters.filtered}
          page={filters.page}
          pages={data.total_pages}
          perPage={filters.per_page}
          onChangePerPage={onChangePerPage}
          rightSide={
            <>
              <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                {t('previous')}
              </Button>

              <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                {t('next')}
              </Button>
            </>
          }
        />
      </Loader>

      <CardAlert label={t('didYouFindProblem')} t="reportCurrentSessions" />
    </Card>
  );
};
