import * as React from 'react';
import { useRequest } from 'estafette';
import { useHistory } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { useStateHandlers } from 'hooks';
import { NomenclaturesContext, UserContext } from 'contexts';
import { users, events, file, manuals } from 'libs/http/api';
import { ManualList } from 'libs/http/api/manuals/manuals.types';
import { UserList } from 'libs/http/api/users/users.types';
import { Event } from 'libs/http/api/events/events.types';
import { EurasiaFile } from 'libs/http/api/file/file.types';
import { User } from 'libs/http/api/me/me.types';
import { Save } from 'libs/http/api/index.types';
import { toISO } from 'libs/date';
import { DateRangePicker, InputPhone } from 'ui/molecules';
import { Form, FormItems, FormItem, ImageUpload, Head } from 'ui/organisms';
import { Input, Select, Button, Icon, Fragment, RichText, Alert } from 'ui/atoms';

import { EventLayout } from '../organisms';

export const AddEventsPage: React.FC = () => {
  const { request: requestManuals, data: dataManuals, loading: loadingManuals } = useRequest<ManualList[]>();
  const { request: requestProfessors, data: dataProfessors, loading: loadingProfessors } = useRequest<UserList[]>();
  const { request: requestCoordinator, data: dataCoordinator, loading: loadingCoordinator } = useRequest<UserList[]>();
  const { request: requestUser, data: dataUser, loading: loadingUser } = useRequest<User>();
  const { request: requestPhoto, loading: loadingPhoto, errors: errorsPhoto } = useRequest<EurasiaFile>();
  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<Save>();
  const { t } = useIntl();
  const { push, goBack } = useHistory();
  const {
    events: eventsList,
    getCountries,
    countries,
    getActiveCountries,
    activeCountries,
    loadingActiveCountries,
    getActiveCities,
    activeCities,
    loadingActiveCities,
    getActiveLanguages,
    activeLanguages,
    loadingActiveLanguages,
  } = React.useContext(NomenclaturesContext);
  const { userData } = React.useContext(UserContext);

  const [state, setState] = useStateHandlers<Event>({
    name: '',
    organizer: null,
    professor: null,
    country: null,
    city: null,
    address: '',
    start_date: '',
    end_date: '',
    language: null,
    type: '',
    image: '',
    description: '',
    books: [],
    facebook_url: '',
    vkontakte_url: '',
    odnoklasniki_url: '',
    linkedin_url: '',
    viber: '',
    whatsup: '',
    skype: '',
    telegram: '',
    email: '',
    phone_number: '',
  });

  const [searchCountries, setSearchCountries] = React.useState('');
  const [searchCities, setSearchCities] = React.useState('');

  React.useEffect(() => {
    if (state.organizer) {
      requestUser(users.load.action({ id: state.organizer }));
    }
  }, [state.organizer]);

  React.useEffect(() => {
    setState({
      country: (userData.country && userData.country.id) || null,
      city: (userData.city && userData.city.id) || null,
      facebook_url: userData.facebook_url || '',
      vkontakte_url: userData.vkontakte_url || '',
      odnoklasniki_url: userData.odnoklasniki_url || '',
      linkedin_url: userData.linkedin_url || '',
      viber: userData.viber || '',
      whatsup: userData.whatsup || '',
      skype: userData.skype || '',
      telegram: userData.telegram || '',
      email: userData.email || '',
      phone_number: userData.phone_number || '',
    });
  }, [userData]);

  React.useEffect(() => {
    setState({
      country: (dataUser.country && dataUser.country.id) || null,
      city: (dataUser.city && dataUser.city.id) || null,
      facebook_url: dataUser.facebook_url || '',
      vkontakte_url: dataUser.vkontakte_url || '',
      odnoklasniki_url: dataUser.odnoklasniki_url || '',
      linkedin_url: dataUser.linkedin_url || '',
      viber: dataUser.viber || '',
      whatsup: dataUser.whatsup || '',
      skype: dataUser.skype || '',
      telegram: dataUser.telegram || '',
      email: dataUser.email || '',
      phone_number: dataUser.phone_number ? `+${dataUser.phone_number.replace(/\+/g, '')}` : '',
    });
  }, [dataUser]);

  React.useEffect(() => {
    requestManuals(manuals.list.action());

    getCountries();
    getActiveLanguages();

    return () => {
      events.save.cancel();
      manuals.list.cancel();
    };
  }, []);

  React.useEffect(() => {
    getActiveCountries(searchCountries);
  }, [searchCountries]);

  React.useEffect(() => {
    getActiveCities(state.country, searchCities);
  }, [state.country, searchCities]);

  React.useEffect(() => {
    requestProfessors(users.professorsList.action({}));
    requestCoordinator(users.coordinatorsList.action({}));

    if (!state.professor && userData.allowed_roles?.includes('professor') && userData.id) {
      setState({ professor: userData.id });
    }
    if (!state.organizer && userData.allowed_roles?.includes('coordinator') && userData.id) {
      setState({ organizer: userData.id });
    }
  }, []);

  const onSubmit = async (): Promise<void> => {
    const { image: $image, ...options } = state;
    let image = $image;

    if (image && typeof image !== 'string') {
      const fileFormData: any = new FormData();
      fileFormData.append('file', image);

      const { url } = await requestPhoto(file.upload.action(fileFormData));
      image = url;

      setState({ image: url });
    }

    await requestSave(
      events.save.action({
        ...options,
        ...(options.start_date ? { start_date: toISO(options.start_date) } : {}),
        ...(options.end_date ? { end_date: toISO(options.end_date) } : {}),
        image,
      }),
    );

    push('EventsPage');
  };

  const onChange = React.useCallback(
    (target: string, value: any): void =>
      setState({
        [target]: value,
        ...(target === 'country' ? { city: null, professor: null, phone_number: '', email: '' } : {}),
        ...(target === 'city' ? { professor: null, phone_number: '', email: '' } : {}),
      }),
    [],
  );

  const eventOptions = React.useMemo(() => eventsList.map((event) => ({ value: event, title: t(event) })), [t]);

  const languageOptions = React.useMemo(
    () => activeLanguages.map((language) => ({ value: language.key, title: language.label })),
    [activeLanguages],
  );

  const manualOptions = React.useMemo(() => dataManuals.map((manual) => ({ value: manual.key, title: manual.label })), [
    dataManuals,
  ]);

  const professorOptions = React.useMemo(
    () => dataProfessors.map((professor) => ({ value: professor.key, title: professor.label })),
    [dataProfessors],
  );

  const coordinatorOptions = React.useMemo(
    () => dataCoordinator.map((coordinator) => ({ value: coordinator.key, title: coordinator.label })),
    [dataCoordinator],
  );

  const onGoBack = (): void => goBack();

  const handleCountriesSearch = (input: string): void => {
    setSearchCountries(input);
  };

  const handleCitiesSearch = (input: string): void => {
    setSearchCities(input);
  };

  return (
    <>
      <Head t="addEvent" />

      <EventLayout>
        <h1>{t('addEvent')}</h1>

        <Alert message={dataSave.message || errors.non_field_errors || errors.detail} form type="error" />

        <Form onSubmit={onSubmit}>
          <Fragment
            footer={
              <>
                <Button onClick={onGoBack} disabled={loadingSave}>
                  {t('cancel')}
                </Button>

                <Button submit type="primary" prefix={<Icon type="check" />} loading={loadingSave || loadingPhoto}>
                  {t('confirm')}
                </Button>
              </>
            }
          >
            <FormItem className="mx-w-660" required label={t('typeOfEvent')} extra={errors.type}>
              <Select
                placeholder={t('selectTypeOfEvent')}
                options={eventOptions}
                value={state.type}
                onChange={(value) => onChange('type', value)}
              />
            </FormItem>

            <FormItem className="mx-w-660" required label={t('nameOfEvent')} extra={errors.name}>
              <Input value={state.name} onChange={(value) => onChange('name', value)} />
            </FormItem>

            <FormItem className="mx-w-660" label={t('langOfInstruction')} extra={errors.language}>
              <Select
                placeholder={t('selectLanguage')}
                loading={loadingActiveLanguages}
                options={languageOptions}
                value={state.language}
                onChange={(value) => onChange('language', value)}
              />
            </FormItem>

            <hr className="hr-2" />

            <h2 className="medium mb-20">{t('dateTime')}</h2>

            <FormItems className="mx-w-660">
              <FormItem required label={t('startDate')} extra={errors.start_date}>
                <DateRangePicker
                  minDate={new Date()}
                  type="date-time"
                  onChange={({ date__gte: value }) => onChange('start_date', value)}
                />
              </FormItem>

              <FormItem required label={t('endDate')} extra={errors.end_date}>
                <DateRangePicker
                  minDate={(state.start_date && new Date(toISO(state.start_date))) || new Date()}
                  type="date-time"
                  onChange={({ date__gte: value }) => onChange('end_date', value)}
                />
              </FormItem>
            </FormItems>

            <hr className="hr-2" />

            <h2 className="medium mb-20">{t('venue')}</h2>

            <FormItems className="mx-w-660">
              <FormItem label={t('country')} extra={errors.country}>
                <Select
                  placeholder={t('selectCountry')}
                  options={activeCountries}
                  loading={loadingActiveCountries}
                  onSearch={handleCountriesSearch}
                  value={state.country}
                  onChange={(value) => onChange('country', value)}
                />
              </FormItem>

              <FormItem
                label={t('city')}
                extra={errors.city ? errors.city : !state.country ? t('selectCountryError') : ''}
                extraStatus={errors.city ? 'error' : 'regular'}
              >
                <Select
                  placeholder={t('selectCity')}
                  onSearch={handleCitiesSearch}
                  options={state.country ? activeCities.filter((city) => city.countryID === state.country) : []}
                  loading={loadingActiveCities}
                  value={state.city}
                  onChange={(value) => onChange('city', value)}
                  disabled={!state.country}
                />
              </FormItem>
            </FormItems>

            <FormItem required label={t('fullAddress')} className="mx-w-660" extra={errors.address}>
              <Input value={state.address} onChange={(value) => onChange('address', value)} />
            </FormItem>

            <hr className="hr-2" />

            <FormItem className="mx-w-660" label={t('studiedManual')} error={errors.books}>
              <Select
                mode="multiple"
                cancelable
                options={manualOptions}
                loading={loadingManuals}
                value={state.books}
                onChange={(value) => onChange('books', value)}
              />
            </FormItem>

            <FormItems className="mx-w-660">
              <FormItem label={t('teacher')} extra={errors.professor}>
                <Select
                  cancelable
                  placeholder={t('selectTeacher')}
                  options={professorOptions}
                  value={state.professor}
                  loading={loadingProfessors || loadingUser}
                  onChange={(value) => onChange('professor', value)}
                />
              </FormItem>

              <FormItem
                required
                label={t('organizer')}
                extra={errors.organizer ? errors.organizer : !state.country ? t('selectCountryError') : ''}
                extraStatus={errors.organizer ? 'error' : 'regular'}
              >
                <Select
                  placeholder={t('selectOrganizer')}
                  options={coordinatorOptions}
                  value={state.organizer || (userData.allowed_roles?.includes('coordinator') && userData.id)}
                  loading={loadingCoordinator}
                  onChange={(value) => onChange('organizer', value)}
                />
              </FormItem>
            </FormItems>

            <hr className="hr-2" />

            <h2 className="medium mb-20">{t('contactData')}</h2>

            <FormItems className="mx-w-660">
              <FormItem required label="E-mail" extra={errors.email}>
                <Input value={state.email} onChange={(value) => onChange('email', value)} />
              </FormItem>

              <FormItem required label={t('phone')} extra={errors.phone_number}>
                <InputPhone
                  country={countries?.filter((item) => item.value === state.country)[0]?.code}
                  value={state.phone_number}
                  onChange={(value: string): void => onChange('phone_number', value)}
                />
              </FormItem>
            </FormItems>

            <hr className="hr-2" />

            <h2 className="medium mb-20">{t('otherContacts')}</h2>

            <FormItems className="mx-w-660">
              <FormItem label="Telegram" extra={errors.telegram}>
                <Input value={state.telegram} onChange={(value) => onChange('telegram', value)} />
              </FormItem>

              <FormItem label="Skype" extra={errors.skype}>
                <Input value={state.skype} onChange={(value) => onChange('skype', value)} />
              </FormItem>
            </FormItems>

            <FormItems className="mx-w-660">
              <FormItem label="WhatsApp" extra={errors.whatsup}>
                <Input value={state.whatsup} onChange={(value) => onChange('whatsup', value)} />
              </FormItem>

              <FormItem label="Viber" extra={errors.viber}>
                <Input value={state.viber} onChange={(value) => onChange('viber', value)} />
              </FormItem>
            </FormItems>

            <hr className="hr-2" />

            <h2 className="medium mb-20">{t('socialsNetworks')}</h2>

            <FormItems className="mx-w-660">
              <FormItem label="Facebook" extra={errors.facebook_url}>
                <Input value={state.facebook_url} onChange={(value) => onChange('facebook_url', value)} />
              </FormItem>

              <FormItem label="Vkontakte" extra={errors.vkontakte_url}>
                <Input value={state.vkontakte_url} onChange={(value) => onChange('vkontakte_url', value)} />
              </FormItem>
            </FormItems>

            <FormItems className="mx-w-660">
              <FormItem label="Odnoklassniki" extra={errors.odnoklasniki_url}>
                <Input value={state.odnoklasniki_url} onChange={(value) => onChange('odnoklasniki_url', value)} />
              </FormItem>

              <FormItem label="Linkedin" extra={errors.linkedin_url}>
                <Input value={state.linkedin_url} onChange={(value) => onChange('linkedin_url', value)} />
              </FormItem>
            </FormItems>

            <hr className="hr-2" />

            <h2 className="medium mb-20">{t('othersInformation')}</h2>

            <FormItem className="mx-w-660" extra={errors.image || errorsPhoto.file}>
              <ImageUpload
                alt={state.name}
                defaultPreview={state.image}
                onChange={(value) => onChange('image', value)}
              />
            </FormItem>

            <FormItem required className="mx-w-660" label={t('description')} extra={errors.description}>
              <RichText initialValue={state.description} onChange={(value) => onChange('description', value)} />
            </FormItem>
          </Fragment>
        </Form>
      </EventLayout>
    </>
  );
};
