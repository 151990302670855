import * as React from 'react';
import { notify } from 'react-notify-toast';
import { useRequest } from 'estafette';
import { useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { UserContext } from 'contexts';
import { sessions } from 'libs/http/api';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { Report } from 'libs/http/api/reports/reports.types';
import { dateFormat, dayjsObject, format, today } from 'libs/date';
import { copySessionPublicLink } from 'libs/copy';
import { Head } from 'ui/organisms';
import { Animated, Button, Card, CardHeader, Icon, Info, Loader, Time, Status } from 'ui/atoms';

import { ContactCard, SessionLayout, SessionReport } from '../../organisms';

import './GeneralSessionPage.scss';

export const GeneralSessionPage: React.FC = () => {
  const { t } = useIntl();
  const { id } = useParams();
  const { userData } = React.useContext(UserContext);

  const { request, data, loading } = useRequest<Session>({ data: {} });
  const { request: requestReport, data: dataReport, loading: loadingReport, errors } = useRequest<Report>({ data: {} });

  const [report, setReport] = React.useState(false);

  React.useEffect(() => {
    return () => {
      sessions.load.cancel();
      sessions.report.cancel();
    };
  }, []);

  React.useEffect(() => {
    onFetch();
  }, [id]);

  const onFetch = (): void => {
    request(sessions.load.action({ id }));
    if (userData.role !== 'student') {
      requestReport(sessions.report.action({ id }));
    }
  };

  const onToggleReport = (): void => setReport((s) => !s);

  const isReportCompleted = React.useMemo(() => dataReport && !!dataReport.session, [dataReport]);

  const handleCopyLink = (): void => {
    copySessionPublicLink(data);
    notify.show(`${t('successfullyCopied')}`, 'success');
  };

  return (
    <>
      {report && (
        <SessionReport
          defaultReport={isReportCompleted ? { ...dataReport, session: Number(id) } : { session: Number(id) }}
          onRefetch={onFetch}
          onClose={onToggleReport}
          disabledForm={
            !(
              ((dataReport.created_at && dayjsObject(dataReport.created_at).add(2, 'day') >= today) ||
                !dataReport.created_at) &&
              (userData.role !== 'admin' ||
                (userData?.id && ![data.coordinator?.id, data.professor?.id].includes(userData.id)))
            )
          }
        />
      )}

      <Head title={data.course?.name || 'n/a'} />

      <SessionLayout>
        <Animated loading={loading} debounce={500}>
          <Card>
            <CardHeader
              title={
                <>
                  <h2 className="zh-session-title">
                    {data.course?.name}
                    {data.status && ` - `}
                    {data.status && <Status label={t(data.status)} status={data.status} />}
                  </h2>

                  {data.language && <h3 className="zh-session-subtitle">{data.language.name}</h3>}
                </>
              }
              rightSide={
                <>
                  <Button
                    disabled={!['professor_invitation_accepted', 'accepted'].includes(data.status)}
                    onClick={handleCopyLink}
                  >
                    {t('copyLink')}
                  </Button>
                  {!errors.detail && (
                    <Animated loading={loadingReport}>
                      {dataReport.created_at && dayjsObject(dataReport.created_at).add(2, 'day') >= today ? (
                        <Button prefix={<Icon type={'edit'} />} type={'invert'} onClick={onToggleReport}>
                          {t('editReport')}
                        </Button>
                      ) : (
                        <Button
                          prefix={<Icon type={isReportCompleted ? 'eye' : 'plus'} />}
                          type={isReportCompleted ? 'regular' : 'invert'}
                          onClick={onToggleReport}
                        >
                          {t(isReportCompleted ? 'viewReport' : 'completeReport')}
                        </Button>
                      )}
                    </Animated>
                  )}
                </>
              }
            />

            <div className="zh-session-card">
              <div className="zh-session-card-item">
                {data.institution && (
                  <div className="zh-session-card-info">
                    <div className="zh-session-card-info-label">{t('institute')}</div>
                    <div className="zh-session-card-info-text">{data.institution.name}</div>
                  </div>
                )}

                <div className="zh-session-card-info">
                  <div className="zh-session-card-info-label">{t('sessionDuration')}</div>
                  <div className="zh-session-card-info-text nowrap">
                    <Time
                      date={`${format(data.start_date, dateFormat)} - ${format(data.end_date, dateFormat)}`}
                      noParse
                    />
                  </div>
                </div>

                <div className="zh-session-card-info">
                  <div className="zh-session-card-info-label">{t('numOfRegisteredStudents')}</div>
                  <div className="zh-session-card-info-text">{data.number_of_students}</div>
                </div>

                <div className="zh-session-card-info">
                  <div className="zh-session-card-info-label">{t('location')}</div>
                  <div className="zh-session-card-info-text">
                    <Info
                      icon={<Icon type="location-filled" />}
                      label={[data.country && data.country.title, data.city && data.city.title, data.address]
                        .filter((i) => i)
                        .join(', ')}
                    />
                  </div>
                </div>
              </div>

              <div className="zh-session-card-item">
                <h3 className="zh-session-card-item-title">{t('taughtCourses')}</h3>

                <ul className="zh-session-card-item-faculties">
                  <li>{(data.course && data.course.name) || '---'}</li>
                </ul>
              </div>
            </div>
          </Card>
        </Animated>

        <Loader loading={loading}>
          {data.professor || data.coordinator || data.coordinator_global ? (
            <Card>
              <CardHeader className="mt-40" title={t('contactData')} />

              <div className="zh-session-contacts">
                {data.professor || data.coordinator ? (
                  <div className="zh-session-contact-item">
                    <h3>{t('teacher')}</h3>

                    <div className="zh-session-contacts">
                      {data.professor && <ContactCard linkToUser role="professor" data={data.professor} />}

                      {data.coordinator && <ContactCard linkToUser role="coordinator" data={data.coordinator} />}
                    </div>
                  </div>
                ) : null}

                {data.coordinator_global && (
                  <div className="zh-session-contact-item">
                    <h3>{t('coordinators')}</h3>

                    <div className="zh-session-contacts">
                      <ContactCard linkToUser role="coordinator" data={data.coordinator_global} />
                    </div>
                  </div>
                )}
              </div>
            </Card>
          ) : null}
        </Loader>
      </SessionLayout>
    </>
  );
};
