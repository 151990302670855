import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useFilters } from 'hooks';
import { format, dateTimeFormat, toISO, today } from 'libs/date';
import { me } from 'libs/http/api';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { Results } from 'libs/http/api/index.types';
import { perPage } from 'libs/storage';
import { AvatarInline, Time, Card, CardHeader, CardFooter, CardAlert, Table, Icon, Button, Loader } from 'ui/atoms';
import { InputSearch, SortBy } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';

import { Column } from 'ui/atoms/Table/Table';
import { Expande } from 'ui/atoms/Table/Expanded';

export const MeSessions: React.FC = () => {
  const { t } = useIntl();
  const { request, data, loading } = useRequest<Results<Session>>({ data: { results: [] } });
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    ordering: '' as Sorts,
    search: '',
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    return () => {
      me.getSessions.cancel();
    };
  }, []);

  React.useEffect(() => {
    request(me.getSessions.action({ ...filters, end_date__gte: toISO(today.format(dateTimeFormat)) }));
  }, [filters]);

  const onChangeFilters = React.useCallback(
    (searchFilters): void => setFilters({ filtered: true, page: 1, ...searchFilters }),
    [],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const columns: Column<Session>[] = React.useMemo(
    () => [
      {
        title: t('course'),
        render: ({ course }) => <b>{course?.name || '---'}</b>,
      },
      {
        title: t('teacher'),
        render: ({ professor }) =>
          professor ? (
            <AvatarInline
              img={professor.profile_picture}
              alt={[professor.first_name, professor.last_name].filter((i) => i).join(' ')}
              size="small"
            />
          ) : (
            '---'
          ),
      },
      {
        title: t('period'),
        width: 240,
        render: ({ start_date: startDate, end_date: endDate }): React.ReactNode => (
          <Time
            date={[startDate && format(startDate, dateTimeFormat), endDate && format(endDate, dateTimeFormat)]
              .filter((i) => i)
              .join(' - ')}
            noParse
          />
        ),
        className: 'nowrap',
      },
      {
        title: t('language'),
        render: ({ language }) => (language && language.name) || '---',
      },
    ],
    [t],
  );

  const expandable = ({
    professor,
    start_date: startDate,
    end_date: endDate,
    country,
    city,
    course,
    institution,
    language,
    faculty,
  }: Session): Expande => [
    [
      { label: t('course'), value: (course && course.name) || '---', icon: <Icon type="requests" /> },
      {
        label: t('startAt'),
        value: startDate ? format(startDate, dateTimeFormat) : '---',
        icon: <Icon type="calendar" />,
      },
      {
        label: t('finishAt'),
        value: startDate ? format(endDate, dateTimeFormat) : '---',
        icon: <Icon type="calendar" />,
      },
    ],
    [
      {
        label: t('teacher'),
        value: professor ? [professor.first_name, professor.last_name].filter((i) => i).join(' ') : '---',
      },
      {
        label: t('place'),
        value:
          country && country.title ? [country && country.title, city && city.title].filter((i) => i).join(', ') : '---',
        icon: <Icon type="pin" />,
      },
      {
        label: t('faculty'),
        value: faculty ? faculty.name : '---',
      },
    ],
    [
      { label: t('institute'), value: (institution && institution.name) || '---', icon: <Icon type="location" /> },
      { label: t('language'), value: (language && language.name) || '---' },
    ],
  ];

  return (
    <Card>
      <CardHeader
        title={t('sessions')}
        count={data.count}
        leftSide={<InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters({ search: newValue })} />}
        rightSide={
          <SortBy
            options={[
              {
                title: t('name'),
                value: 'name',
              },
              {
                title: t('country'),
                value: 'country',
              },
              {
                title: t('city'),
                value: 'city',
              },
            ]}
            value={filters.ordering}
            onChange={(newValue) => onChangeFilters({ ordering: newValue })}
          />
        }
      />

      <Loader loading={loading}>
        <Table size="small" page={filters.page} data={data.results} columns={columns} expandable={expandable} />

        <CardFooter
          onRefresh={onRefetch}
          onRefreshDisabled={!filters.filtered}
          page={filters.page}
          pages={data.total_pages}
          perPage={filters.per_page}
          onChangePerPage={onChangePerPage}
          rightSide={
            <>
              <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                {t('previous')}
              </Button>

              <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                {t('next')}
              </Button>
            </>
          }
        />

        <CardAlert label={t('didYouFindProblem')} t="reportSessionsApplication" />
      </Loader>
    </Card>
  );
};
