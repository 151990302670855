export const firstLetters = (target: string): string =>
  target
    ? target
        .split(' ')
        .map((n) => n[0])
        .join('')
        .toUpperCase()
        .substr(0, 2)
    : '';

export const truncate = (str: string, limit = 5): string =>
  str && str.length > limit ? `${str.substring(0, limit)} ...` : str;

export const hasUpperCase = (str: string): boolean => (str ? /[A-Z]/.test(str) : false);

export const cleanString = (str: string): string =>
  str
    ? str
        .toLowerCase()
        .trim()
        .replace(/å/g, 'a')
        .replace(/ă/g, 'a')
        .replace(/â/g, 'a')
        .replace(/ț/g, 't')
        .replace(/ţ/g, 't')
        .replace(/î/g, 'i')
        .replace(/ş/g, 's')
        .replace(/[.-]/g, '')
        .replace(/ţ/g, 'o')
        .replace(/\(/g, '')
        .replace(/\\/g, '')
        .replace(/\//g, '')
    : '';

export const lowerFirstLetter = (str: string): string => `${str[0].toLowerCase()}${str.slice(1)}`;
