import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Button } from 'ui/atoms';

import './Pagination.scss';

interface Props {
  total?: number;
  label?: string;
  current?: number;
  onChange?: (page: number) => void;
}

export const Pagination: React.FC<Props> = ({ total = 0, label = '', current = 1, onChange }) => {
  const { t } = useIntl();

  const [page, setPage] = React.useState(current);

  const disabledPreview = React.useMemo(() => current === 1, [current]);
  const disabledNext = React.useMemo(() => current === Math.ceil(total / 10), [current, total]);

  const onPageChange = (dir: number): void => {
    if (dir && !disabledNext) {
      setPage((prevPage) => prevPage + 1);
    } else if (!disabledPreview) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  React.useEffect(() => {
    if (onChange !== undefined) {
      onChange(page);
    }
  }, [page]);

  return (
    <div className="pagination flex flex-between">
      <div>
        {total > 10 ? 10 : total} {label} {t('from')} {total}
      </div>

      <div className="pagination-buttons">
        <Button onClick={() => onPageChange(0)} disabled={disabledPreview}>
          {t('previous')}
        </Button>

        <Button onClick={() => onPageChange(1)} disabled={disabledNext}>
          {t('next')}
        </Button>
      </div>
    </div>
  );
};
