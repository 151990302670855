import * as React from 'react';
import { useParams } from 'estafette-router';
import { Head } from 'ui/organisms';
import { AnnouncementsTemplate } from 'features/announcements/templates';

import { SessionLayout } from '../organisms';

export const AnnouncementsSessionPage: React.FC = () => {
  const { id } = useParams();

  return (
    <>
      <Head t="announcements" />

      <SessionLayout>
        <AnnouncementsTemplate id={id} provider="session" />
      </SessionLayout>
    </>
  );
};
