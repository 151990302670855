import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';
import { Language } from './languages.types';

export const languages = {
  list: {
    action: (params = {}): Promise<{ data: Language[] }> =>
      axios.get(`languages/list`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (languages.list.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  activeList: {
    action: (params = {}): Promise<{ data: Language[] }> =>
      axios.get(`languages/active-list`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (languages.list.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
