import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { Link, useHistory, useParams } from 'estafette-router';
import { UserContext } from 'contexts';
import { useFilters } from 'hooks';
import { today, format, dateTimeFormat, toISO, dateFormat } from 'libs/date';
import { institutions, users } from 'libs/http/api';
import { sessions } from 'libs/http/api/sessions/sessions';
import { EurasiaInstitution } from 'libs/http/api/institutions/institutions.types';
import { Results } from 'libs/http/api/index.types';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { User } from 'libs/http/api/me/me.types';
import { perPage } from 'libs/storage';
import { Actions, Head, InputSearch, ProfileCard, SortBy } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import {
  Animated,
  Info,
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Time,
  Icon,
  Loader,
  CardAlert,
  AvatarInline,
} from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';
import { Expande } from 'ui/atoms/Table/Expanded';

import { InstituteLayout } from '../organisms';

const initialFilters = {
  current: {
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    ordering: '' as Sorts,
    start_date__lte: format(today.toString(), dateTimeFormat),
    end_date__gte: format(today.toString(), dateTimeFormat),
  },
  planned: {
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    ordering: '' as Sorts,
    start_date__gte: format(today.toString(), dateTimeFormat),
  },
};

export const GeneralInstitutePage: React.FC = () => {
  const { t } = useIntl();
  const { id } = useParams();
  const { push } = useHistory();
  const { userData } = React.useContext(UserContext);

  const [filters, setFilters] = useFilters<{ [key: string]: any }>({ ...initialFilters });

  const { request, data: dataInstitution, loading: loadingInstitution } = useRequest<EurasiaInstitution>();
  const { request: requestCurrent, data: dataCurrent, loading: loadingCurrent } = useRequest<Results<Session>>({
    data: { results: [] },
  });
  const { request: requestPlanned, data: dataPlanned, loading: loadingPlanned } = useRequest<Results<Session>>({
    data: { results: [] },
  });
  const { request: requestCoordinator, data: dataCoordinator } = useRequest<User>();
  const { request: requestDelete, loading: loadingDelete } = useRequest();

  React.useEffect(() => {
    setFilters({
      current: { ...filters.current, per_page: perPage, page: 1 },
      planned: { ...filters.planned, per_page: perPage, page: 1 },
    });
    return () => {
      users.load.cancel();
      institutions.load.cancel();
      institutions.get.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (id) {
      request(institutions.load.action({ id }));
    }
  }, [id]);

  React.useEffect(() => {
    if (id && !loadingDelete) {
      requestPlanned(
        sessions.get.action({
          ...filters.planned,
          start_date__gte: toISO(filters.planned.start_date__gte),
          institution__in: id,
        }),
      );
    }
  }, [filters.planned, loadingDelete]);

  React.useEffect(() => {
    if (id && !loadingDelete) {
      requestCurrent(
        sessions.get.action({
          ...filters.current,
          start_date__lte: toISO(filters.current.start_date__lte),
          end_date__gte: toISO(filters.current.end_date__gte),
          institution__in: id,
        }),
      );
    }
  }, [filters.current, loadingDelete]);

  React.useEffect(() => {
    if (dataInstitution.coordinator && dataInstitution.coordinator.id) {
      requestCoordinator(users.load.action({ id: dataInstitution.coordinator.id }));
    }
  }, [dataInstitution]);

  const onChangePlannedFilters = React.useCallback(
    (target, value: any): void =>
      setFilters({ planned: { ...filters.planned, filtered: true, page: 1, [target]: value } }),
    [],
  );
  const onChangeCurrentFilters = React.useCallback(
    (target, value: any): void =>
      setFilters({ current: { ...filters.current, filtered: true, page: 1, [target]: value } }),
    [],
  );

  const onRefetchCurrent = (): void => setFilters({ current: { ...filters.current } });
  const onRefetchPlanned = (): void => setFilters({ planned: { ...filters.planned } });
  const onChangeCurrentPerPage = (per_page: number): void => setFilters({ current: { ...filters.current, per_page } });
  const onChangePlannedPerPage = (per_page: number): void => setFilters({ planned: { ...filters.planned, per_page } });
  const onManagement = (action: number): void => push('EditCoordinatorPage', { action, id: dataInstitution.id });
  const onViewMore = (): void => push('SessionsinstitutePage', { id });

  const onIncreasePage = (): void =>
    setFilters((prevState) => ({
      current: { ...prevState.current, filtered: true, page: prevState.current.page + 1 },
    }));
  const onDecreasePage = (): void =>
    setFilters((prevState) => ({
      current: { ...prevState.current, filtered: true, page: prevState.current.page - 1 },
    }));

  const coordinatorId = React.useMemo(() => dataCoordinator.id, [dataCoordinator]);

  const onDeleteSession = React.useCallback((id) => requestDelete(sessions.delete.action({ id })), []);
  const onManagementSession = (id: number, type: string): void =>
    push('AddSessionPage', { action: id, query: { page: filters[type].page } });

  const columns: Column<Session>[] = React.useMemo(
    () => [
      {
        title: t('dateAndTime'),
        render: ({ start_date: startDate }): React.ReactNode => <Time date={format(startDate, dateFormat)} noParse />,
      },
      {
        title: t('teacher'),
        render: ({ professor }) =>
          professor ? (
            <AvatarInline
              img={professor.profile_picture}
              alt={[professor.first_name, professor.last_name].filter((i) => i).join(' ')}
              size="small"
            />
          ) : (
            '---'
          ),
      },
      {
        title: t('institute'),
        render: ({ institution }) => (institution && institution.name) || '---',
      },
      {
        title: t('course'),
        render: ({ course }) => <b>{course?.name || '---'}</b>,
      },
      {
        title: t('numOfRegisteredStudentsShort'),
        width: 80,
        render: ({ number_of_students: students }) => (students && <b>{students}</b>) || '---',
      },
      {
        action: true,
        render: ({ id }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <Link className="nowrap" route="GeneralSessionPage" params={{ id }}>
              {t('details')}
            </Link>

            {['admin', 'coordinator'].includes(userData.role) && (
              <Actions
                onEdit={
                  ['admin', 'coordinator'].includes(userData.role)
                    ? () => onManagementSession(id, 'current')
                    : undefined
                }
                onDelete={userData.role === 'admin' ? () => onDeleteSession(id) : undefined}
              />
            )}
          </div>
        ),
        className: 'nowrap',
      },
    ],
    [t, userData.role],
  );

  const plannedColumns: Column<Session>[] = React.useMemo(
    () => [
      {
        title: t('dateAndTime'),
        render: ({ start_date: startDate }): React.ReactNode => <Time date={format(startDate, dateFormat)} noParse />,
      },
      {
        title: t('teacher'),
        render: ({ professor }) =>
          professor ? (
            <AvatarInline
              img={professor.profile_picture}
              alt={[professor.first_name, professor.last_name].filter((i) => i).join(' ')}
              size="small"
            />
          ) : (
            '---'
          ),
      },
      {
        title: t('place'),
        render: ({ country, city }) =>
          country && country.title ? (
            <Info
              icon={<Icon type="pin" />}
              label={[country && country.title, city && city.title].filter((i) => i).join(', ')}
            />
          ) : (
            '---'
          ),
      },
      {
        title: t('institute'),
        render: ({ institution }) => (institution && institution.name) || '---',
      },
      {
        title: t('course'),
        render: ({ course }) => (course && course.name) || '---',
      },
      {
        title: t('numOfRegisteredStudentsShort'),
        width: 80,
        render: ({ number_of_students: students }) => (students && <b>{students}</b>) || '---',
      },
      {
        action: true,
        render: ({ id }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <Link className="nowrap" route="GeneralSessionPage" params={{ id }}>
              {t('details')}
            </Link>

            {['admin', 'coordinator'].includes(userData.role) && (
              <Actions
                onEdit={
                  ['admin', 'coordinator'].includes(userData.role)
                    ? () => onManagementSession(id, 'planned')
                    : undefined
                }
                onDelete={userData.role === 'admin' ? () => onDeleteSession(id) : undefined}
              />
            )}
          </div>
        ),
        className: 'nowrap',
      },
    ],
    [t, userData.role],
  );

  const expandable = ({
    professor,
    start_date: startDate,
    end_date: endDate,
    country,
    city,
    info,
    course,
    institution,
    number_of_students: students,
    language,
  }: Session): Expande => [
    [
      { label: t('startAt'), value: startDate, icon: <Icon type="calendar" /> },
      { label: t('finishAt'), value: endDate, icon: <Icon type="calendar" /> },
    ],
    [
      { label: t('course'), value: (course && course.name) || '---', icon: <Icon type="requests" /> },
      {
        label: t('teacher'),
        value: professor ? [professor.first_name, professor.last_name].filter((i) => i).join(' ') : '---',
      },
    ],
    [
      {
        label: t('place'),
        value:
          country && country.title ? [country && country.title, city && city.title].filter((i) => i).join(', ') : '---',
        icon: <Icon type="pin" />,
      },
      {
        label: t('info_short'),
        value: info ? <span dangerouslySetInnerHTML={{ __html: info }} /> : '---',
        icon: <Icon type="info" />,
      },
    ],
    [
      { label: t('numOfStudents'), value: students, icon: <Icon type="student" /> },
      { label: t('language'), value: (language && language.name) || '---' },
    ],
    [{ label: t('institute'), value: (institution && institution.name) || '---', icon: <Icon type="location" /> }],
  ];

  return (
    <>
      <Head t="coordinatorDetails" />

      <InstituteLayout>
        {coordinatorId && !loadingInstitution && (
          <Animated className="mt-20">
            <h1>{t('coordinatorDetails')}</h1>

            <ProfileCard
              data={dataCoordinator}
              onSendMessage={(): null => null}
              onEdit={() => onManagement(coordinatorId)}
            />
          </Animated>
        )}

        <Card>
          <CardHeader
            title={`${t('currentSessions')}`}
            count={dataCurrent.count}
            leftSide={
              <InputSearch
                value={filters.current.search}
                onSearch={(newValue) => onChangeCurrentFilters('search', newValue)}
              />
            }
            rightSide={
              <SortBy
                options={[
                  {
                    title: t('name'),
                    value: 'name',
                  },
                  {
                    title: t('institute'),
                    value: 'institution',
                  },
                  {
                    title: t('course'),
                    value: 'course',
                  },
                ]}
                value={filters.current.ordering}
                onChange={(newValue) => onChangeCurrentFilters('ordering', newValue)}
              />
            }
          />

          <Loader loading={loadingCurrent}>
            <Table page={filters.current.page} data={dataCurrent.results} columns={columns} expandable={expandable} />

            <CardFooter
              onRefresh={onRefetchCurrent}
              page={filters.current.page}
              pages={dataCurrent.total_pages}
              perPage={filters.current.per_page}
              onChangePerPage={onChangeCurrentPerPage}
              onViewMore={onViewMore}
            />
          </Loader>
        </Card>

        <Card>
          <CardHeader
            title={`${t('plannedSessions')}`}
            count={dataPlanned.count}
            leftSide={
              <InputSearch
                value={filters.planned.search}
                onSearch={(newValue) => onChangePlannedFilters('search', newValue)}
              />
            }
            rightSide={
              <SortBy
                options={[
                  {
                    title: t('name'),
                    value: 'name',
                  },
                  {
                    title: t('country'),
                    value: 'country',
                  },
                  {
                    title: t('city'),
                    value: 'city',
                  },
                ]}
                value={filters.planned.ordering}
                onChange={(newValue) => onChangePlannedFilters('ordering', newValue)}
              />
            }
          />

          <Loader loading={loadingPlanned}>
            <Table
              page={filters.planned.page}
              data={dataPlanned.results}
              columns={plannedColumns}
              expandable={expandable}
            />

            <CardFooter
              onRefresh={onRefetchPlanned}
              page={filters.planned.page}
              pages={dataPlanned.total_pages}
              perPage={filters.planned.per_page}
              onChangePerPage={onChangePlannedPerPage}
              rightSide={
                <>
                  <Button onClick={onDecreasePage} disabled={filters.planned.page === 1}>
                    {t('previous')}
                  </Button>

                  <Button onClick={onIncreasePage} disabled={filters.planned.page >= dataPlanned.total_pages}>
                    {t('next')}
                  </Button>
                </>
              }
            />
          </Loader>

          <CardAlert label={t('didYouFindProblem')} t="reportPlannedSessions" />
        </Card>
      </InstituteLayout>
    </>
  );
};
