import * as React from 'react';
import { useRequest } from 'estafette';
import { countries, cities, languages, geoip } from 'libs/http/api';
import { Country } from 'libs/http/api/countries/countries.types';
import { City } from 'libs/http/api/cities/cities.types';
import { Language } from 'libs/http/api/languages/languages.types';
import { UserContext } from './UserContext';

interface Props {
  readonly getLanguages: () => void;
  readonly languages: Language[];
  readonly loadingLanguages: boolean;

  readonly getActiveLanguages: () => void;
  readonly activeLanguages: Language[];
  readonly loadingActiveLanguages: boolean;

  readonly getCountries: (start_with?: string | null) => void;
  readonly countries: Country[];
  readonly loadingCountries: boolean;

  readonly getActiveCountries: (start_with?: string | null) => void;
  readonly activeCountries: Country[];
  readonly loadingActiveCountries: boolean;

  readonly getCities: (country: number | null, start_with?: string | null) => void;
  readonly cities: City[];
  readonly loadingCities: boolean;

  readonly getActiveCities: (country: number | null, start_with?: string | null) => void;
  readonly activeCities: City[];
  readonly loadingActiveCities: boolean;

  readonly getGeoip: () => void;
  readonly geoip: { country_code2: string };
  readonly loadingGeoip: boolean;

  readonly events: string[];
  readonly roles: string[];
  readonly subjects: string[];
  readonly grades: string[];
}

const events = ['conference', 'camp', 'meeting', 'professor_training', 'forms'];
const roles = ['student', 'professor', 'coordinator', 'admin'];
const subjects = ['subject1', 'subject2'];
const grades = ['1', '2', '3', '4', '5'];

export const NomenclaturesContext = React.createContext<Props>({
  getLanguages: (): null => null,
  languages: [],
  loadingLanguages: false,

  getActiveLanguages: (): null => null,
  activeLanguages: [],
  loadingActiveLanguages: false,

  getCountries: (): null => null,
  countries: [],
  loadingCountries: false,

  getActiveCountries: (): null => null,
  activeCountries: [],
  loadingActiveCountries: false,

  getCities: (): null => null,
  cities: [],
  loadingCities: false,

  getActiveCities: (): null => null,
  activeCities: [],
  loadingActiveCities: false,

  getGeoip: (): null => null,
  geoip: { country_code2: '' },
  loadingGeoip: false,

  events,
  roles,
  subjects,
  grades,
});

export const NomenclaturesProvider = ({ children }: { children: React.ReactElement }): React.ReactElement => {
  const { language } = React.useContext(UserContext);
  const { request: requestLanguage, data: dataLanguages, loading: loadingLanguages } = useRequest<Language[]>();
  const { request: requestActiveLanguage, data: dataActiveLanguages, loading: loadingActiveLanguages } = useRequest<
    Language[]
  >();
  const { request: requestCountries, data: dataCountries, loading: loadingCountries } = useRequest<Country[]>();
  const { request: requestActiveCountries, data: dataActiveCountries, loading: loadingActiveCountries } = useRequest<
    Country[]
  >();
  const { request: requestCities, data: dataCities, loading: loadingCities } = useRequest<City[]>();
  const { request: requestActiveCities, data: dataActiveCities, loading: loadingActiveCities } = useRequest<City[]>();
  const { request: requestGeoip, data: dataGeoip, loading: loadingGeoip } = useRequest<{ country_code2: string }>();

  React.useEffect(() => {
    return () => {
      languages.list.cancel();
      countries.get.cancel();
      cities.get.cancel();
      geoip.get.cancel();
    };
  }, []);

  const getLanguages = React.useCallback(() => {
    languages.list.cancel();

    if (dataLanguages.length === 0) {
      requestLanguage(languages.list.action());
    }
  }, [dataLanguages]);

  const getActiveLanguages = React.useCallback(() => {
    languages.activeList.cancel();

    if (dataLanguages.length === 0) {
      requestActiveLanguage(languages.activeList.action());
    }
  }, [dataActiveLanguages]);

  const getCountries = React.useCallback(
    (start_with?: string | null) => {
      countries.get.cancel();
      requestCountries(countries.get.action({ lang: language, start_with }));
    },
    [dataCountries, language],
  );

  const getActiveCountries = React.useCallback(
    (start_with?: string | null) => {
      countries.get.cancel();
      requestActiveCountries(countries.active.action({ lang: language, start_with }));
    },
    [dataActiveCountries, language],
  );

  const getCities = React.useCallback(
    (country: number | null, start_with?: string | null) => {
      cities.get.cancel();

      if (country) {
        requestCities(cities.get.action({ country, lang: language, start_with }));
      }
    },
    [dataCities, language],
  );

  const getActiveCities = React.useCallback(
    (country: number | null, start_with?: string | null) => {
      cities.get.cancel();

      if (country) {
        requestActiveCities(cities.active.action({ country, lang: language, start_with }));
      }
    },
    [dataActiveCities, language],
  );

  const getGeoip = React.useCallback(() => {
    geoip.get.cancel();
    if (!dataGeoip.country_code2) {
      requestGeoip(geoip.get.action());
    }
  }, [dataGeoip]);

  return (
    <NomenclaturesContext.Provider
      value={{
        getLanguages,
        languages: dataLanguages,
        loadingLanguages,

        getActiveLanguages,
        activeLanguages: dataActiveLanguages,
        loadingActiveLanguages,

        getCountries,
        countries: dataCountries,
        loadingCountries,

        getActiveCountries,
        activeCountries: dataActiveCountries,
        loadingActiveCountries,

        getCities,
        cities: dataCities,
        loadingCities,

        getActiveCities,
        activeCities: dataActiveCities,
        loadingActiveCities,

        getGeoip,
        geoip: dataGeoip,
        loadingGeoip,

        events,
        roles,
        subjects,
        grades,
      }}
    >
      {children}
    </NomenclaturesContext.Provider>
  );
};
