import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { Student, InviteUsersOptions, Session, SessionStudent, RequestsCount, SessionListItem } from './sessions.types';
import { Results, ListUser, Save } from '../index.types';
import { Announcement, AnnouncementFilters, AnnouncementOptions } from '../announces/announces.types';
import { Report } from '../reports/reports.types';
import { Grades } from '../courses/courses.types';
import { Invitation } from '../events/events.types';

export const sessions = {
  get: {
    action: (params: { [key: string]: any }): Promise<{ data: Results<Session> }> =>
      axios.get(`sessions`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (sessions.get.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  list: {
    action: (params: any): Promise<{ data: SessionListItem[] }> =>
      axios.get('sessions/list', {
        params,
        cancelToken: new cancelToken((c: Canceler) => (sessions.list.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  usersList: {
    action: ({ id, ...params }: { id: number | string; exam_failed?: boolean }): Promise<{ data: ListUser[] }> =>
      axios.get(`sessions/${id}/users_list`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (sessions.usersList.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  load: {
    action: ({ id, ...params }: { id: number }): Promise<{ data: Session }> =>
      axios.get(`sessions/${id}`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (sessions.get.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getGradeFields: {
    action: ({ id, ...params }: { id: number }): Promise<{ data: Grades[] }> =>
      axios.get(`sessions/${id}/report-grade-fields`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (sessions.get.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  requests: {
    action: (params: { [key: string]: any }): Promise<{ data: Results<Session> }> =>
      axios.get(`sessions/requests`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (sessions.requests.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  save: {
    action: ({ id = 0, ...options }) =>
      axios.patch(`sessions/${id}`, options, {
        cancelToken: new cancelToken((c: Canceler) => (sessions.save.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  register: {
    action: ({ id, ...options }: { [key: string]: any }) =>
      axios.patch(`sessions/${id}/register`, options, {
        cancelToken: new cancelToken((c: Canceler) => (sessions.register.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  add: {
    action: (options = {}) =>
      axios.post(`sessions`, options, {
        cancelToken: new cancelToken((c: Canceler) => (sessions.add.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  request: {
    action: (options = {}) =>
      axios.post(`sessions/make-request`, options, {
        cancelToken: new cancelToken((c: Canceler) => (sessions.add.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  delete: {
    action: ({ id }: { id: number }) =>
      axios.delete(`sessions/${id}`, {
        cancelToken: new cancelToken((c: Canceler) => (sessions.delete.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  cancel: {
    action: ({ id }: { id: number }) =>
      axios.patch(`sessions/${id}/cancel`, null, {
        cancelToken: new cancelToken((c: Canceler) => (sessions.cancel.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getStudents: {
    action: ({ id, ...params }: { id: number }): Promise<{ data: Results<Student> }> =>
      axios.get(`sessions/${id}/students`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (sessions.getStudents.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getStudent: {
    action: <T = SessionStudent>({
      id,
      student,
      ...params
    }: {
      id: number | string;
      student: number | string;
    }): Promise<{ data: T }> =>
      axios.get(`sessions/${id}/students/${student}`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (sessions.getStudents.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  invitations: {
    action: (params = {}): Promise<{ data: Results<Invitation> }> =>
      axios.get(`sessions/invitations`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (sessions.invitations.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  respondToInvitations: {
    action: ({ id, ...option }: { id: number; status: string }) =>
      axios.patch(`/sessions/${id}/invitations`, option, {
        cancelToken: new cancelToken((c: Canceler) => (sessions.respondToInvitations.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  saveStudent: {
    action: ({
      id,
      student,
      ...params
    }: {
      id: number | string | null;
      student: number | string | null | undefined;
      [key: string]: any;
    }): Promise<{ data: Save }> =>
      axios.patch(`sessions/${id}/students/${student}`, params, {
        cancelToken: new cancelToken((c: Canceler) => (sessions.getStudents.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  addUser: {
    action: ({ id, ...params }: { id: number | string | null; users: number[] }): Promise<{ data: any }> =>
      axios.patch(`sessions/${id}/add-users`, params, {
        cancelToken: new cancelToken((c: Canceler) => (sessions.getStudents.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getAnnounces: {
    action: ({ id, ...params }: AnnouncementFilters): Promise<{ data: Results<Announcement> }> =>
      axios.get(`sessions/${id}/announces`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (sessions.getAnnounces.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  saveAnnounce: {
    action: (options: AnnouncementOptions) =>
      axios.post(`announces/session-users`, options, {
        cancelToken: new cancelToken((c: Canceler) => (sessions.saveAnnounce.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  inviteUsers: {
    action: ({ id, ...options }: InviteUsersOptions) =>
      axios.patch(`sessions/${id}/invite-user`, options, {
        cancelToken: new cancelToken((c: Canceler) => (sessions.inviteUsers.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  nonInvitedUsersList: {
    action: ({ id, search }: { id: number | string; search?: string }): Promise<{ data: ListUser[] }> =>
      axios.get(`sessions/${id}/invite-students/list`, {
        params: { search },
        cancelToken: new cancelToken((c: Canceler) => (sessions.nonInvitedUsersList.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  report: {
    action: ({ id }: { id: number | string }): Promise<{ data: Report }> =>
      axios.get(`sessions/${id}/reports`, {
        cancelToken: new cancelToken((c: Canceler) => (sessions.report.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  removeStudent: {
    action: ({ id, studentId }: { id: number; studentId: number }) =>
      axios.delete(`sessions/${id}/students/${studentId}`, {
        cancelToken: new cancelToken((c: Canceler) => (sessions.removeStudent.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  requestsCount: {
    action: (params = {}): Promise<{ data: RequestsCount }> =>
      axios.get(`sessions/requests/count`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (sessions.requestsCount.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
