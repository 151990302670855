import * as React from 'react';
import { useRequest } from 'estafette';
import { Link, useHistory, useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { NomenclaturesContext } from 'contexts';
import { useStateHandlers, useModules } from 'hooks';
import { faculties, institutions, users } from 'libs/http/api';
import { UserList } from 'libs/http/api/users/users.types';
import { EurasiaInstitution } from 'libs/http/api/institutions/institutions.types';
import { FacultiesList } from 'libs/http/api/faculties/faculties.types';
import { Save } from 'libs/http/api/index.types';
import { keys, parseQuery } from 'libs/object';
import { Form, FormItem, FormItems, Head } from 'ui/organisms';
import { Button, Fragment, Icon, Input, Loader, Select, Alert, Checkbox } from 'ui/atoms';

import { InstituteLayout } from '../organisms';

interface State {
  name: string;
  country: number | null;
  city: number | null;
  coordinator: number | null;
  faculties: number[];
  is_active: boolean;
}

export const AddInstitutePage: React.FC = () => {
  const { t } = useIntl();
  const { action } = useParams();
  const { modules, indexModule } = useModules();
  const { push, goBack } = useHistory();
  const { request: requestFaculties, data: dataFaculties, loading: loadingFaculties } = useRequest<FacultiesList[]>();
  const { request: requestCoordinator, data: dataCoordinator, loading: loadingCoordinator } = useRequest<UserList[]>();
  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<Save>();
  const { request: requestInstitute, loading: loadingInstitute } = useRequest<EurasiaInstitution>({ data: {} });
  const { getCountries, countries, loadingCountries, getCities, cities, loadingCities } = React.useContext(
    NomenclaturesContext,
  );

  const [state, setState] = useStateHandlers<State>({
    name: '',
    country: null,
    city: null,
    coordinator: null,
    faculties: [],
    is_active: true,
  });

  const [searchCountries, setSearchCountries] = React.useState('');
  const [searchCities, setSearchCities] = React.useState('');
  const [searchCoordinator, setSearchCoordinator] = React.useState('');

  React.useEffect(() => {
    if (indexModule && !modules.filter((module) => module.active).length) {
      push(indexModule.route, indexModule.params);
    }
  }, [indexModule, modules]);

  React.useEffect(() => {
    requestFaculties(faculties.list.action());

    return () => {
      faculties.list.cancel();
      institutions.add.cancel();
      institutions.load.cancel();
      institutions.save.cancel();
      users.coordinatorsList.cancel();
    };
  }, []);

  React.useEffect(() => {
    requestCoordinator(users.coordinatorsList.action({ search: searchCoordinator }));
  }, [searchCoordinator]);

  React.useEffect(() => {
    getCountries(searchCountries);
  }, [searchCountries]);

  React.useEffect(() => {
    getCities(state.country, searchCities);
  }, [state.country, searchCities]);

  React.useEffect(() => {
    const onFetchInstitute = async (): Promise<void> => {
      if (action !== 'add') {
        const data = await requestInstitute(institutions.load.action({ id: action }));

        setState((prevState) =>
          keys(prevState).reduce((acc, current) => {
            const item = data[current];
            return {
              ...acc,
              [current]:
                !Array.isArray(item) && item instanceof Object
                  ? item.id
                  : typeof item === 'boolean'
                  ? item
                  : item || prevState[current],
            };
          }, {}),
        );
      }
    };

    onFetchInstitute();
  }, [action]);

  const onSubmit = async (): Promise<void> => {
    const query: { [key: string]: any } = {};

    if (action === 'add') {
      await requestSave(institutions.add.action(state));
    } else {
      await requestSave(institutions.save.action({ ...state, id: action }));

      const { page } = parseQuery<{ page: number }>(window.location.search);
      query.page = page;
    }

    push('InstitutesPage', { query });
  };

  const onChange = React.useCallback(
    (target: string, value: any): void =>
      setState({
        [target]: value,
        ...(target === 'country' ? { city: null, coordinator: null } : {}),
        ...(target === 'city' ? { coordinator: null } : {}),
      }),
    [],
  );
  const onCancel = (): void => goBack();

  const coordinatorOptions = React.useMemo(
    () => dataCoordinator.map((coordinator) => ({ value: coordinator.key, title: coordinator.label })),
    [dataCoordinator],
  );

  const facultiesOptions = React.useMemo(
    () => dataFaculties.map((faculty) => ({ value: faculty.key, title: faculty.label })),
    [dataFaculties],
  );

  const isEdit = React.useMemo(() => action !== 'add', [action]);

  const handleCountriesSearch = (input: string): void => {
    setSearchCountries(input);
  };

  const handleCitiesSearch = (input: string): void => {
    setSearchCities(input);
  };

  const handleCoordinatorSearch = (input: string): void => {
    setSearchCoordinator(input);
  };

  return (
    <>
      <Head t={isEdit ? 'editInstitute' : 'addInstitute'} />

      <InstituteLayout showTabs={false}>
        <h1>{t(isEdit ? 'editInstitute' : 'addInstitute')}</h1>

        <Alert message={dataSave.message || errors.non_field_errors || errors.detail} form type="error" />

        <Form onSubmit={onSubmit}>
          <Fragment
            footer={
              <>
                <Link route="InstitutesPage">
                  <Button onClick={onCancel}>{t('cancel')}</Button>
                </Link>

                <Button submit type="primary" prefix={<Icon type="check" />} loading={loadingSave}>
                  {t('confirm')}
                </Button>
              </>
            }
          >
            <Loader loading={isEdit && loadingInstitute} height={261}>
              <FormItem required label={t('name')} extra={errors.name} className="mx-w-660">
                <Input value={state.name} onChange={(value): void => onChange('name', value)} />
              </FormItem>

              <FormItems className="mx-w-660">
                <FormItem required label={t('country')} extra={errors.country}>
                  <Select
                    placeholder={t('selectCountry')}
                    options={countries}
                    loading={loadingCountries}
                    onSearch={handleCountriesSearch}
                    value={state.country}
                    onChange={(value): void => onChange('country', value)}
                  />
                </FormItem>

                <FormItem
                  required
                  label={t('city')}
                  extra={errors.city ? errors.city : !state.country ? t('selectCountryError') : ''}
                  extraStatus={errors.city ? 'error' : 'regular'}
                >
                  <Select
                    placeholder={t('selectCity')}
                    options={state.country ? cities.filter((city) => city.countryID === state.country) : []}
                    loading={loadingCities}
                    onSearch={handleCitiesSearch}
                    value={state.city}
                    onChange={(value): void => onChange('city', value)}
                    disabled={!state.country}
                  />
                </FormItem>
              </FormItems>

              <hr className="hr-2" />

              <FormItems className="mx-w-660">
                <FormItem required label={t('faculty')} error={errors.faculties}>
                  <Select
                    mode="multiple"
                    cancelable
                    placeholder={t('selectFaculty')}
                    options={facultiesOptions}
                    loading={loadingFaculties}
                    value={state.faculties}
                    onChange={(value) => onChange('faculties', value)}
                  />
                </FormItem>

                <FormItem
                  required
                  label={t('coordinator')}
                  extra={errors.coordinator ? errors.coordinator : !state.country ? t('selectCountryError') : ''}
                  extraStatus={errors.coordinator ? 'error' : 'regular'}
                >
                  <Select
                    placeholder={t('selectCoordinator')}
                    options={coordinatorOptions}
                    onSearch={handleCoordinatorSearch}
                    value={state.coordinator}
                    loading={loadingCoordinator}
                    onChange={(value) => onChange('coordinator', value)}
                    disabled={!state.country}
                  />
                </FormItem>
              </FormItems>

              <hr className="hr-2" />

              <FormItems className="mx-w-660">
                <FormItem>
                  <Checkbox
                    checked={state.is_active}
                    onChange={() => onChange('is_active', !state.is_active)}
                    label={t('isActive')}
                  />
                </FormItem>
              </FormItems>
            </Loader>
          </Fragment>
        </Form>
      </InstituteLayout>
    </>
  );
};
