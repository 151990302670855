import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { Checkbox, Tooltip, Input, Icon, Button, Scroll, Loader } from 'ui/atoms';
import { users } from 'libs/http/api';
import { UserList } from 'libs/http/api/users/users.types';
import { Professor } from 'libs/http/api/sessions/sessions.types';

import './SelectUser.scss';

export type SelectedSession = {
  id?: number;
  institution?: number;
  start_date: string;
  end_date: string;
};

interface Props {
  selected: SelectedSession;
  professor: Professor;
  loading: boolean;
  onSave?: (value: number | null) => void;
  onVisible?: () => void;
}

export const SelectUser: React.FC<Props> = ({ professor, loading, selected, onVisible, onSave }) => {
  const { t } = useIntl();
  const [val, setVal] = React.useState<number | null>(null);
  const [search, setSearch] = React.useState('');
  const [isVisible, setVisible] = React.useState(false);

  const { request, data, loading: loadingProfessors } = useRequest<UserList[]>();
  const { request: requestAll, data: dataAll, loading: loadingProfessorsAll } = useRequest<UserList[]>({});

  React.useEffect(() => {
    if (isVisible && selected.id && selected.start_date && selected.end_date) {
      if (selected.institution) {
        request(
          users.professorsList.action({
            session: selected.id,
            institution: selected.institution,
            start_date: selected.start_date,
            end_date: selected.end_date,
          }),
        );
      }

      requestAll(
        users.professorsList.action({
          session: selected.id,
          start_date: selected.start_date,
          end_date: selected.end_date,
        }),
      );
    }
  }, [isVisible, selected]);

  React.useEffect(() => {
    return () => {
      if (!isVisible) {
        users.professorsList.cancel();
      }
    };
  }, [isVisible]);

  React.useEffect(() => setVal(professor?.id), [professor]);

  const loadingList = React.useMemo(() => loadingProfessors || loadingProfessorsAll, [
    loadingProfessors,
    loadingProfessorsAll,
  ]);

  const options = React.useMemo(
    () =>
      (professor
        ? [
            {
              value: professor.id,
              title: `${professor.first_name} ${professor.last_name}`,
              experience: professor.experience,
              session_count: professor.session_count,
              recommended: true,
            },
          ]
        : []
      )
        .concat(
          data
            .filter((item) => (professor ? professor.id !== item.key : true))
            .map(({ key, label, experience, session_count }) => ({
              value: key,
              title: label,
              experience: experience,
              session_count: session_count,
              recommended: true,
            })),
        )
        .concat(
          dataAll
            .filter(({ key }) => {
              const alreadyExist = data.filter((professor) => professor.key === key).length;

              return professor ? !alreadyExist && professor.id !== key : !alreadyExist;
            })
            .map(({ key, label, experience, session_count }) => ({
              value: key,
              title: label,
              experience: experience,
              session_count: session_count,
              recommended: false,
            })),
        ),
    [professor, data, dataAll],
  );

  const optionsList = React.useMemo(
    () => (options ? options.filter(({ title }) => title.toLowerCase().includes(search)) : []),
    [search, options],
  );

  const label = React.useMemo(
    () => (val && options && options.length ? options.filter(({ value }: any) => value === val)[0].title : '---'),
    [val],
  );

  const handleVisibleChange = React.useCallback(
    (visible: boolean) => {
      if (visible && onVisible) {
        onVisible();
      } else if (!visible && professor?.id !== val) {
        setVal(professor?.id);
      }
      setVisible(visible);
    },
    [onVisible, professor, val],
  );

  const handleSave = React.useCallback(() => {
    if (onSave) {
      onSave(val);
    }
    setVisible(false);
  }, [onSave, val]);

  const setCurrentValue = React.useCallback((val) => setVal((prevState) => (val === prevState ? null : val)), []);

  const content = React.useMemo(
    () => (
      <>
        <div className="zh-select-user-tooltip-title">
          <Input
            styleType="transparent"
            suffix={<Icon type="search" />}
            placeholder={t('searchByName')}
            value={search}
            onChange={setSearch}
          />
        </div>

        <Loader loading={loadingList} height={150} size="small">
          <Scroll>
            <div className="zh-select-user-tooltip-items">
              <div className="zh-checkbox-group">
                {optionsList.length
                  ? optionsList.map(({ value, title, experience, recommended, session_count }, i) => (
                      <div key={i} className={`zh-checkbox-group-content ${recommended ? 'recommended' : ''}`}>
                        <Checkbox
                          key={value}
                          label={title}
                          checked={value === val}
                          onChange={() => setCurrentValue(value)}
                        />
                        <div className="info-grade">
                          {session_count !== undefined && <span className="grade square">{session_count}</span>}
                          {experience !== undefined && (
                            <span className="grade" aria-experience={experience}>
                              {experience}
                            </span>
                          )}
                        </div>
                      </div>
                    ))
                  : t('empty')}
              </div>
            </div>
          </Scroll>
        </Loader>

        <div className="zh-select-user-tooltip-footer">
          <Button type="primary" onClick={handleSave} loading={loading}>
            {t('apply')}
          </Button>
        </div>
      </>
    ),
    [search, setSearch, optionsList, loadingList, val, loading, handleSave],
  );

  return (
    <Tooltip
      placement="bottomLeft"
      className="zh-select-user-tooltip"
      content={content}
      visible={isVisible}
      onVisibleChange={handleVisibleChange}
    >
      <Input
        className="zh-select-user-input"
        styleType="transparent"
        prefix={<Icon type="user-strict" />}
        value={label}
        placeholder={t('selectProfessor')}
      />
    </Tooltip>
  );
};
