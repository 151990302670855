import * as React from 'react';
import { notify } from 'react-notify-toast';
import { save, load, remove } from 'react-cookies';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { getRoute, useHistory } from 'estafette-router';
import { NomenclaturesContext } from 'contexts';
import { useStateHandlers } from 'hooks';
import { me, file as FileApi, announces, faculties } from 'libs/http/api';
import { EurasiaFile } from 'libs/http/api/file/file.types';
import { SendAnnounceOptions } from 'libs/http/api/announces/announces.types';
import { FacultiesList } from 'libs/http/api/faculties/faculties.types';
import { Option } from 'ui/atoms/Select/Select';
import { List } from 'libs/http/api/index.types';

import { FileUpload } from 'ui/molecules';
import { Loader, Select, Fragment, Button, Icon, CardHeader, RichText, Alert, Input } from 'ui/atoms';
import { Form, FormItem, Head } from 'ui/organisms';

import { CommunicationLayout } from '../organisms';
import { routes } from 'routes';

const initialState = {
  users: [],
  subject: '',
  institutions: [],
  language: null,
  roles: [],
  faculties: [],
  description: '',
  file: '',
};

export const CommunicationPage: React.FC = () => {
  const { t } = useIntl();
  const { goBack } = useHistory();

  const { request: requestFaculties, data: dataFaculties, loading: loadingFaculties } = useRequest<FacultiesList[]>();
  const { request: requestInstitutions, data: dataInstitutions, loading: loadingInstitutions } = useRequest<List[]>();
  const { request: requestFile, loading: loadingFile, errors: errorsFile } = useRequest<EurasiaFile>();
  const { request: requestSend, loading: loadingSend, errors: errorsSend } = useRequest();
  const { getActiveLanguages, activeLanguages, loadingActiveLanguages, roles } = React.useContext(NomenclaturesContext);

  const [state, setState] = useStateHandlers<SendAnnounceOptions>(initialState);

  React.useEffect(() => {
    const state = load('state');
    if (state) {
      setState(state);
    }
  }, []);

  React.useEffect(() => {
    save('state', state, { path: getRoute(routes, 'CommunicationPage') });
  }, [setState, state]);

  React.useEffect(() => {
    requestInstitutions(me.getInstitutions.action());
    requestFaculties(faculties.list.action());

    getActiveLanguages();

    return () => {
      faculties.list.cancel();
      me.getStudentsList.cancel();
      FileApi.upload.cancel();
      announces.send.cancel();
    };
  }, []);

  const onSubmit = async (): Promise<void> => {
    const { file: $file, ...options } = state;
    let file = $file;

    if (file && typeof file !== 'string') {
      const fileFormData: any = new FormData();
      fileFormData.append('file', file);

      const { url } = await requestFile(FileApi.upload.action(fileFormData));
      file = url;

      setState({ file });
    }

    await requestSend(announces.saveAnnounce.action({ ...options, file }));

    setState(initialState);
    remove('state');

    notify.show(`${t('messageSended')}`, 'success');
  };

  const onSelectAll = React.useCallback(
    (target: keyof SendAnnounceOptions, arr: Option[]): void =>
      setState((prevState) => {
        const item = prevState[target];

        return {
          [target]: Array.isArray(item) && item.length !== arr.length ? arr.map(({ value }) => value) : [],
        };
      }),
    [],
  );

  const onChange = React.useCallback((target: string, value: any): void => setState({ [target]: value }), []);
  const onCancel = (): void => goBack();

  const languageOptions = React.useMemo(
    () => activeLanguages.map((language) => ({ value: language.key, title: language.label })),
    [activeLanguages],
  );

  const roleOptions = React.useMemo(() => roles.map((role) => ({ value: role, title: t(role) })), [roles]);

  const instituteOptions = React.useMemo(
    () => dataInstitutions.map((institute) => ({ value: institute.key, title: institute.label })),
    [dataInstitutions],
  );

  const facultiesOptions = React.useMemo(
    () => dataFaculties.map((faculty) => ({ value: faculty.key, title: faculty.label })),
    [dataFaculties],
  );

  return (
    <>
      <Head t="writeMessage" />

      <CommunicationLayout>
        <CardHeader className="mt-5" title={t('writeMessage')} />

        <Fragment
          className="zh-create-announcement-content pa-0"
          footer={
            <>
              <Button onClick={onCancel}>{t('cancel')}</Button>

              <Button
                submit
                type="primary"
                loading={loadingFile || loadingSend}
                onClick={onSubmit}
                prefix={<Icon type="check" />}
              >
                {t('sendMessage')}
              </Button>
            </>
          }
        >
          <Alert
            message={
              errorsFile.message || errorsSend.message || errorsFile.non_field_errors || errorsSend.non_field_errors
            }
            form
            type="error"
          />

          <Loader loading={false}>
            <Form onSubmit={onSubmit} className="mx-w-660">
              <FormItem required label={t('subject')} extra={errorsSend.subject}>
                <Input value={state.subject} onChange={(value) => onChange('subject', value)} />
              </FormItem>

              <FormItem
                required
                label={t('institute')}
                className="zh-create-announcement-multitool"
                error={errorsSend.institutions}
              >
                <Select
                  getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
                  mode="multiple"
                  cancelable
                  placeholder={t('selectInstitute')}
                  options={instituteOptions}
                  loading={loadingInstitutions}
                  value={state.institutions}
                  onChange={(value) => onChange('institutions', value)}
                />

                <div
                  className={`zh-create-announcement-select-all ${
                    state.institutions && state.institutions.length === instituteOptions.length ? ' active' : ''
                  }`}
                  onClick={() => onSelectAll('institutions', instituteOptions)}
                >
                  <Icon type="check" />
                  <span>{t('sendToEveryone')}</span>
                </div>
              </FormItem>

              <div className="zh-create-announcement-items">
                <FormItem label={t('language')} extra={errorsSend.language}>
                  <Select
                    getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
                    placeholder={t('selectLanguage')}
                    options={languageOptions}
                    value={state.language}
                    loading={loadingActiveLanguages}
                    onChange={(value) => onChange('language', value)}
                  />
                </FormItem>

                <FormItem label={t('role')} className="zh-create-announcement-multitool" error={errorsSend.roles}>
                  <Select
                    getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
                    mode="multiple"
                    cancelable
                    placeholder={t('selectRole')}
                    options={roleOptions}
                    value={state.roles}
                    onChange={(value) => onChange('roles', value)}
                  />

                  <div
                    className={`zh-create-announcement-select-all${
                      state.roles && state.roles.length === roleOptions.length ? ' active' : ''
                    }`}
                    onClick={() => onSelectAll('roles', roleOptions)}
                  >
                    <Icon type="check" />
                    <span>{t('allRoles')}</span>
                  </div>
                </FormItem>
              </div>

              <FormItem
                required
                label={t('faculty')}
                className="zh-create-announcement-multitool"
                error={errorsSend.faculties}
              >
                <Select
                  getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
                  mode="multiple"
                  cancelable
                  placeholder={t('selectFaculty')}
                  options={facultiesOptions}
                  loading={loadingFaculties}
                  value={state.faculties}
                  onChange={(value) => onChange('faculties', value)}
                />

                <div
                  className={`zh-create-announcement-select-all${
                    state.faculties && state.faculties.length === facultiesOptions.length ? ' active' : ''
                  }`}
                  onClick={() => onSelectAll('faculties', facultiesOptions)}
                >
                  <Icon type="check" />
                  <span>{t('allFaculties')}</span>
                </div>
              </FormItem>

              <FormItem required label={t('message')} extra={errorsSend.description}>
                <RichText initialValue={state.description} onChange={(value) => onChange('description', value)} />
              </FormItem>

              <FormItem required label={t('attachFile')} extra={errorsFile.file}>
                {state.file !== undefined && (
                  <FileUpload file={state.file} onChange={(value) => onChange('file', value)} />
                )}
              </FormItem>
            </Form>
          </Loader>
        </Fragment>
      </CommunicationLayout>
    </>
  );
};
