import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { Results } from '../index.types';
import { Course, CourseList } from './courses.types';

export const courses = {
  get: {
    action: (params = {}): Promise<{ data: Results<Course> }> =>
      axios.get(`courses`, { params, cancelToken: new cancelToken((c: Canceler) => (courses.get.cancel = c)) }),
    cancel: (() => null) as Canceler,
  },

  list: {
    action: (params?: { faculties?: string }): Promise<{ data: CourseList[] }> =>
      axios.get(`courses/list`, { params, cancelToken: new cancelToken((c: Canceler) => (courses.list.cancel = c)) }),
    cancel: (() => null) as Canceler,
  },

  add: {
    action: (options = {}) =>
      axios.post(`courses`, options, {
        cancelToken: new cancelToken((c: Canceler) => (courses.add.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  save: {
    action: ({ id, ...options }: { id: number }) =>
      axios.patch(`courses/${id}`, options, {
        cancelToken: new cancelToken((c: Canceler) => (courses.save.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  load: {
    action: ({ id, ...params }: { id: number }): Promise<{ data: Course }> =>
      axios.get(`courses/${id}`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (courses.load.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  delete: {
    action: ({ id }: { id: number }) =>
      axios.delete(`courses/${id}`, {
        cancelToken: new cancelToken((c: Canceler) => (courses.delete.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
