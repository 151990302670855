import * as React from 'react';

import './Status.scss';

interface Props {
  status: string;
  label?: string | number;
  className?: string;
  nowrap?: boolean;
  maxWidth?: string;
}

export const getStatus = ($status: string): string => {
  const status = $status?.toLowerCase();

  if (['active', 'success', 'accepted'].includes(status)) {
    return 'active';
  }

  if (['unactive'].includes(status)) {
    return 'unactive';
  }

  if (['declined'].includes(status)) {
    return 'declined';
  }

  return 'regular';
};

export const Status: React.FC<Props> = ({ status: $status = 'regular', label, nowrap, className = '', maxWidth }) => {
  const status = React.useMemo(() => getStatus($status), [$status]);

  return (
    <div
      className={`zh-status zh-status-type-${status} ${className} ${maxWidth ? `zh-status-max-width` : ``}`}
      style={{ whiteSpace: nowrap ? 'nowrap' : 'initial', maxWidth: maxWidth }}
      data-label={label}
    >
      <span className={maxWidth ? `zh-status-span-max-width` : ``}>{label}</span>
    </div>
  );
};
