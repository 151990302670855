import * as React from 'react';
import { City } from 'libs/http/api/cities/cities.types';
import { useDebounce } from 'hooks';

import './CityInput.scss';

export interface CityInputProps {
  value: string | number | null | undefined;
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  options: City[];
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
}

export const CityInput: React.FC<CityInputProps> = ({
  onChange,
  onSearch,
  value,
  loading,
  className = '',
  options,
  ...props
}) => {
  const [isVisibleCitiesList, setIsVisibleCitiesList] = React.useState(false);
  const [searchCity, setSearchCity] = React.useState('');

  const debouncedSearch = useDebounce(searchCity);

  React.useEffect(() => {
    onSearch?.(debouncedSearch);
  }, [debouncedSearch]);

  const onChangeInput = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange !== undefined) {
      onChange(target?.value);
      setSearchCity(target?.value);
    }
  };

  const onSelectListItem = (value: string): void => {
    onChange?.(value);
    setIsVisibleCitiesList(false);
    setSearchCity('');
  };

  const onFocusInput = (): void => setIsVisibleCitiesList(true);
  const onBlurInput = (): void => setIsVisibleCitiesList(false);

  return (
    <div
      className={`zh-input-wrapper 
      ${value === '' ? 'zh-input-empty' : ''} 
      zh-input-wrapper-${value !== '' ? 'active' : 'unactive'}  
       ${props.disabled ? ' disabled' : ''} ${className}`}
    >
      <div className="zh-input-container">
        <input
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          className="zh-input"
          value={value || ''}
          onChange={onChangeInput}
          placeholder={props.placeholder}
          disabled={props.disabled || loading}
        />

        {isVisibleCitiesList && !!options?.length && (
          <ul className="zh-input-container-list">
            {options?.map((city) => (
              <li
                className="zh-input-container-list__item"
                key={city?.value}
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => onSelectListItem(city?.title)}
              >
                {city?.title}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
