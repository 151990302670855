import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useRouteMatch, useRouterHelpers } from 'estafette-router';
import { UserContext } from 'contexts';

export interface Module {
  iconType: string;
  label: string;
  route: string;
  params?: { [key: string]: any };
  active: boolean;
  index?: boolean;
}

export type Roles = 'admin' | 'student' | 'professor' | 'coordinator';

export interface Modules {
  admin: Module[];
  student: Module[];
  professor: Module[];
  coordinator: Module[];
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useModules = () => {
  const { t } = useIntl();
  const match = useRouteMatch();
  const { isRouteActive } = useRouterHelpers();
  const { userData } = React.useContext(UserContext);

  const modules = React.useMemo(
    (): Modules => ({
      admin: [
        {
          iconType: 'calendar',
          label: t('events'),
          route: 'EventsPage',
          params: {},
          active: isRouteActive(['EventsPage', 'AddEventsPage', 'InvitePage']),
          index: true,
        },
        {
          iconType: 'location',
          label: t('institutes'),
          route: 'InstitutesPage',
          active: isRouteActive([
            'InstitutesPage',
            'GeneralInstitutePage',
            'SessionsinstitutePage',
            'PrevSessionsinstitutePage',
            'TeachersInstitutePage',
            'TeacherManagementPage',
            'StudentsInstitutePage',
            'StudentManagementPage',
            'AnnouncementsInstitutePage',
            'EditCoordinatorPage',
            'AddInstitutePage',
          ]),
        },
        {
          iconType: 'faculty',
          label: t('faculties'),
          route: 'FacultiesPage',
          active: isRouteActive(['FacultiesPage', 'AddFacultyPage']),
        },
        {
          iconType: 'course',
          label: t('courses'),
          route: 'CoursesPage',
          active: isRouteActive(['CoursesPage', 'AddCoursePage']),
        },
        {
          iconType: 'manual',
          label: t('manuals'),
          route: 'ManualsPage',
          active: isRouteActive(['ManualsPage', 'AddManualPage']),
        },
        {
          iconType: 'announcement',
          label: t('announcements'),
          route: 'AnnouncementsPage',
          active: isRouteActive(['AnnouncementsPage', 'AnnouncementsDetailsPage']),
        },
        {
          iconType: 'session',
          label: t('sessions'),
          route: 'PlannedSessionsPage',
          active: isRouteActive([
            'PlannedSessionsPage',
            'CurrentSessionsPage',
            'ClosedSessionsPage',
            'SessionApplicationsPage',
            'AddSessionPage',
            'GeneralSessionPage',
            'StudentsSessionPage',
            'AddStudentSessionPage',
            'AnnouncementsSessionPage',
            'ResourcesSessionPage',
          ]),
        },
        {
          iconType: 'graduation',
          label: t('graduations'),
          route: 'GraduationsPage',
          active: isRouteActive([
            'GraduationsPage',
            'GeneralGraduationPage',
            'AddGraduationPage',
            'AddGraduationGraduatePage',
          ]),
        },
        {
          iconType: 'requests',
          label: t('requests'),
          route: 'ApplicationsPage',
          active: isRouteActive(['ApplicationsPage']),
        },
        {
          iconType: 'resources',
          label: t('resources'),
          route: 'ResourcePage',
          params: { type: 'all' },
          active: isRouteActive(['ResourcePage']),
        },
        {
          iconType: 'user-strict',
          label: t('users'),
          route: 'StudentsUsersPage',
          active: isRouteActive([
            'CoordinatorsUsersPage',
            'CoordinatorEditPage',
            'TeachersUsersPage',
            'TeacherEditPage',
            'StudentsUsersPage',
            'StudentEditPage',
            'ProfilePage',
          ]),
        },
      ],
      student: [
        {
          iconType: 'informative-panel',
          label: t('informativePanel'),
          route: 'StudentPanel',
          params: {},
          active: isRouteActive(['StudentPanel']),
          index: true,
        },
        {
          iconType: 'register',
          label: t('register2'),
          route: 'RegisterPage',
          active: isRouteActive(['RegisterPage', 'RegisterSessionsPage', 'SessionPage']),
        },
        {
          iconType: 'plus-rounded',
          label: t('registerSession'),
          route: 'SessionsApplicationPage',
          active: isRouteActive(['SessionsApplicationPage']),
        },
        {
          iconType: 'calendar',
          label: t('events'),
          route: 'EventsPage',
          active: isRouteActive(['EventsPage', 'AddEventsPage', 'InvitePage']),
        },
        {
          iconType: 'resources',
          label: t('resources'),
          route: 'ResourcePage',
          params: { type: 'all' },
          active: isRouteActive(['ResourcePage']),
        },
        {
          iconType: 'user-strict',
          label: t('contacts'),
          route: 'ContactsPage',
          active: isRouteActive(['ContactsPage']),
        },
      ],
      professor: [
        {
          iconType: 'session-checked',
          label: t('sessionsCurrent'),
          route: 'SingleCurrentSessionsPage',
          active: isRouteActive(['SingleCurrentSessionsPage']),
          index: true,
        },
        {
          iconType: 'calendar',
          label: t('events'),
          route: 'EventsPage',
          active: isRouteActive(['EventsPage', 'AddEventsPage', 'InvitePage']),
        },
        {
          iconType: 'resources',
          label: t('resources'),
          route: 'ResourcePage',
          params: { type: 'all' },
          active: isRouteActive(['ResourcePage']),
        },
        {
          iconType: 'archive',
          label: t('sessionsClosed'),
          route: 'SingleClosedSessionsPage',
          active: isRouteActive(['SingleClosedSessionsPage']),
        },
        {
          iconType: 'student',
          label: t('myStudents'),
          route: 'MyStudentsUsersPage',
          active: isRouteActive(['MyStudentsUsersPage', 'ProfilePage', 'MyStudentEditPage']),
        },
        {
          iconType: 'requests',
          label: t('requests'),
          route: 'ApplicationsPage',
          active: isRouteActive(['ApplicationsPage']),
        },
        {
          iconType: 'user-strict',
          label: t('contacts'),
          route: 'ContactsPage',
          active: isRouteActive(['ContactsPage']),
        },
      ],

      coordinator: [
        {
          iconType: 'events',
          label: t('events'),
          route: 'EventsPage',
          active: isRouteActive(['EventsPage', 'AddEventsPage', 'InvitePage']),
          index: true,
        },
        {
          iconType: 'alarm',
          label: t('plannedSessions'),
          route: 'SinglePlannedSessionsPage',
          active: isRouteActive(['SinglePlannedSessionsPage', 'RequestSessionPage', 'AddSessionPage']),
        },
        {
          iconType: 'lock',
          label: t('sessionsClosed'),
          route: 'SingleClosedSessionsPage',
          active: isRouteActive(['SingleClosedSessionsPage']),
        },
        {
          iconType: 'communication',
          label: t('communication'),
          route: 'CommunicationPage',
          active: isRouteActive(['CommunicationPage']),
        },
        {
          iconType: 'student',
          label: t('myStudents'),
          route: 'MyStudentsUsersPage',
          active: isRouteActive(['MyStudentsUsersPage', 'ProfilePage', 'MyStudentEditPage']),
        },
        {
          iconType: 'resources',
          label: t('resources'),
          route: 'ResourcePage',
          params: { type: 'all' },
          active: isRouteActive(['ResourcePage']),
        },
      ],
    }),
    [match.url, match.params, t],
  );

  const [userModules, indexModule] = React.useMemo(
    () => [
      modules[userData.role] || [],
      userData.role && modules[userData.role] && modules[userData.role].find(({ index }) => index),
    ],
    [userData, t],
  );

  return {
    allModules: modules,
    modules: userModules,
    indexModule,
    commonModule: {
      route: 'PersonalAccountPage',
    },
  };
};
