import * as React from 'react';
import { useRequest } from 'estafette';
import { notify } from 'react-notify-toast';
import { Link } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { format, dateTimeFormat, toISO, dateFormatInput } from 'libs/date';
import { UserContext } from 'contexts';
import { useStateHandlers } from 'hooks';
import { sessions } from 'libs/http/api';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { copySessionPublicLink } from 'libs/copy';
import { Column } from 'ui/atoms/Table/Table';
import { Info, Icon, Table, Time, Status, Button } from 'ui/atoms';
import { DateRangePicker } from 'ui/molecules';
import { Actions } from 'ui/organisms';

import { SelectUser, SelectedSession } from '.';

import './PlannedSessionTable.scss';
import { Dispatch } from 'react';

interface Props {
  page: number | undefined;
  data: Session[];
  setData: Dispatch<any>;
  expandable: any;
  onManagementSession: (value: number) => void;
  onDeleteSession: (value: number) => void;
  onRefetch: () => void;
  handleDownload: () => void;
}

export const PlannedSessionsTable: React.FC<Props> = ({
  page,
  data = [],
  setData,
  expandable,
  onManagementSession,
  onDeleteSession,
  onRefetch,
  handleDownload,
}) => {
  const { t } = useIntl();
  const { userData } = React.useContext(UserContext);

  const [selected, setSelected] = useStateHandlers<SelectedSession>({
    id: undefined,
    institution: undefined,
    start_date: '',
    end_date: '',
  });

  const { request: requestSave, loading: loadingSave } = useRequest();

  const onSave = async ({ id, type, value }: any): Promise<void> => {
    const savedData: any = await requestSave(sessions.save.action({ [type]: toISO(value), id }));
    setData(({ results, ...rest }: any) => {
      const data = results.map((i: Session) => {
        if (i.id === savedData.id) {
          return savedData;
        }
        return i;
      });
      return { results: data, ...rest };
    });
  };

  const onPublish = async ({ id, force }: { id: number; force: boolean }): Promise<void> => {
    await requestSave(
      sessions.save.action({
        id,
        ...(force ? { professor_status: 'accepted' } : {}),
        status: 'accepted',
      }),
    );

    onRefetch();
  };

  const onChangePeriod = ({ id, start_date__gte, end_date__lte }: any): void => {
    if (start_date__gte) {
      onSave({ id, value: start_date__gte, type: 'start_date' });
    }

    if (end_date__lte) {
      onSave({ id, value: end_date__lte, type: 'end_date' });
    }
  };

  const handleCopyLink = (session: Session): void => {
    copySessionPublicLink(session);
    notify.show(`${t('successfullyCopied')}`, 'success');
  };

  const columns: Column<Session>[] = React.useMemo(
    () => [
      {
        title: t('teacher'),
        render: ({ id, professor, start_date: startDate, end_date: endDate, institution }) => (
          <SelectUser
            onVisible={() =>
              setSelected({
                id,
                ...(institution ? { institution: institution.id } : {}),
                start_date: startDate,
                end_date: endDate,
              })
            }
            selected={selected}
            professor={professor}
            loading={loadingSave}
            onSave={(value) => onSave({ id, value, type: 'professor' })}
          />
        ),
        className: 'zh-select-user-cell',
      },
      {
        title: `${t('country')}, ${t('city')}`,
        render: ({ country, city }) =>
          country && country.title ? (
            <Info
              className="nowrap"
              icon={<Icon type="pin" />}
              label={[country && country.title, city && city.title].filter((i) => i).join(', ')}
            />
          ) : (
            '---'
          ),
      },
      {
        title: t('course'),
        className: 'zh-course-cell',
        render: ({ course }) => course?.name || '---',
      },
      {
        title: t('period'),
        render: ({ id, start_date: startDate, end_date: endDate }) => {
          return userData.role === 'admin' ? (
            <div key={`${id}-picker`}>
              <Icon type="clock" />
              <DateRangePicker
                from={startDate}
                to={endDate}
                showFormat={dateFormatInput}
                type="period"
                withTime
                divider
                withPortal
                onChange={(values) => onChangePeriod({ id, ...values })}
              />
            </div>
          ) : (
            <div key={`${id}-picker`}>
              <Time
                date={[startDate && format(startDate, dateTimeFormat), endDate && format(endDate, dateTimeFormat)]
                  .filter((i) => i)
                  .join(' - ')}
                noParse
              />
            </div>
          );
        },
        className: 'zh-period-cell nowrap',
      },
      {
        title: t('numOfRegisteredStudentsShort'),
        width: 80,
        render: ({ number_of_students: students }) => (students && <b>{students}</b>) || 0,
      },
      {
        title: t('status'),
        render: ({ status }) => <Status label={t(status)} status={status} maxWidth="120px" nowrap />,
      },
      {
        title: userData.role === 'admin' && (
          <Button type="primary" onClick={handleDownload} className="download-report">
            {t('download')}
          </Button>
        ),
        action: true,
        render: (session) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            {userData.role === 'admin' &&
              !['accepted', 'declined', 'professor_invitation_declined'].includes(session.status) && (
                <Button
                  type="primary"
                  size="small"
                  className="f10px"
                  onClick={() => onPublish({ id: session.id, force: false })}
                  disabled={session.status !== 'professor_invitation_accepted'}
                >
                  {t('publishSession')}
                </Button>
              )}

            <Link className="nowrap" route="GeneralSessionPage" params={{ id: session.id }}>
              {t('details')}
            </Link>

            {['admin'].includes(userData.role) && (
              <Actions
                onAccept={
                  userData.role === 'admin' && session.status !== 'accepted'
                    ? () =>
                        onPublish({
                          id: session.id,
                          force: true,
                        })
                    : undefined
                }
                onConfirm={() => handleCopyLink(session)}
                onConfirmLabel={t('copyLink')}
                onAcceptLabel={t('forcePublish')}
                onEdit={
                  ['admin', 'coordinator'].includes(userData.role) ? () => onManagementSession(session.id) : undefined
                }
                onDelete={userData.role === 'admin' ? () => onDeleteSession(session.id) : undefined}
              />
            )}
          </div>
        ),
        className: 'nowrap',
      },
    ],
    [t, loadingSave, userData.role, onSave],
  );

  return <Table size="small" page={page} data={data} columns={columns} scroll={{ x: true }} expandable={expandable} />;
};
