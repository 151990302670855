import * as React from 'react';
import IntlTelInput from 'react-intl-tel-input';

import { renderPhone } from 'libs/mask';

import 'react-intl-tel-input/dist/main.css';
import './CountryFlags.css';
import './InputPhone.scss';

interface Props {
  value?: string;
  country?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
}

const getPhone = (value?: string): string => {
  if (!value) {
    return '';
  }

  if (value.length > 9 && value[0] !== '+') {
    return `+${value}`;
  }

  return value;
};

const getCleanedSpaceNumber = (n: string): string => {
  return n.replace(/ /g, '');
};

export const InputPhone: React.FC<Props> = ({ value, onChange, country, autoFocus }) => {
  const [number, setNumber] = React.useState<string>('');
  const [formatedNumber, setFormatedNumber] = React.useState<string>('');

  React.useEffect(() => {
    if (value && value !== number) {
      setNumber(getPhone(value));
    }
  }, [value]);

  const handlePhoneNumberChange = (
    isValid: boolean,
    fullNumber: string,
    numberObject: {
      name: string;
      iso2: string;
      dialCode: string;
      priority: number;
      areaCodes: any;
    },
    formatNumber: string,
  ): void => {
    setNumber(fullNumber);
    setFormatedNumber(formatNumber);
  };

  const handleBlur = (): void => {
    if (onChange) {
      const processedPhone = getCleanedSpaceNumber(renderPhone(formatedNumber));

      setNumber(processedPhone);
      onChange(processedPhone);
    }
  };

  return (
    <div className="zh-phone">
      <IntlTelInput
        containerClassName="intl-tel-input"
        defaultCountry={country || 'md'}
        nationalMode={false}
        autoFocus={autoFocus}
        preferredCountries={[]}
        value={number}
        onPhoneNumberChange={handlePhoneNumberChange}
        onPhoneNumberBlur={handleBlur}
      />
    </div>
  );
};
