import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Link, useParams, useRouterHelpers } from 'estafette-router';
import { UserContext } from 'contexts';
import { Layout } from 'ui/organisms';
import { Button, Icon, Tabs } from 'ui/atoms';

export const GraduationLayout: React.FC<{ showTabs?: boolean }> = ({ children }) => {
  const { userData } = React.useContext(UserContext);
  const { isRouteActive } = useRouterHelpers();
  const { id } = useParams();
  const { t, locale } = useIntl();

  const tabsData = React.useMemo(
    () => [
      {
        route: 'GeneralGraduationPage',
        params: { id },
        label: t('generalInformation'),
        active: isRouteActive(['GeneralGraduationPage']),
      },
    ],
    [id, locale, isRouteActive],
  );

  return (
    <Layout
      title={t('graduation')}
      rightSide={
        userData.role && (
          <>
            {userData.role === 'admin' && (
              <Link route="AddGraduationGraduatePage" params={{ id, action: 'add' }}>
                <Button type="invert" prefix={<Icon type="plus" />}>
                  {t('addItem', { item: t('graduate').toLowerCase() })}
                </Button>
              </Link>
            )}
          </>
        )
      }
    >
      <Tabs data={tabsData} className="mb-0" />

      {children}
    </Layout>
  );
};
