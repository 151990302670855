import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { Results } from '../index.types';
import { FacultiesList, Faculty } from './faculties.types';

export const faculties = {
  get: {
    action: (params = {}): Promise<{ data: Results<Faculty> }> =>
      axios.get(`faculties`, { params, cancelToken: new cancelToken((c: Canceler) => (faculties.get.cancel = c)) }),
    cancel: (() => null) as Canceler,
  },

  list: {
    action: (params = {}): Promise<{ data: FacultiesList[] }> =>
      axios.get('faculties/list', {
        params,
        cancelToken: new cancelToken((c: Canceler) => (faculties.list.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  add: {
    action: (options = {}) =>
      axios.post(`faculties`, options, {
        cancelToken: new cancelToken((c: Canceler) => (faculties.add.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  save: {
    action: ({ id, ...options }: { id: number }) =>
      axios.patch(`faculties/${id}`, options, {
        cancelToken: new cancelToken((c: Canceler) => (faculties.save.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  load: {
    action: ({ id, ...params }: { id: number }): Promise<{ data: Faculty }> =>
      axios.get(`faculties/${id}`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (faculties.load.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  delete: {
    action: ({ id }: { id: number }) =>
      axios.delete(`faculties/${id}`, {
        cancelToken: new cancelToken((c: Canceler) => (faculties.delete.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
