import * as React from 'react';
import AnimateHeight from 'react-animate-height';
import { Head } from 'ui/organisms';

import { PanelLayout } from '../organisms';
import { Events, Announces, Outstandings, SuggestedEvents } from '../molecules';
import { MeSessions } from '../templates';

import './StudentPanel.scss';

export const StudentPanel: React.FC = () => {
  const timer = React.useRef<NodeJS.Timeout>();

  const [update, setUpdate] = React.useState(false);

  React.useEffect(() => {
    return () => {
      if (timer.current !== undefined) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const toggleUpdate = (): void => {
    setUpdate(true);

    // clear update status to let them reupdate
    timer.current = setTimeout(() => setUpdate(false), 1000);
  };

  return (
    <>
      <Head t="informativePanel" />

      <PanelLayout>
        <div className="informativePanel">
          <Announces />
          <Events update={update} />

          <SuggestedEvents onUpdate={toggleUpdate} />

          <AnimateHeight duration={150} height={!update ? 'auto' : 0}>
            <MeSessions />
          </AnimateHeight>

          <Outstandings />
        </div>
      </PanelLayout>
    </>
  );
};
