import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Link, useRouterHelpers } from 'estafette-router';
import { NotificationsContext, UserContext } from 'contexts';
import { Layout } from 'ui/organisms';
import { Button, Tabs, Icon } from 'ui/atoms';

export const SessionsLayout: React.FC<{ showTabs?: boolean }> = ({ showTabs = true, children }) => {
  const { t } = useIntl();
  const { isRouteActive } = useRouterHelpers();
  const {
    fetchedSessionRequestsCounts,
    sessionRequests,
    onFetchSessionRequestsCounts,
    institutionWithoutPlanCount,
  } = React.useContext(NotificationsContext);
  const { userData } = React.useContext(UserContext);

  React.useEffect(() => {
    if (!fetchedSessionRequestsCounts) {
      onFetchSessionRequestsCounts();
    }
  }, [fetchedSessionRequestsCounts]);

  const tabsData = React.useMemo(
    () => [
      {
        route: 'PlannedSessionsPage',
        label: t('plannedSessions'),
        active: isRouteActive('PlannedSessionsPage'),
      },
      {
        route: 'SessionApplicationsPage',
        label: t('sessionRequests'),
        active: isRouteActive('SessionApplicationsPage'),
        count: sessionRequests || undefined,
      },
      {
        route: 'CurrentSessionsPage',
        label: t('currentSessions'),
        active: isRouteActive('CurrentSessionsPage'),
      },
      {
        route: 'ClosedSessionsPage',
        label: t('sessionsClosed'),
        active: isRouteActive('ClosedSessionsPage'),
      },
      {
        route: 'InstituteWithoutPlanPage',
        label: t('needToPlan'),
        active: isRouteActive('InstituteWithoutPlanPage'),
        count: institutionWithoutPlanCount || undefined,
      },
    ],
    [isRouteActive, sessionRequests],
  );

  return (
    <Layout
      title={t('sessions')}
      rightSide={
        userData.role && (
          <>
            {userData.role === 'admin' && (
              <Link route="AddSessionPage" params={{ action: 'add' }}>
                <Button type="invert" prefix={<Icon type="plus" />}>
                  {t('addSession')}
                </Button>
              </Link>
            )}

            {userData.role === 'coordinator' && (
              <Link route="RequestSessionPage">
                <Button type="invert" prefix={<Icon type="plus" />}>
                  {t('sessionRequest')}
                </Button>
              </Link>
            )}
          </>
        )
      }
    >
      {showTabs && <Tabs data={tabsData} className="mb-0" />}

      {children}
    </Layout>
  );
};
