import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { User } from 'libs/http/api/me/me.types';
import { renderPhone } from 'libs/mask';
import { Avatar, Status, Button, Icon, Info, Put } from 'ui/atoms';
import { SendMessage } from 'ui/organisms';

import './ProfileCard.scss';
import { Confirmation } from '../Confirmation/Confirmation';

interface Props {
  onSendMessage?: () => void;
  onEdit?: () => void;
  onDelete?: (id: any) => void;
  data?: User;
  role?: string;
}

export const ProfileCard: React.FC<Props> = ({ data = {}, role, onSendMessage, onEdit, onDelete }) => {
  const { t } = useIntl();

  const {
    fullName,
    location,
    hasAnyMessenger,
    hasAnySocialNetwork,
    nativeLanguages,
    foreignLanguage,
    institutions,
    faculties,
    allowedRoles,
    experience,
  } = React.useMemo(
    () => ({
      fullName: [data.first_name, data.last_name].filter((i) => i).join(' '),
      location: [data.country && data.country.title, data.city && data.city.title].filter((i) => i).join(', '),
      hasAnyMessenger: [data.telegram, data.skype, data.whatsup, data.viber].filter((i) => i).length > 0,
      hasAnySocialNetwork:
        [data.vkontakte_url, data.facebook_url, data.odnoklasniki_url, data.linkedin_url].filter((i) => i).length > 0,
      nativeLanguages:
        data.native_language !== undefined ? data.native_language.map(({ name }) => name).join(', ') : '',
      foreignLanguage:
        data.foreign_language !== undefined ? data.foreign_language.map(({ name }) => name).join(', ') : '',
      institutions:
        data.institutions !== undefined ? data.institutions.map((institution) => institution.name).join(', ') : '',
      faculties: data.faculties !== undefined ? data.faculties.map((faculty) => faculty.name).join(', ') : '',
      allowedRoles: data.allowed_roles !== undefined ? data.allowed_roles.map((s) => t(s)).join(', ') : '',
      experience: data.experience ? data.experience : undefined,
    }),
    [t, data],
  );

  return (
    <div className={`zh-profile-card${data.is_deleted ? ' zh-profile-card-deleted' : ''}`}>
      <div className="zh-profile-card-item">
        <div className="zh-profile-card-avatar">
          <Avatar size="big" img={data.profile_picture} alt={fullName} />

          <div className="zh-profile-card-avatar-details">
            <div className="zh-profile-card-avatar-title">
              {fullName}
              {data.is_deleted ? ' - ' : ''}
              {data.is_deleted && <Status status="declined" label={t('deleted')} />}
            </div>
            <div className="zh-profile-card-avatar-desc">
              {(role && t(role)) || (data.role && t(data.role)) || '---'}
            </div>

            {data.status ? (
              <div className="zh-profile-card-avatar-status">
                <Status label={data.status} status={data.status} />
              </div>
            ) : null}
          </div>
        </div>

        <div className="zh-profile-card-tools">
          {onSendMessage !== undefined && <SendMessage users={data.id ? [data.id] : []} />}

          {onEdit !== undefined && !data.is_deleted && (
            <Button prefix={<Icon type="edit" />} type="primary" onClick={onEdit}>
              {t('edit')}
            </Button>
          )}

          {onDelete !== undefined && data.id && !data.is_deleted && (
            <Confirmation onConfirm={() => onDelete(data.id)}>
              <Button prefix={<Icon type="delete" />} type="dark">
                {t('delete')}
              </Button>
            </Confirmation>
          )}
        </div>
      </div>

      <div className="zh-profile-card-item">
        <div className="zh-profile-card-contacts">
          <div className="zh-profile-card-contact">
            <div className="zh-profile-card-contact-label">{t('phone')}</div>
            <Info icon={<Icon type="phone" />} label={data.phone_number ? renderPhone(data.phone_number) : '---'} />
          </div>

          <div className="zh-profile-card-contact">
            <div className="zh-profile-card-contact-label">E-mail</div>
            <Info icon={<Icon type="mail-filled" />} label={data.email || '---'} />
          </div>

          <div className="zh-profile-card-contact">
            <div className="zh-profile-card-contact-label">{t('location')}</div>
            <Info icon={<Icon type="location-filled" />} label={location || '---'} />
          </div>

          <div className="zh-profile-card-contact">
            <div className="zh-profile-card-contact-label">{t('allowedRoles')}</div>
            <Info label={allowedRoles || '---'} />
          </div>

          <div className="zh-profile-card-contact">
            <div className="zh-profile-card-contact-label">{t('institutions')}</div>
            <Info label={institutions || '---'} />
          </div>

          <div className="zh-profile-card-contact">
            <div className="zh-profile-card-contact-label">{t('faculties')}</div>
            <Info label={<Put>{faculties}</Put>} />
          </div>
        </div>

        {hasAnyMessenger && (
          <div className="zh-profile-card-contacts">
            {data.telegram && (
              <div className="zh-profile-card-contact">
                <div className="zh-profile-card-contact-label">Telegram</div>
                <Info icon={<Icon type="telegram" />} label={data.telegram} />
              </div>
            )}

            {data.skype && (
              <div className="zh-profile-card-contact">
                <div className="zh-profile-card-contact-label">Skype</div>
                <Info icon={<Icon type="skype" />} label={data.skype} />
              </div>
            )}

            {data.viber && (
              <div className="zh-profile-card-contact">
                <div className="zh-profile-card-contact-label">Viber</div>
                <Info icon={<Icon type="viber" />} label={data.viber} />
              </div>
            )}

            {data.whatsup && (
              <div className="zh-profile-card-contact">
                <div className="zh-profile-card-contact-label">WhatsApp</div>
                <Info icon={<Icon type="whatsapp" />} label={data.whatsup} />
              </div>
            )}
          </div>
        )}

        <div className="zh-profile-card-contacts">
          <div className="zh-profile-card-contact">
            <div className="zh-profile-card-contact-label">{t('dateOfBirth')}</div>
            <Info label={data.birth_date} />
          </div>

          <div className="zh-profile-card-contact">
            <div className="zh-profile-card-contact-label">{t('sex')}</div>
            <Info
              icon={<Icon type={`gender-${data.gender === 'M' ? 'male' : 'female'}`} />}
              label={t(data.gender === 'M' ? 'male' : 'female')}
            />
          </div>

          <div className="zh-profile-card-contact">
            <div className="zh-profile-card-contact-label">{t('nativeLanguage')}</div>
            <Info label={nativeLanguages || '---'} />
          </div>

          <div className="zh-profile-card-contact">
            <div className="zh-profile-card-contact-label">{t('foreignLanguages')}</div>
            <Info label={foreignLanguage || '---'} />
          </div>

          {experience && role === 'professor' && (
            <div className="zh-profile-card-contact">
              <div className="zh-profile-card-contact-label">{t('experienceGrade')}</div>
              <Info label={experience} />
            </div>
          )}
        </div>

        {hasAnySocialNetwork && (
          <div className="zh-profile-card-networks">
            <div className="zh-profile-card-networks-title">{t('socialsNetworks')}</div>

            {data.facebook_url && (
              <a href={data.facebook_url} target="_blank" rel="noopener noreferrer">
                <Info icon={<Icon type="facebook" />} label="Facebook" />
              </a>
            )}

            {data.vkontakte_url && (
              <a href={data.vkontakte_url} target="_blank" rel="noopener noreferrer">
                <Info icon={<Icon type="vk" />} label="Vkontakte" />
              </a>
            )}

            {data.linkedin_url && (
              <a href={data.linkedin_url} target="_blank" rel="noopener noreferrer">
                <Info icon={<Icon type="linkedin" />} label="Linkedin" />
              </a>
            )}

            {data.odnoklasniki_url && (
              <a href={data.odnoklasniki_url} target="_blank" rel="noopener noreferrer">
                <Info icon={<Icon type="odnoklasniki" />} label="Odnoklasniki" />
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
