import * as React from 'react';
import { Link, useHistory } from 'estafette-router';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { UserContext } from 'contexts';
import { useStateHandlers, useQueryUpdate, useQueryParams } from 'hooks';
import { isAfter, format, dateFormat, toISO } from 'libs/date';
import { parseQuery } from 'libs/object';
import { users, sessions } from 'libs/http/api';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { EurasiaRegister } from 'libs/http/api/users/users.types';
import { Results, Save } from 'libs/http/api/index.types';
import { Content } from 'features/public/atoms';
import { Time, Table, Button, Icon, Info, Loader, Modal, ModalFooterButton, Input, Alert } from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';
import { Searchbar } from 'features/public/molecules';
import type { FiltersProps } from 'features/public/molecules';
import { Pagination, DateRangePicker } from 'ui/molecules';
import { Public } from 'features/public/organisms';
import { Confirmation, Form, FormItems, FormItem } from 'ui/organisms';

import './SessionsPage.scss';

interface StateProps {
  filters: FiltersProps;
  page?: number;
  mobile?: boolean;
}

interface QueryProps extends FiltersProps {
  page?: number;
}

export const SessionsPage: React.FC = () => {
  const auth = React.useContext(UserContext);
  const { page, ...params }: QueryProps = useQueryParams();
  const { t } = useIntl();
  const { push } = useHistory();
  const { updateQuery } = useQueryUpdate();
  const smsRef = React.useRef<any>(null);
  const { confirm, smsconfirm, restore } = parseQuery<{ confirm?: string; smsconfirm?: string; restore?: string }>(
    window.location.search,
  );
  const { request, data: response, loading } = useRequest<Results<Session>>({ loading: true, data: { results: [] } });
  const {
    request: requestRegister,
    data: responseRegister,
    loading: loadingRegister,
    errors: errorsRegister,
  } = useRequest<Save>();
  const { request: requestConfirm, data: dataConfirm, errors: errorsConfirm } = useRequest<EurasiaRegister>();
  const { request: requestVerifyCode, data: dataVerifyCode, errors: errorsVerifyCode } = useRequest<EurasiaRegister>();
  const [sms, setSms] = React.useState('');
  const [state, setState] = useStateHandlers<StateProps>({
    filters: {
      filtered: params.filtered || false,
      start_date__gte: params.start_date__gte || '',
      end_date__lte: params.end_date__lte || '',
      ...(params.city ? { city: Number(params.city) } : {}),
      ...(params.country ? { country: Number(params.country) } : {}),
      ...(params.faculty__in ? { faculty__in: Number(params.faculty__in) } : {}),
      ...(params.search ? { search: params.search } : {}),
    },
    page: page || 1,
    mobile: false,
  });

  React.useEffect(() => {
    request(
      sessions.get.action({
        ...state.filters,
        ...(state.filters.start_date__gte ? { start_date__gte: toISO(state.filters.start_date__gte) } : {}),
        ...(state.filters.end_date__lte ? { end_date__lte: toISO(state.filters.end_date__lte) } : {}),
        page: state.page,
      }),
    );
  }, [state.filters, state.page]);

  React.useEffect(() => updateQuery({ ...state.filters, page: state.page, smsconfirm, restore }), [
    state,
    updateQuery,
    smsconfirm,
    restore,
  ]);

  React.useEffect(() => {
    const onResize = (): void => setState({ mobile: window.innerWidth < 900 });

    onResize();

    window.addEventListener('resize', onResize);

    return (): void => window.removeEventListener('resize', onResize);
  }, []);

  React.useEffect(() => {
    if (responseRegister && responseRegister.success) push('PlannedSessionsPage');
  }, [responseRegister]);

  React.useEffect(() => {
    if (dataConfirm.success) {
      push('SignInPage', { query: { confirm: true } });
    }
  }, [dataConfirm]);

  React.useEffect(() => {
    if (dataVerifyCode.success) {
      push('ConfirmRestorePage', { token: sms });
    }
  }, [dataVerifyCode]);

  const onCancel = (): void => push('SignInPage');
  const onChangePeriod = (period: any): void =>
    setState({
      page: 1,
      filters: {
        ...state.filters,
        filtered: true,
        ...(period.start_date__gte ? { start_date__gte: period.start_date__gte } : {}),
        ...(period.end_date__lte ? { end_date__lte: period.end_date__lte } : {}),
      },
    });

  const onSubmit = (id: number): void => {
    if (auth.logged) {
      requestRegister(sessions.register.action({ id: id }));
    } else {
      push('SignUpPage', { query: { session: id } });
    }
  };

  const onSetSms = React.useCallback(
    (position: number, val: string): void => {
      if (val.replace(/\D/g, '').length) {
        if (sms.length < 6) {
          const firstPart = sms.substr(0, position);
          const lastPart = sms.substr(position + 1);

          setSms(firstPart + val + lastPart);
        }

        if (smsRef.current) {
          const elements = smsRef.current.childNodes[0].childNodes;
          for (let i = 0; i < elements.length; i++) {
            if (position < elements.length && i === position + 1) {
              elements[i].getElementsByTagName('input')[0].focus();
              break;
            }
          }
        }
      }
    },
    [sms, smsRef.current],
  );

  const onSMSKeyDown = React.useCallback(
    (position, e) => {
      if ([8, 46].indexOf(e.keyCode) > -1) {
        const firstPart = sms.substr(0, position);

        setSms(firstPart);

        if (smsRef.current) {
          const elements = smsRef.current.childNodes[0].childNodes;
          for (let i = elements.length - 1; i > -1; i--) {
            if (position < elements.length && i === position - 1) {
              elements[i].getElementsByTagName('input')[0].focus();
              break;
            }
          }
        }
      }
    },
    [sms, smsRef.current],
  );

  const onConfirm = async (token: string): Promise<void> => {
    if (restore === 'true') {
      await requestVerifyCode(users.restoreVerify.action({ token }));
    } else {
      await requestConfirm(users.confirm.action({ token }));
    }
  };

  const onChangePage = (page: number): void => setState({ page });

  const columns: Column<Session>[] = React.useMemo(
    () => [
      {
        title: t('course'),
        render: ({ course, professor, id }) => (
          <div className="colInfo">
            <Link route="PublicSession" params={{ id }} className="link-to-session">
              <b>{`${course ? course.name : '---'}`}</b>
            </Link>
            {professor && (
              <Info
                icon={<Icon type="user-public" />}
                label={[professor.first_name, professor.last_name].filter((i) => i).join(' ')}
              />
            )}
          </div>
        ),
      },
      {
        title: t('nameOfFaculty'),
        width: 200,
        render: ({ faculty, country, city }) => (
          <div className="colInfo">
            <b>{(faculty && faculty.name) || '---'}</b>
            {country && (
              <Info
                icon={<Icon type="pin" />}
                label={[country.title, city && city.title].filter((i) => i).join(', ')}
              />
            )}
          </div>
        ),
      },
      {
        title: t('startAt'),
        render: ({ start_date: startDate }) => <Time className="nowrap" date={format(startDate, dateFormat)} noParse />,
        width: 135,
        className: 'vertical-align-bottom',
      },
      {
        title: t('finishAt'),
        render: ({ end_date: endDate }) => <Time className="nowrap" date={format(endDate, dateFormat)} noParse />,
        width: 135,
        className: 'vertical-align-bottom',
      },
      {
        title: '',
        width: 280,
        render: ({ id, end_date: endDate, is_bible_group: isBibleGroup, is_advance_level: isAdvanceLevel }) =>
          !isAdvanceLevel ? (
            isAfter(endDate) ? (
              <Button type="border" disabled={true}>
                {t('registerSession')}
              </Button>
            ) : isBibleGroup ? (
              <Confirmation isBibleGroup={true} onConfirm={(): void => onSubmit(id)}>
                <Button type="border">{t('registerSession')}</Button>
              </Confirmation>
            ) : (
              <Button type="border" onClick={(): void => onSubmit(id)}>
                {t('registerSession')}
              </Button>
            )
          ) : (
            <Alert className="zh-alert-session-table" message={t('graduate_level_1')} type="info" />
          ),
      },
    ],
    [t],
  );

  return (
    <Public>
      <Content className={`container ${state.mobile ? 'responsive' : ''}`}>
        <Searchbar
          state={state.filters}
          onChange={(stateFilter): void => setState({ filters: { ...state.filters, ...stateFilter } })}
        />

        <hr className="hr" />

        <div className="container" style={{ margin: 0, border: 0 }}>
          <div className="flex flex-between">
            <h3 style={{ margin: 0 }}>{t('sessionsList')}</h3>

            <div className="picker-wrapper">
              <div className="hideTablet">{t('period')}</div>
              <DateRangePicker withTime type="period" onChange={onChangePeriod} />
            </div>
          </div>

          <hr />
        </div>

        <Loader loading={loading || loadingRegister} height={350}>
          <Table columns={columns} data={response.results} />
        </Loader>

        {response.count > 10 && (
          <Pagination
            label={t('sessions').toLocaleLowerCase()}
            total={response.count}
            current={state.page}
            onChange={onChangePage}
          />
        )}

        {confirm === 'true' && (
          <Modal
            footer={
              <ModalFooterButton align="center">
                <Confirmation onConfirm={onCancel}>
                  <Button>{t('cancel')}</Button>
                </Confirmation>

                <Button submit type="primary" prefix={<Icon type="check" />} onClick={() => push('SessionsPage')}>
                  {t('confirm')}
                </Button>
              </ModalFooterButton>
            }
            size="small"
          >
            {t('pleaseActivateAccount')}
          </Modal>
        )}

        {smsconfirm === 'true' && (
          <Modal
            footer={
              <ModalFooterButton align="center">
                <Confirmation onConfirm={onCancel}>
                  <Button>{t('cancel')}</Button>
                </Confirmation>

                <Button
                  submit
                  type="primary"
                  prefix={<Icon type="check" />}
                  onClick={() => onConfirm(sms)}
                  disabled={sms.length < 6}
                >
                  {t('confirm')}
                </Button>
              </ModalFooterButton>
            }
            size="small"
          >
            {restore === 'true' ? t('enterCodeFromSmsToSetNewPassword') : t('pleaseActivateAccountByPhone')}

            <Alert
              message={
                responseRegister.message ||
                dataConfirm.message ||
                errorsConfirm.non_field_errors ||
                errorsRegister.non_field_errors ||
                errorsRegister.detail ||
                errorsVerifyCode.non_field_errors
              }
              form
              className="mt-15"
              type="error"
            />

            <Form onSubmit={() => null} className="mt-20 sms-confirm">
              <div ref={smsRef}>
                <FormItems>
                  <FormItem>
                    <Input
                      value={sms[0]}
                      width={32}
                      onChange={(value) => onSetSms(0, value)}
                      onKeyDown={(e) => onSMSKeyDown(0, e)}
                    />
                  </FormItem>

                  <FormItem>
                    <Input
                      value={sms[1]}
                      width={32}
                      onChange={(value) => onSetSms(1, value)}
                      onKeyDown={(e) => onSMSKeyDown(1, e)}
                    />
                  </FormItem>

                  <FormItem>
                    <Input
                      value={sms[2]}
                      width={32}
                      onChange={(value) => onSetSms(2, value)}
                      onKeyDown={(e) => onSMSKeyDown(2, e)}
                    />
                  </FormItem>

                  <FormItem>
                    <Input
                      value={sms[3]}
                      width={32}
                      onChange={(value) => onSetSms(3, value)}
                      onKeyDown={(e) => onSMSKeyDown(3, e)}
                    />
                  </FormItem>

                  <FormItem>
                    <Input
                      value={sms[4]}
                      width={32}
                      onChange={(value) => onSetSms(4, value)}
                      onKeyDown={(e) => onSMSKeyDown(4, e)}
                    />
                  </FormItem>

                  <FormItem
                    extra={
                      (!errorsConfirm.success && errorsConfirm.message) ||
                      (!dataVerifyCode.success && dataVerifyCode.message) ||
                      errorsConfirm.detail ||
                      errorsVerifyCode.detail
                        ? t('invalidCode')
                        : undefined
                    }
                  >
                    <Input
                      value={sms[5]}
                      width={32}
                      onChange={(value) => onSetSms(5, value)}
                      onKeyDown={(e) => onSMSKeyDown(5, e)}
                    />
                  </FormItem>
                </FormItems>
              </div>
            </Form>
          </Modal>
        )}
      </Content>
    </Public>
  );
};
