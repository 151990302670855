import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useHistory } from 'estafette-router';
import { useFilters } from 'hooks';
import { me } from 'libs/http/api';
import { perPage } from 'libs/storage';
import { Results } from 'libs/http/api/index.types';
import { SortBy, InputSearch } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { Card, CardHeader, CardFooter, Button, Loader, Icon, CardAlert, Put } from 'ui/atoms';
import { Expande } from 'ui/atoms/Table/Expanded';
import { Graduation } from 'libs/http/api/graduations/graduations.types';
import { graduations } from 'libs/http/api/graduations/graduations';

import { GraduationsTable } from '../organisms';

export const GraduationsTemplate: React.FC<{ update?: boolean }> = ({ update }) => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { request, data, loading, setData } = useRequest<Results<Graduation>>({ data: { results: [] } });
  const { request: requestDelete, loading: loadingDelete } = useRequest();
  const initialFilters = React.useRef({
    filtered: true,
    page: 1,
    per_page: perPage,
    search: '',
    ordering: '-date' as Sorts,
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    return () => {
      me.getSessions.cancel();
      graduations.delete.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (!loadingDelete) {
      request(graduations.get.action(filters));
    }
  }, [filters, loadingDelete, update]);

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });
  const onSearchChange = (search: string): void => onChangeFilters('search', search);

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onDeleteGraduation = React.useCallback((id) => requestDelete(graduations.delete.action(id)), []);
  const onManagementSession = (id: number): void => push('AddGraduationPage', { action: id });

  const expandable = ({
    date,
    coordinator,
    country,
    city,
    institution,
    address,
    students_count,
  }: Graduation): Expande => [
    [
      {
        label: t('coordinator'),
        value: coordinator ? [coordinator.first_name, coordinator.last_name].filter((i) => i).join(' ') : '---',
      },
      { label: t('date'), value: <Put>{date}</Put>, icon: <Icon type="requests" /> },
    ],
    [
      {
        label: t('place'),
        value:
          country && country.title ? [country && country.title, city && city.title].filter((i) => i).join(', ') : '---',
        icon: <Icon type="pin" />,
      },
      {
        label: t('short_address'),
        value: address,
      },
    ],
    [
      { label: t('institute'), value: institution && institution.name },
      { label: t('studentsCount'), value: students_count },
    ],
  ];

  return (
    <Card className="mb-30">
      <CardHeader
        title={`${t('graduations')}`}
        count={data.count}
        leftSide={<InputSearch value={filters.search} onSearch={onSearchChange} />}
        rightSide={
          <SortBy
            options={[
              {
                title: 'ID',
                value: 'id',
              },
              {
                title: t('date'),
                value: 'date',
              },
              {
                title: t('address'),
                value: 'address',
              },
              {
                title: t('country'),
                value: 'country',
              },
              {
                title: t('city'),
                value: 'city',
              },
              {
                title: t('institution'),
                value: 'institution',
              },
              {
                title: t('studentsCount'),
                value: 'students_count',
              },
            ]}
            value={filters.ordering}
            onChange={(newValue) => onChangeFilters('ordering', newValue)}
          />
        }
      />

      <Loader loading={loading}>
        <GraduationsTable
          page={filters.page}
          data={data.results}
          setData={setData}
          onManagementSession={onManagementSession}
          onDeleteSession={onDeleteGraduation}
          expandable={expandable}
          onRefetch={onRefetch}
        />

        <CardFooter
          onRefresh={onRefetch}
          onRefreshDisabled={!filters.filtered}
          page={filters.page}
          pages={data.total_pages}
          perPage={filters.per_page}
          onChangePerPage={onChangePerPage}
          rightSide={
            <>
              <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                {t('previous')}
              </Button>

              <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                {t('next')}
              </Button>
            </>
          }
        />
        <CardAlert label={t('didYouFindProblem')} t="reportGraduations" />
      </Loader>
    </Card>
  );
};
