import * as React from 'react';
import { notify } from 'react-notify-toast';
import { useRequest } from 'estafette';
import { getRoute } from 'estafette-router';
import { save, load, remove } from 'react-cookies';
import { routes } from 'routes';
import { add } from 'libs/date';
import { history } from 'libs/history';
import { parseQuery } from 'libs/object';
import { axiosHeadersUpdater } from 'libs/http/axios';
import { Save } from 'libs/http/api/index.types';
import { me, users } from 'libs/http/api';
import { User } from 'libs/http/api/me/me.types';
import { EurasiaAccess, EurasiaRefreshAccess } from 'libs/http/api/users/users.types';
import { PermissionsGuard } from 'ui/organisms';
import { Loader } from 'ui/atoms';

type Verify = { token?: string; refresh?: string; remember?: boolean };

interface Queries {
  permissions?: string;
  permissions_guard?: string;
  token_not_valid?: string;
}

interface Props {
  readonly finished: boolean;
  readonly logged: boolean;
  readonly data: EurasiaAccess;
  readonly userData: User;
  setUserData: React.Dispatch<any>;
  onVerifyUser: (params?: Verify) => Promise<void>;
  onFetchUserData: () => Promise<User>;
  onLogout: () => void;
  language: string;
  onChangeLang: (lang: string, updateUser?: boolean, loader?: boolean) => void;
}

export const clearCookies = (): void => {
  remove('jwt-token', { path: '/' });
  remove('jwt-refresh-token', { path: '/' });
  remove('breadcrumbs', { path: '/' });

  axiosHeadersUpdater();
};

export const UserContext = React.createContext<Props>({
  finished: false,
  logged: false,
  data: {},
  userData: {} as User,
  setUserData: () => ({} as User),
  onVerifyUser: async (): Promise<void> => Promise.resolve(),
  onFetchUserData: async (): Promise<User> => Promise.resolve({} as User),
  onLogout: (): null => null,
  language: localStorage.getItem('lang') || 'en',
  onChangeLang: (): null => null,
});

export const UserProvider = ({ children }: { children: (values: Props) => React.ReactNode }): React.ReactElement => {
  const { request: requestUser, data: dataUser, setData: setDataUser, errors: errorsUser } = useRequest<User>({
    data: {},
  });
  const { request, data } = useRequest<EurasiaAccess>({ data: {} });
  const { request: requestSave } = useRequest<Save>();
  const { request: requestRefresh, errors: errorsRefresh } = useRequest<EurasiaRefreshAccess>({
    data: {},
  });
  const timer = React.useRef<NodeJS.Timeout>();
  const [language, setLanguage] = React.useState<string>(localStorage.getItem('lang') || 'en');
  const [loadingLanguage, setLoadingLanguage] = React.useState<boolean>(false);

  const [finished, setFinished] = React.useState<boolean>(false);
  const [logged, setLogged] = React.useState<boolean>(false);
  const [guard, setGuard] = React.useState<boolean>(false);

  React.useEffect(() => {
    const token = load('jwt-token');
    if (token) {
      onVerifyUser({ token });
    } else {
      setFinished(true);
    }

    history.listen(() => {
      const queries = parseQuery<Queries>(history.location.search);

      if (queries.permissions === 'true') {
        setGuard(true);

        // redirect to the same page to avoid alert reopening
        history.push(history.location.pathname);
      }

      if (queries.permissions_guard === 'true') {
        onVerifyUser({ token: load('jwt-refresh-token') });
      }

      if (queries.token_not_valid === 'true') {
        onRefreshUser();
      }
    });

    document.body.className = `language-${language}`;

    return () => {
      me.get.cancel();
      me.changeMyAccount.cancel();
      users.refresh.cancel();
      users.verify.cancel();

      if (timer.current !== undefined) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  React.useEffect(() => {
    const onFetchUserData = async (): Promise<void> => {
      if (logged) {
        await requestUser(me.get.action());
        setFinished(true);
      }
    };

    onFetchUserData();
  }, [logged]);

  React.useEffect(() => {
    if (errorsRefresh?.code === 'token_not_valid') {
      clearCookies();
      onRedirect();
    }
  }, [errorsRefresh]);

  React.useEffect(() => {
    if (errorsUser && (errorsUser.detail === 'User not found' || errorsUser.detail === 'Invalid payload')) {
      clearCookies();

      setLogged(false);
      onRedirect({ query: { user_not_found: true } });

      notify.show(errorsUser.detail, 'error');
    }
  }, [errorsUser]);

  const onChangeLang = React.useCallback((lang: string, updateUser = true, loader = true): void => {
    if (updateUser) {
      requestSave(me.changeMyLanguage.action(lang));
    }
    if (timer.current !== undefined) {
      clearTimeout(timer.current);
    }

    if (loader) {
      setLoadingLanguage(true);
    }

    setLanguage(lang);
    axiosHeadersUpdater(lang);
    localStorage.setItem('lang', lang);
    document.body.className = `language-${lang}`;

    timer.current = setTimeout(() => {
      setLoadingLanguage(false);
    }, 1000);
  }, []);

  const onVerifyUser = async (params?: Verify): Promise<void> => {
    setLogged(false);
    setFinished(false);

    if (params) {
      const options: { path: string; expires?: Date } = { path: '/' };

      if (params.remember) {
        options.expires = new Date(add(14).toString());
      }

      if (params.token) {
        save('jwt-token', params.token, options);
      }

      if (params.refresh) {
        save('jwt-refresh-token', params.refresh, options);
      }

      axiosHeadersUpdater();
    }

    try {
      const token = params?.token || load('jwt-token');

      if (token) {
        await request(users.verify.action({ token }));
      } else {
        setFinished(true);
      }

      // if there's no token it will be false, also if request passed successfully it will be true anyway
      setLogged(Boolean(token));
    } catch (err) {
      if (err.code && err.code === 'token_not_valid') {
        onRefreshUser();
      } else {
        setLogged(false);
        setFinished(true);
      }
    }
  };

  const onRefreshUser = async (): Promise<void> => {
    setLogged(false);
    setFinished(false);

    const refresh = load('jwt-refresh-token');

    if (refresh) {
      // see errorsRefresh useEffect for catch
      const { access } = await requestRefresh(users.refresh.action({ refresh }));

      if (access) {
        onVerifyUser({ token: access });
      }
    } else {
      clearCookies();

      onRedirect();

      setFinished(true);
    }
  };

  const onFetchUserData = (): Promise<User> => requestUser(me.get.action());

  const onLogout = (): void => {
    clearCookies();

    setLogged(false);
    setDataUser({});

    onRedirect();
  };

  const onRedirect = (params?: { [key: string]: any }): void => history.push(getRoute(routes, 'IndexEntry', params));

  const onCloseGuard = (): void => setGuard(false);

  const $loading = React.useMemo(() => !finished || loadingLanguage, [finished, loadingLanguage]);

  const values = {
    finished,
    logged,
    data,
    language,
    onChangeLang,
    onVerifyUser,
    userData: dataUser,
    setUserData: setDataUser,
    onFetchUserData,
    onLogout,
  };

  return (
    <>
      {guard && <PermissionsGuard onClose={onCloseGuard} />}

      <UserContext.Provider value={values}>
        <Loader loading={$loading} height="100vh" fixed>
          {!$loading && children(values)}
        </Loader>
      </UserContext.Provider>
    </>
  );
};
