import * as React from 'react';
import { Fragment, Icon } from 'ui/atoms';

import './SeeOther.scss';

interface Props {
  label?: string;
  icon?: string;
  count?: number;
  onClick?: () => void;
}

export const SeeOther: React.FC<Props> = ({ icon, label, count, onClick }) => (
  <Fragment className={`zh-see-other ${onClick ? 'pointer' : ''}`} type="white" onClick={onClick}>
    {icon && <Icon type={icon} />}
    {label && label}
    {count !== undefined && <span>{count}</span>}
    <Icon type="arrow-filled" />
  </Fragment>
);
