import * as React from 'react';
import { Head } from 'ui/organisms';
import { ResourceTemplate } from 'features/resources/templates';
import { UploadModal } from 'features/resources/organisms';

import { SessionLayout } from '../organisms';

export const ResourcesSessionPage: React.FC = () => {
  const [id, setId] = React.useState<number | null>(null);
  const [uploadType, setUploadType] = React.useState<number | null>(null);
  const [open, setOpen] = React.useState(false);
  const onRefetch = React.useRef<() => void>(() => null);

  const onCloseUpload = (): void => {
    setUploadType(null);
    setOpen(false);
  };

  const onOpenUpload = React.useCallback((params?: { id?: number; type?: number }): void => {
    if (params) {
      if (params.type) {
        setUploadType(params.type);
      }

      if (params.id) {
        setId(params.id);
      }
    }

    setOpen(true);
  }, []);

  const onCreateRefetch = (fn: () => void): void => {
    onRefetch.current = fn;
  };

  return (
    <>
      {open && <UploadModal id={id} type={uploadType} onClose={onCloseUpload} onRefetch={onRefetch.current} />}

      <Head t="didacticMaterials" />

      <SessionLayout onOpenUpload={onOpenUpload}>
        <ResourceTemplate onCreateRefetch={onCreateRefetch} />
      </SessionLayout>
    </>
  );
};
