import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { EurasiaEvent, Invitation } from './events.types';
import { Results } from '../index.types';

export const events = {
  get: {
    action: (params = {}): Promise<{ data: Results<EurasiaEvent> }> =>
      axios.get(`events`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (events.get.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getById: {
    action: ({ id, ...params }: { id: number }): Promise<{ data: Invitation }> =>
      axios.get(`events/${id}`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (events.getById.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  save: {
    action: (options = {}) =>
      axios.post(`events`, options, { cancelToken: new cancelToken((c: Canceler) => (events.save.cancel = c)) }),
    cancel: (() => null) as Canceler,
  },
  invitations: {
    action: (params = {}): Promise<{ data: Results<Invitation> }> =>
      axios.get(`events/invitations`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (events.invitations.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  respondToInvitations: {
    action: ({ id, ...option }: { id: number; status: string }) =>
      axios.patch(`/events/${id}/respond-invitation`, option, {
        cancelToken: new cancelToken((c: Canceler) => (events.respondToInvitations.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  delete: {
    action: ({ id }: { id: number }) =>
      axios.delete(`events/${id}`, {
        cancelToken: new cancelToken((c: Canceler) => (events.delete.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
