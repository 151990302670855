import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Link } from 'estafette-router';
import { Layout } from 'ui/organisms';
import { Button, Icon } from 'ui/atoms';

export const CoursesLayout: React.FC = ({ children }) => {
  const { t } = useIntl();

  return (
    <Layout
      title={t('courses')}
      rightSide={
        <Link route="AddCoursePage" params={{ action: 'add' }}>
          <Button type="invert" prefix={<Icon type="plus" />}>
            {t('addCourse2')}
          </Button>
        </Link>
      }
    >
      {children}
    </Layout>
  );
};
