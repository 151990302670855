import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Link, useLocation, useRouterHelpers } from 'estafette-router';
import { Content } from 'features/public/atoms';
import { parseQuery } from 'libs/object';
import { Breadcrumbs } from 'ui/atoms';
import { Report } from 'ui/organisms';
import { headerData } from './data';

import './Header.scss';

export const Header: React.FC = () => {
  const { t } = useIntl();
  const location = useLocation();
  const { session } = parseQuery<{ session?: number }>(location.search);
  const { getRoute, isRouteActive } = useRouterHelpers();

  // remove it and get it from props
  const header = headerData.filter((route) => isRouteActive(route.name))[0];
  const data = [{ route: getRoute('SessionsPage'), label: t('home') }];

  if (!isRouteActive('SessionsPage') && header && header.breadcrumb) {
    data.push({ route: location.pathname, label: t(header.breadcrumb) });
  }

  return (
    <header>
      <Content>
        <Breadcrumbs data={data} />

        {header && (
          <div className="header">
            <div>
              {header.title && <div className="title">{t(header.title)}</div>}
              {header.subtitle && <div className="subtitle">{t(header.subtitle)}</div>}
            </div>

            {header.extra && (
              <div className="extra">
                {t(header.extra)}
                {header.extra === 'noAccount' ? (
                  <Link route="SignUpPage" query={session ? { session } : undefined}>
                    {t('register')}
                  </Link>
                ) : null}
                {header.extra === 'haveAccount' ? (
                  <Link route="SignInPage" query={session ? { session } : undefined}>
                    {t('haveLogin')}
                  </Link>
                ) : null}
                <p>
                  {t('didYouFindProblem')}
                  {header.extra === 'noAccount' ? <Report type={'signin'} /> : <Report type={'signup'} />}
                </p>
              </div>
            )}
          </div>
        )}
      </Content>
    </header>
  );
};
