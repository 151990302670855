import * as React from 'react';
import { useRequest } from 'estafette';
import { useHistory, useParams } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { faculties } from 'libs/http/api';
import { useFilters } from 'hooks';
import { Results } from 'libs/http/api/index.types';
import { Faculty } from 'libs/http/api/faculties/faculties.types';
import { perPage } from 'libs/storage';
import { Actions, Head, InputSearch, SortBy } from 'ui/organisms';
import { Card, CardHeader, CardFooter, Table, Button, Loader } from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';
import { Sorts } from 'ui/organisms/SortBy/SortBy';

import { FacultiesLayout } from '../organisms';

export const FacultiesPage: React.FC = () => {
  const { locale, t } = useIntl();
  const { id } = useParams();
  const { push } = useHistory();
  const { request, data, loading } = useRequest<Results<Faculty>>({ data: { results: [] } });
  const { request: requestDelete, loading: loadingDelete } = useRequest();
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    search: '',
    per_page: perPage,
    ordering: '' as Sorts,
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    return () => {
      faculties.get.cancel();
      faculties.delete.cancel();
    };
  }, []);

  React.useEffect(() => {
    request(faculties.get.action({ id, ...filters }));
  }, [filters]);

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => {
    if (filters.filtered) {
      setFilters({ ...initialFilters.current });
    } else {
      request(faculties.get.action(filters));
    }
  };
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onManagement = (action = 'add'): void => push('AddFacultyPage', { action, query: { page: filters.page } });
  const onDelete = React.useCallback(async (id: number) => {
    await requestDelete(faculties.delete.action({ id }));

    onRefetch();
  }, []);

  const columns: Column<Faculty>[] = React.useMemo(
    () => [
      {
        title: t('id'),
        className: 'vertical-align-top',
        render: ({ id }) => id,
      },
      {
        title: t('name'),
        className: 'nowrap vertical-align-top',
        render: (record): React.ReactNode => {
          const index = `name_${locale}` as 'name_en' | 'name_ro' | 'name_ru';

          return <span>{record[index] || '---'}</span>;
        },
      },
      {
        title: t('courses'),
        render: (record): React.ReactNode => {
          const courses = record.courses.map((item, key) => (
            <div key={item.id}>
              {++key}. {item[`title_${locale as 'en' | 'ro'}` as 'title_en' | 'title_ro' | 'title_ru']}
            </div>
          ));

          return <span>{courses || '---'}</span>;
        },
      },
      {
        width: 70,
        action: true,
        textAlign: 'center',
        className: 'vertical-align-top',
        render: ({ id }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <Actions
              onEdit={() => onManagement(`${id}`)}
              onDelete={() => onDelete(id)}
              onDeleteLabel={t('deleteFaculty')}
              loadingDelete={loadingDelete}
            />
          </div>
        ),
      },
    ],
    [t],
  );

  return (
    <>
      <Head t="faculties" />

      <FacultiesLayout>
        <Card>
          <CardHeader
            className="mt-10 mb-10"
            title={t('results')}
            leftSide={
              <InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />
            }
            rightSide={
              <SortBy
                options={[
                  {
                    title: 'ID',
                    value: 'id',
                  },
                  {
                    title: t('name'),
                    value: 'name',
                  },
                  {
                    title: t('courses'),
                    value: 'courses',
                  },
                ]}
                value={filters.ordering}
                onChange={(newValue) => onChangeFilters('ordering', newValue)}
              />
            }
            count={data.count}
          />

          <Loader loading={loading}>
            <Table page={filters.page} data={data.results} columns={columns} />

            <CardFooter
              onRefresh={onRefetch}
              onRefreshDisabled={!filters.filtered}
              page={filters.page}
              pages={data.total_pages}
              perPage={filters.per_page}
              onChangePerPage={onChangePerPage}
              rightSide={
                <>
                  <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                    {t('previous')}
                  </Button>

                  <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                    {t('next')}
                  </Button>
                </>
              }
            />
          </Loader>
        </Card>
      </FacultiesLayout>
    </>
  );
};
