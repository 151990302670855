import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useStateHandlers } from 'hooks';
import { file as FileApi, questions, reports } from 'libs/http/api';
import { Question } from 'libs/http/api/questions/questions.types';
import { Report } from 'libs/http/api/reports/reports.types';
import { Save } from 'libs/http/api/index.types';
import { EurasiaFile } from 'libs/http/api/file/file.types';
import { FileUpload } from 'ui/molecules';
import { Form, FormItem } from 'ui/organisms';
import { Animated, Button, Icon, Input, Loader, Alert, Info, Progressbar } from 'ui/atoms';
import { Modal, ModalFooterButton } from 'ui/atoms/Modal/Modal';

import './SessionReport.scss';

interface Props {
  defaultReport?: Report;
  onRefetch?: () => void;
  onClose: () => void;
  disabledForm?: boolean;
}

export const SessionReport: React.FC<Props> = ({ onRefetch, onClose, defaultReport, disabledForm = false }) => {
  const { t } = useIntl();
  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<Save>();
  const { request: requestFile } = useRequest<EurasiaFile>();
  const { request: requestQuestions, data: dataQuestions, loading: loadingQuestions } = useRequest<Question[]>();

  const [state, setState] = useStateHandlers<Report>({
    report: [],
    average_mark: null,
  });

  React.useEffect(() => {
    requestQuestions(questions.get.action());

    return () => {
      questions.get.cancel();
      reports.add.cancel();
      reports.patch.cancel();
    };
  }, []);

  React.useEffect(() => {
    setState({
      ...defaultReport,
      report: dataQuestions.map((item, key) => ({
        question: item.id,
        answer:
          (defaultReport && defaultReport.report && defaultReport.report[key] && defaultReport.report[key].answer) ||
          '',
      })),
    });
  }, [defaultReport, dataQuestions]);

  const onUploadFile = async (key: number, value: File | null): Promise<void> => {
    const file = value;
    if (file && typeof file !== 'string') {
      const fileFormData: any = new FormData();
      fileFormData.append('file', file);

      const { url } = await requestFile(FileApi.upload.action(fileFormData));
      onChangeAnswer(key, url);
    }
  };

  const handleRemoveIcon = (key: number): void => {
    onChangeAnswer(key, '');
  };

  const onSubmit = async (): Promise<void> => {
    const options = { ...state, id: defaultReport && defaultReport.id };

    if (defaultReport && defaultReport.id) {
      await requestSave(reports.patch.action(options));
    } else {
      await requestSave(reports.add.action(options));
    }

    onClose();

    if (onRefetch) {
      onRefetch();
    }
  };

  // const onChangeAverageMark = (value: string): void => setState({ average_mark: grade(value) });//todo temporary disabled

  const onChangeAnswer = (key: number, val: string): void =>
    setState((prevState) => ({
      report: prevState.report
        ? prevState.report.map((item, $key) =>
            key === $key && prevState.report ? { ...prevState.report[$key], answer: val } : item,
          )
        : [],
    }));

  const renderField = (
    question: Question,
    item: {
      question: number;
      answer: string;
    },
    key: number,
  ): JSX.Element => {
    switch (question.type) {
      default:
      case 'text':
        return (
          <textarea
            disabled={disabledForm}
            placeholder={t('enterAnswer')}
            value={item.answer || question.default_value || ''}
            onChange={(ev) => onChangeAnswer(key, ev.target.value)}
          />
        );
      case 'int':
        return (
          <Input
            disabled={disabledForm}
            placeholder={t('enterAnswer')}
            value={item.answer || question.default_value}
            onChange={(ev) => onChangeAnswer(key, (parseInt(ev) >= 0 ? parseInt(ev) : '').toString())}
          />
        );
      case 'float':
        return (
          <Input
            disabled={disabledForm}
            placeholder={t('enterAnswer')}
            value={item.answer || question.default_value}
            onChange={(ev) =>
              onChangeAnswer(
                key,
                (ev[ev.length - 1] === '.' ? ev : parseFloat(ev) >= 0 ? parseFloat(ev) : '').toString(),
              )
            }
          />
        );
      case 'file':
        return (
          <>
            {!(item.answer || question.default_value) && <FileUpload onChange={(value) => onUploadFile(key, value)} />}
            {(item.answer || question.default_value) && (
              <div className="file-upload">
                <div className="file-upload-active">
                  <Info
                    primary
                    label={
                      <a
                        href={item.answer || question.default_value}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'initial' }}
                      >
                        {t('file')}
                      </a>
                    }
                    icon={<Icon type="file" />}
                  />

                  <div className="file-upload-data">
                    <Progressbar finished={true} />
                  </div>
                </div>
                <Icon type="close" onClick={() => handleRemoveIcon(key)} />
              </div>
            )}
          </>
        );
    }
  };

  return (
    <Modal
      size="large"
      contentClass="zh-session-report"
      onClose={onClose}
      title={t('finalReport')}
      footer={
        <ModalFooterButton>
          <Button onClick={onClose} disabled={loadingSave}>
            {t('cancel')}
          </Button>

          <Button
            submit
            form="session-report"
            type="primary"
            prefix={<Icon type="check" />}
            loading={loadingSave}
            disabled={disabledForm}
          >
            {t('send')}
          </Button>
        </ModalFooterButton>
      }
    >
      <Alert message={dataSave.message || errors.non_field_errors || errors.detail} form type="error" />

      <Form onSubmit={onSubmit} id="session-report">
        <Loader fade={false} height={250} loading={loadingQuestions}>
          <Animated loading={loadingQuestions} startFrom={250}>
            {state.report &&
              state.report.map((item, key) => {
                return (
                  <React.Fragment key={dataQuestions[key].id}>
                    <div className="zh-session-report-question">
                      <div className="zh-session-report-question-section">
                        <FormItem>
                          <h3 className="zh-session-report-question-label">{dataQuestions[key].title}</h3>
                        </FormItem>

                        <FormItem
                          required={dataQuestions[key].required || undefined}
                          label={t('answer')}
                          extra={errors.report && errors.report[key] && errors.report[key].answer}
                        >
                          {renderField(dataQuestions[key], item, key)}
                        </FormItem>
                      </div>
                    </div>

                    <hr className="mt-25 mb-25" />
                  </React.Fragment>
                );
              })}

            {/*todo this is disabled for this moment*/}
            {/*<div>
              <FormItem>
                <h3 className="zh-session-report-question-label">{t('averageNote')}</h3>
              </FormItem>

              <div className="zh-session-report-question-section no-label">
                <FormItem extra={errors.average_mark}>
                  <Input
                    disabled={disabledForm}
                    placeholder={t('enterNote')}
                    value={`${state.average_mark || ''}`}
                    onChange={onChangeAverageMark}
                  />
                </FormItem>
              </div>
            </div>*/}
          </Animated>
        </Loader>
      </Form>
    </Modal>
  );
};
