import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { getRoute, useHistory } from 'estafette-router';
import { save, load, remove } from 'react-cookies';
import { useStateHandlers } from 'hooks';
import { me, file as FileApi, announces } from 'libs/http/api';
import { EurasiaFile } from 'libs/http/api/file/file.types';
import { List } from 'libs/http/api/index.types';
import { NomenclaturesContext } from 'contexts';
import { routes } from 'routes';

import { FileUpload } from 'ui/molecules';
import { Loader, Select, Fragment, Button, Icon, CardHeader, RichText, Alert } from 'ui/atoms';
import { Form, FormItem, Head } from 'ui/organisms';

import { ApplicationLayout } from '../organisms';

const initialState = {
  user: 0,
  subject: 0,
  description: '',
  file: '',
};

export const ApplicationPage: React.FC = () => {
  const { t } = useIntl();
  const { goBack } = useHistory();
  const { subjects } = React.useContext(NomenclaturesContext);

  const { request: requestStudents, data: dataStudents, loading: loadingStudents } = useRequest<List[]>();
  const { request: requestFile, loading: loadingFile, errors: errorsFile } = useRequest<EurasiaFile>();
  const { request: requestSend, loading: loadingSend, errors: errorsSend } = useRequest();

  const [state, setState] = useStateHandlers(initialState);

  React.useEffect(() => {
    const state = load('state');
    if (state) {
      setState(state);
    }
  }, []);

  React.useEffect(() => {
    save('state', state, { path: getRoute(routes, 'ApplicationPage') });
  }, [setState, state]);

  React.useEffect(() => {
    requestStudents(me.getStudentsList.action());

    return () => {
      me.getStudentsList.cancel();
      FileApi.upload.cancel();
      announces.send.cancel();
    };
  }, []);

  const subjectOptions = React.useMemo(() => subjects.map((subject, index) => ({ value: index + 1, title: subject })), [
    subjects,
  ]);

  const studentOptions = React.useMemo(
    () =>
      dataStudents.map((student) => ({
        value: student.key,
        title: student.label,
      })),
    [dataStudents],
  );

  const onSubmit = async (): Promise<void> => {
    const { file: $file, subject: $subject, ...options } = state;
    let file = $file;

    if (file && typeof file !== 'string') {
      const fileFormData: any = new FormData();
      fileFormData.append('file', file);

      const { url } = await requestFile(FileApi.upload.action(fileFormData));
      file = url;
    }

    let subject = '';
    if ($subject !== 0) {
      subject = subjectOptions[$subject - 1]?.title;
    }

    await requestSend(announces.send.action({ ...options, subject, file }));

    setState(initialState);
    remove('state');
  };

  const onChange = React.useCallback((target: string, value: any): void => setState({ [target]: value }), []);
  const onCancel = (): void => goBack();

  return (
    <>
      <Head t="fillOutApplication" />

      <ApplicationLayout>
        <CardHeader title={t('fillOutApplication')} className="mt-10" />
        <Fragment
          footer={
            <>
              <Button onClick={onCancel}>{t('cancel')}</Button>

              <Button
                submit
                type="primary"
                loading={loadingFile || loadingSend}
                onClick={onSubmit}
                prefix={<Icon type="check" />}
              >
                {t('send')}
              </Button>
            </>
          }
        >
          <Alert
            message={
              errorsFile.message || errorsSend.message || errorsSend.non_field_errors || errorsFile.non_field_errors
            }
            form
            type="error"
          />

          <Loader loading={false}>
            <Form onSubmit={onSubmit}>
              <FormItem className="mx-w-660" label={t('messageRecipient')} extra={errorsSend.user}>
                <Select
                  getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
                  placeholder={t('selectRecipient')}
                  options={studentOptions}
                  loading={loadingStudents}
                  value={state.user !== 0 ? state.user : ''}
                  onChange={(value) => onChange('user', value)}
                />
              </FormItem>

              <FormItem className="mx-w-660" label={t('subjectOfApplication')} extra={errorsSend.subject}>
                <Select
                  getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
                  placeholder={t('selectSubject')}
                  options={subjectOptions}
                  value={state.subject !== 0 ? state.subject : ''}
                  onChange={(value) => onChange('subject', value)}
                />
              </FormItem>

              <FormItem className="mx-w-660" label={t('message')} extra={errorsSend.description}>
                <RichText initialValue={state.description} onChange={(value) => onChange('description', value)} />
              </FormItem>

              <FormItem className="mx-w-660" label={t('attachFile')} extra={errorsFile.file}>
                <FileUpload onChange={(value) => onChange('file', value)} />
              </FormItem>
            </Form>
          </Loader>
        </Fragment>
      </ApplicationLayout>
    </>
  );
};
