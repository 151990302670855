import * as React from 'react';
import { useRequest } from 'estafette';
import { Link } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { useFilters } from 'hooks';
import { me } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { Faculty } from 'libs/http/api/faculties/faculties.types';
import { perPage } from 'libs/storage';
import { Head, InputSearch, SortBy } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import { Card, CardHeader, CardFooter, Table, Button, Loader } from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';

import { RecoveryGrades, RegisterLayout } from '../organisms';
import { GraduatesTable } from 'features/graduations/organisms';
import { UserContext } from 'contexts';

export const RegisterPage: React.FC = () => {
  const { t, locale } = useIntl();
  const { userData } = React.useContext(UserContext);
  const [recoveryGrades, setRecoveryGrades] = React.useState(false);
  const { request, data, loading } = useRequest<Results<Faculty>>({ data: { results: [] } });
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    ordering: '' as Sorts,
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    return () => {
      me.getFaculties.cancel();
    };
  }, []);

  React.useEffect(() => {
    request(me.getFaculties.action(filters));
  }, [filters]);

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => {
    if (filters.filtered) {
      setFilters({ ...initialFilters.current });
    } else {
      request(me.getFaculties.action(filters));
    }
  };
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const toggleRecoveryGrades = (): void => setRecoveryGrades((s) => !s);

  const columns: Column<Faculty>[] = React.useMemo(
    () => [
      {
        title: t('name'),
        render: (record) => {
          const index = `name_${locale}` as 'name_en' | 'name_ro' | 'name_ru';

          return (
            <Link route="RegisterSessionsPage" params={{ query: { faculty__in: record['id'] } }}>
              {record[index]}
            </Link>
          );
        },
      },
      {
        width: 165,
        action: true,
        render: ({ id }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <Link route="RegisterSessionsPage" params={{ query: { faculty__in: id } }}>
              {t('seeSessions')}
            </Link>
          </div>
        ),
      },
    ],
    [t],
  );

  return (
    <>
      {recoveryGrades && <RecoveryGrades onClose={toggleRecoveryGrades} />}
      <Head t="register2" />

      <RegisterLayout>
        <Card>
          <CardHeader
            title={t('register2')}
            count={data.count}
            leftSide={
              <InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />
            }
            rightSide={
              <>
                <Button type="danger" onClick={toggleRecoveryGrades}>
                  {t('recoveryGrades')}
                </Button>
                <SortBy
                  options={[
                    {
                      title: t('name'),
                      value: `name_${locale}`,
                    },
                  ]}
                  value={filters.ordering}
                  onChange={(newValue) => onChangeFilters('ordering', newValue)}
                />
              </>
            }
          />

          <Loader loading={loading}>
            <Table size="big" page={filters.page} data={data.results} columns={columns} />

            <CardFooter
              onRefresh={onRefetch}
              onRefreshDisabled={!filters.filtered}
              page={filters.page}
              pages={data.total_pages}
              perPage={filters.per_page}
              onChangePerPage={onChangePerPage}
              rightSide={
                <>
                  <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                    {t('previous')}
                  </Button>

                  <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                    {t('next')}
                  </Button>
                </>
              }
            />
          </Loader>
        </Card>
        {userData?.id && <GraduatesTable studentId={userData?.id} />}
      </RegisterLayout>
    </>
  );
};
