import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useStateHandlers } from 'hooks';
import { faculties, institutions, sessions, courses, users, issues, me } from 'libs/http/api';
import { List, Results, Save } from 'libs/http/api/index.types';
import { RecoveryGradeForm, SessionListItem } from 'libs/http/api/sessions/sessions.types';
import { FacultiesList } from 'libs/http/api/faculties/faculties.types';
import { Course } from 'libs/http/api/courses/courses.types';
import { UserList } from 'libs/http/api/users/users.types';
import { Form, FormItems, FormItem } from 'ui/organisms';
import { Modal, ModalFooterButton, Button, Icon, Select, Alert, Checkbox, Input } from 'ui/atoms';
import { dateFormat, format } from '../../../libs/date';
import { grade } from '../../../libs/mask';

interface Props {
  onClose: () => void;
}

interface IncludeGrades {
  course_grade: boolean;
  individual_work_grade: boolean;
  practise_grade: boolean;
  practise_sub_grade_1: boolean;
  practise_sub_grade_2: boolean;
  practise_sub_grade_3: boolean;
  practise_sub_grade_4: boolean;
  practise_sub_grade_5: boolean;
}

const checkGrade = (value?: number | null): number | undefined => (value !== null ? value : undefined);

export const RecoveryGrades: React.FC<Props> = ({ onClose }) => {
  const { locale, t } = useIntl();

  const [registeredToSession, setRegisteredToSession] = React.useState(false);

  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<Save>();
  const { request: requestChange, data: dataChange, loading: loadingChange, errors: errorsChange } = useRequest<Save>();
  const { request: requestInstitutions, data: dataInstitutions, loading: loadingInstitutions } = useRequest<List[]>();
  const { request: requestFaculties, data: dataFaculties, loading: loadingFaculties } = useRequest<FacultiesList[]>();
  const { request: requestCourses, data: dataCourses, loading: loadingCourses } = useRequest<Results<Course>>();
  const { request: requestTeacher, data: dataTeachers, loading: loadingTeacher } = useRequest<UserList[]>();
  const { request: requestSessions, data: dataSessions, loading: loadingSessions } = useRequest<SessionListItem[]>();

  const initialState = {
    description: '',
    institution: null,
    faculty: null,
    course: null,
    teacher: null,
    session: null,
    course_grade: undefined,
    individual_work_grade: undefined,
    practise_sub_grade_1: undefined,
    practise_sub_grade_2: undefined,
    practise_sub_grade_3: undefined,
    practise_sub_grade_4: undefined,
    practise_sub_grade_5: undefined,
    change_course_grade: undefined,
    change_individual_work_grade: undefined,
    change_practise_sub_grade_1: undefined,
    change_practise_sub_grade_2: undefined,
    change_practise_sub_grade_3: undefined,
    change_practise_sub_grade_4: undefined,
    change_practise_sub_grade_5: undefined,
  };

  const [state, setState] = useStateHandlers<RecoveryGradeForm>(initialState);

  React.useEffect(() => {
    requestInstitutions(institutions.list.action({ all_objects: true }));

    return () => {
      faculties.list.cancel();
      institutions.list.cancel();
      sessions.list.cancel();
      users.professorsList.cancel();
      courses.get.cancel();
    };
  }, []);

  React.useEffect(() => {
    setState(initialState);
  }, [registeredToSession]);

  React.useEffect(() => {
    if (registeredToSession) {
      requestFaculties(me.getFacultiesList.action());
    } else if (state.institution && !registeredToSession) {
      requestFaculties(faculties.list.action({ institution: state.institution }));
    }
  }, [state.institution, registeredToSession]);

  React.useEffect(() => {
    if (state.faculty) {
      requestCourses(courses.get.action({ faculties: state.faculty }));
    }
  }, [state.faculty]);

  React.useEffect(() => {
    if (state.course && state.faculty && state.institution && !registeredToSession) {
      requestTeacher(
        users.professorsList.action({
          course: state.course,
          faculties: state.faculty,
          institution: state.institution,
        }),
      );
    }
  }, [state.course, state.faculty]);

  React.useEffect(() => {
    if (!registeredToSession && state.course && state.institution && state.teacher) {
      requestSessions(
        me.getUnregisteredSessionList.action({
          professor: state.teacher,
          course: state.course,
          institution: state.institution,
        }),
      );
    } else if (registeredToSession && state.course) {
      requestSessions(me.getRecoveryGradeSessionList.action({ course: state.course }));
    }
  }, [state.course, state.institution, state.teacher, registeredToSession]);

  const onSubmit = async (): Promise<void> => {
    const selectedSession = dataSessions.find((i) => i.key === state.session);
    if (
      state.course_grade ||
      state.practise_sub_grade_1 ||
      state.practise_sub_grade_2 ||
      state.practise_sub_grade_3 ||
      state.practise_sub_grade_4 ||
      state.practise_sub_grade_5 ||
      state.individual_work_grade
    ) {
      await requestSave(
        issues.add.action({
          details: {
            course_grade: checkGrade(state?.course_grade),
            individual_work_grade: checkGrade(state?.individual_work_grade),
            practise_sub_grade_1: checkGrade(state?.practise_sub_grade_1),
            practise_sub_grade_2: checkGrade(state?.practise_sub_grade_2),
            practise_sub_grade_3: checkGrade(state?.practise_sub_grade_3),
            practise_sub_grade_4: checkGrade(state?.practise_sub_grade_4),
            practise_sub_grade_5: checkGrade(state?.practise_sub_grade_5),
          },
          type: 'recover_grade',
          session: state.session,
        }),
      );
    }
    if (
      state.change_course_grade ||
      state.change_practise_sub_grade_1 ||
      state.change_practise_sub_grade_2 ||
      state.change_practise_sub_grade_3 ||
      state.change_practise_sub_grade_4 ||
      state.change_practise_sub_grade_5 ||
      state.change_individual_work_grade
    ) {
      await requestChange(
        issues.add.action({
          details: {
            course_grade: checkGrade(state?.change_course_grade),
            individual_work_grade: checkGrade(state?.change_individual_work_grade),
            practise_sub_grade_1: checkGrade(state?.change_practise_sub_grade_1),
            practise_sub_grade_2: checkGrade(state?.change_practise_sub_grade_2),
            practise_sub_grade_3: checkGrade(state?.change_practise_sub_grade_3),
            practise_sub_grade_4: checkGrade(state?.change_practise_sub_grade_4),
            practise_sub_grade_5: checkGrade(state?.change_practise_sub_grade_5),
            old: {
              course_grade: checkGrade(selectedSession?.course_grade),
              individual_work_grade: checkGrade(selectedSession?.individual_work_grade),
              practise_sub_grade_1: checkGrade(selectedSession?.practise_sub_grade_1),
              practise_sub_grade_2: checkGrade(selectedSession?.practise_sub_grade_2),
              practise_sub_grade_3: checkGrade(selectedSession?.practise_sub_grade_3),
              practise_sub_grade_4: checkGrade(selectedSession?.practise_sub_grade_4),
              practise_sub_grade_5: checkGrade(selectedSession?.practise_sub_grade_5),
            },
          },
          type: 'change_grade',
          session: state.session,
        }),
      );
    }

    onClose();
  };

  const onChange = React.useCallback(
    (target: string, value: any): void =>
      setState({
        [target]: value,
        ...(target === 'institution' ? { faculty: null } : {}),
      }),
    [],
  );

  const institutionsOptions = React.useMemo(
    () => dataInstitutions.map((institution) => ({ value: institution.key, title: institution.label })),
    [dataInstitutions],
  );

  const facultiesOptions = React.useMemo(
    () =>
      dataFaculties?.map((faculty) => ({
        value: faculty.key,
        title: faculty.label,
      })) || [],
    [dataFaculties],
  );

  const coursesOptions = React.useMemo(
    () =>
      dataCourses.results?.map((courses) => ({
        value: courses.id,
        title: courses[`title_${locale}` as 'title_en' | 'title_ro' | 'title_ru'],
      })) || [],
    [dataCourses],
  );

  const teachersOptions = React.useMemo(
    () =>
      dataTeachers?.map((teacher) => ({
        value: teacher.key,
        title: teacher.label,
      })) || [],
    [dataTeachers],
  );

  const sessionOptions = React.useMemo(
    () =>
      dataSessions?.map((session) => ({
        value: session.key,
        title: `${format(session.start_date, dateFormat)} - ${format(session.end_date, dateFormat)}`,
      })) || [],
    [dataSessions],
  );

  const renderGradeInput = (
    field:
      | 'course_grade'
      | 'individual_work_grade'
      | 'practise_sub_grade_1'
      | 'practise_sub_grade_2'
      | 'practise_sub_grade_3'
      | 'practise_sub_grade_4'
      | 'practise_sub_grade_5',
  ): JSX.Element | null => {
    if (!state.session) {
      return (
        <FormItem>
          <div className="mb-10">
            <Input value={state[field]} disabled />
          </div>
        </FormItem>
      );
    }
    const selectedSession = dataSessions.find((i) => i.key === state.session);

    const grades_to_show = [
      'practise_sub_grade_1',
      'practise_sub_grade_2',
      'practise_sub_grade_3',
      'practise_sub_grade_4',
      'practise_sub_grade_5',
    ].includes(field)
      ? 'practise_grade'
      : (field as 'individual_work_grade' | 'practise_grade' | 'course_grade' | 'average_grade');

    if (!selectedSession?.grades_to_show?.includes(grades_to_show)) {
      return null;
    }

    if (selectedSession[field]) {
      return (
        <FormItem>
          <div className="mb-10">
            <FormItem label={t(field)}>
              <Input value={selectedSession[field]} disabled />
            </FormItem>
          </div>
          <div className="mb-10">
            <FormItem label={t('change_grade')}>
              <Input
                value={
                  state[
                    `change_${field}` as
                      | 'change_course_grade'
                      | 'change_individual_work_grade'
                      | 'change_practise_sub_grade_1'
                      | 'change_practise_sub_grade_2'
                      | 'change_practise_sub_grade_3'
                      | 'change_practise_sub_grade_4'
                      | 'change_practise_sub_grade_5'
                  ]
                }
                onChange={(value) =>
                  setState((s) => ({
                    ...s,
                    [`change_${field}` as
                      | 'change_course_grade'
                      | 'change_individual_work_grade'
                      | 'change_practise_sub_grade_1'
                      | 'change_practise_sub_grade_2'
                      | 'change_practise_sub_grade_3'
                      | 'change_practise_sub_grade_4'
                      | 'change_practise_sub_grade_5']: grade(value),
                  }))
                }
              />
            </FormItem>
          </div>
        </FormItem>
      );
    }

    return (
      <FormItem label={t(field)}>
        <div className="mb-10">
          <Input value={state[field]} onChange={(value) => setState((s) => ({ ...s, [field]: grade(value) }))} />
        </div>
      </FormItem>
    );
  };

  return (
    <Modal
      size="large"
      mask={false}
      title={t('recoveryGrades')}
      onClose={onClose}
      footer={
        <ModalFooterButton>
          <Button onClick={onClose} disabled={loadingSave || loadingChange}>
            {t('cancel')}
          </Button>

          <Button
            submit
            form="recovery-grades"
            type="primary"
            prefix={<Icon type="check" />}
            loading={loadingSave || loadingChange}
            disabled={
              !state.course_grade &&
              !state.individual_work_grade &&
              !state.practise_sub_grade_1 &&
              !state.practise_sub_grade_2 &&
              !state.practise_sub_grade_3 &&
              !state.practise_sub_grade_4 &&
              !state.practise_sub_grade_5 &&
              !state.change_course_grade &&
              !state.change_practise_sub_grade_1 &&
              !state.change_practise_sub_grade_2 &&
              !state.change_practise_sub_grade_3 &&
              !state.change_practise_sub_grade_4 &&
              !state.change_practise_sub_grade_5 &&
              !state.change_individual_work_grade
            }
          >
            {t('send')}
          </Button>
        </ModalFooterButton>
      }
    >
      <Alert
        message={
          dataSave.message ||
          errors.non_field_errors ||
          errors.detail ||
          dataChange.message ||
          errorsChange.detail ||
          errorsChange.non_field_errors
        }
        type="error"
      />

      <Form onSubmit={onSubmit} id="recovery-grades">
        <FormItems>
          <FormItem>
            <Checkbox
              checked={!registeredToSession}
              onChange={() => setRegisteredToSession(false)}
              label={t('notRegisteredToSession')}
            />
          </FormItem>
          <FormItem>
            <Checkbox
              checked={registeredToSession}
              onChange={() => setRegisteredToSession(true)}
              label={t('registeredToSession')}
            />
          </FormItem>
        </FormItems>
        {!registeredToSession && (
          <FormItems>
            <FormItem label={t('institute')}>
              <Select
                placeholder={t('selectInstitute')}
                options={institutionsOptions}
                loading={loadingInstitutions}
                value={state.institution}
                onChange={(value) => onChange('institution', value)}
              />
            </FormItem>
          </FormItems>
        )}
        <FormItems>
          <FormItem label={t('faculty')}>
            <Select
              placeholder={t('selectFaculty')}
              disabled={!state.institution && !registeredToSession}
              options={facultiesOptions}
              loading={loadingFaculties}
              value={state.faculty}
              onChange={(value) => onChange('faculty', value)}
            />
          </FormItem>
          <FormItem label={t('course')}>
            <Select
              placeholder={t('selectCourse')}
              disabled={!state.faculty}
              options={coursesOptions}
              loading={loadingCourses}
              value={state.course}
              onChange={(value) => onChange('course', value)}
            />
          </FormItem>
        </FormItems>
        {!registeredToSession && (
          <FormItems>
            <FormItem label={t('teacher')}>
              <Select
                placeholder={t('selectTeacher')}
                disabled={!state.course}
                options={teachersOptions}
                loading={loadingTeacher}
                value={state.teacher}
                onChange={(value) => onChange('teacher', value)}
              />
            </FormItem>
          </FormItems>
        )}

        <FormItems>
          <FormItem label={t('session')}>
            <Select
              placeholder={t('selectSession')}
              disabled={(!state.teacher && !registeredToSession) || (registeredToSession && !state.course)}
              options={sessionOptions}
              loading={loadingSessions}
              value={state.session}
              onChange={(value) => onChange('session', value)}
            />
          </FormItem>
        </FormItems>

        {registeredToSession && (
          <FormItems>
            {renderGradeInput('course_grade')}
            {renderGradeInput('individual_work_grade')}
          </FormItems>
        )}
        {registeredToSession && (
          <FormItems>
            {renderGradeInput('practise_sub_grade_1')}
            {renderGradeInput('practise_sub_grade_2')}
            {renderGradeInput('practise_sub_grade_3')}
            {renderGradeInput('practise_sub_grade_4')}
            {renderGradeInput('practise_sub_grade_5')}
          </FormItems>
        )}
        {!registeredToSession && (
          <>
            <FormItems>
              <FormItem label={t('course_grade')}>
                <div className="mb-10">
                  <Input
                    value={state.course_grade}
                    onChange={(value) => setState((s) => ({ ...s, course_grade: grade(value) }))}
                  />
                </div>
              </FormItem>

              <FormItem label={t('individual_work_grade')}>
                <div className="mb-10">
                  <Input
                    value={state.individual_work_grade}
                    onChange={(value) =>
                      setState((s) => ({
                        ...s,
                        individual_work_grade: grade(value),
                      }))
                    }
                  />
                </div>
              </FormItem>
            </FormItems>

            <FormItems>
              <FormItem label={t('practise_sub_grade_1')}>
                <div className="mb-10">
                  <Input
                    value={state.practise_sub_grade_1}
                    onChange={(value) =>
                      setState((s) => ({
                        ...s,
                        practise_sub_grade_1: grade(value),
                      }))
                    }
                  />
                </div>
              </FormItem>
              <FormItem label={t('practise_sub_grade_2')}>
                <div className="mb-10">
                  <Input
                    value={state.practise_sub_grade_2}
                    onChange={(value) =>
                      setState((s) => ({
                        ...s,
                        practise_sub_grade_2: grade(value),
                      }))
                    }
                  />
                </div>
              </FormItem>
              <FormItem label={t('practise_sub_grade_3')}>
                <div className="mb-10">
                  <Input
                    value={state.practise_sub_grade_3}
                    onChange={(value) =>
                      setState((s) => ({
                        ...s,
                        practise_sub_grade_3: grade(value),
                      }))
                    }
                  />
                </div>
              </FormItem>
              <FormItem label={t('practise_sub_grade_4')}>
                <div className="mb-10">
                  <Input
                    value={state.practise_sub_grade_4}
                    onChange={(value) =>
                      setState((s) => ({
                        ...s,
                        practise_sub_grade_4: grade(value),
                      }))
                    }
                  />
                </div>
              </FormItem>
              <FormItem label={t('practise_sub_grade_5')}>
                <div className="mb-10">
                  <Input
                    value={state.practise_sub_grade_5}
                    onChange={(value) =>
                      setState((s) => ({
                        ...s,
                        practise_sub_grade_5: grade(value),
                      }))
                    }
                  />
                </div>
              </FormItem>
            </FormItems>
          </>
        )}
      </Form>
    </Modal>
  );
};
