import * as React from 'react';
import { notify } from 'react-notify-toast';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useFilters } from 'hooks';
import { NomenclaturesContext } from 'contexts';
import { format, dateTimeFormat, isBefore, toISO, today, dateFormat } from 'libs/date';
import { me, sessions } from 'libs/http/api';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { Results, Save } from 'libs/http/api/index.types';
import { perPage } from 'libs/storage';
import {
  Alert,
  AvatarInline,
  Time,
  Select,
  Card,
  CardHeader,
  CardFooter,
  CardAlert,
  Table,
  Input,
  Icon,
  Button,
  Loader,
  Info,
} from 'ui/atoms';
import { Form, FormItem, FormItems, Confirmation, InputSearch, SortBy } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';

import { Column } from 'ui/atoms/Table/Table';
import { Expande } from 'ui/atoms/Table/Expanded';

import './SessionsApplication.scss';

const initialSearch = {
  search: '',
  country: null,
  city: null,
};

export const SessionsApplicationTemplate: React.FC<{ hideSearchBar?: boolean; country?: { id: number } }> = ({
  hideSearchBar = false,
  country,
}) => {
  const { t } = useIntl();
  const { request, data, loading } = useRequest<Results<Session>>({ data: { results: [] } });
  const { request: requestRegister, data: dataRegister, loading: loadingRegister, errors } = useRequest<Save>();
  const { request: requestCancel, data: dataCancel, loading: loadingCancel } = useRequest<Save>();
  const {
    getActiveCountries,
    activeCountries,
    loadingActiveCountries,
    getActiveCities,
    activeCities,
    loadingActiveCities,
  } = React.useContext(NomenclaturesContext);
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    ordering: '' as Sorts,
    ...initialSearch,
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });
  const [search, setSearch] = useFilters({ ...initialSearch });

  const [searchCountries, setSearchCountries] = React.useState('');
  const [searchCities, setSearchCities] = React.useState('');

  React.useEffect(() => {
    return () => {
      sessions.get.cancel();
      me.getSessions.cancel();
    };
  }, []);

  React.useEffect(() => {
    getActiveCountries(searchCountries);
  }, [searchCountries]);

  React.useEffect(() => {
    getActiveCities(search.country, searchCities);
  }, [search.country, searchCities]);

  React.useEffect(() => {
    request(
      sessions.get.action({
        ...filters,
        end_date__gte: toISO(today.format(dateTimeFormat)),
        country: country?.id || filters.country,
      }),
    );
  }, [filters, dataCancel, dataRegister]);

  const onChangeFilters = React.useCallback(
    (searchFilters): void => setFilters({ filtered: true, page: 1, ...searchFilters }),
    [],
  );

  React.useEffect(() => {
    if (dataRegister.message) {
      notify.show(dataRegister.message, 'success');
    }
  }, [dataRegister.message]);

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onChangeSearch = React.useCallback(
    (target: string, value: any): void =>
      setSearch({
        [target]: value,
        ...(target === 'country' ? { city: null } : {}),
      }),
    [],
  );

  const onRegister = (id: number): void => {
    requestRegister(sessions.register.action({ id: id }));
  };

  const onCancel = (id: number): void => {
    requestCancel(sessions.cancel.action({ id: id }));
  };

  const handleCountriesSearch = (input: string): void => {
    setSearchCountries(input);
  };

  const handleCitiesSearch = (input: string): void => {
    setSearchCities(input);
  };

  const renderRegistrationButton = (
    id: number,
    endDate: string,
    isBibleGroup: boolean,
    my_session?: boolean,
  ): JSX.Element | null => {
    if (isBefore(endDate)) {
      if (my_session) {
        return (
          <Confirmation onConfirm={() => onCancel(id)}>
            <Button type="invert" className="confirm-button">
              {t('cancel')}
            </Button>
          </Confirmation>
        );
      }
      return (
        <Confirmation isBibleGroup={isBibleGroup} onConfirm={() => onRegister(id)}>
          <Button type="border" className="confirm-button">
            {t('registration')}
          </Button>
        </Confirmation>
      );
    }
    return null;
  };

  const columns: Column<Session>[] = React.useMemo(
    () => [
      {
        title: t('course'),
        className: 'zh-course-cell',
        render: ({ course }) => <b>{course?.name || '---'}</b>,
      },
      {
        title: t('faculty'),
        render: ({ faculty }) => <b>{faculty?.name || '---'}</b>,
      },
      {
        title: t('teacher'),
        render: ({ professor }) =>
          professor ? (
            <AvatarInline
              img={professor.profile_picture}
              alt={[professor.first_name, professor.last_name].filter((i) => i).join(' ')}
              size="small"
            />
          ) : (
            '---'
          ),
      },
      {
        title: t('period'),
        width: 240,
        render: ({ start_date: startDate, end_date: endDate }): React.ReactNode => (
          <Time
            date={[startDate && format(startDate, dateFormat), endDate && format(endDate, dateFormat)]
              .filter((i) => i)
              .join(' - ')}
            noParse
          />
        ),
        className: 'nowrap',
      },
      {
        title: `${t('country')}, ${t('city')}`,
        render: ({ country, city }) =>
          country && country.title ? (
            <Info
              icon={<Icon type="pin" />}
              label={[country && country.title, city && city.title].filter((i) => i).join(', ')}
            />
          ) : (
            '---'
          ),
      },
      {
        title: t('language'),
        render: ({ language }) => (language && language.name) || '---',
      },
      {
        title: t('actions'),
        action: true,
        render: ({ id, end_date: endDate, is_bible_group: isBibleGroup, my_session }) =>
          renderRegistrationButton(id, endDate, isBibleGroup, my_session),
        className: 'nowrap',
      },
    ],
    [t],
  );

  const expandable = ({
    professor,
    start_date: startDate,
    end_date: endDate,
    country,
    city,
    course,
    institution,
    language,
    faculty,
  }: Session): Expande => [
    [
      { label: t('course'), value: (course && course.name) || '---', icon: <Icon type="requests" /> },
      {
        label: t('startAt'),
        value: startDate ? format(startDate, dateFormat) : '---',
        icon: <Icon type="calendar" />,
      },
      {
        label: t('finishAt'),
        value: startDate ? format(endDate, dateFormat) : '---',
        icon: <Icon type="calendar" />,
      },
    ],
    [
      {
        label: t('teacher'),
        value: professor ? [professor.first_name, professor.last_name].filter((i) => i).join(' ') : '---',
      },
      {
        label: t('place'),
        value:
          country && country.title ? [country && country.title, city && city.title].filter((i) => i).join(', ') : '---',
        icon: <Icon type="pin" />,
      },
      {
        label: t('faculty'),
        value: faculty ? faculty.name : '---',
      },
    ],
    [
      { label: t('institute'), value: (institution && institution.name) || '---', icon: <Icon type="location" /> },
      { label: t('language'), value: (language && language.name) || '---' },
    ],
  ];

  return (
    <>
      {!hideSearchBar && (
        <>
          <h1>{t('searchSession')}</h1>

          <Alert message={errors.non_field_errors || errors.detail} form type="error" />

          <Form onSubmit={() => onChangeFilters(search)}>
            <FormItems>
              <FormItem label={t('rapidSearch')} className="mb-20">
                <Input
                  suffix={<Icon type="search" />}
                  placeholder={t('findSession')}
                  value={search.search}
                  onChange={(value) => onChangeSearch('search', value)}
                  width={240}
                  styleType="grey"
                />
              </FormItem>

              <FormItem label={t('country')} className="mb-20">
                <Select
                  cancelable
                  cancelValue={null}
                  styleType="grey"
                  placeholder={t('selectCountry')}
                  onSearch={handleCountriesSearch}
                  options={activeCountries}
                  loading={loadingActiveCountries}
                  value={search.country}
                  onChange={(value) => onChangeSearch('country', value)}
                />
              </FormItem>

              <FormItem label={t('city')} className="mb-20">
                <Select
                  cancelable
                  cancelValue={null}
                  styleType="grey"
                  placeholder={t('selectCity')}
                  onSearch={handleCitiesSearch}
                  options={search.country ? activeCities.filter((city) => city.countryID === search.country) : []}
                  loading={loadingActiveCities}
                  value={search.city}
                  onChange={(value) => onChangeSearch('city', value)}
                  disabled={!search.country}
                />
              </FormItem>

              <FormItem itemClass="flex flex-end" className="mb-20" style={{ width: 'auto' }}>
                <Button type="invert" submit>
                  {t('search')}
                </Button>
              </FormItem>
            </FormItems>
          </Form>

          <hr />
        </>
      )}

      <Card>
        <CardHeader
          title={!hideSearchBar ? t('sessions') : t('plannedSessions')}
          count={data.count}
          leftSide={
            <InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters({ search: newValue })} />
          }
          rightSide={
            <SortBy
              options={[
                {
                  title: t('name'),
                  value: 'name',
                },
                {
                  title: t('country'),
                  value: 'country',
                },
                {
                  title: t('city'),
                  value: 'city',
                },
              ]}
              value={filters.ordering}
              onChange={(newValue) => onChangeFilters({ ordering: newValue })}
            />
          }
        />

        <Loader loading={loading || loadingRegister || loadingCancel}>
          <Table size="small" page={filters.page} data={data.results} columns={columns} expandable={expandable} />

          <CardFooter
            onRefresh={onRefetch}
            onRefreshDisabled={!filters.filtered}
            page={filters.page}
            pages={data.total_pages}
            perPage={filters.per_page}
            onChangePerPage={onChangePerPage}
            rightSide={
              <>
                <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                  {t('previous')}
                </Button>

                <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                  {t('next')}
                </Button>
              </>
            }
          />

          <CardAlert
            label={t('didYouFindProblem')}
            t={!hideSearchBar ? 'reportSessionsApplication' : 'reportPlannedSessionsApplication'}
          />
        </Loader>
      </Card>
    </>
  );
};
