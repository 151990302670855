import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { UserContext } from 'contexts';
import { useFilters } from 'hooks';
import { format, dateTimeFormat, dateFormat, toISODate, today, toISO } from 'libs/date';
import { perPage } from 'libs/storage';
import { me } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { StudentRegister } from 'libs/http/api/sessions/sessions.types';
import { InputSearch, SortBy } from 'ui/organisms';
import { DateRangePicker } from 'ui/molecules';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import {
  AvatarInline,
  Table,
  Card,
  CardHeader,
  CardFooter,
  Icon,
  Button,
  Loader,
  Time,
  Status,
  CardAlert,
} from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';
import { Expande } from 'ui/atoms/Table/Expanded';
import { RecoveryGrades } from '../organisms';

import './RegisterSessions.scss';

export const RegisterSessionsTemplate: React.FC = () => {
  const { t } = useIntl();
  const { userData } = React.useContext(UserContext);
  const { request, data, loading } = useRequest<Results<StudentRegister>>({
    data: { results: [] },
  });
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    start_date__date__gte: '',
    end_date__date__lte: '',
    ordering: '' as Sorts,
  });

  const [recoveryGrades, setRecoveryGrades] = React.useState(false);
  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    return () => {
      me.getSessions.cancel();
    };
  }, []);

  React.useEffect(() => {
    request(
      me.getStudentSessions.action({
        ...filters,
        end_date__lte: toISO(today.format(dateTimeFormat)),
        start_date__date__gte: filters.start_date__date__gte ? toISODate(filters.start_date__date__gte, true) : '',
        end_date__date__lte: filters.end_date__date__lte ? toISODate(filters.end_date__date__lte, true) : '',
      }),
    );
  }, [filters, userData]);

  const onChangePeriod = (newValue: any): void => {
    setFilters({
      filtered: true,
      page: 1,
      ...(newValue.start_date__gte ? { start_date__date__gte: newValue.start_date__gte } : {}),
      ...(newValue.end_date__lte ? { end_date__date__lte: newValue.end_date__lte } : {}),
    });
  };

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const toggleRecoveryGrades = (): void => setRecoveryGrades((s) => !s);

  const columns: Column<StudentRegister>[] = React.useMemo(
    () => [
      {
        title: t('course'),
        render: ({ course }) => <b>{course?.name || '---'}</b>,
      },
      {
        title: t('teacher'),
        render: ({ professor }) =>
          professor ? (
            <AvatarInline
              img={professor.profile_picture}
              alt={[professor.first_name, professor.last_name].filter((i) => i).join(' ')}
              size="small"
            />
          ) : (
            '---'
          ),
      },
      {
        title: t('period'),
        width: 240,
        render: ({ start_date: startDate, end_date: endDate }): React.ReactNode => (
          <Time
            date={[startDate && format(startDate, dateFormat), endDate && format(endDate, dateFormat)]
              .filter((i) => i)
              .join(' - ')}
            noParse
          />
        ),
        className: 'nowrap',
      },
      {
        title: t('finalNote'),
        render: ({ final_grade: finalMark }) =>
          finalMark ? <b>{`${finalMark}%`}</b> : <Status status="declined" label={t('arrear')} />,
      },
    ],
    [t, userData.role],
  );

  const expandable = ({
    professor,
    start_date: startDate,
    end_date: endDate,
    country,
    city,
    course,
    institution,
    language,
    faculty,
    grades_to_show,
    individual_work_grade,
    practise_grade,
    course_grade,
  }: StudentRegister): Expande => {
    const grades = [];
    if (grades_to_show.includes('individual_work_grade')) {
      grades.push({
        label: t('individual_work_grade'),
        value: individual_work_grade || <Status status="declined" label={t('arrear')} />,
        className: 'flex-item-row-col-grades',
      });
    }
    if (grades_to_show.includes('practise_grade')) {
      grades.push({
        label: t('practise_grade'),
        value: practise_grade || <Status status="declined" label={t('arrear')} />,
        className: 'flex-item-row-col-grades',
      });
    }
    if (grades_to_show.includes('course_grade')) {
      grades.push({
        label: t('course_grade'),
        value: course_grade || <Status status="declined" label={t('arrear')} />,
        className: 'flex-item-row-col-grades',
      });
    }

    return [
      [
        { label: t('course'), value: (course && course.name) || '---', icon: <Icon type="requests" /> },
        {
          label: t('startAt'),
          value: startDate ? format(startDate, dateFormat) : '---',
          icon: <Icon type="calendar" />,
        },
        {
          label: t('finishAt'),
          value: startDate ? format(endDate, dateFormat) : '---',
          icon: <Icon type="calendar" />,
        },
      ],
      [
        {
          label: t('teacher'),
          value: professor ? [professor.first_name, professor.last_name].filter((i) => i).join(' ') : '---',
        },
        {
          label: t('place'),
          value:
            country && country.title
              ? [country && country.title, city && city.title].filter((i) => i).join(', ')
              : '---',
          icon: <Icon type="pin" />,
        },
        {
          label: t('faculty'),
          value: faculty ? faculty.name : '---',
        },
      ],
      [
        { label: t('institute'), value: (institution && institution.name) || '---', icon: <Icon type="location" /> },
        { label: t('language'), value: (language && language.name) || '---' },
      ],
      grades,
    ];
  };

  return (
    <Card>
      {recoveryGrades && <RecoveryGrades onClose={toggleRecoveryGrades} />}
      <CardHeader
        title={t('sessionsClosed')}
        count={data.count}
        leftSide={<InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />}
        rightSide={
          <>
            <div className="picker-wrapper">
              <div className="hideTablet">{t('period')}</div>
              <DateRangePicker
                from={filters.start_date__date__gte}
                to={filters.end_date__date__lte}
                type="period"
                onChange={onChangePeriod}
              />
            </div>
            <SortBy
              options={[
                {
                  title: t('name'),
                  value: 'name',
                },
                {
                  title: t('country'),
                  value: 'country',
                },
                {
                  title: t('city'),
                  value: 'city',
                },
                {
                  title: t('startAt'),
                  value: 'start_date',
                },
                {
                  title: t('finishAt'),
                  value: 'end_date',
                },
                {
                  title: t('created_at'),
                  value: 'created_at',
                },
              ]}
              value={filters.ordering}
              onChange={(newValue) => onChangeFilters('ordering', newValue)}
            />
          </>
        }
      />
      <Loader loading={loading}>
        <Table page={filters.page} data={data.results} columns={columns} expandable={expandable} />

        <CardFooter
          onRefresh={onRefetch}
          onRefreshDisabled={!filters.filtered}
          page={filters.page}
          pages={data.total_pages}
          perPage={filters.per_page}
          onChangePerPage={onChangePerPage}
          rightSide={
            <>
              <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                {t('previous')}
              </Button>

              <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                {t('next')}
              </Button>
            </>
          }
        />
      </Loader>
      <CardAlert label={t('didYouFindProblem')} t="reportClosedSessions">
        <Button type="danger" className="nowrap recovery-button" onClick={toggleRecoveryGrades}>
          {t('recoveryGrades')}
        </Button>
      </CardAlert>
    </Card>
  );
};
