import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { NomenclaturesContext } from 'contexts';
import { Roles } from 'hooks/useModules';
import { users } from 'libs/http/api';
import { SwitchUserRoleParams, UserList } from 'libs/http/api/users/users.types';
import { Confirmation, Form, FormItem } from 'ui/organisms';
import {
  Modal,
  ModalFooterButton,
  Button,
  Icon,
  Input,
  Loader,
  AvatarInline,
  Checkbox,
  Scroll,
  Select,
} from 'ui/atoms';

interface Props {
  to?: Roles;
  title: string;
  open?: boolean;
  onToggle?: () => void;
  onSave?: () => void;
}

export const SwitchUserRole: React.FC<Props> = ({ to = 'professor', title, open = false, onToggle, onSave }) => {
  const { t } = useIntl();
  const { request, data, loading } = useRequest<UserList[]>();
  const { request: requestSave, loading: loadingSave, errors } = useRequest();
  const { grades } = React.useContext(NomenclaturesContext);

  const [val, setVal] = React.useState<any>(null);
  const [search, setSearch] = React.useState('');
  const [experience, setExperience] = React.useState('1');

  React.useEffect(() => {
    if (to === 'coordinator') {
      request(users.professorsList.action({}));
    } else {
      request(users.studentsList.action({}));
    }

    return () => {
      if (to === 'coordinator') {
        users.professorsList.cancel();
      } else {
        users.studentsList.cancel();
      }
    };
  }, [to]);

  const options = React.useMemo(
    () =>
      data.map((user) => ({ value: user.key, title: user.label, email: user.email, picture: user.profile_picture })),
    [data],
  );

  const optionsList = React.useMemo(
    () =>
      options
        ? options
            .filter(
              (option) => option.title?.toLowerCase().includes(search) || option.email?.toLowerCase().includes(search),
            )
            .map((option) => ({
              value: option.value,
              label: option.title,
              email: option.email,
              picture: option.picture,
            }))
        : [],
    [search, options],
  );

  const onConfirm = async (): Promise<void> => {
    const options: SwitchUserRoleParams = { id: val, role: to };

    if (to === 'professor') {
      options.experience = experience;
    }

    await requestSave(users.save.action(options));

    if (onToggle) {
      onToggle();
    }

    if (onSave) {
      onSave();
    }
  };

  return (
    <>
      {open && (
        <Modal
          onClose={onToggle}
          title={title}
          footer={
            <ModalFooterButton>
              <Button onClick={onToggle}>{t('reject')}</Button>

              <Confirmation onConfirm={onConfirm}>
                <Button type="primary" prefix={<Icon type="check" />} disabled={!val}>
                  {t('confirm')}
                </Button>
              </Confirmation>
            </ModalFooterButton>
          }
        >
          <Loader loading={loading || loadingSave}>
            <div className="zh-select-user-tooltip-title">
              <Input
                prefix={<Icon type="user-strict" />}
                suffix={<Icon type="search" />}
                value={search}
                placeholder={t('searchByNameOrEmail')}
                onChange={setSearch}
              />
            </div>

            <Scroll className="mt-20">
              <div className="zh-checkbox-group">
                {optionsList.map((option) => (
                  <Checkbox
                    key={option.value}
                    label={
                      <AvatarInline
                        img={option.picture}
                        alt={option.label}
                        role={option.email}
                        roleLowerCase
                        size="small"
                      />
                    }
                    checked={option.value === val}
                    onChange={() => setVal(val === option.value ? null : option.value)}
                  />
                ))}
              </div>
            </Scroll>

            {to === 'professor' && (
              <Form>
                <FormItem itemClass="mt-25" label={t('experienceGrade')} extra={errors.experience}>
                  <Select
                    getPopupContainer={(trigger) => (trigger ? trigger.parentNode : null)}
                    placeholder={t('note')}
                    options={grades.map((grade) => ({ title: grade, value: grade }))}
                    value={experience}
                    onChange={setExperience}
                  />
                </FormItem>
              </Form>
            )}
          </Loader>
        </Modal>
      )}
    </>
  );
};
