import * as React from 'react';
import { validate } from 'libs/object';

import './Form.scss';

interface Props {
  onSubmit?: () => void;
  className?: string;
  id?: string;
  autoComplete?: boolean;
}

export const Form: React.FC<Props> = ({ onSubmit, className = '', id, children, autoComplete = true }) => {
  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    if (e.preventDefault) e.preventDefault();

    return onSubmit && onSubmit();
  };

  return (
    <form className={`zh-form ${className}`} onSubmit={onSubmitHandler} id={id} autoComplete={`${autoComplete}`}>
      {children}
    </form>
  );
};

interface ItemProps {
  label?: React.ReactNode;
  className?: string;
  itemClass?: string;
  style?: any;
  required?: true;
  extraStatus?: 'error' | 'warning' | 'regular' | 'success';
  extra?: string | string[];
  error?: string[] | { [key: string]: string[] };
  flex?: boolean;
  column?: boolean;
}

export const FormItem: React.FC<ItemProps> = ({
  label,
  itemClass = '',
  className = '',
  required,
  style,
  extraStatus: $extraStatus = 'error',
  extra: $extra,
  error,
  flex,
  children,
}) => {
  const extraStatus = error ? 'error' : $extraStatus;
  const extra = error ? validate(error) : $extra;

  return (
    <div className={`zh-form-item ${itemClass}`} style={style}>
      {label && (
        <div className="zh-form-item-label">
          {label} {required && <span>*</span>}
        </div>
      )}

      <div
        className={`zh-form-children${flex ? ' zh-form-children-flex' : ''}
        ${extra && extraStatus === 'error' ? ' has-error' : ''} 
        ${className}`}
      >
        {children}

        {extra && className && (
          <div className={`zh-form-extra zh-form-extra-${extraStatus}`}>
            {Array.isArray(extra) ? extra.join(',') : extra}
          </div>
        )}
      </div>

      {extra && !className && (
        <div className={`zh-form-extra zh-form-extra-${extraStatus}`}>
          {Array.isArray(extra) ? extra.join(',') : extra}
        </div>
      )}
    </div>
  );
};

export const FormItems: React.FC<{ className?: string; three?: boolean }> = ({ className = '', three, children }) => (
  <div className={`zh-form-items ${three ? 'zh-form-items-three' : ''} ${className}`}>{children}</div>
);
