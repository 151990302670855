import * as React from 'react';
import { load } from 'react-cookies';
import { messages } from 'locales';
import { Button, Icon } from 'ui/atoms';
import { Modal, ModalFooterButton } from 'ui/atoms/Modal/Modal';

import './PermissionsGuard.scss';

interface Props {
  onClose: () => void;
}

const locale = load('localization') || 'en';

export const PermissionsGuard: React.FC<Props> = ({ onClose }) => (
  <Modal
    size="small"
    contentClass="zh-permissions-guard"
    onClose={onClose}
    footer={
      <ModalFooterButton>
        {messages.confirm ? (
          <Button onClick={onClose} type="primary" prefix={<Icon type="check" />}>
            {messages.confirm[locale]}
          </Button>
        ) : null}
      </ModalFooterButton>
    }
  >
    <Icon type="alert" size="big" />

    {messages.permissions ? <p>{messages.permissions[locale]}</p> : null}
  </Modal>
);
