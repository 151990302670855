import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { Results } from '../index.types';
import { Resource, ResourceList, DataTypes, ResourceEditOptions, ResourceAddOptions } from './resources.types';

export const resources = {
  get: {
    action: (params = {}): Promise<{ data: Results<Resource> }> =>
      axios.get(`resources`, { params, cancelToken: new cancelToken((c: Canceler) => (resources.get.cancel = c)) }),
    cancel: (() => null) as Canceler,
  },

  list: {
    action: (params: { type: string }): Promise<{ data: ResourceList[] }> =>
      axios.get(`resources/list`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (resources.list.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getTypes: {
    action: (params = {}): Promise<{ data: DataTypes[] }> =>
      axios.get(`resource-types`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (resources.getTypes.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  add: {
    action: (params: ResourceAddOptions) =>
      axios.post(`resources`, params, {
        cancelToken: new cancelToken((c: Canceler) => (resources.add.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  getResource: {
    action: ({ id, ...params }: { id: number }) =>
      axios.get(`resources/${id}`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (resources.getResource.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  putResource: {
    action: ({ id, ...params }: { id: number }) =>
      axios.put(`resources/${id}`, params, {
        cancelToken: new cancelToken((c: Canceler) => (resources.putResource.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  editResource: {
    action: ({ id, ...params }: ResourceEditOptions) =>
      axios.patch(`resources/${id}`, params, {
        cancelToken: new cancelToken((c: Canceler) => (resources.editResource.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  deleteResource: {
    action: ({ id }: { id: number }) =>
      axios.delete(`resources/${id}`, {
        cancelToken: new cancelToken((c: Canceler) => (resources.getTypes.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
