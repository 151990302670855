import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useFilters } from 'hooks';
import { events } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { Invitation } from 'libs/http/api/events/events.types';
import { dateTimeFormat, format, toISODate } from 'libs/date';

import { Button, Info, Card, CardHeader, CardFooter, Loader, Icon, Time, EmptyList, Status } from 'ui/atoms';
import { InputSearch, Confirmation } from 'ui/organisms';
import { DateRangePicker } from 'ui/molecules';
import { Sorts } from 'ui/organisms/SortBy/SortBy';

import './SuggestedEvents.scss';

const initialFilters = {
  filtered: false,
  page: 1,
  per_page: 3,
  search: '',
  ordering: '' as Sorts,
  start_date__date__gte: '',
  end_date__date__lte: '',
};

export const SuggestedEvents: React.FC<{ onUpdate: () => void }> = ({ onUpdate }) => {
  const { t } = useIntl();
  const { request: requestRejectInvitation, loading: loadingRejectInvitation } = useRequest<void>();
  const { request: requestAcceptInvitation, loading: loadingAcceptInvitation } = useRequest<void>();
  const { request, data, loading } = useRequest<Results<Invitation>>({
    data: { results: [] },
  });

  const [selectedId, setSelectedId] = React.useState<number | null>(null);
  const [filters, setFilters] = useFilters({ ...initialFilters });

  React.useEffect(() => {
    request(
      events.invitations.action({
        ...filters,
        start_date__date__gte: filters.start_date__date__gte ? toISODate(filters.start_date__date__gte, true) : '',
        end_date__date__lte: filters.end_date__date__lte ? toISODate(filters.end_date__date__lte, true) : '',
      }),
    );
  }, [filters]);

  React.useEffect(() => {
    return () => {
      events.invitations.cancel();
    };
  }, []);

  const onRejectInvitation = async (id: number): Promise<void> => {
    setSelectedId(id);

    await requestRejectInvitation(events.respondToInvitations.action({ id, status: 'declined' }));

    setSelectedId(null);
    onRefetch();
  };

  const onAcceptInvitation = async (id: number): Promise<void> => {
    setSelectedId(id);

    await requestAcceptInvitation(events.respondToInvitations.action({ id, status: 'accepted' }));

    setSelectedId(null);
    onRefetch();
    onUpdate();
  };

  const onRefetch = (): void => setFilters({ ...initialFilters });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onChangePeriod = (period: any): void =>
    setFilters({
      filtered: true,
      page: 1,
      ...(period.start_date__gte ? { start_date__date__gte: period.start_date__gte } : {}),
      ...(period.end_date__lte ? { end_date__date__lte: period.end_date__lte } : {}),
    });

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  return (
    <Card className="events-suggestions">
      <CardHeader
        title={t('suggestedEvents')}
        count={data.count}
        leftSide={<InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />}
        rightSide={
          <div className="picker-wrapper">
            <div className="hideTablet">{t('period')}</div>

            <DateRangePicker
              from={filters.start_date__date__gte}
              to={filters.end_date__date__lte}
              type="period"
              onChange={onChangePeriod}
            />
          </div>
        }
      />

      <Loader loading={loading}>
        {data.results.length > 0 ? (
          <div className="flex-data">
            {data.results.map((item) => (
              <div key={item.id} className="flex-item">
                <div className="flex-item-row">
                  <div>
                    <Icon type="bookmark" />
                  </div>

                  <div className="flex-item-row-col">
                    {item.name && (
                      <div className="flex-item-row-col-item">
                        <label>{t('name')}</label>
                        <Info primary label={item.name} />
                      </div>
                    )}

                    {item.type === 'session' && (
                      <div className="flex-item-row-col-item">
                        <label>{t('course')}</label>
                        {item.course ? <Info primary label={item.course.name} /> : '---'}
                      </div>
                    )}

                    <div className="flex-item-row-col-item">
                      <label>{t('location')}</label>
                      <Info
                        icon={<Icon type="pin" />}
                        label={[item.country && item.country.title, item.city && item.city.title, item.address]
                          .filter((i) => i)
                          .join(', ')}
                      />
                    </div>
                    <div className="flex-item-row-col-item">
                      <label>{t('dateAndTime')}</label>
                      <Time date={`${format(item.start_date, dateTimeFormat)}`} noParse />
                    </div>

                    {item.type === 'session' && (
                      <div className="flex-item-row-col-item">
                        <label>{t('info_short')}</label>
                        <div dangerouslySetInnerHTML={{ __html: item.info || '---' }} />
                      </div>
                    )}

                    {item.professor && (
                      <div className="flex-item-row-col-item">
                        <label>{t('teacher')}</label>
                        <Info
                          primary
                          icon={<Icon type="user-strict" />}
                          label={[item.professor.first_name, item.professor.last_name].filter((i) => i).join(' ')}
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex-item-row-buttons">
                    {item.status === 'pending' ? (
                      <>
                        <Confirmation onConfirm={() => onAcceptInvitation(item.id)}>
                          <Button
                            type="primary"
                            prefix={<Icon type="check" />}
                            loading={loadingAcceptInvitation && selectedId === item.id}
                          >
                            {t('accept')}
                          </Button>
                        </Confirmation>

                        <Confirmation onConfirm={() => onRejectInvitation(item.id)}>
                          <Button
                            prefix={<Icon type="close" />}
                            loading={loadingRejectInvitation && selectedId === item.id}
                          >
                            {t('reject')}
                          </Button>
                        </Confirmation>
                      </>
                    ) : (
                      <Status label={t(item.status)} status={item.status} />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex-data">
            <EmptyList />
          </div>
        )}

        <CardFooter
          onRefresh={onRefetch}
          onRefreshDisabled={!filters.filtered}
          page={filters.page}
          pages={data.total_pages}
          rightSide={
            <>
              <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                {t('previous')}
              </Button>

              <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                {t('next')}
              </Button>
            </>
          }
        />
      </Loader>
    </Card>
  );
};
