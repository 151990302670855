import * as React from 'react';
import AnimateHeight from 'react-animate-height';
import { Icon } from 'ui/atoms';

import './Collapse.scss';

interface Props {
  className?: string;
  collapsedKeys: number[];
  children: React.ReactElement[];
  onToggle: (key: number) => void;
}

export const Collapse: React.FC<Props> = ({ onToggle, className = '', collapsedKeys, children }) => {
  if (!children && !Array.isArray(children)) {
    console.error('ErrorCollapse children have to be an array of React.ReactElement');

    return null;
  }

  return (
    <div className={`zh-collapse ${className}`}>
      {children.map((child, key) =>
        React.cloneElement(child, {
          key,
          opened: collapsedKeys.includes(key),
          onToggle: () => onToggle(key),
        }),
      )}
    </div>
  );
};

function hasSomeParentTheClass(element: any, classname: any): boolean {
  if (
    element &&
    element.className &&
    element.className.length &&
    element.className.split(' ').indexOf(classname) >= 0
  ) {
    return true;
  }
  return element && element.parentNode && hasSomeParentTheClass(element.parentNode, classname);
}

interface ItemProps {
  className?: string;
  opened?: boolean;
  onToggle?: (key: number) => void;
  title: React.ReactElement;
}

export const CollapseItem: React.FC<ItemProps> = ({ onToggle, className = '', title, opened, children }) => {
  const onClick = (e: any): void => {
    if (
      onToggle !== undefined &&
      !hasSomeParentTheClass(e.target, 'zh-button-wrapper') &&
      hasSomeParentTheClass(e.target, 'zh-collapse-item-title')
    ) {
      onToggle(0);
    }
  };

  return (
    <div className={`zh-collapse-item ${className}`} {...{ onClick }}>
      <div className={`zh-collapse-item-title ${opened ? 'open' : ''}`}>
        <Icon type="arrow-filled" /> {title}
      </div>

      <AnimateHeight duration={150} height={opened ? 'auto' : 0}>
        <div className={`zh-collapse-item-children`}>{children}</div>
      </AnimateHeight>
    </div>
  );
};
