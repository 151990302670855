import * as React from 'react';
import { useRequest } from 'estafette';
import { useParams, useHistory } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { useFilters } from 'hooks';
import { NomenclaturesContext, UserContext } from 'contexts';
import { resources } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { dateTimeFormat } from 'libs/date';
import { DataTypes, Resource } from 'libs/http/api/resources/resources.types';
import { perPage } from 'libs/storage';
import { SortBy, Actions, InputSearch } from 'ui/organisms';
import { Sorts } from 'ui/organisms/SortBy/SortBy';
import {
  Card,
  CardHeader,
  CardFooter,
  ButtonGroup,
  Button,
  Table,
  Info,
  Icon,
  Collapse,
  CollapseItem,
  Loader,
  Time,
  Animated,
  EmptyList,
  Select,
} from 'ui/atoms';
import { Column } from 'ui/atoms/Table/Table';

import { ResourceDetails, UploadModal } from '../organisms';

interface Props {
  onCreateRefetch: (fn: () => void) => void;
}

export const ResourceTemplate: React.FC<Props> = ({ onCreateRefetch }) => {
  const { locale, t } = useIntl();
  const params = useParams();
  const { push } = useHistory();
  const { userData } = React.useContext(UserContext);
  const { languages, getLanguages } = React.useContext(NomenclaturesContext);
  const { request, data, loading } = useRequest<Results<Resource>>({ loading: true, data: { results: [] } });
  const { request: requestTypes, data: resourcesTypes, loading: loadingTypes } = useRequest<DataTypes[]>({
    loading: true,
  });
  const { request: requestDelete, loading: loadingDelete } = useRequest();
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    language: null,
    search: '',
    type: '',
    ordering: '' as Sorts,
  });

  const [currentType, setCurrentType] = React.useState<DataTypes>({} as DataTypes);
  const [id, setId] = React.useState<number | null>(null);
  const [uploadType, setUploadType] = React.useState<number | null>(null);
  const [open, setOpen] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState<number[]>([0]);
  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => getLanguages(), []);

  React.useEffect(() => {
    requestTypes(resources.getTypes.action());

    return () => {
      resources.getTypes.cancel();
    };
  }, [data.results]);

  React.useEffect(() => {
    onCreateRefetch(onRefetch);
  }, [onCreateRefetch]);

  React.useEffect(() => {
    if (filters.type !== params.type) {
      setFilters({ type: params.type });
    }
  }, [params, filters]);

  React.useEffect(() => {
    if (filters.type && filters.type.length && (filters.type !== 'all' || filters.language)) {
      onFetch().catch(() => onFetch());
    }
  }, [filters]);

  React.useEffect(() => {
    setCurrentType(
      resourcesTypes.reduce((acc, current) => (current.name === filters.type ? current : acc), {
        id: 0,
        name: 'all',
        // TODO: tell ion to give you a counter of all resources in that object
        resources_count: 0,
      }),
    );
  }, [filters, resourcesTypes]);

  const onFetch = (): Promise<Results<Resource>> =>
    request(
      resources.get.action({
        type: filters.type,
        search: filters.search,
        page: filters.page,
        ordering: filters.ordering,
        language: filters.language,
        per_page: 5,
      }),
    );

  const onRefetch = (): void => {
    if (filters.filtered) {
      setFilters({ ...initialFilters.current });
    } else {
      onFetch();
    }
  };
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const onToggleCollapse = (key: number): void =>
    setCollapsed((prevCollapsed) =>
      prevCollapsed.includes(key) ? prevCollapsed.filter(($key) => key !== $key) : [...prevCollapsed, key],
    );

  const onCloseUpload = (): void => {
    setUploadType(null);
    setOpen(false);
  };

  const onOpenUpload = React.useCallback((params?: { id?: number; type?: number }): void => {
    if (params) {
      if (params.type) {
        setUploadType(params.type);
      }

      if (params.id) {
        setId(params.id);
      }
    }

    setOpen(true);
  }, []);

  const onGoToType = React.useCallback((type: string): void => push('ResourcePage', { type }), []);

  const onDelete = async (id: number): Promise<void> => {
    await requestDelete(resources.deleteResource.action({ id }));
  };

  const onDeleteResource = async (id: number): Promise<void> => {
    await onDelete(id);
    onFetch();
  };

  const AddButton: React.FC<{ type: number }> = ({ type }) => (
    <Button
      className="zh-send-message-button primary"
      prefix={<Icon type="plus" />}
      onClick={() => onOpenUpload({ type })}
    >
      {t('addFile')}
    </Button>
  );

  const columns = React.useMemo(
    (): Column<Resource>[] => [
      {
        title: t('name'),
        render: (record): React.ReactNode => {
          const index = `name_${locale}` as 'name_en' | 'name_ro' | 'name_ru';

          return <Info primary label={record[index] || '---'} icon={<Icon type="file" />} />;
        },
      },
      {
        title: t('description'),
        dataIndex: 'description',
      },
      {
        title: t('course'),
        // render: ({ course }) => <Info primary label={course} />,
        render: () => <Info primary label="---" />,
      },
      {
        title: t('addingDate'),
        render: ({ created_at: date }) => <Time date={date} format={dateTimeFormat} className="nowrap" />,
      },
      {
        title: '',
        width: 123,
        render: ({ id, type, file }) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <a href={file} target="_blank" rel="noopener noreferrer">
              <Button className="zh-send-message-button primary" prefix={<Icon type="download" />}>
                {t('unload')}
              </Button>
            </a>

            {['student', 'professor'].indexOf(userData.role) < 0 && (
              <Actions
                onEdit={() => onOpenUpload({ type: type.id, id })}
                loadingDelete={loadingDelete}
                onDelete={() => onDeleteResource(id)}
              />
            )}
          </div>
        ),
      },
    ],
    [t, userData.role],
  );

  const dataResources = React.useMemo(() => resourcesTypes.filter((resource) => resource.resources_count > 0), [
    resourcesTypes,
  ]);
  const totalCountResources = React.useMemo(() => {
    let total = 0;

    resourcesTypes.map((resource) => {
      total += resource.resources_count;

      return resource;
    });

    return total;
  }, [resourcesTypes]);

  return (
    <>
      {open && <UploadModal id={id} type={uploadType} onClose={onCloseUpload} onRefetch={onRefetch} />}

      <Card>
        <CardHeader
          title={t('didacticMaterials')}
          leftSide={<InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />}
          rightSide={
            <>
              <Animated loading={loadingTypes} debounce={500}>
                <ButtonGroup>
                  <Button type={filters.type === 'all' ? 'invert' : 'regular'} onClick={() => onGoToType('all')}>
                    {t('all')} ({totalCountResources})
                  </Button>

                  {resourcesTypes.map((resource) => (
                    <Button
                      key={resource.id}
                      type={filters.type === resource.name ? 'invert' : 'regular'}
                      onClick={() => onGoToType(resource.name)}
                      disabled={resource.resources_count === 0}
                    >
                      {t(resource.name)} ({resource.resources_count})
                    </Button>
                  ))}
                </ButtonGroup>
              </Animated>

              <Select
                placeholder={t('selectLanguage2')}
                options={languages.map(({ label: title }) => ({ value: title, title }))}
                value={filters.language}
                onChange={(newValue) => onChangeFilters('language', newValue)}
              />

              <SortBy
                options={[
                  {
                    title: t('name'),
                    value: `name_${locale}`,
                  },
                  {
                    title: t('created_at'),
                    value: 'created_at',
                  },
                ]}
                value={filters.ordering}
                onChange={(newValue) => onChangeFilters('ordering', newValue)}
              />
            </>
          }
        />

        {currentType.name === 'all' && (
          <>
            {!dataResources.length && (
              <div className="flex-data">
                <EmptyList />
              </div>
            )}

            <Collapse collapsedKeys={collapsed} onToggle={onToggleCollapse}>
              {dataResources.map((resource, key) => (
                <CollapseItem
                  key={resource.id}
                  title={
                    <div className="collapse-item flex flex-between">
                      <Info
                        primary
                        label={`${resource.name ? t(`${resource.name}`) : ''} (${resource.resources_count})`}
                        icon={<Icon type="file" />}
                      />

                      {['student', 'professor'].indexOf(userData.role) < 0 && <AddButton type={resource.id} />}
                    </div>
                  }
                >
                  <ResourceDetails
                    opened={collapsed.includes(key)}
                    onEdit={onOpenUpload}
                    onDelete={onDelete}
                    loadingDelete={loadingDelete}
                    resourceType={resource}
                    search={filters.search}
                    ordering={filters.ordering}
                  />
                </CollapseItem>
              ))}
            </Collapse>
          </>
        )}

        {currentType.name !== 'all' && (
          <Loader loading={loading} height={250}>
            <Card>
              <CardHeader
                title={currentType.name ? t(`${currentType.name}`) : undefined}
                count={currentType.resources_count}
                rightSide={['student', 'professor'].indexOf(userData.role) < 0 && <AddButton type={currentType.id} />}
              />

              <Table size="small" page={currentType.resources_count} data={data.results} columns={columns} />

              <CardFooter
                onRefresh={onRefetch}
                onRefreshDisabled={!filters.filtered}
                page={filters.page}
                pages={data.total_pages}
                perPage={filters.per_page}
                onChangePerPage={onChangePerPage}
                rightSide={
                  <>
                    <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                      {t('previous')}
                    </Button>

                    <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                      {t('next')}
                    </Button>
                  </>
                }
              />
            </Card>
          </Loader>
        )}
      </Card>
    </>
  );
};
