import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Link, useParams, useRouterHelpers } from 'estafette-router';
import { Layout } from 'ui/organisms';
import { Button, Icon, Tabs } from 'ui/atoms';

export const InstituteLayout: React.FC<{ showTabs?: boolean }> = ({ showTabs = true, children }) => {
  const { t } = useIntl();
  const { id } = useParams();
  const { isRouteActive } = useRouterHelpers();

  const tabsData = [
    {
      route: 'GeneralInstitutePage',
      params: { id },
      label: t('generalInformation'),
      active: isRouteActive(['GeneralInstitutePage', 'EditCoordinatorPage']),
    },
    {
      route: 'SessionsinstitutePage',
      params: { id },
      label: t('currentSessions'),
      active: isRouteActive('SessionsinstitutePage'),
    },
    {
      route: 'PrevSessionsinstitutePage',
      params: { id },
      label: t('previousSessions'),
      active: isRouteActive('PrevSessionsinstitutePage'),
    },
    {
      route: 'TeachersInstitutePage',
      params: { id },
      label: t('teachers'),
      active: isRouteActive(['TeachersInstitutePage', 'TeacherManagementPage']),
    },
    {
      route: 'StudentsInstitutePage',
      params: { id },
      label: t('students'),
      active: isRouteActive(['StudentsInstitutePage', 'StudentManagementPage']),
    },
    {
      route: 'AnnouncementsInstitutePage',
      params: { id },
      label: t('announcements'),
      active: isRouteActive('AnnouncementsInstitutePage'),
    },
  ];

  return (
    <Layout
      title={t('institutes')}
      rightSide={
        <Link route="AddInstitutePage" params={{ action: id || 'add' }}>
          <Button type="invert" prefix={<Icon type={id ? 'edit' : 'plus'} />}>
            {id ? t('editInstitute') : t('addInstitute2')}
          </Button>
        </Link>
      }
    >
      {showTabs && <Tabs data={tabsData} className="mb-0" />}

      {children}
    </Layout>
  );
};
