import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { AvatarInline, Icon, Info, MediaGroup, Media } from 'ui/atoms';

import { Professor } from 'libs/http/api/sessions/sessions.types';

// $$fix webpack cache
import './ContactCard.scss';
import { Link } from 'estafette-router';

interface Props {
  data: Professor;
  role: string;
  linkToUser?: boolean;
}

export const ContactCard: React.FC<Props> = ({ data = {}, role, linkToUser }) => {
  const { t } = useIntl();

  const name = [data.first_name, data.last_name].filter((i) => i).join(' ');

  return (
    <div className="zh-session-contact-card">
      <AvatarInline
        img={data.profile_picture}
        shortAlt={name}
        alt={
          <>
            {linkToUser && (
              <Link route="ProfilePage" params={{ action: data.id }} className="zh-session-contact-card-name">
                {name}
              </Link>
            )}
            {!linkToUser && <span className="zh-session-contact-card-name">{name}</span>}

            <span className="zh-session-contact-card-desc">{t(role)}</span>
          </>
        }
      />

      {data.email && <Info icon={<Icon type="mail-filled" />} label={data.email} />}

      {data.phone_number && <Info icon={<Icon type="phone" />} label={data.phone_number} />}

      <MediaGroup className="zh-session-contact-card-medias">
        {data.telegram && <Media type="telegram" link={data.telegram} />}

        {data.skype && <Media type="skype" link={data.skype} />}

        {data.whatsup && <Media type="whatsapp" link={data.whatsup} />}

        {data.viber && <Media type="viber" link={data.viber} />}

        {data.facebook_url && <Media type="facebook" link={data.facebook_url} />}

        {data.linkedin_url && <Media type="linkedin" link={data.linkedin_url} />}

        {data.odnoklasniki_url && <Media type="odnoklasniki" link={data.odnoklasniki_url} />}

        {data.vkontakte_url && <Media type="vk" link={data.vkontakte_url} />}
      </MediaGroup>
    </div>
  );
};
