import * as React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { enGB, ro, ru, es } from 'date-fns/locale';
import { useIntl } from 'estafette-intl';
import { useStateHandlers } from 'hooks';
import { format, dateTimeFormat, dateFormatInput, dateTimeFormatInput } from 'libs/date';

import 'react-datepicker/dist/react-datepicker.css';
import './DateRangePicker.scss';

registerLocale('en', enGB);
registerLocale('ro', ro);
registerLocale('ru', ru);
registerLocale('es', es);

interface Props {
  from?: any;
  to?: any;
  date?: any;
  type: 'period' | 'date' | 'date-time';
  placeholder?: string;
  className?: string;
  withTime?: boolean;
  showFormat?: string;
  minDate?: Date;
  divider?: boolean;
  onChange?: (state: { [key: string]: string | null }) => void;
  withPortal?: boolean;
}

export const DateRangePicker: React.FC<Props> = ({
  type = 'period',
  placeholder = '',
  className = '',
  withTime,
  showFormat,
  onChange,
  minDate,
  divider,
  withPortal = false,
  ...props
}) => {
  const { t, locale } = useIntl();
  const [state, setState] = useStateHandlers<{ [key: string]: any }>({
    from: null,
    to: null,
    date: null,
  });
  const [limitTime, setLimitTime] = useStateHandlers<{ [key: string]: Date | undefined }>({
    min: undefined,
    max: undefined,
  });

  const { from, to, date } = state;

  const periodFormat = showFormat || (withTime ? dateTimeFormatInput : dateFormatInput);

  React.useEffect(() => {
    setLimitTime({
      min: new Date(new Date(new Date().setHours(7)).setMinutes(0)),
      max: new Date(new Date(new Date().setHours(22)).setMinutes(0)),
    });
  }, []);

  React.useEffect(() => {
    if (onChange && from && (!props.from || (props.from && new Date(props.from).toString() !== from.toString()))) {
      onChange({
        start_date__gte: from ? format(from, withTime ? dateTimeFormat : undefined) : null,
      });
    }
  }, [from]);

  React.useEffect(() => {
    if (onChange && to && (!props.to || (props.to && new Date(props.to).toString() !== to.toString()))) {
      onChange({
        end_date__lte: to ? format(to, withTime ? dateTimeFormat : undefined) : null,
      });
    }
  }, [to]);

  React.useEffect(() => {
    if (onChange) {
      onChange({ date__gte: date ? format(date, type === 'date-time' ? dateTimeFormat : undefined) : null });
    }
  }, [date]);

  React.useEffect(() => {
    const newState: any = {};
    if (!state.from && props.from) {
      newState.from = new Date(props.from);
    }

    if (!state.to && props.to) {
      newState.to = new Date(props.to);
    }

    if (!state.date && props.date) {
      newState.date = new Date(props.date);
    }

    if (['from', 'to', 'date'].filter((i) => i in newState).length) {
      setState(newState);
    }
  }, [state, props.from, props.to, props.date]);

  const onChangeFrom = (value: Date): void => setState({ from: value });
  const onChangeTo = (value: Date): void => setState({ to: value });
  const onChangeDate = (value: Date | [Date, Date] | null): void => {
    const val = value as Date;
    setState({
      date:
        val && minDate && minDate > val
          ? new Date(new Date(minDate.setHours(val.getHours() < 7 ? 10 : val.getHours())).setMinutes(val.getMinutes()))
          : val
          ? new Date(val.setHours(val.getHours() < 7 ? 10 : val.getHours()))
          : new Date(),
    });
  };

  return (
    <div className={`picker-label ${className}`}>
      {type === 'period' ? (
        <>
          <DatePicker
            dateFormat={periodFormat}
            minDate={minDate}
            minTime={withTime ? limitTime.min : undefined}
            maxTime={withTime ? limitTime.max : undefined}
            placeholderText={t('startDate')}
            className={!from ? `empty` : undefined}
            selected={from}
            onChange={onChangeFrom}
            selectsStart
            portalId={withPortal ? 'datePickerPortal' : undefined}
            showTimeSelect={withTime}
            startDate={from}
            endDate={to}
            locale={locale}
            timeFormat="HH:mm"
            timeIntervals={30}
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            yearDropdownItemNumber={70}
          />
          {divider ? <span>-</span> : null}
          <DatePicker
            dateFormat={periodFormat}
            minDate={from || minDate}
            minTime={limitTime.min}
            maxTime={limitTime.max}
            placeholderText={t('endDate')}
            className={!to ? `empty` : undefined}
            selected={to}
            onChange={onChangeTo}
            selectsEnd
            portalId={withPortal ? 'datePickerPortal' : undefined}
            showTimeSelect={withTime}
            startDate={from}
            endDate={to}
            timeFormat="HH:mm"
            locale={locale}
            timeIntervals={30}
            showDisabledMonthNavigation={false}
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            yearDropdownItemNumber={70}
          />
        </>
      ) : type === 'date' ? (
        <DatePicker
          minDate={minDate}
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          yearDropdownItemNumber={70}
          className={`hide-header ${!date ? `empty` : ''}`}
          dateFormat={dateFormatInput}
          placeholderText={placeholder || t('date')}
          selected={date}
          onChange={onChangeDate}
          timeFormat="HH:mm"
          locale={locale}
          portalId={withPortal ? 'datePickerPortal' : undefined}
        />
      ) : type === 'date-time' ? (
        <DatePicker
          minDate={minDate}
          minTime={limitTime.min}
          maxTime={limitTime.max}
          dateFormat={dateTimeFormatInput}
          placeholderText={placeholder || t('dateAndTime')}
          className={!date ? `empty` : undefined}
          selected={date}
          onChange={onChangeDate}
          showTimeSelect
          timeIntervals={30}
          timeFormat="HH:mm"
          locale={locale}
          showDisabledMonthNavigation={false}
          portalId={withPortal ? 'datePickerPortal' : undefined}
        />
      ) : null}
    </div>
  );
};
