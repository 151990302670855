import { Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

import { Announcement, AnnouncementFilters, AnnouncementOptions, SendAnnounceOptions } from './announces.types';
import { Results } from '../index.types';

export const announces = {
  get: {
    action: (params: AnnouncementFilters): Promise<{ data: Results<Announcement> }> =>
      axios.get('announces', {
        ...params,
        cancelToken: new cancelToken((c: Canceler) => (announces.get.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  load: {
    action: ({ id, ...params }: { id: number }): Promise<{ data: Announcement }> =>
      axios.get(`announces/${id}`, {
        ...params,
        cancelToken: new cancelToken((c: Canceler) => (announces.load.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  saveAnnounce: {
    action: (options: AnnouncementOptions) =>
      axios.post(`announces`, options, {
        cancelToken: new cancelToken((c: Canceler) => (announces.saveAnnounce.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  send: {
    action: (options: SendAnnounceOptions) =>
      axios.post(`announces/users`, options, {
        cancelToken: new cancelToken((c: Canceler) => (announces.send.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  sessionSend: {
    action: (options: SendAnnounceOptions) =>
      axios.post(`announces/session-users`, options, {
        cancelToken: new cancelToken((c: Canceler) => (announces.send.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
