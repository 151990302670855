import * as React from 'react';
import { useRequest } from 'estafette';
import { useHistory } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { NomenclaturesContext, UserContext } from 'contexts';
import { useStateHandlers } from 'hooks';
import { faculties, courses, institutions, me } from 'libs/http/api';
import { sessions } from 'libs/http/api/sessions/sessions';
import { EurasiaInstitution } from 'libs/http/api/institutions/institutions.types';
import { FacultiesList } from 'libs/http/api/faculties/faculties.types';
import { CourseList } from 'libs/http/api/courses/courses.types';
import { List, Save } from 'libs/http/api/index.types';
import { toISO } from 'libs/date';
import { Form, FormItem, FormItems, Head } from 'ui/organisms';
import { DateRangePicker } from 'ui/molecules';
import { Button, Fragment, Icon, Input, RichText, Select, Alert, Checkbox } from 'ui/atoms';

import { SessionsLayout } from '../organisms';

export const RequestSessionPage: React.FC = () => {
  const { t } = useIntl();
  const { push, goBack } = useHistory();
  const { request: requestInstitutions, data: dataInstitutions, loading: loadingInstitutions } = useRequest<List[]>();
  const { request: requestInstitute, data: dataInstitute, loading: loadingInstitute } = useRequest<EurasiaInstitution>({
    data: {},
  });
  const { request: requestCourses, data: dataCourses, loading: loadingCourses } = useRequest<CourseList[]>();
  const { request: requestFaculties, data: dataFaculties, loading: loadingFaculties } = useRequest<FacultiesList[]>();
  const { request: requestSave, data: dataSave, loading: loadingSave, errors } = useRequest<Save>();
  const {
    getActiveCountries,
    activeCountries,
    loadingActiveCountries,
    getActiveCities,
    activeCities,
    loadingActiveCities,
    getActiveLanguages,
    activeLanguages,
    loadingActiveLanguages,
  } = React.useContext(NomenclaturesContext);
  const { userData } = React.useContext(UserContext);

  const [state, setState] = useStateHandlers<{ [key: string]: string | number | null }>({
    institution: null,
    faculty: null,
    course: null,
    info: '',
    start_date: '',
    end_date: '',
    city: null,
    country: null,
    language: null,
    address: '',
    facebook_url: '',
    vkontakte_url: '',
    odnoklasniki_url: '',
    linkedin_url: '',
    viber: '',
    whatsup: '',
    skype: '',
    telegram: '',
    is_hidden: 0,
  });

  const [searchCountries, setSearchCountries] = React.useState('');
  const [searchCities, setSearchCities] = React.useState('');

  React.useEffect(() => {
    if (state.institution) {
      requestInstitute(institutions.load.action({ id: state.institution as number }));
    }
  }, [state.institution]);

  React.useEffect(() => {
    if (dataInstitute.country) {
      setState({ country: dataInstitute.country.id });
    }

    if (dataInstitute.city) {
      setState({ city: dataInstitute.city.id });
    }
  }, [dataInstitute]);

  React.useEffect(() => {
    requestFaculties(faculties.list.action());
    requestInstitutions(
      userData.role === 'coordinator' ? me.getInstitutions.action() : institutions.list.action({ is_active: true }),
    );

    getActiveLanguages();
  }, [userData]);

  React.useEffect(() => {
    getActiveCountries(searchCountries);
  }, [searchCountries]);

  React.useEffect(() => {
    return () => {
      faculties.list.cancel();
      courses.list.cancel();
      sessions.add.cancel();
      sessions.save.cancel();
      me.getInstitutions.cancel();
      institutions.list.cancel();
    };
  }, []);

  React.useEffect(() => {
    getActiveCities(state.country as number | null, searchCities);
  }, [state.country, searchCities]);

  React.useEffect(() => {
    if (state.faculty) {
      requestCourses(courses.list.action({ faculties: (state.faculty as string) || '' }));
    }
  }, [state.faculty]);

  const onSubmit = async (): Promise<void> => {
    await requestSave(
      sessions.request.action({
        ...state,
        ...(state.start_date ? { start_date: toISO(`${state.start_date} 10:00`) } : {}),
        ...(state.end_date ? { end_date: toISO(`${state.end_date}  16:00`) } : {}),
      }),
    );

    push('SinglePlannedSessionsPage');
  };

  const onChange = React.useCallback((target: string, value: any): void => setState({ [target]: value }), []);
  const onCancel = (): void => goBack();

  const institutionsOptions = React.useMemo(
    () => dataInstitutions.map((institution) => ({ value: institution.key, title: institution.label })),
    [dataInstitutions],
  );

  const languageOptions = React.useMemo(
    () => activeLanguages.map((language) => ({ value: language.key, title: language.label })),
    [activeLanguages],
  );

  const facultiesOptions = React.useMemo(
    () => dataFaculties.map((faculty) => ({ value: faculty.key, title: faculty.label })),
    [dataFaculties],
  );

  const coursesOptions = React.useMemo(
    () => dataCourses.map((course) => ({ value: course.key, title: course.label })),
    [dataCourses],
  );

  const handleCountriesSearch = (input: string): void => {
    setSearchCountries(input);
  };

  const handleCitiesSearch = (input: string): void => {
    setSearchCities(input);
  };

  return (
    <>
      <Head t="sessionRequest" />

      <SessionsLayout showTabs={false}>
        <h1>{t('sessionRequest')}</h1>

        <Alert message={dataSave.message || errors.non_field_errors || errors.detail} form type="error" />

        <Form onSubmit={onSubmit}>
          <Fragment
            footer={
              <>
                <Button onClick={onCancel}>{t('cancel')}</Button>

                <Button submit type="primary" prefix={<Icon type="check" />} loading={loadingSave}>
                  {t('confirm')}
                </Button>
              </>
            }
          >
            <FormItem className="mx-w-660" label={t('institute')} extra={errors.institution}>
              <Select
                placeholder={t('selectInstitute')}
                options={institutionsOptions}
                loading={loadingInstitutions}
                value={state.institution}
                onChange={(value): void => onChange('institution', value)}
              />
            </FormItem>

            <hr className="hr-2" />

            <FormItems className="mx-w-660">
              <FormItem label={t('country')} extra={errors.country}>
                <Select
                  placeholder={t('selectCountry')}
                  options={activeCountries}
                  onSearch={handleCountriesSearch}
                  loading={loadingActiveCountries || loadingInstitute}
                  value={state.country}
                  onChange={(value): void => onChange('country', value)}
                />
              </FormItem>

              <FormItem
                label={t('city')}
                extra={errors.city ? errors.city : !state.country ? t('selectCountryError') : ''}
                extraStatus={errors.city ? 'error' : 'regular'}
              >
                <Select
                  placeholder={t('selectCity')}
                  options={state.country ? activeCities.filter((city) => city.countryID === state.country) : []}
                  loading={loadingActiveCities || loadingInstitute}
                  onSearch={handleCitiesSearch}
                  value={state.city}
                  onChange={(value): void => onChange('city', value)}
                  disabled={!state.country}
                />
              </FormItem>
            </FormItems>

            <FormItem className="mx-w-660" label={t('fullAddress')} extra={errors.address}>
              <Input value={state.address} onChange={(value) => onChange('address', value)} />
            </FormItem>

            <hr className="hr-2" />

            <FormItems className="mx-w-660">
              <FormItem label={t('faculty')} extra={errors.faculty}>
                <Select
                  placeholder={t('selectFaculty')}
                  options={facultiesOptions}
                  loading={loadingFaculties}
                  value={state.faculty}
                  onChange={(value) => onChange('faculty', value)}
                />
              </FormItem>

              <FormItem
                label={t('course')}
                extra={errors.course ? errors.course : !state.faculty ? t('selectFacultyError') : ''}
                extraStatus={errors.course ? 'error' : 'regular'}
              >
                <Select
                  placeholder={t('selectCourse')}
                  options={coursesOptions}
                  loading={loadingCourses}
                  value={state.course}
                  onChange={(value) => onChange('course', value)}
                  disabled={!state.faculty}
                />
              </FormItem>
            </FormItems>

            <hr className="hr-2" />

            <h2 className="medium mb-20">{t('deploymentPeriod')}</h2>

            <FormItems className="mx-w-660">
              <FormItem required label={t('startDate')} extra={errors.start_date}>
                <DateRangePicker
                  minDate={new Date()}
                  type="date"
                  onChange={({ date__gte: value }) => onChange('start_date', value)}
                />
              </FormItem>

              <FormItem required label={t('endDate')} extra={errors.end_date}>
                <DateRangePicker
                  minDate={(state.start_date && new Date(toISO(state.start_date as string, true))) || new Date()}
                  type="date"
                  onChange={({ date__gte: value }) => onChange('end_date', value)}
                />
              </FormItem>
            </FormItems>

            <hr className="hr-2" />

            <FormItem className="mx-w-660" label={t('language')} extra={errors.language}>
              <Select
                placeholder={t('selectLanguage')}
                loading={loadingActiveLanguages}
                options={languageOptions}
                value={state.language}
                onChange={(value): void => onChange('language', value)}
              />
            </FormItem>

            <h2 className="medium mb-20">{t('socialsNetworks')}</h2>

            <FormItems className="mx-w-660">
              <FormItem label="Facebook" extra={errors.facebook_url}>
                <Input value={state.facebook_url} onChange={(value): void => onChange('facebook_url', value)} />
              </FormItem>

              <FormItem label="Vkontakte" extra={errors.vkontakte_url}>
                <Input value={state.vkontakte_url} onChange={(value): void => onChange('vkontakte_url', value)} />
              </FormItem>
            </FormItems>

            <FormItems className="mx-w-660">
              <FormItem label="Odnoklassniki" extra={errors.odnoklasniki_url}>
                <Input value={state.odnoklasniki_url} onChange={(value): void => onChange('odnoklasniki_url', value)} />
              </FormItem>

              <FormItem label="Linkedin" extra={errors.linkedin_url}>
                <Input value={state.linkedin_url} onChange={(value): void => onChange('linkedin_url', value)} />
              </FormItem>
            </FormItems>

            <hr className="hr-2" />

            <FormItem required className="mx-w-660" label={t('othersInformation')} extra={errors.info}>
              <RichText initialValue={state.info as string} onChange={(value): void => onChange('info', value)} />
            </FormItem>

            <FormItem required extra={errors.is_hidden}>
              <Checkbox
                checked={Boolean(state.is_hidden || 0)}
                onChange={() => onChange('is_hidden', !state.is_hidden)}
                label={t('isHidden')}
              />
            </FormItem>
          </Fragment>
        </Form>
      </SessionsLayout>
    </>
  );
};
