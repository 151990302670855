import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useHistory } from 'estafette-router';
import { UserContext } from 'contexts';
import { Head, Layout, ProfileCard } from 'ui/organisms';

export const PersonalAccountPage: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { userData } = React.useContext(UserContext);

  const onEdit = (): void => push('EditPersonalAccountPage');

  return (
    <>
      <Head title={t('personalAccount')} />

      <Layout title={t('personalAccount')}>
        <ProfileCard data={userData} onEdit={onEdit} />
      </Layout>
    </>
  );
};
