import * as React from 'react';
import { useRequest } from 'estafette';
import { Link } from 'estafette-router';
import { useIntl } from 'estafette-intl';
import { dateFormat, format, toISO } from 'libs/date';
import { UserContext } from 'contexts';
import { sessions } from 'libs/http/api';
import { Session } from 'libs/http/api/sessions/sessions.types';
import { Column } from 'ui/atoms/Table/Table';
import { Info, Icon, Table, Put } from 'ui/atoms';
import { Actions } from 'ui/organisms';
import { Graduation } from 'libs/http/api/graduations/graduations.types';

interface Props {
  page: number | undefined;
  data: Graduation[];
  setData: React.Dispatch<any>;
  expandable: any;
  onManagementSession: (value: number) => void;
  onDeleteSession: (value: number) => void;
  onRefetch: () => void;
}

export const GraduationsTable: React.FC<Props> = ({
  page,
  data = [],
  setData,
  expandable,
  onManagementSession,
  onDeleteSession,
}) => {
  const { t } = useIntl();
  const { userData } = React.useContext(UserContext);

  const { request: requestSave, loading: loadingSave } = useRequest();

  const onSave = async ({ id, type, value }: any): Promise<void> => {
    const savedData: any = await requestSave(sessions.save.action({ [type]: toISO(value), id }));
    setData(({ results, ...rest }: any) => {
      const data = results.map((i: Session) => {
        if (i.id === savedData.id) {
          return savedData;
        }
        return i;
      });
      return { results: data, ...rest };
    });
  };

  const columns: Column<Graduation>[] = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: t('date'),
        render: ({ date }) => <Put>{format(date, dateFormat)}</Put>,
      },
      {
        title: `${t('country')}, ${t('city')}`,
        render: ({ country, city }) => (
          <Put>
            {country && country.title && (
              <Info
                className="nowrap"
                icon={<Icon type="pin" />}
                label={[country && country.title, city && city.title].filter((i) => i).join(', ')}
              />
            )}
          </Put>
        ),
      },
      {
        title: t('institution'),
        render: ({ institution }) => (
          <Put>
            {institution && (
              <Link route="GeneralInstitutePage" params={{ id: institution.id }}>
                {institution.name}
              </Link>
            )}
          </Put>
        ),
      },
      {
        title: t('studentsCount'),
        render: ({ students_count }) => <Put>{students_count}</Put>,
      },
      {
        action: true,
        render: (graduation) => (
          <div className="flex flex-justify-end flex-margin-between flex-nowrap">
            <Link className="nowrap" route="GeneralGraduationPage" params={{ id: graduation.id }}>
              {t('details')}
            </Link>

            {['admin'].includes(userData.role) && (
              <Actions
                onConfirmLabel={t('copyLink')}
                onAcceptLabel={t('forcePublish')}
                onEdit={
                  ['admin', 'coordinator'].includes(userData.role)
                    ? () => onManagementSession(graduation.id)
                    : undefined
                }
                onDelete={userData.role === 'admin' ? () => onDeleteSession(graduation.id) : undefined}
              />
            )}
          </div>
        ),
        className: 'nowrap',
      },
    ],
    [t, loadingSave, userData.role, onSave],
  );

  return <Table size="small" page={page} data={data} columns={columns} scroll={{ x: true }} expandable={expandable} />;
};
