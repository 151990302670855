import * as React from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import { useFilters } from 'hooks';
import { institutions, me, sessions } from 'libs/http/api';
import { Results } from 'libs/http/api/index.types';
import { groupBy } from 'libs/array/array';
import { getFullMonth, getYear, toISODate } from 'libs/date';
import { Announcement } from 'libs/http/api/announces/announces.types';
import { perPage } from 'libs/storage';
import { Announcements, CreateAnnouncement, InputSearch } from 'ui/organisms';
import { DateRangePicker } from 'ui/molecules';
import { Button, Card, CardFooter, CardHeader, Loader } from 'ui/atoms';

interface Props {
  id?: number | string;
  provider?: 'announcements' | 'session' | 'institute';
}

export const $AnnouncementsTemplate: React.FC<Props> = ({ id, provider = 'announcements' }) => {
  const { t } = useIntl();

  const { request, data, loading } = useRequest<Results<Announcement>>({ data: { results: [] } });
  const api = React.useMemo(() => (provider === 'session' ? sessions : provider === 'institute' ? institutions : me), [
    provider,
  ]);
  const initialFilters = React.useRef({
    filtered: false,
    page: 1,
    per_page: perPage,
    search: '',
    created_at__date__gte: '',
    created_at__date__lte: '',
  });

  const [filters, setFilters] = useFilters({ ...initialFilters.current });

  React.useEffect(() => {
    return () => {
      api.getAnnounces.cancel();
    };
  }, []);

  React.useEffect(() => {
    request(
      api.getAnnounces.action({
        id,
        ...filters,
        created_at__date__gte: filters.created_at__date__gte ? toISODate(filters.created_at__date__gte, true) : '',
        created_at__date__lte: filters.created_at__date__lte ? toISODate(filters.created_at__date__lte, true) : '',
      }),
    );
  }, [filters]);

  const onChangePeriod = (newValue: any): void =>
    setFilters({
      filtered: true,
      page: 1,
      ...(newValue.start_date__gte ? { created_at__date__gte: newValue.start_date__gte } : {}),
      ...(newValue.end_date__lte ? { created_at__date__lte: newValue.end_date__lte } : {}),
    });
  const onChangeFilters = React.useCallback(
    (target, value: any): void => setFilters({ filtered: true, page: 1, [target]: value }),
    [],
  );

  const onRefetch = (): void => setFilters({ ...initialFilters.current });
  const onChangePerPage = (perPage: number): void => setFilters({ per_page: perPage, page: 1 });

  const onIncreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page + 1 }));
  const onDecreasePage = (): void => setFilters((prevState) => ({ filtered: true, page: prevState.page - 1 }));

  const dataAnnounces = React.useMemo(
    () =>
      groupBy(
        'calendar',
        data.results.map((item) => ({
          ...item,
          calendar: `${getFullMonth(item.created_at)} ${getYear(item.created_at)}`,
        })),
      ),
    [data.results],
  );

  return (
    <Card>
      <CardHeader
        title={t('announcements')}
        count={data.count}
        leftSide={<InputSearch value={filters.search} onSearch={(newValue) => onChangeFilters('search', newValue)} />}
        rightSide={
          <>
            <div className="picker-wrapper">
              <div className="hideTablet">{t('period')}</div>
              <DateRangePicker
                from={filters.created_at__date__gte}
                to={filters.created_at__date__lte}
                type="period"
                onChange={onChangePeriod}
              />
            </div>

            <CreateAnnouncement provider={provider} id={id} onRefetch={onRefetch} />
          </>
        }
      />

      <Loader loading={loading}>
        <Announcements viewEmpty data={dataAnnounces} />

        <CardFooter
          onRefresh={onRefetch}
          onRefreshDisabled={!filters.filtered}
          page={filters.page}
          pages={data.total_pages}
          perPage={filters.per_page}
          onChangePerPage={onChangePerPage}
          rightSide={
            <>
              <Button onClick={onDecreasePage} disabled={filters.page === 1}>
                {t('previous')}
              </Button>

              <Button onClick={onIncreasePage} disabled={filters.page >= data.total_pages}>
                {t('next')}
              </Button>
            </>
          }
        />
      </Loader>
    </Card>
  );
};
