import * as React from 'react';
import { LoaderSpinner } from 'ui/atoms';

import './Info.scss';

interface Props {
  className?: string;
  inlineFlex?: boolean;
  url?: string;
  primary?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  label: React.ReactNode;
}

export const Info: React.FC<Props> = ({ className = '', inlineFlex, url, loading, icon, label, primary }) => (
  <div
    className={`
      zh-info zh-info-${icon ? 'icon' : 'no-icon'}
      ${loading ? ' zh-info-loading' : ''} 
      zh-info-text-${primary ? 'primary' : 'regular'}
      ${url ? 'pointer' : ''} ${inlineFlex ? 'inline-flex' : ''} ${className}`}
    onClick={() => (url ? window.open(url) : null)}
  >
    {icon && <div className="zh-info-icon">{!loading ? icon : <LoaderSpinner size="small" />}</div>}

    {label}
  </div>
);
